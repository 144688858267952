import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Status } from 'shared/types/status'
import { User } from 'shared/types/user'
import type { RootState } from '../store'

type authState = {
    user: User
    loginResponseErrorMessage: string
    loginStatus: Status
    forgotPasswordResponseErrorMessage: string
    forgotPasswordStatus: Status
    resetPasswordResponseErrorMessage: string
    resetPasswordStatus: Status
}

const initialState: authState = {
    user: {
        id: '',
        firstname: '',
        surname: '',
        email: '',
        role_id: {
            role_name: '',
            can_create: false,
            id: '',
        },
        token: '',
        enabled: false,
        last_login: ''
    },
    loginResponseErrorMessage: '',
    loginStatus: Status.idle,
    forgotPasswordResponseErrorMessage: '',
    forgotPasswordStatus: Status.idle,
    resetPasswordResponseErrorMessage: '',
    resetPasswordStatus: Status.idle,
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserInfo(state, action: PayloadAction<User>) {
            state.user = action.payload
        },
        removeUserInfo(state, action) {
            state.user = {
                id: '',
                firstname: '',
                surname: '',
                email: '',
                role_id: {
                    role_name: '',
                    can_create: false,
                    id: '',
                },
                token: '',
                enabled: false,
                last_login: ''
            }
        },
        setLoginResponseErrorMessage(state, action) {
            state.loginResponseErrorMessage = action.payload
        },
        setLoginStatus(state, action) {
            state.loginStatus = action.payload
        },
        setForgotPasswordResponseErrorMessage(state, action) {
            state.forgotPasswordResponseErrorMessage = action.payload
        },
        setForgotPasswordStatus(state, action) {
            state.forgotPasswordStatus = action.payload
        },
        setResetPasswordResponseErrorMessage(state, action) {
            state.resetPasswordResponseErrorMessage = action.payload
        },
        setResetPasswordStatus(state, action) {
            state.resetPasswordStatus = action.payload
        },
        /*         setFirstName ( state, action ) { },
                setLastName ( state, action ) { },
                setToken ( state, action ) { }, */
    },
})

export const {
    setUserInfo,
    removeUserInfo,
    setLoginResponseErrorMessage,
    setLoginStatus,
    setForgotPasswordResponseErrorMessage,
    setForgotPasswordStatus,
    setResetPasswordResponseErrorMessage,
    setResetPasswordStatus,
} = userSlice.actions

export const getUser = (state: RootState) => state.authorisation.user

export default userSlice.reducer
