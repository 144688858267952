import { Button, Typography } from '@material-ui/core'
import config from 'config'
import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'
import { uploadFile } from 'react-s3'
import { editDocument } from 'redux/slices/documents'
import { DocumentContext } from 'shared/components/DocumentEditor/DocumentEditor'
import { WageTypeCatalogue } from 'shared/components/DocumentEditor/WageTypeCatalogueContainer/WageTypeCatalogue/WageTypeCatalogue'
import * as reduxDocumentType from 'shared/types/document'
import { v4 as uuidv4 } from 'uuid'
import { uploadWageTypeCatalogue } from './WageTypeCatalogue/WageTypeUtils'

const S3_BUCKET = config.wageTypeBucket.S3_BUCKET_NAME
const REGION = config.wageTypeBucket.REGION
const ACCESS_KEY = config.keys.ACCESS_KEY
const SECRET_ACCESS_KEY = config.keys.SECRET_ACCESS_KEY

const awsConfig = {
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY
}

export const WageTypeCatalogueContainer: React.FC = () => {
  // const [extractedWorksheet, setExtractedWorksheet] = React.useState<
  //   reduxDocumentType.WageCatalogueSheet
  // >(null)
  // const [uploadedFile, setUploadedFile] = React.useState<File>(null)
  // const [wb, setWb] = React.useState<XLSX.WorkBook>(null)

  const context = useContext(DocumentContext)
  const { 
    activeDocument,
    extractedWorksheet, 
    setExtractedWorksheet,
    uploadedFile,
    setUploadedFile,
    wb,
    setWb
   } = context
  const dispatch = useDispatch()

  const saveWtcToDatabase = (
    uploadedFile: File,
    activeDocument: reduxDocumentType.Document
  ) => {
    let location: string

    // let tempUploadedFile = uploadedFile
    // tempUploadedFile.name = uuidv4()
    const tempUploadedFile = new File([uploadedFile], uuidv4())
    uploadFile(tempUploadedFile, awsConfig)
      .then(data => {
        location = data.location
        // let tmp: reduxDocumentType.DocumentChange = cloneDeep(activeDocumentChange);
        // tmp.wageDataFileLocation = location;
        // tmp.wageDataFileName = tempUploadedFile.name;

        let activeDocumentVersions = activeDocument.documentVersions
        let activeDocumentVersion =
          activeDocument.documentVersions[
            activeDocument.documentVersions.length - 1
          ]
        let activeDocumentChange =
          activeDocumentVersion.changes[
            activeDocumentVersion.changes.length - 1
          ]

        let newReduxObj: reduxDocumentType.DocumentChange = {
          ...activeDocumentChange,
          wageDataFileLocation: location,
          wageDataFileName: tempUploadedFile.name,
          wageDataFilters: activeDocumentChange.wageDataFilters,
          version: activeDocumentVersion.latestVersion + 1,
          changedBy: {
            firstname: sessionStorage.getItem('firstname'),
            id: sessionStorage.getItem('id'),
            surname: sessionStorage.getItem('surname')
          },
          id: null
        }

        if (
          activeDocumentVersion.changes[
            activeDocumentVersion.changes.length - 1
          ].id === null
        ) {
          activeDocumentVersion.changes[
            activeDocumentVersion.changes.length - 1
          ] = newReduxObj
        } else {
          activeDocumentVersion.changes.push(newReduxObj)
        }

        let newDocument: reduxDocumentType.Document = {
          ...activeDocument,
          documentVersions: activeDocumentVersions
        }
        console.log(newDocument)
        dispatch(editDocument(newDocument))
        // const editDocumentOnDBThunk = publishDocumentsToProjectsOnDB(docs);
        // dispatch(editDocumentOnDBThunk);
      })
      .catch(err => console.error(err))
  }

  React.useEffect(() => {
    if (uploadedFile !== null) {
      saveWtcToDatabase(uploadedFile, activeDocument)
    }
  }, [uploadedFile])

  // React.useEffect(() => {
  //   console.log('UPDATED SHEET')
  // }, [extractedWorksheet])


  return (
    <>
      {activeDocument.name === 'Wage Type Catalogue' &&
        extractedWorksheet === null && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              alignItems: 'center',
              flexGrow: 1
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                alignItems: 'center',
                flexGrow: 1
              }}
            >
              <div>
                <Typography>No file uploaded.</Typography>
              </div>
              <div>
                <input
                  accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                  id='contained-button-file'
                  style={{
                    display: 'none'
                  }}
                  type='file'
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    console.log('UPLOADING')
                    uploadWageTypeCatalogue(
                      event,
                      setUploadedFile,
                      setWb,
                      setExtractedWorksheet
                    )
                  }}
                />
                <label htmlFor='contained-button-file'>
                  <Button
                    variant='outlined'
                    color='primary'
                    component='span'
                    size='small'
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
          </div>
        )}
      <WageTypeCatalogue
        wb={wb}
        setExtractedWorksheet={setExtractedWorksheet}
        uploadedFile={uploadedFile}
        extractedWorksheet={extractedWorksheet}
      />
    </>
  )
}
