import axios from 'axios'
import { getRolesForProject } from 'redux/slices/projectRoles'
import { ProjectRole } from 'shared/types/project'
import config from '../../config'
import type { EPIDispatch, RootState } from '../store'

export type ResProjectRole = {
    role_name: string
    Description: string
    id: string  
}

export function fetchProjectRoles() {
    return async function fetchProjectRoles(dispatch: EPIDispatch, getState: RootState) {
        axios.get(`${config.apiGateway.URL}/project_roles/get`)
         .then(function (response) {
                const attributes = response.data.message as ResProjectRole[]
                let projects: ProjectRole[] = []
                attributes.map((res) => {
                    projects.push({
                        role_name: res.role_name,
                        description: res.Description,
                        id: res.id
                    })
                })
                dispatch({ type: getRolesForProject.type, payload: projects })
            })
            .catch(function (error) {
                /*dsda */
            })
    }
}

