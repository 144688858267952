import { makeStyles, Theme } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {},
  dialogTitle: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(6),
  },
  dialogContent: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  dialogContentText: {},
  form: {},
  dialogActions: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    paddingTop: theme.spacing(4),
  },
  button: {},
}));
