import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles, Theme } from '@material-ui/core/styles'
import * as EmailValidator from 'email-validator'
import React from 'react'
import { useDispatch } from 'react-redux'
import {
  editUserOnDB,
  fetchUserProjectsOnDB
} from 'redux/thunks/usersSliceThunk'
import { Role_Id, User } from 'shared/types/user'
import EditUserTabNav from './EditTabNavigation'

export type dialogProps = {
  isOpen: boolean
  setOpen: (value: boolean) => void
  user: User
}

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {},
  dialogTitle: { padding: theme.spacing(5, 5, 0, 5) },
  dialogContent: {
    /* overflow: 'hidden', */
  },
  dialogContentText: { padding: theme.spacing(0, 5, 5, 2) },
  dialogActions: { padding: theme.spacing(5, 6, 5, 5) },
  button: {
    paddingLeft: '2rem'
  }
}))

const EditUserInfoDialog: React.FC<dialogProps> = ({
  isOpen,
  setOpen,
  user
}: dialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [firstName, setFirstName] = React.useState<string | undefined>(
    user && user.firstname ? user.firstname : undefined
  )
  const [lastName, setLastName] = React.useState<string | undefined>(
    user && user.surname ? user.surname : undefined
  )
  const [emailInput, setEmailInput] = React.useState<string | undefined>(
    user && user.email ? user.email : undefined
  )
  const [roleInput, setRoleInput] = React.useState<Role_Id>(user.role_id)
  const [firstNameError, setFirstNameError] = React.useState<boolean>(false)
  const [surnameError, setSurnameError] = React.useState<boolean>(false)
  const [emailError, setEmailError] = React.useState<boolean>(false)
  const [firstNameHelperText, setFirstNameHelperText] = React.useState<string>(
    ''
  )
  const [surnameHelperText, setSurnameHelperText] = React.useState<string>('')
  const [emailHelperText, setEmailHelperText] = React.useState<string>('')

  // const userProjects: Project[] = useSelector(
  //   (state: RootState) => state.users.userProjects
  // );

  React.useEffect(() => {
    if (isOpen) {
      setFirstName(user && user.firstname ? user.firstname : undefined)
      setLastName(user && user.surname ? user.surname : undefined)
      setEmailInput(user && user.email ? user.email : undefined)
      setRoleInput(user && user.role_id ? user.role_id : undefined)
      const userProjectsThunk = fetchUserProjectsOnDB(user)
      dispatch(userProjectsThunk)
    }
  }, [isOpen])

  const handleClose = () => {
    setOpen(false)
  }

  const onSaveButtonClicked = () => {
    let valid = true

    if (firstName === '') {
      setFirstNameError(true)
      setFirstNameHelperText('First Name can not be empty')
      valid = false
    } else {
      setFirstNameError(false)
      setFirstNameHelperText('')
    }

    if (lastName === '') {
      setSurnameError(true)
      setSurnameHelperText('Last Name can not be empty')
      valid = false
    } else {
      setSurnameError(false)
      setSurnameHelperText('')
    }

    if (emailInput === '') {
      setEmailHelperText('Email can not be empty')
      setEmailError(true)
      valid = false
    } else {
      if (EmailValidator.validate(emailInput)) {
        setEmailHelperText('')
        setEmailError(false)
      } else {
        setEmailHelperText('Invalid email')
        setEmailError(true)
        valid = false
      }
    }

    if (valid) {
      user = {
        ...user,
        firstname: firstName,
        surname: lastName,
        email: emailInput,
        role_id: roleInput
      } as User

      const editUserOnDBThunk = editUserOnDB(user)
      dispatch(editUserOnDBThunk)
      handleClose()
    }
  }

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth='lg'
        aria-labelledby='form-dialog-title'
        className={classes.gridContainer}
        open={isOpen}
        /* onClose={handleClose} */
      >
        <DialogTitle className={classes.dialogTitle} id='form-dialog-title'>
          Edit user
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText className={classes.dialogContentText}>
            View/change user’s role, version, projects.
          </DialogContentText>
          <div>
            <div>
              <EditUserTabNav
                firstName={firstName}
                lastName={lastName}
                setFirstName={setFirstName}
                setLastName={setLastName}
                setEmailInput={setEmailInput}
                emailInput={emailInput}
                roleInput={roleInput}
                setRoleInput={setRoleInput}
                firstNameError={firstNameError}
                firstNameHelperText={firstNameHelperText}
                surnameError={surnameError}
                surnameHelperText={surnameHelperText}
                emailError={emailError}
                emailHelperText={emailHelperText}
                user={user}
              />
            </div>
            <div></div>
          </div>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button color='primary' onClick={handleClose}>
            Cancel
          </Button>
          <span className={classes.button} />
          <Button
            color='primary'
            variant='contained'
            onClick={onSaveButtonClicked}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default EditUserInfoDialog
