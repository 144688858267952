import CssBaseline from '@material-ui/core/CssBaseline'
import { Suspense } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import authInterceptors from 'shared/auth/authInterceptors'
import RestrictedRoute from 'shared/auth/RestrictedRoute'
import FooterNav from 'shared/components/MainNav/FooterNav'
import MainNav from 'shared/components/MainNav/MainNav'
import styled from 'styled-components'
import ForgotPassword from 'views/Pages/Auth/Login/ForgotPassword'
import Login from 'views/Pages/Auth/Login/Login'
import PasswordReset from 'views/Pages/Auth/Login/PasswordReset'
import Registration from 'views/Pages/Auth/Registration/Registration'
import RegistrationCompletion from 'views/Pages/Auth/Registration/RegistrationCompletion'
import Guide from 'views/Pages/Home/Guide'
import Displayer from 'views/Pages/Home/HomeContent/Home'
import { ProjectEnvironmentHome } from 'views/Pages/ProjectEnvironment/Home/Home'
import { TemplateEnvironmentHome } from 'views/Pages/TemplateEnvironment/Home/Home'
import Loader from './shared/components/Feedback/Loader'
import ForgotPasswordEmailSent from './views/Pages/Auth/Login/ForgotPasswordEmailSent'
import Copyright from './views/Pages/Home/Disclaimers/Copyright'
import Disclaimer from './views/Pages/Home/Disclaimers/Disclaimer'
import PrivacyPolicy from './views/Pages/Home/Disclaimers/PrivacyPolicy'
import TermsAndConditions from './views/Pages/Home/Disclaimers/TermsAndConditions'
import { DBConfig } from './DBConfig';
import { initDB } from 'react-indexed-db';

initDB(DBConfig);

function App() {
  const location = useLocation()
  const auth = authInterceptors()
  console.log(auth)

  return (
    <EPIContainer
      style={
        location.pathname === '/management' ||
        location.pathname === '/management/project_management' ||
        location.pathname === '/management/document_management' ||
        location.pathname === '/management/user_management' ||
        location.pathname.includes('/project/') ||
        location.pathname.includes('/vcs/document/') ||
        location.pathname.includes('template') ||
        location.pathname.includes('/changelog/document/') ||
        location.pathname.includes('/diff/document/') ||
        location.pathname.includes('/project_wizard/')
          ? { flexDirection: 'column', height: '100%' }
          : { flexDirection: 'column', height: '100%' }
      }
    >
      <CssBaseline />
      <Suspense fallback={<Loader open={true} />}>
        <MainNav />

        <Switch>
          <Route path='/' exact>
            <Displayer />
          </Route>
          <Route path='/signin' exact>
            <Login />
          </Route>
          <Route path='/forgotPass' exact>
            <ForgotPassword />
          </Route>
          <Route path='/forgotPassEmailSent'>
            <ForgotPasswordEmailSent />
          </Route>
          <Route path='/resetPassword'>
            <PasswordReset />
          </Route>
          <Route path='/register' exact>
            <Registration />
          </Route>
          <Route path='/completion' exact>
            <RegistrationCompletion />
          </Route>
          <RestrictedRoute path='/guide' component={Guide} exact />
          <Route path='/privacy_policy'>
            <PrivacyPolicy />
          </Route>
          <Route path='/terms_and_conditions'>
            <TermsAndConditions />
          </Route>
          <Route path='/disclaimer'>
            <Disclaimer />
          </Route>
          <Route path='/copyright'>
            <Copyright />
          </Route>
          <RestrictedRoute
            path='/projectEnvironment'
            component={() => <ProjectEnvironmentHome />}
            exact={false}
          />
          <RestrictedRoute
            path='/templateEnvironment'
            component={() => <TemplateEnvironmentHome />}
            exact={false}
          />
        </Switch>
        {!location.pathname.includes('management') &&
          !location.pathname.includes('/management/user_management') &&
          !location.pathname.includes('/management/project_management') &&
          !location.pathname.includes('/management/document_management') &&
          !location.pathname.includes('project') &&
          !location.pathname.includes('/vcs/document/') &&
          !location.pathname.includes('template') &&
          !location.pathname.includes('/changelog/document/') &&
          !location.pathname.includes('/diff/document/') && <FooterNav />}
      </Suspense>
    </EPIContainer>
  )
}

export default App

const EPIContainer = styled.div`
  display: flex;
  overflow-x: hidden;

  height: 100vh;
`

// const EPICenterProgressBarDiv = styled.div`
//   min-height: 5px;
//   width: 100%auto;
//   height: 100%auto;
//   display: flex;
//   flex: 1;
//   align-items: flex-start;
//   justify-content: flex-start;
// `;
