import userSliceReducer from './slices/authorisation'
import usersSliceReducer from './slices/usersSlice'
import registrationReducer from './slices/registration'
import projectsReducer from './slices/projects'
import documentsReducer from './slices/documents'
import projectRolesReducer from './slices/projectRoles'
import { combineReducers } from '@reduxjs/toolkit'

const RootReducer = combineReducers( {
    authorisation: userSliceReducer,
    users: usersSliceReducer,
    registration: registrationReducer,
    projects: projectsReducer,
    documents: documentsReducer,
    projectRoles: projectRolesReducer,
} )

export default RootReducer
