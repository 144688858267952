import { Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import RefreshIcon from '@material-ui/icons/Refresh'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { fetchProjects } from 'redux/thunks/projectsThunk'
import TableSelectorGroup from './ProjectsTableSelectorGroup'
import CreateProjectDialog from '../Dialogs/CreateProjectDialog'

const useStyles = makeStyles((theme: Theme) => ({
  titleDiv: {
    display: 'grid',
    gridTemplateColumns: '2fr  1fr',
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column'
    }
  },
  left: {
    gridRows: '2/3'
  },
  right: {
    gridRows: '2/4',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'flex-start'
    }
  },
  formSpan: {
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(4),
    flex: '1 1 15px',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      flex: '1 1 25ch'
    }
  },
  icon1: { marginRight: '8px !important' },
  button: {}
}))
const TableHeader: React.FC<{
  version: string
  setVersion: (version: string) => void
  role: string
  setRole: (role: string) => void
  search: string
  setSearch: (search: string) => void
  setIsNewProject: (b: boolean) => void
}> = ({
  version,
  setVersion,
  role,
  setRole,
  search,
  setSearch,
  setIsNewProject
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()

  return (
    <div>
      <div
        style={{
          flexGrow: 1
        }}
      >
        <Grid container spacing={1} justify='space-between' alignItems='center'>
          <Grid item xs>
            <Typography variant='h5' color='textPrimary' component='h1'>
              Project Management
            </Typography>
            <Typography variant='body2' color='textSecondary' component='p'>
              Create, edit or remove projects of EPI-CENTRE
            </Typography>
          </Grid>
          <Grid container xs>
            <TableSelectorGroup
              version={version}
              setVersion={setVersion}
              role={role}
              setRole={setRole}
              search={search}
              setSearch={setSearch}
            />
          </Grid>
          <Grid container xs alignItems='center' justify='flex-end'>
            <Button
              className={classes.button}
              style={{
                backgroundColor: '#2c8cd8',
                color: 'white'
              }}
              startIcon={<RefreshIcon className={classes.icon1} />}
              variant='contained'
              onClick={() => {
                const fetchProjectsThunk = fetchProjects()
                dispatch(fetchProjectsThunk)
              }}
            >
              Refresh
            </Button>

            <Button
              style={{
                marginLeft: '0.25em'
              }}
              className={classes.button}
              color='primary'
              startIcon={<AddIcon className={classes.icon1} />}
              variant='contained'
              onClick={() => setOpen(true)}
            >
              Create project
            </Button>
          </Grid>
        </Grid>
      </div>

      <CreateProjectDialog
        isOpen={open}
        setOpen={setOpen}
        setIsNewProject={setIsNewProject}
      />
    </div>
  )
}

export default TableHeader
