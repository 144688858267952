import { useHistory, useLocation } from 'react-router-dom'
import { ProjectDocumentEditor } from 'views/Pages/ProjectEnvironment/Home/DocumentHome/Content/ProjectDocumentEditor'
import DocumentInformation from 'views/Pages/ProjectEnvironment/Home/DocumentHome/Information/DocumentInformation'
import Sections from 'views/Pages/ProjectEnvironment/Home/DocumentHome/Sections/Sections'
import Versions from 'views/Pages/ProjectEnvironment/Home/DocumentHome/Versions/Versions'

export const DocumentHomeContent: React.FC = () => {
  function useQuery () {
    return new URLSearchParams(useLocation().search)
  }

  let query = useQuery()
  const section = query.get('section')
  const history = useHistory()

  if (history.location.pathname.includes('/document/')) {
    return (
      <>
        {section === 'Content' && <ProjectDocumentEditor />}
        {section === 'General' && <DocumentInformation />}
        {section === 'Versions' && <Versions />}
        {section === 'Sections' && <Sections />}
      </>
    )
  }

  return null
}
