import { ContentInfo } from "shared/types/introduction";
import { Role } from "shared/types/roles";

export type NavigationData = {
  link?: string;
  role?: Role;
} & ContentInfo;

export const featureData: Array<ContentInfo> = [
  {
    content: {
      title: "Feature 1",
      body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
  },
  {
    content: {
      title: "Feature 2",
      body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
  },
  {
    content: {
      title: "Feature 3",
      body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
  },
  {
    content: {
      title: "Feature 4",
      body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
  },
];

const manageUsers: NavigationData = {
  content: {
    title: "Manage users",
    body: "Create, view, edit, remove users of the system. ",
  },
  link: "/management",
  role: Role.administrator,
};

const manageProjects: NavigationData = {
  content: {
    title: "Manage projects",
    body: "Create, view, edit, remove users of the system. ",
  },
  link: "/management",
  role: Role.administrator,
};

const manageGlobalDocs: NavigationData = {
  content: {
    title: "Manage global documents",
    body: "Create, view, edit, remove global documents. ",
  },
  link: "/manage-global-docs",
  role: Role.administrator,
};

const manageCountries: NavigationData = {
  content: {
    title: "Manage countries",
    body: "Add or remove countries. ",
  },
  link: "/manage-countries",
  role: Role.administrator,
};

export const navData: Array<NavigationData> = [
  manageUsers,
  manageProjects,
  manageGlobalDocs,
  manageCountries,
];
