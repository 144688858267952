// declare global {
//     var AWS_URL: string
// }

// globalThis.AWS_URL = 'https://m40y0oitb2.execute-api.eu-west-1.amazonaws.com/prod'

// /* globalThis.AWS_URL = 'https://my-json-server.typicode.com/typicode/demo/db' */

// export {}

//'https://my-json-server.typicode.com/typicode/demo/db'

const dev = {
    apiGateway: {
        URL: 'https://dev-api.epi-centre.eu',
    },
    cognito: {
        COGNITO_URL: '',
        REGION: 'YOUR_DEV_COGNITO_REGION',
        USER_POOL_ID: 'YOUR_DEV_COGNITO_USER_POOL_ID',
        COGNITO_CLIENT_ID: 'YOUR_DEV_COGNITO_APP_CLIENT_ID',
        IDENTITY_POOL_ID: 'YOUR_DEV_IDENTITY_POOL_ID',
        COGNITO_REDIRECT_URL: '',
        COGNITO_SCOPE: '',
    },
    wageTypeBucket: {
        S3_BUCKET_NAME: 'epi-centre-develop-frontend-wage-type-bucket',
        REGION: 'eu-west-1'
    },
    keys: {
        ACCESS_KEY: process.env.REACT_APP_AWS_ACCESS_KEY,
        SECRET_ACCESS_KEY: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
    }
}

const staging = {
    apiGateway: {
        URL: 'https://staging-api.epi-centre.eu',
    },
    cognito: {
        COGNITO_URL: '',
        REGION: 'YOUR_STAGING_COGNITO_REGION',
        USER_POOL_ID: 'YOUR_STAGING_COGNITO_USER_POOL_ID',
        COGNITO_CLIENT_ID: 'YOUR_STAGING_COGNITO_APP_CLIENT_ID',
        IDENTITY_POOL_ID: 'YOUR_STAGING_IDENTITY_POOL_ID',
        COGNITO_REDIRECT_URL: '',
        COGNITO_SCOPE: '',
    },
    wageTypeBucket: {
        S3_BUCKET_NAME: 'epi-centre-develop-frontend-wage-type-bucket',
        REGION: 'eu-west-1'
    },
    keys: {
        ACCESS_KEY: process.env.REACT_APP_AWS_ACCESS_KEY,
        SECRET_ACCESS_KEY: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
    }
}

const prod = {
    apiGateway: {
        URL: 'https://api.epi-centre.eu',
    },
    cognito: {
        COGNITO_URL: '',
        REGION: 'YOUR_PROD_COGNITO_REGION',
        USER_POOL_ID: 'YOUR_PROD_COGNITO_USER_POOL_ID',
        COGNITO_CLIENT_ID: 'YOUR_PROD_COGNITO_APP_CLIENT_ID',
        IDENTITY_POOL_ID: 'YOUR_PROD_IDENTITY_POOL_ID',
        COGNITO_REDIRECT_URL: '',
        COGNITO_SCOPE: '',
    },
    wageTypeBucket: {
        S3_BUCKET_NAME: 'epi-centre-develop-frontend-wage-type-bucket',
        REGION: 'eu-west-1'
    },
    keys: {
        ACCESS_KEY: process.env.REACT_APP_AWS_ACCESS_KEY,
        SECRET_ACCESS_KEY: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
    }
}

const config =
    process.env.REACT_APP_ENVIRONMENT === 'prod' ? prod : process.env.REACT_APP_ENVIRONMENT === 'staging' ? staging : dev

export default {
    // Add common config values here
    ...config,
}
