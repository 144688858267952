import { useHistory, useLocation } from 'react-router-dom'
import { MyProjects } from 'views/Pages/ProjectEnvironment/Home/MyProjects/MyProjects'
import ProjectHome from 'views/Pages/ProjectEnvironment/Home/ProjectHome/ProjectHome'
import ManageCountries from 'views/Pages/ProjectEnvironment/Home/ProjectHome/ManageCountries/ManageCountries'
import UserManagement from 'views/Pages/ProjectEnvironment/Home/ProjectHome/Users/UserManagement'

export const ProjectHomeContent: React.FC = () => {
  function useQuery () {
    return new URLSearchParams(useLocation().search)
  }

  let query = useQuery()
  const section = query.get('section')
  const history = useHistory()

  if (
    history.location.pathname.includes('/project/') &&
    !history.location.pathname.includes('/document/')
  ) {
    return (
      <>
        {section === undefined || (section === null && <MyProjects />)}
        {section === 'Home' && <ProjectHome />}
        {section === 'Countries' && <ManageCountries />}
        {section === 'Users' && <UserManagement />}
      </>
    )
  }
  return null
}
