import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel
} from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import React, { useContext } from 'react'
import { DocumentContext } from 'shared/components/DocumentEditor/DocumentEditor'
import { findDFSByTreeIndexString } from 'shared/components/DocumentEditor/treeUtils'
import * as reduxDocumentType from '../../../../../types/document'
import IndexedDBFunctions from "../../../../../../IndexedDBFunctions";

export const RenameChapterDialog: React.FC<{
  isOpen: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  chapterName: string
}> = ({ isOpen, setOpen, chapterName }) => {
  const context = useContext(DocumentContext)
  const {
    activeChapter,
    documentChapters,
    setDocumentChapters,
    activeCountryIndex,
    activeDocumentChange,
    activeDocumentVersion,
    activeDocument,
    setActiveDocument,
    activeDocumentVersions,
    documents,
    setActiveDocumentChange,
    activeCountry,
    setNumberOfEdits
  } = context

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActiveChapterName(event.target.value)
  }

  const handleChangeSubchapter = event => {
    setSubchapterCreateAllowed(event.target.checked)
  }

  const [activeChapterName, setActiveChapterName] = React.useState(chapterName)
  const [subchapterCreateAllowed, setSubchapterCreateAllowed] = React.useState(
    activeChapter.subchapterCreateAllowed
  )


  const changeActiveChapterName = () => {
    if (activeCountryIndex === null) {
      const copiedDocumentChapters = JSON.parse(
        JSON.stringify(documentChapters)
      )
      let chapterInDocument = findDFSByTreeIndexString(
        copiedDocumentChapters,
        activeChapter.treeIndexString
      )

      let newReduxObj: reduxDocumentType.DocumentChange = {
        ...activeDocumentChange,
        chapters: documentChapters,
        id: null,
        lastUpdated: new Date().toString(),
        changedBy: {
          firstname: sessionStorage.getItem('firstname'),
          id: sessionStorage.getItem('id'),
          surname: sessionStorage.getItem('surname')
        },
        globalTreeIndexCounter: activeDocumentChange.globalTreeIndexCounter + 1
      }

      let activeDocDocumentChanges = activeDocumentVersion.changes
      activeDocumentVersion.lastUpdated = new Date().toString()

      if (
        activeDocDocumentChanges[activeDocDocumentChanges.length - 1].id ===
        null
      ) {
        activeDocDocumentChanges[
          activeDocDocumentChanges.length - 1
        ] = newReduxObj
      } else {
        activeDocDocumentChanges.push(newReduxObj)
      }

      let newDocument: reduxDocumentType.Document = {
        ...activeDocument,
        documentVersions: activeDocumentVersions,
        lastUpdated: new Date().toString()
      }

      const newActiveChapter = JSON.parse(JSON.stringify(activeChapter))
      newActiveChapter.name = activeChapterName
      newActiveChapter.subchapterCreateAllowed = subchapterCreateAllowed
      chapterInDocument.name = activeChapterName
      chapterInDocument.subchapterCreateAllowed = subchapterCreateAllowed
      Object.assign(activeChapter, newActiveChapter)
      Object.assign(chapterInDocument, newActiveChapter)
      setDocumentChapters(copiedDocumentChapters)

      Object.assign(activeDocument, newDocument)
      setActiveDocument(JSON.parse(JSON.stringify(activeDocument)))
      setActiveDocumentChange(newReduxObj)
      //localStorage.setItem('currentDocumentEdit', JSON.stringify(documents))

      IndexedDBFunctions.deleteDocuments()
      IndexedDBFunctions.addDocuments(documents)
      //This is just a work around as we encountered issues with Local Storage
      setNumberOfEdits(Math.random() * 1000)
      localStorage.setItem('ContentIsDirty', 'true')

      setOpen(false)
    } else {
      const copiedDocumentChapters = JSON.parse(
        JSON.stringify(documentChapters)
      )
      let chapterInDocument = findDFSByTreeIndexString(
        copiedDocumentChapters,
        activeChapter.treeIndexString
      )

      let newReduxObj: reduxDocumentType.DocumentChange = {
        ...activeDocumentChange,
        chapters: documentChapters,
        id: null,
        lastUpdated: new Date().toString(),
        changedBy: {
          firstname: sessionStorage.getItem('firstname'),
          id: sessionStorage.getItem('id'),
          surname: sessionStorage.getItem('surname')
        },
        globalTreeIndexCounter: activeDocumentChange.globalTreeIndexCounter + 1
      }

      let activeDocDocumentChanges = activeDocumentVersion.changes
      activeDocumentVersion.lastUpdated = new Date().toString()

      if (
        activeDocDocumentChanges[activeDocDocumentChanges.length - 1].id ===
        null
      ) {
        activeDocDocumentChanges[
          activeDocDocumentChanges.length - 1
        ] = newReduxObj
      } else {
        activeDocDocumentChanges.push(newReduxObj)
      }

      let newDocument: reduxDocumentType.Document = {
        ...activeDocument,
        documentVersions: activeDocumentVersions,
        lastUpdated: new Date().toString()
      }

      const newActiveChapter = JSON.parse(JSON.stringify(activeChapter))
      newActiveChapter.subchapterCreateAllowed = subchapterCreateAllowed

      newActiveChapter.sections.map(section => {
        section.countrySpecificContent[
          activeCountryIndex
        ].chapterCountrySpecificName = activeChapterName
      })
      chapterInDocument.sections.map(section => {
        section.countrySpecificContent[
          activeCountryIndex
        ].chapterCountrySpecificName = activeChapterName
      })
      Object.assign(activeChapter, newActiveChapter)
      Object.assign(chapterInDocument, newActiveChapter)
      setDocumentChapters(copiedDocumentChapters)

      Object.assign(activeDocument, newDocument)
      setActiveDocument(JSON.parse(JSON.stringify(activeDocument)))
      setActiveDocumentChange(newReduxObj)
      //localStorage.setItem('currentDocumentEdit', JSON.stringify(documents))

      IndexedDBFunctions.deleteDocuments()
      IndexedDBFunctions.addDocuments(documents)
      //This is just a work around as we encountered issues with Local Storage
      setNumberOfEdits(Math.random() * 1000)
      localStorage.setItem('ContentIsDirty', 'true')

      setOpen(false)
    }
  }

  React.useEffect(() => {
    setActiveChapterName(chapterName)
  }, [chapterName])

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      fullWidth
      maxWidth='xs'
      aria-labelledby='confirmation-dialog-title'
      open={isOpen}
    >
      <DialogTitle id='confirmation-dialog-title'>Chapter Settings</DialogTitle>
      <DialogContent dividers>
        <TextField
          autoFocus
          value={activeChapterName}
          id='name'
          label='Chapter name'
          type='text'
          fullWidth
          onChange={handleChange}
        />
        <br />
        <br />
        <br />
        {
          activeCountry.country_name === undefined && <>
          <FormControl component='fieldset'>
          <FormLabel component='legend'>Subchapter Settings</FormLabel>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={subchapterCreateAllowed}
                  onChange={handleChangeSubchapter}
                  name='changeSubchapterCreate'
                />
              }
              label='Subchapter Create Allowed'
            />
          </FormGroup>
        </FormControl>
          </>
        }

      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={() => {
            setOpen(false)
          }}
          color='primary'
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            changeActiveChapterName()
          }}
          color='primary'
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
