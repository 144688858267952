import { createStyles, makeStyles, Paper } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListSubheader from '@material-ui/core/ListSubheader'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { Theme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Delete from '@material-ui/icons/Delete'
import SaveIcon from '@material-ui/icons/Save'
import { cloneDeep } from 'lodash'
import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setUpdateProjectStatus } from 'redux/slices/projects'
import { RootState } from 'redux/store'
import { editProjectOnDB } from 'redux/thunks/projectsThunk'
import { DocumentContext } from 'shared/components/DocumentEditor/DocumentEditor'
import { copyDFSForProjectOnlySections } from 'shared/components/DocumentEditor/treeUtils'
import { SnackType } from 'shared/components/Feedback/EPISnackBar'
import Loader from 'shared/components/Feedback/Loader'
import { ChapterSection } from 'shared/types/document'
import { Status } from 'shared/types/status'
import IndexedDBFunctions from "../../../../../../IndexedDBFunctions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      listStyle: 'none',
      '& > *': {
        margin: theme.spacing(0.5),
      },
      padding: theme.spacing(1, 1),
    },
    list: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      flexWrap: 'wrap',
      listStyle: 'none',
      '& > *': {
        margin: theme.spacing(0.5),
      },
      padding: theme.spacing(1, 1),
    },
    chip: {
      margin: theme.spacing(0.5),
      padding: theme.spacing(1, 2, 1, 2),
    },
  })
)

const Sections = () => {
  const context = useContext(DocumentContext)
  const {
    activeDocumentVersion,
    activeDocumentChange,
    documents,
    setActiveDocumentChange,
    project,
    setProject,
    setSnackMessage,
    setSnackSeverity,
    setDisplaySnack,
    setContentIsDirty,
    setActiveCountriesOnProject,
    setNumberOfEdits
  } = context
  const [sections, setSections] = React.useState([] as ChapterSection[])
  const [editability, setEditability] = React.useState([])
  const [busy, setBusy] = React.useState(false)
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (activeDocumentVersion.changes !== undefined) {
      setSections(
        activeDocumentVersion.changes[activeDocumentVersion.changes.length - 1]
          .sections
      )
    }
  }, [activeDocumentVersion])

  const updateProjectStatus = useSelector(
    (state: RootState) => state.projects.updateProjectStatus
  )

  React.useEffect(() => {
    if(project && project.countries.find((country) => country.active_status) !== undefined) {
      setActiveCountriesOnProject(true)
    }
  })

  useEffect(() => {
    if (updateProjectStatus === Status.success) {
      setSnackMessage('Sections updated successfully')
      setSnackSeverity(SnackType.SnackSuccess as string)
      setDisplaySnack(true)
      setBusy(false)
      dispatch({ type: setUpdateProjectStatus.type, payload: Status.idle })
    } else if (updateProjectStatus === Status.failed) {
      // setIsUpdateSuccess(false);
      // setIsUpdateError(true);
      // dispatch({ type: setUpdateProjectStatus.type, payload: Status.idle })
      dispatch({ type: setUpdateProjectStatus.type, payload: Status.idle })
      setBusy(false)
    }
  }, [updateProjectStatus])

  const classes = useStyles()

  const handleChange = (event, index) => {
    const newEditability = editability
    newEditability[index] = event.target.value
    setEditability(newEditability)
    let newSections: Array<ChapterSection> = sections?.map((x) =>
      Object.assign({}, x)
    )
    const sectionNew = newSections[index]

    if (editability[index] === '0') {
      sectionNew.isGlobal = true
      sectionNew.isClientEditable = false
    } else if (editability[index] === '1') {
      sectionNew.isClientEditable = true
      sectionNew.isGlobal = false
    } else if (editability[index] === '3') {
      sectionNew.isClientEditable = false
      sectionNew.isGlobal = false
    }
    setSections(newSections)
  }

  const updateSections = () => {
    let tempActiveDocumentChange = cloneDeep(activeDocumentChange)
    tempActiveDocumentChange.sections = sections
    tempActiveDocumentChange.lastUpdated = new Date().toString()
    tempActiveDocumentChange.changedBy = {
      firstname: sessionStorage.getItem('firstname'),
      id: sessionStorage.getItem('id'),
      surname: sessionStorage.getItem('surname'),
    }

    //TODO: split functionality in this for projects so that sections are only added to project-only chapters
    copyDFSForProjectOnlySections(
      tempActiveDocumentChange,
      tempActiveDocumentChange
    )

    tempActiveDocumentChange.id = null

    Object.assign(activeDocumentChange, tempActiveDocumentChange)
    console.log('UPDATED SECTIONS', tempActiveDocumentChange)
    setActiveDocumentChange(tempActiveDocumentChange)
    //localStorage.setItem('currentDocumentEdit', JSON.stringify(documents))

    IndexedDBFunctions.deleteDocuments()
    IndexedDBFunctions.addDocuments(documents)
    //This is just a work around as we encountered issues with Local Storage
    setNumberOfEdits(Math.random() * 1000)

    project.documents = documents
    setProject(project)

    const editThunk = editProjectOnDB(project)
    dispatch(editThunk)
    localStorage.setItem('ContentIsDirty', 'false')
    setContentIsDirty(false)
    setBusy(true)
  }

  if (sections.length > 0) {
    return (
      <>
        {busy === true && <Loader open={true} />}
        <div
          style={{
            padding: '1em',
          }}
        >
          <Paper style={{ padding: '1em' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <h3
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                Document Sections (Project Specific Chapters only)
              </h3>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Button
                  variant={'outlined'}
                  style={{
                    width: '100%',
                  }}
                  onClick={() => {
                    let newSections: Array<ChapterSection> = sections.map((x) =>
                      Object.assign({}, x)
                    )

                    const newSection: ChapterSection = {
                      name: 'New Section',
                      isGlobal: false,
                      isClientEditable: false,
                      chapterGlobalContent: {
                        rawHTML: '',
                      },
                      countrySpecificContent: [],
                      viewing: false,
                      wasChanged: true,
                    }
                    newSections.push(newSection)
                    setSections(newSections)
                  }}
                  disabled={busy}
                >
                  + Add Section
                </Button>
                <Button
                  style={{
                    minWidth: 100,
                    marginLeft: '0.5em',
                  }}
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    updateSections()
                  }}
                  disabled={busy}
                >
                  <SaveIcon />
                  &nbsp;Save
                </Button>
              </div>
            </div>
          </Paper>
          <br />
          <Paper
            style={{
              padding: '1em',
            }}
          >
            <div className={classes.list}>
              <List
                subheader={
                  <ListSubheader component='div' id='nested-list-subheader'>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div style={{ width: '40%' }}>Section Name</div>
                      <div style={{ width: '20%', textAlign: 'center' }}>
                        Global
                      </div>
                      <div style={{ width: '20%', textAlign: 'center' }}>
                        Country
                      </div>
                      <div style={{ width: '20%', textAlign: 'center' }}>
                        Client-Editable
                      </div>
                      <div style={{ width: '20%', textAlign: 'center' }}>
                        Delete
                      </div>
                    </div>
                  </ListSubheader>
                }
                dense={true}
              >
                {sections.map((section, index) => {
                  let sectionEditability = editability[index]

                  if (
                    sectionEditability === null ||
                    sectionEditability === undefined
                  ) {
                    const newEditability = editability

                    if (section.isGlobal === true) {
                      newEditability.push('0')
                    } else if (section.isClientEditable === true) {
                      newEditability.push('1')
                    } else {
                      newEditability.push('3')
                    }
                    setEditability(newEditability)
                  }
                  sectionEditability = editability[index]

                  return (
                    <ListItem>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '100%',
                        }}
                      >
                        <div style={{ width: '40%' }}>
                          <TextField
                            fullWidth
                            value={`${section.name}`}
                            id='standard-basic'
                            label=''
                            onChange={(newValue: any) => {
                              let newSections: Array<ChapterSection> =
                                sections.map((x) => Object.assign({}, x))
                              const sectionNew = newSections[index]
                              sectionNew.name = newValue.target.value
                              setSections(newSections)
                            }}
                          />
                        </div>
                        <RadioGroup
                          style={{
                            width: '60%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                          aria-label='gender'
                          name='gender1'
                          value={sectionEditability}
                          onChange={(e) => {
                            handleChange(e, index)
                          }}
                        >
                          <div
                            style={{
                              width: '33.33%',
                              justifyContent: 'center',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <FormControlLabel
                              value='0'
                              control={<Radio />}
                              label=''
                              labelPlacement='bottom'
                            />
                          </div>
                          <div
                            style={{
                              width: '33.33%',
                              justifyContent: 'center',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <FormControlLabel
                              value='3'
                              control={<Radio />}
                              label=''
                              labelPlacement='bottom'
                            />
                          </div>
                          <div
                            style={{
                              width: '33.33%',
                              justifyContent: 'center',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <FormControlLabel
                              value='1'
                              control={<Radio />}
                              label=''
                              labelPlacement='bottom'
                            />
                          </div>
                        </RadioGroup>
                        <div
                          style={{
                            width: '20%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <IconButton
                            aria-label='delete'
                            onClick={() => {
                              let newSections: Array<ChapterSection> =
                                sections.map((x) => Object.assign({}, x))
                              newSections.splice(index, 1)
                              setSections(newSections)
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </div>
                      </div>
                    </ListItem>
                  )
                })}
              </List>
            </div>
          </Paper>
        </div>
      </>
    )
  }

  return null
}

export default Sections
