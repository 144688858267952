import {
  Divider,
  FormControlLabel,
  Switch,
  Typography,
} from '@material-ui/core'
import { Editor } from '@tinymce/tinymce-react'
import React, { useContext, useEffect } from 'react'
import ReactDiffViewer from 'react-diff-viewer'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import { DocumentContext } from 'shared/components/DocumentEditor/DocumentEditor'
import {
  ChapterObject,
  ChapterSection,
  Document,
  DocumentChange,
  DocumentVersion,
} from 'shared/types/document'
import {findDFSBySharedId} from '../../treeUtils'

export const SyncGlobalContentComparison: React.FC = () => {
  const context = useContext(DocumentContext)
  const { activeChapter, activeDocument, activeCountry } = context
  const templateDocuments = useSelector(
    (state: RootState) => state.documents.Documents
  )
  const [templateDocument, setTemplateDocument] =
    React.useState<Document>(undefined)
  const [templateDocumentVersion, setTemplateDocumentVersion] =
    React.useState<DocumentVersion>(undefined)
  const [templateDocumentChange, setTemplateDocumentChange] =
    React.useState<DocumentChange>(undefined)
  const [templateChapter, setTemplateChapter] =
    React.useState<ChapterObject>(undefined)

  useEffect(() => {
    if (templateDocuments && templateDocuments.length > 0) {
      setTemplateDocument(
        templateDocuments.find((tempDoc) => tempDoc.id === activeDocument.refId)
      )
      let tempDoc = templateDocuments.find(
        (tempDoc) => tempDoc.id === activeDocument.refId
      )

      if (typeof tempDoc.documentVersions[0] !== 'string') {
        setTemplateDocumentVersion(
          tempDoc.documentVersions[tempDoc.documentVersions.length - 1]
        )

        if (templateDocumentVersion !== undefined) {
          setTemplateDocumentChange(
            templateDocumentVersion.changes[
              templateDocumentVersion.changes.length - 1
            ]
          )
          let temp = findDFSBySharedId(
            templateDocumentVersion.changes[
              templateDocumentVersion.changes.length - 1
            ].chapters,
            activeChapter.sharedId
          )
          console.log('FINDING BY STRING', temp)
          setTemplateChapter(
            findDFSBySharedId(
              templateDocumentVersion.changes[
                templateDocumentVersion.changes.length - 1
              ].chapters,
              activeChapter.sharedId
            )
          )
        }
      }
    }
  }, [
    templateDocuments,
    templateDocument,
    templateDocumentVersion,
    templateDocumentChange,
  ])

  const [diffControlToggle, setDiffControlToggle] =
    React.useState<boolean>(false)

  return (
    <>
      <div>
        <h3 style={{ flexGrow: 1, alignItems: 'center', display: 'flex' }}>
          Syncing: {activeDocument.name} - {activeChapter.name} Global Content
          with Template
        </h3>
        <FormControlLabel
          control={
            <Switch
              checked={diffControlToggle}
              onChange={() => setDiffControlToggle(!diffControlToggle)}
              name='checkedB'
              color='primary'
            />
          }
          label='View in Editor'
        />
      </div>

      {templateDocumentChange !== undefined &&
        templateChapter.sections &&
        activeDocument.name !== 'Wage Type Catalogue' &&
        templateChapter.sections
          .filter((section) => section.isGlobal)
          .map((chapterSection: ChapterSection, index) => {
            let oldContent = ''
            let newContent = ''

            if (chapterSection.isGlobal) {
              newContent = activeCountry.country_name && chapterSection.countrySpecificContent.find(content =>
                content.country.country_name === activeCountry.country_name).globalContent
                ?chapterSection.countrySpecificContent.find(content =>
                  content.country.country_name === activeCountry.country_name).globalContent.rawHTML
                :chapterSection.chapterGlobalContent.rawHTML
            } else if (chapterSection.isClientEditable) {
              if (activeCountry !== null) {
                newContent =
                  chapterSection.countrySpecificContent.find(content =>
                    content.country.country_name === activeCountry.country_name)
                    .clientContent.rawHTML
              }
            } else {
              newContent =
                chapterSection.countrySpecificContent.find(content =>
                  content.country.country_name === activeCountry.country_name)
                  .nonClientContent.rawHTML
            }

            if (activeChapter) {
              let prevSection = activeChapter.sections.filter((section) => section.isGlobal)[index]

              if (prevSection) {
                if (prevSection.isGlobal) {
                  oldContent = activeCountry.country_name && prevSection.countrySpecificContent.find(content =>
                    content.country.country_name === activeCountry.country_name).globalContent
                    ?prevSection.countrySpecificContent.find(content =>
                      content.country.country_name === activeCountry.country_name).globalContent.rawHTML
                    :prevSection.chapterGlobalContent.rawHTML
                } else if (prevSection.isClientEditable) {
                  if (activeCountry !== null) {
                    oldContent =
                      prevSection.countrySpecificContent.find(content =>
                        content.country.country_name === activeCountry.country_name)
                        .clientContent.rawHTML
                  }
                } else {
                  oldContent =
                    prevSection.countrySpecificContent.find(content =>
                      content.country.country_name === activeCountry.country_name)
                      .nonClientContent.rawHTML
                }
              } else {
                oldContent = 'Section does not exist on Project Document'
              }
            } else {
              oldContent =
                'No content. Chapter does not exist on Project Document.'
            }

            return (
              <div
                style={{
                  paddingTop: '1em',
                  paddingBottom: '1em',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    height: 'auto',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    flexGrow: 1,
                    /* paddingRight: '2em', */
                  }}
                >
                  <h3>Section: {chapterSection.name} </h3>
                </div>

                {templateChapter ? (
                  <div>
                    {!diffControlToggle && (
                      <>
                        {newContent !== oldContent ? (
                          <ReactDiffViewer
                            oldValue={oldContent}
                            newValue={newContent}
                            splitView={true}
                          />
                        ) : (
                          <Typography>
                            New chapter. No content to compare against.
                          </Typography>
                        )}
                      </>
                    )}
                    {diffControlToggle && (
                      <div
                        style={{
                          display: 'flex',
                          height: 'auto',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          flexGrow: 1,
                          /* paddingRight: '2em', */
                        }}
                      >
                        <Editor
                          apiKey={
                            'lk8ioa673o60urr91q2n1synl65hxg5def93oobzlaadkwrl'
                          }
                          value={oldContent}
                          disabled={true}
                          init={{
                            height: 500,
                            width: '40%',
                            min_height: 200,
                            min_width: 200,
                            resize: 'both',
                            menubar: false,
                            plugins: [
                              'advlist autolink lists link image charmap print preview anchor',
                              'searchreplace visualblocks code fullscreen',
                              'insertdatetime media table paste code help wordcount table',
                              'autoresize',
                            ],
                            toolbar: false,
                            content_style:
                              'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                          }}
                          // onEditorChange={(newState: string) => {
                          //   setInitialState(newState);
                          // }}
                        />
                        <Editor
                          apiKey={
                            'lk8ioa673o60urr91q2n1synl65hxg5def93oobzlaadkwrl'
                          }
                          value={newContent}
                          disabled={true}
                          init={{
                            height: 500,
                            width: '40%',
                            min_height: 200,
                            min_width: 200,
                            resize: 'both',
                            menubar: false,
                            plugins: [
                              'advlist autolink lists link image charmap print preview anchor',
                              'searchreplace visualblocks code fullscreen',
                              'insertdatetime media table paste code help wordcount table',
                              'autoresize',
                            ],
                            toolbar: false,
                            content_style:
                              'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                          }}
                          // onEditorChange={(newState: string) => {
                          //   setInitialState(newState);
                          // }}
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <Typography>
                    New chapter. No content to compare against.
                  </Typography>
                )}
                <Divider />
              </div>
            )
          })}
    </>
  )
}
