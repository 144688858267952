import AWS from 'aws-sdk'
import config from 'config'
import React, { useContext } from 'react'
import { DocumentContext } from 'shared/components/DocumentEditor/DocumentEditor'
import Loader from 'shared/components/Feedback/Loader'
import { Expression, WageCatalogueSheet } from 'shared/types/document'
import { Project } from 'shared/types/project'
import * as XLSX from 'xlsx'
import AppTable from './MaterialTable'
import { convertWorksheetToTableFormat } from './WageTypeUtils'

export type TempXLSXDataStructure = {
  wsname: string
  worksheet: XLSX.WorkSheet
  data: unknown[]
  columns: string[]
}

export type MaterialColumnObject = {
  title: string
  field: string
  customSort: any
  customFilter: any
  colIndex: number
  hidden: boolean
}

export type TableData = {
  columns: MaterialColumnObject[]
  data: any[]
}

type EditorProps = {
  project?: Project
  wb: XLSX.WorkBook
  extractedWorksheet: WageCatalogueSheet
  uploadedFile: File
  setExtractedWorksheet: (extractedWorksheet: WageCatalogueSheet) => void
}

export const WageTypeCatalogue: React.FC<EditorProps> = () => {
  const context = useContext(DocumentContext)
  const {
    activeDocumentChange,
    extractedWorksheet,
    setExtractedWorksheet,
    selectedFilter,
  } = context

  const [tableColumns, setTableColumns] = React.useState<
    MaterialColumnObject[]
  >([])
  const [tableData, setTableData] = React.useState<any[]>([])
  const [finalTableData, setFinalTableData] = React.useState<any[]>([])
  const [fileLocation] = React.useState<string>(
    activeDocumentChange !== undefined &&
      activeDocumentChange.wageDataFileLocation !== null
      ? activeDocumentChange.wageDataFileLocation
      : null
  )
  const [fileName] = React.useState<string>(
    activeDocumentChange !== undefined &&
      activeDocumentChange.wageDataFileName !== null
      ? activeDocumentChange.wageDataFileName
      : null
  )
  const [tableHeading, setTableHeading] = React.useState<string>('')
  const [tableFilters] = React.useState<Expression[]>([])
  const [filteredTableColumns, setFilteredTableColumns] = React.useState<
    MaterialColumnObject[]
  >([])

  const [busy, setBusy] = React.useState<boolean>(false)
  React.useEffect(() => {
    setFinalTableData(tableData)
  }, [tableData])

  React.useEffect(() => {
    console.log('WORKSHEET', extractedWorksheet)

    if (extractedWorksheet !== null && extractedWorksheet !== undefined) {
      console.log('EXTRACTED WORKSHEET', extractedWorksheet)
      setTableHeading(extractedWorksheet.worksheetName)
      setTableColumns(extractedWorksheet.columns)
      setFinalTableData(extractedWorksheet.parsedData)
      setFilteredTableColumns(extractedWorksheet.columns)
      setTableData(extractedWorksheet.parsedData)
    }
  }, [extractedWorksheet])
  React.useEffect(() => {
    //
  }, [tableFilters])
  React.useEffect(() => {
    AWS.config.update({
      accessKeyId: config.keys.ACCESS_KEY,
      secretAccessKey: config.keys.SECRET_ACCESS_KEY,
      region: config.wageTypeBucket.REGION,
    })

    const s3 = new AWS.S3()

    const params = {
      Bucket: config.wageTypeBucket.S3_BUCKET_NAME,
      Key: fileName,
    }

    console.log(fileLocation)

    if (
      fileLocation !== null ||
      (fileLocation !== undefined && extractedWorksheet === null)
    ) {
      setBusy(true)
      s3.getObject(params, (err, data) => {
        if (err) {
          console.log(err, err.stack)
          setBusy(false)
        } else {
          let workbook = XLSX.read(data.Body, { type: 'array' })
          console.log(workbook)
          convertWorksheetToTableFormat(workbook, setExtractedWorksheet)
          setBusy(false)
        }
      })
    }
  }, [])

  React.useEffect(() => {
    if (selectedFilter && selectedFilter.columns !== null) {
      setFilteredTableColumns(selectedFilter.columns)
    } else if (extractedWorksheet) {
      setFilteredTableColumns(extractedWorksheet.columns)
    }
  }, [selectedFilter])

  return (
    <>
      {busy === true && <Loader open={true} />}
      <div
        style={{
          display: 'flex',
          height: 'auto',
          width: '100%',
          flexDirection: 'column',
          justifyContent: 'normal',
          flexGrow: 1,
          // paddingLeft: '1em',
          overflow: 'auto',
        }}
      >
        {extractedWorksheet ? (
          <div>
            {finalTableData && filteredTableColumns && (
              <AppTable
                data={finalTableData}
                columns={tableColumns}
                tableHeading={tableHeading}
                setColumns={setTableColumns}
                tableColumns={filteredTableColumns}
                setTableColumns={setFilteredTableColumns}
              />
            )}
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </>
  )
}
