import {Accordion, AccordionDetails, AccordionSummary, Typography,} from '@material-ui/core'
import {Editor} from '@tinymce/tinymce-react'
import React, {useContext, useRef, useState} from 'react'
import {DocumentContext} from 'shared/components/DocumentEditor/DocumentEditor'
import {findDFSBySharedId, findDFSByTreeIndexString} from 'shared/components/DocumentEditor/treeUtils'
import * as reduxDocumentType from 'shared/types/document'
import {Project} from 'shared/types/project'
import UneditableComponent from './UneditableComponent'
import {DocumentEdit} from "shared/types/document";
import IndexedDBFunctions from "../../../../../../IndexedDBFunctions";

const DocumentSection: React.FC<{
  chapterSection: reduxDocumentType.ChapterSection
}> = ({chapterSection}) => {
  const editorRef = useRef(null)
  const context = useContext(DocumentContext)
  const {
    activeCountry,
    activeDocument,
    setContentIsDirty,
    activeChapter,
    activeDocumentChange,
    activeDocumentVersion,
    documentChapters,
    documents,
    mode,
    project,
    setNumberOfEdits
  } = context

  const [chapterChange, setChapterChange] = useState<boolean>(true)
  const [documentChange, setDocumentChange] = useState<boolean>(true)
  const [countryChange, setCountrytChange] = useState<boolean>(true)
  const getSectionHTMLContent = () => {
    return activeCountry !== null
      ? chapterSection.isGlobal
        ? activeCountry.country_name && chapterSection.countrySpecificContent.find(content =>
          content.country.country_name === activeCountry.country_name).globalContent
          ?chapterSection.countrySpecificContent.find(content =>
            content.country.country_name === activeCountry.country_name).globalContent.rawHTML
          :chapterSection.chapterGlobalContent.rawHTML
        : chapterSection.isClientEditable
          ? chapterSection.countrySpecificContent &&
          chapterSection.countrySpecificContent.find(content =>
            content.country.country_name === activeCountry.country_name) !== undefined
            ? chapterSection.countrySpecificContent.find(content =>
              content.country.country_name === activeCountry.country_name)
              .clientContent.rawHTML
            : ''
          : chapterSection.countrySpecificContent &&
          chapterSection.countrySpecificContent.find(content =>
            content.country.country_name === activeCountry.country_name) !== undefined
            ? chapterSection.countrySpecificContent.find(content =>
              content.country.country_name === activeCountry.country_name)
              .nonClientContent.rawHTML
            : ''
      : chapterSection.isGlobal && chapterSection.chapterGlobalContent.rawHTML
  }

  const [initialState, setInitialState] = React.useState<string>(
    getSectionHTMLContent()
  )

  const displayEditable =
    mode === 'template'
      ? !chapterSection.isGlobal || activeCountry.country_name === undefined
      : activeChapter.refId === undefined
        ? true
        : chapterSection.isClientEditable && activeCountry !== null

  const save = () => {
    if (!chapterChange && !documentChange && !countryChange) {
      localStorage.setItem('ContentIsDirty', 'true')

      if(localStorage.getItem('DocumentEdits')) {
        let currentDocumentEdit: DocumentEdit[] = JSON.parse(localStorage.getItem('DocumentEdits'))
        let findDoc = currentDocumentEdit.find(edit => edit.document === activeDocument.name)

        if(findDoc) {
          let actCountry = activeCountry.country_name ? activeCountry.country_name : 'Global'
          let findCountry = findDoc.countries.find((country) => country === actCountry)

          if(!findCountry) {
            if(activeCountry === undefined || activeCountry.country_name === undefined) {
              findDoc.countries.push('Global')
            } else {
              findDoc.countries.push(activeCountry.country_name)
            }
          }
        } else {
          let actCountry = activeCountry.country_name ? activeCountry.country_name : 'Global'
          let newDocumentEdit: DocumentEdit = {
            document: activeDocument.name,
            countries: [actCountry]
          }
          currentDocumentEdit.push(newDocumentEdit)
        }
        localStorage.setItem('DocumentEdits', JSON.stringify(currentDocumentEdit))
      } else {
        let actCountry = activeCountry.country_name ? activeCountry.country_name : 'Global'
        let newDocumentEdit: DocumentEdit[] = [{
          document: activeDocument.name,
          countries: [actCountry]
        }]
        localStorage.setItem('DocumentEdits', JSON.stringify(newDocumentEdit))
      }
      activeChapter.wasChanged = true
    } else {
      setChapterChange(false)
      setDocumentChange(false)
      setCountrytChange(false)
    }

    if (editorRef.current) {
      editorRef.current.setDirty(false)
      const newState = editorRef.current.getContent()

      let foundChapter: reduxDocumentType.ChapterObject = findDFSByTreeIndexString(
        documentChapters,
        activeChapter.treeIndexString
      )

      let foundChapterSection = foundChapter.sections.find(
        (section) => section.name === chapterSection.name
      )

      const temp: reduxDocumentType.ChapterSection = JSON.parse(
        JSON.stringify(chapterSection)
      )

      if (temp.isGlobal) {
        temp.chapterGlobalContent.rawHTML = newState
      } else {
        if (temp.isClientEditable) {
          temp.countrySpecificContent.find(content =>
            content.country.country_name === activeCountry.country_name).clientContent.rawHTML = newState
        } else {
          temp.countrySpecificContent.find(content =>
            content.country.country_name === activeCountry.country_name).nonClientContent.rawHTML = newState
        }
      }
      //activeChapter.wasChanged = true

      let newReduxObj: reduxDocumentType.DocumentChange = {
        ...activeDocumentChange,
        chapters: documentChapters,
        version: activeDocumentVersion.latestVersion + 1,
        id: null,
        lastUpdated: new Date().toString(),
        changedBy: {
          firstname: sessionStorage.getItem('firstname'),
          id: sessionStorage.getItem('id'),
          surname: sessionStorage.getItem('surname'),
        },
      }

      if (
        activeDocumentVersion.changes[activeDocumentVersion.changes.length - 1]
          .id === null
      ) {
        activeDocumentVersion.changes[
        activeDocumentVersion.changes.length - 1
          ] = newReduxObj
      } else {
        activeDocumentVersion.changes.push(newReduxObj)
      }

      let newDocument: reduxDocumentType.Document = {
        ...activeDocument,
        documentVersions: activeDocument.documentVersions,
        lastUpdated: new Date().toString(),
      }

      Object.assign(foundChapterSection, temp)
      let newDocumentVersion = newDocument.documentVersions[newDocument.documentVersions.length - 1]
      let newDocumentChange = newDocumentVersion.changes[newDocumentVersion.changes.length - 1]
      let newDocumentChapter = findDFSBySharedId(newDocumentChange.chapters, foundChapter.sharedId)
      let newChapterSection = newDocumentChapter.sections.find(
        (section) => section.name === chapterSection.name
      )
      Object.assign(newChapterSection, foundChapterSection)
      Object.assign(activeDocument, newDocument)

      if (mode === 'project') {
        let newDocs: reduxDocumentType.Document[] = JSON.parse(JSON.stringify(documents))
        let index = documents.findIndex((d) => d.id === activeDocument.id)

        newDocs[index] = newDocument
        let newReduxProject: Project = {
          ...project,
          documents: JSON.parse(JSON.stringify(newDocs)),
          lastUpdated: new Date().toString(),
        }
        Object.assign(project, newReduxProject)
        //localStorage.setItem('currentDocumentEdit', JSON.stringify(newReduxProject.documents))

        IndexedDBFunctions.deleteDocuments()
        IndexedDBFunctions.addDocuments(newReduxProject.documents)
        //This is just a work around as we encountered issues with Local Storage
        setNumberOfEdits(Math.random() * 1000)
      } else {
        //localStorage.setItem('currentDocumentEdit', JSON.stringify(documents))

        IndexedDBFunctions.deleteDocuments()
        IndexedDBFunctions.addDocuments(documents)
        //This is just a work around as we encountered issues with Local Storage
        setNumberOfEdits(Math.random() * 1000)
      }
    }

  }

  React.useEffect(() => {
    setInitialState(getSectionHTMLContent())
    setDocumentChange(true)
  }, [activeDocument])
  React.useEffect(() => {
    setInitialState(getSectionHTMLContent())
    setChapterChange(true)
  }, [activeChapter])
  React.useEffect(() => {
    setInitialState(getSectionHTMLContent())
    setCountrytChange(true)
  }, [activeCountry])

  return (
    <Accordion
      expanded={true}
      elevation={0}
      style={{
        border: '0.5px solid #ebe9e9',
        backgroundColor: !displayEditable ? '#f1f1f1' : '#fff',
      }}
    >
      <AccordionSummary aria-controls='panel1a-content' id='panel1a-header'>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Typography>{chapterSection.name}</Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {displayEditable ? (
          <Editor
            apiKey={'lk8ioa673o60urr91q2n1synl65hxg5def93oobzlaadkwrl'}
            value={initialState}
            onInit={(evt, editor) => (editorRef.current = editor)}
            onChange={() => {
              // setDirtyState(true)
              // setContentIsDirty(true)
              // save()
            }}
            init={{
              height: 500,
              width: '100%',
              min_height: 200,
              min_width: 200,
              resize: 'both',

              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount table',
                'autoresize',
                'image',
              ],
              toolbar:
                'undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | image',
              content_style:
                'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            }}
            onEditorChange={(newState: string) => {
              setInitialState(newState)
              setContentIsDirty(true)
              save()
            }}
          />
        ) : (
          <UneditableComponent content={initialState}/>
        )}
      </AccordionDetails>
    </Accordion>
  )
}

export default DocumentSection
