import { Paper } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
//import SaveIcon from '@material-ui/icons/Save';
import React, { useContext, useEffect, useState } from 'react';
import { DocumentContext } from 'shared/components/DocumentEditor/DocumentEditor';
const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: '100%',
    padding: theme.spacing(0, 0, 0, 0),
  },
  EPIGridSpacingLeft: {
    paddingLeft: '0px !important',
  },
  EPIGridSpacingLeftMobile: {
    paddingLeft: '0px !important',
  },
}));

const DocumentInformation = () => {
  const classes = useStyles();
  const context = useContext(DocumentContext);
  const { activeDocument, project, setActiveCountriesOnProject } = context;
  const [localName, setLocalName] = useState(activeDocument.name);
  const [localAuthor, setLocalAuthor] = useState(activeDocument.author);
  console.log(localName, localAuthor);

  useEffect(() => {
    setLocalName(activeDocument.name);
    setLocalAuthor(activeDocument.author);
  }, [activeDocument]);

  React.useEffect(() => {
    if(project && project.countries.find((country) => country.active_status) !== undefined) {
      setActiveCountriesOnProject(true)
    }
  })

  return (
    <div
      style={{
        padding: '1em',
      }}
    >
      <Paper style={{ padding: '1em' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <h3>Document Information</h3>
          {/*<Button variant="contained" color="primary">*/}
          {/*  <SaveIcon />*/}
          {/*  &nbsp;Save*/}
          {/*</Button>*/}
        </div>
      </Paper>
      <br />

      <Paper
        style={{
          height: '85%',
          padding: '2em',
        }}
      >
        <form className={classes.form} noValidate>
          <TextField
            autoComplete='DocumentName'
            id='DocumentName'
            label='Document Name'
            name='DocumentName'
            variant='outlined'
            fullWidth
            focused={false}
            value={localName}
            InputProps={{
              readOnly: true,
            }}
          >
            {localName}
          </TextField>
          <br />
          <br />
          <TextField
            focused={false}
            id='author'
            label='Author'
            name='author'
            variant='outlined'
            value={localAuthor}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          >
            {localAuthor}
          </TextField>
        </form>
      </Paper>
    </div>
  );
};

export default DocumentInformation;
