import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles, Theme } from '@material-ui/core/styles'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setAddProjectStatus,
  setUpdateProjectStatus,
} from 'redux/slices/projects'
import { RootState } from 'redux/store'
import { editProjectInfoOnDB, fetchProjects } from 'redux/thunks/projectsThunk'
import { Country } from 'shared/types/country'
import { Project, ProjectRole, ProjectUserRole } from 'shared/types/project'
import { Status } from 'shared/types/status'
import EditProjectTabNav from './EditProjectTabNavigation'
import { userRow } from './EditProjectUsers'

export type dialogProps = {
  isOpen: boolean
  setOpen: (value: boolean) => void
  project: Project
}

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {},
  dialogTitle: { padding: theme.spacing(5, 5, 0, 5) },
  dialogContent: {
    /* overflow: 'hidden', */
  },
  dialogContentText: { padding: theme.spacing(0, 5, 5, 2) },
  dialogActions: { padding: theme.spacing(5, 6, 5, 5) },
  button: {
    paddingLeft: '2rem',
  },
}))

const EditProjectDialog: React.FC<dialogProps> = ({
  isOpen,
  setOpen,
  project,
}: dialogProps) => {
  const [projectName, setProjectName] = React.useState<string | undefined>(
    project && project.name ? project.name : undefined
  )
  const [author, setAuthor] = React.useState<string | undefined>(
    project && project.author ? project.author : undefined
  )
  const [projectUsers, setProjectUsers] = React.useState<userRow[] | undefined>(
    []
  )
  const [projectNameError, setProjectNameError] = React.useState<boolean>(false)
  const [authorError, setAuthorError] = React.useState<boolean>(false)
  const [projectNameHelperText, setProjectNameHelperText] =
    React.useState<string>('')
  const [authorHelperText, setAuthorHelperText] = React.useState<string>('')
  // const [isError, setIsError] = useState(false);
  // const [isSuccess, setIsSuccess] = useState(false);
  // const Message = useSelector(
  //   (state: RootState) => state.projects.updateProjectResponseMessage
  // );
  const theUpdateStatus = useSelector(
    (state: RootState) => state.projects.updateProjectStatus
  )
  const theCreateStatus = useSelector(
    (state: RootState) => state.projects.addProjectStatus
  )
  const documents = useSelector((state: RootState) => state.documents.Documents)
  const ArrayOfRoles: Array<ProjectRole> = useSelector(
    (state: RootState) => state.projectRoles.roles
  )

  React.useEffect(() => {
    const fetchProjectsThunk = fetchProjects()
    dispatch(fetchProjectsThunk)
  }, [])

  React.useEffect(() => {
    if (isOpen) {
      setProjectName(project && project.name ? project.name : undefined)
      setAuthor(project && project.author ? project.author : undefined)
    }
  }, [isOpen])

  React.useEffect(() => {
    if (theUpdateStatus === Status.success) {
      // setIsSuccess(true);
      // setIsError(false);
      dispatch({ type: setUpdateProjectStatus.type, payload: Status.idle })
    } else if (theUpdateStatus === Status.failed) {
      // setIsSuccess(false);
      // setIsError(true);
    }
  }, [theUpdateStatus])

  React.useEffect(() => {
    if (theCreateStatus === Status.success) {
      // setIsSuccess(true);
      // setIsError(false);
      const fetchProjectsThunk = fetchProjects()
      dispatch(fetchProjectsThunk)
      dispatch({ type: setAddProjectStatus.type, payload: Status.idle })
    } else if (theCreateStatus === Status.failed) {
      // setIsSuccess(false);
      // setIsError(true);
    }
  }, [theCreateStatus])

  const handleClose = () => {
    setOpen(false)
  }

  const classes = useStyles()
  const dispatch = useDispatch()

  const onSaveButtonClicked = () => {
    let valid = true

    if (projectName === '') {
      //empy
      setProjectNameError(true)
      setProjectNameHelperText('Project Name can not be empty')
      valid = false
    }

    if (author === '') {
      //empy
      setAuthorError(true)
      setAuthorHelperText('Project Author can not be empty')
      valid = false
    }

    if (valid) {
      let newProjectUsers: ProjectUserRole[] = []
      projectUsers?.map((finalRow) => {
        if (finalRow.checked) {
          console.log(finalRow)
          let projectRoles: ProjectRole[] = finalRow.roles.map((role) => {
            return ArrayOfRoles.find((r) => r.role_name === role)
          })
          let userRole: ProjectUserRole = {
            user: finalRow.user,
            role: projectRoles
              ? projectRoles
              : [
                  {
                    id: '0',
                    role_name: 'None',
                    description: 'None',
                  },
                ],
          }
          newProjectUsers?.push(userRole)
        }
      })

      let allCountriesTemp: Country[] = []

      documents.forEach((document) => {
        let matchingTemplateDocVersion =
          document.documentVersions[document.documentVersions.length - 1]
        let matchingTemplateDocChange =
          matchingTemplateDocVersion.changes[
            matchingTemplateDocVersion.changes.length - 1
          ]
        let syncCountriesList = matchingTemplateDocChange.countries
        syncCountriesList.forEach((country) => {
          if (
            allCountriesTemp.findIndex(
              (cntry) => cntry.country_name === country.country_name
            ) === -1
          ) {
            allCountriesTemp.push(country)
          }
        })
      })

      let newProject: Project = {
        ...project,
        author: author ? author : '',
        name: projectName ? projectName : '',
        lastUpdated: new Date().toString(),
        value: projectName ? projectName : '',
        users: projectUsers.length > 0 ? newProjectUsers : project.users,
        countries:
          project.countries.length === 0 ? allCountriesTemp : project.countries,
      }
      console.log(newProject)

      const editProjectThunk = editProjectInfoOnDB(newProject)
      dispatch(editProjectThunk)
      handleClose()
    }
  }

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth='lg'
        aria-labelledby='form-dialog-title'
        className={classes.gridContainer}
        open={isOpen}
        /* onClose={handleClose} */
      >
        <DialogTitle className={classes.dialogTitle} id='form-dialog-title'>
          Edit Project
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText className={classes.dialogContentText}>
            View/change project name, and author.
          </DialogContentText>
          <div>
            <div>
              <EditProjectTabNav
                project={project}
                projectName={projectName}
                author={author}
                setProjectName={setProjectName}
                setAuthor={setAuthor}
                projectUsers={projectUsers}
                setProjectUsers={setProjectUsers}
                projectNameError={projectNameError}
                setProjectNameError={setProjectNameError}
                authorError={authorError}
                setAuthorError={setAuthorError}
                projectNameHelperText={projectNameHelperText}
                authorHelperText={authorHelperText}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button color='primary' onClick={handleClose}>
            Cancel
          </Button>
          <span className={classes.button} />
          <Button
            color='primary'
            variant='contained'
            onClick={onSaveButtonClicked}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default EditProjectDialog
