import React from 'react'
import { Country } from 'shared/types/country'
import * as reduxDocumentType from 'shared/types/document'
import { ChapterSection } from 'shared/types/document'
import { Project } from 'shared/types/project'
import { ProjectDocumentEditor } from 'views/Pages/ProjectEnvironment/Home/DocumentHome/Content/ProjectDocumentEditor'
import { TemplateDocumentEditor } from 'views/Pages/TemplateEnvironment/Home/DocumentHome/Content/TemplateDocumentEditor'
import * as XLSX from 'xlsx'

export type DocumentContextType = {
  mode: string
  project: Project
  activeCountryIndex: number
  activeCountry: Country
  activeChapter: reduxDocumentType.ChapterObject
  activeDocument: reduxDocumentType.Document
  numberOfEdits: number
  documentChapters: Array<reduxDocumentType.ChapterObject>
  documents: reduxDocumentType.Document[]
  activeDocumentIndex: number
  activeCountries: Array<Country>
  contentIsDirty: boolean
  activeDocumentVersions: reduxDocumentType.DocumentVersion[]
  activeDocumentChange: reduxDocumentType.DocumentChange
  activeDocumentVersion: reduxDocumentType.DocumentVersion
  sections: ChapterSection[]
  uploadedFile: File
  wb: XLSX.WorkBook
  extractedWorksheet: reduxDocumentType.WageCatalogueSheet
  selectedFilter: reduxDocumentType.Filter
  activePageIndex?: number
  displaySnack: boolean
  snackSeverity: string
  snackMessage: string
  activeCountriesOnProject: boolean
  projectDocumentsLoaded: boolean
  setSections: React.Dispatch<React.SetStateAction<ChapterSection[]>>
  setActiveDocumentVersion: React.Dispatch<
    React.SetStateAction<reduxDocumentType.DocumentVersion>
  >
  setActiveDocumentChange: React.Dispatch<
    React.SetStateAction<reduxDocumentType.DocumentChange>
  >
  setActiveDocumentVersions: React.Dispatch<
    React.SetStateAction<reduxDocumentType.DocumentVersion[]>
  >
  setContentIsDirty: React.Dispatch<React.SetStateAction<boolean>>
  setActiveCountries: React.Dispatch<React.SetStateAction<Array<Country>>>
  setActiveDocumentIndex: React.Dispatch<React.SetStateAction<number>>
  setActiveCountryIndex: React.Dispatch<React.SetStateAction<number>>

  setDocumentChapters: React.Dispatch<
    React.SetStateAction<reduxDocumentType.ChapterObject[]>
  >
  setActiveChapter: React.Dispatch<
    React.SetStateAction<reduxDocumentType.ChapterObject>
  >
  setActiveDocument: React.Dispatch<
    React.SetStateAction<reduxDocumentType.Document>
  >
  setNumberOfEdits: React.Dispatch<
    React.SetStateAction<number>
  >
  setActiveCountry: React.Dispatch<React.SetStateAction<Country>>
  setUploadedFile: React.Dispatch<React.SetStateAction<File>>
  setWb: React.Dispatch<React.SetStateAction<XLSX.WorkBook>>
  setExtractedWorksheet: React.Dispatch<
    React.SetStateAction<reduxDocumentType.WageCatalogueSheet>
  >
  setSelectedFilter: React.Dispatch<
    React.SetStateAction<reduxDocumentType.Filter>
  >
  setCopiedDocuments: React.Dispatch<
    React.SetStateAction<reduxDocumentType.Document[]>
  >
  setActivePageIndex?: React.Dispatch<React.SetStateAction<number>>
  setProject: React.Dispatch<React.SetStateAction<Project>>
  setDisplaySnack: React.Dispatch<React.SetStateAction<boolean>>
  setSnackMessage: React.Dispatch<React.SetStateAction<string>>
  setSnackSeverity: React.Dispatch<React.SetStateAction<string>>
  setDisplayLoader: React.Dispatch<React.SetStateAction<boolean>>
  setActiveCountriesOnProject: React.Dispatch<React.SetStateAction<boolean>>
  setProjectDocumentsLoaded: React.Dispatch<React.SetStateAction<boolean>>
}

export const DocumentContext = React.createContext<DocumentContextType>(
  {} as DocumentContextType
)

const DocumentEditor: React.FC<{ mode?: string }> = ({ mode }) => {
  if (mode !== undefined) {
    return <ProjectDocumentEditor />
  }

  return <TemplateDocumentEditor />
}

export default DocumentEditor
