import { Button, ButtonBase } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import RefreshIcon from '@material-ui/icons/Refresh'
import SyncIcon from '@material-ui/icons/Sync'
import { Skeleton } from '@material-ui/lab'
import React, {useContext, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootState } from 'redux/store'
import {fetchDocuments, fetchVersionNumberByCountry} from 'redux/thunks/documentsThunk'
import {
  fetchProjectsByUserV2,
  getAndSetNewSyncTemplateDocs,
} from 'redux/thunks/projectsThunk'
import { DocumentContext } from 'shared/components/DocumentEditor/DocumentEditor'
import { CountrySelector } from 'shared/components/DocumentEditor/GlobalToolbar/CountrySelector/CountrySelector'
import EPISnackBar from 'shared/components/Feedback/EPISnackBar'
import Loader from 'shared/components/Feedback/Loader'
import { Status } from 'shared/types/status'
import SyncNewTemplateDocsDialog from '../../Dialogs/SyncNewTemplateDocsDialog'
import { useStyles } from '../../Shared/styles'
import CountryIcon from "@material-ui/icons/Language";
import {Project} from "../../../../../shared/types/project";
const ClientUserHomeScreen: React.FC<{
  openDocEditor?: any
  setopenDocEditor?: (open: any) => void
}> = ({ openDocEditor, setopenDocEditor }) => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const context = useContext(DocumentContext)

  const { project, documents, activeCountry, setActiveCountriesOnProject, projectDocumentsLoaded, setProjectDocumentsLoaded } = context

  const [openSyncDialog, setOpenSyncDialog] = React.useState<boolean>(false)
  const [selectCountryPopup, setSelectCountryPopup] = React.useState<boolean>(false)
  const [busy, setBusy] = React.useState<boolean>(false)
  const [clickedButton, setClickedButton] = React.useState(false)
  const [openDocument, setOpenDocument] = React.useState(false)
  const [docId, setDocId] = React.useState<string>('')
  const [docCountry, setDocCountry] = React.useState<string>('')
  // const documents: Document[] = projects.find( ( project ) => project.id === projectId ).documents

  const versionNumber = useSelector(
    (state: RootState) => state.documents.DocumentByCountryVersions
  )

  const fetchStatus = useSelector(
    (state: RootState) => state.projects.getProjectStatus
  )

  const editDocumentStatus = useSelector(
    (state: RootState) => state.projects.editDocumentStatus
  )

  const templateDocuments = useSelector(
    (state: RootState) => state.documents.Documents
  )

  const syncFinished = useSelector(
    (state: RootState) => state.projects.editDocumentStatus
  )

  useEffect(() => {
    if(project && project.countries.find((country) => country.active_status) !== undefined) {
      setActiveCountriesOnProject(true)
    }
  })

  useEffect(()=> {
    if(editDocumentStatus === Status.loading) {
      setBusy(true)
    } else if(editDocumentStatus === Status.success) {
      setBusy(false)
    } else if(editDocumentStatus === Status.idle) {
      setBusy(false)
    }
  }, [editDocumentStatus])

  useEffect(() => {
    if(project) {
      project.documents.map((doc) => {
        const getVersionNumber = fetchVersionNumberByCountry(doc.id, activeCountry)
        dispatch(getVersionNumber)
      })
    }
  }, [activeCountry])

  useEffect(() => {
    if(clickedButton === true) {
      onSyncButtonClicked()
    }
  }, [templateDocuments])

  useEffect(() => {
    if(syncFinished === Status.success) {
      let fetchProjThunk = fetchProjectsByUserV2(
        sessionStorage.getItem('id')
      )
      dispatch(fetchProjThunk)
    }
  }, [syncFinished])

  useEffect(() => {
    if(project) {
      project.documents.map((doc) => {
        const getVersionNumber = fetchVersionNumberByCountry(doc.id, activeCountry)
        dispatch(getVersionNumber)
      })
    }
  }, [versionNumber.length])

  const onSyncOpen = () => {
    setBusy(true)
    dispatch(fetchDocuments())
    setClickedButton(true)
  }

  const onSyncButtonClicked = () => {
    const getSyncDocsThunk = getAndSetNewSyncTemplateDocs(project)
    dispatch(getSyncDocsThunk)
    setOpenSyncDialog(true)
    setBusy(false)
  }

  const noActiveCountries = (project: Project) => {
    if(project) {
      return project.countries.find((country) => country.active_status) !== undefined;
    }

    return false
  }

  useEffect(() => {
    if(projectDocumentsLoaded && openDocument){
      history.push(
        `/projectEnvironment/project/${project.id}/document/${docId}/country/${docCountry}?section=Content`
      )
      setOpenDocument(false)
    }
  }, [projectDocumentsLoaded])

  const openProjectEditor = (id: string, country: string) => {
    setDocId(id)
    setDocCountry(country)
    setProjectDocumentsLoaded(false)
    setOpenDocument(true)
    let fetchProjThunk = fetchProjectsByUserV2(sessionStorage.getItem('id'))
    dispatch(fetchProjThunk)
  }

  const getVersionNumber = (id: string) => {
    const number = versionNumber.find((v) => v.id === id && v.country.country_name === activeCountry.country_name)

    if(number){
      return number.version
    }

    return 1
  }

  return (
    <>
      {busy === true && <Loader open={true} />}
      <div
        style={{
          padding: '1em',
        }}
      >
        <Paper
          style={{
            padding: '1em',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {noActiveCountries(project) ? (
            <CountrySelector />
            ) : (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h3>No countries configured</h3>
                <Button
                  variant='contained'
                  style={{
                    backgroundColor: '#2c8cd8',
                    color: 'white',
                    marginLeft: '15px',
                    marginRight: '15px',
                  }}
                  onClick={() => {
                    history.push('?section=Countries')
                  }}
                  disabled={!projectDocumentsLoaded}
                >
                  <CountryIcon />
                  Manage Countries
                </Button>
              </div>
            )}
            <h3 style={{ marginLeft: '0.5em' }}>Project Documents</h3>

          </div>
          <div>
            <Button
              variant='contained'
              style={{
                backgroundColor: '#2c8cd8',
                color: 'white',
              }}
              startIcon={<RefreshIcon className={classes.icon1} />}
              onClick={() => {
                setProjectDocumentsLoaded(false)
                let fetchProjThunk = fetchProjectsByUserV2(
                  sessionStorage.getItem('id')
                )
                localStorage.setItem('latestChanges', 'false')
                dispatch(fetchProjThunk)
                const fetchTemplateDocs = fetchDocuments()
                dispatch(fetchTemplateDocs)
              }}
              disabled={!projectDocumentsLoaded || busy}
            >
              Refresh
            </Button>
            <Button
              style={{ marginLeft: '0.5em' }}
              color={'primary'}
              variant='contained'
              onClick={onSyncOpen}
              disabled={!projectDocumentsLoaded || busy}
            >
              <SyncIcon />
              Sync Documents
            </Button>
          </div>
        </Paper>
        <div
          // style={{ paddingTop: theme.spacing(1) }}
          style={{
            display: 'flex',
            justifyContent: 'start',
            paddingTop: '1em',
            flexWrap: 'wrap',
          }}
        >
          {/* <Paper elevation={2} className={classes.documentPaper}>
                      <AddIcon className={classes.newProjectIcon} />
                  </Paper> */}
          {documents &&
            documents
              .filter((document) => {
                if (
                  activeCountry !== undefined &&
                  document.documentVersions !== undefined &&
                  document.documentVersions[0].changes !== undefined
                ) {
                  let latestChange = document.documentVersions[0].changes[0]

                  if (
                    latestChange.countries.find(
                      (country) =>
                        country.country_name === activeCountry.country_name
                    ) !== undefined
                  ) {
                    return true
                  }

                  return false
                }

                return true
              })
              .map((document) => (
                <>
                  {typeof document !== 'string' && document.enabled && noActiveCountries(project) ? (
                    <div
                      style={{
                        paddingRight: '1em',
                        paddingBottom: '1em',
                      }}
                    >
                      <ButtonBase>
                        <Paper
                          key={document.id}
                          className={classes.documentPaper}
                          elevation={2}
                          style={{
                            backgroundColor: 'white',
                          }}
                          onClick={() => {
                            openProjectEditor(
                              document.id,
                              activeCountry.country_name
                            )
                          }}
                        >
                          <div className={classes.documentName}>
                            <Typography
                              component='h1'
                              color='primary'
                              className={classes.documentNameText}
                            >
                              <div className={classes.documentNameWordProject}>
                                Document:
                              </div>{' '}
                              <div
                                style={{
                                  fontSize: '1.1rem !important',
                                  fontFamily: 'Lato',
                                  letterSpacing: '0.08em',
                                }}
                              >
                                {document.name}
                              </div>
                            </Typography>
                            {versionNumber.find((v) => v.id === document.id
                              && v.country.country_name === activeCountry.country_name) !== undefined ?
                              (<Typography
                              component='h1'
                              variant='caption'
                              color='primary'
                              className={classes.documentVersion}
                            >
                              Working Version: {getVersionNumber(document.id)}
                            </Typography>) : (<></>)}
                          </div>
                        </Paper>
                      </ButtonBase>
                    </div>
                  ) : (
                    <Skeleton
                      style={{
                        margin: 0,
                        left: 0,
                      }}
                      variant='rect'
                      width={'100%'}
                      height={'100%'}
                    />
                  )}
                </>
              ))}
        </div>

        {openSyncDialog && (
          <div>
            <SyncNewTemplateDocsDialog
              open={openSyncDialog}
              setOpen={setOpenSyncDialog}
              projectId={project.id}
            />
          </div>
        )}

        {selectCountryPopup === true && (
          <EPISnackBar
            message={'Please select a Country'}
            messageType={'info'}
            isOpen={selectCountryPopup}
            setIsOpen={setSelectCountryPopup}
          />
        )}
        <Loader
          open={
            fetchStatus === Status.loading ||
            documents.find((document) => typeof document === 'string') !==
              undefined
          }
        />
      </div>
    </>
  )
}

export default ClientUserHomeScreen
