import { TableSortLabel } from '@material-ui/core'
import Chip from '@material-ui/core/Chip'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import React, { ReactElement, useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getUsers } from 'redux/slices/usersSlice'
import { RootState } from 'redux/store'
import { DocumentContext } from 'shared/components/DocumentEditor/DocumentEditor'
import { SnackType } from 'shared/components/Feedback/EPISnackBar'
import { TableActionMenu } from 'shared/components/TableActionMenu/TableActionMenu'
import { Status } from 'shared/types/status'
import { User } from 'shared/types/user'
import DeleteUserDialog from './Dialogs/DeleteUserConfirmDialog'
import ViewUserInfoDialog from './Dialogs/EditUserDialog/EditUserDialog'

export type UserRow = {
  id: string | undefined
  firstName: string | undefined
  lastName: string | undefined
  email: string | undefined
  projects: ReactElement<any, any> | null
  roles: ReactElement<any, any> | null
  views: null
}
export type HeadCell = {
  disablePadding: boolean
  id: keyof UserRowData
  label: string
}

export type TableHeadEPIProps = {
  headCells: Array<HeadCell>
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof UserRowData
  ) => void
  order: Order
  orderBy: string
  rowCount: number
}

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    overflow: 'auto',
  },

  chips: {
    '& > *': {
      marginRight: `${theme.spacing(0.5)}px !important`,
    },
  },

  buttonSpan: {
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(1),
    },
  },

  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }

  if (b[orderBy] > a[orderBy]) {
    return 1
  }

  return 0
}

type Order = 'asc' | 'desc'

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: string }, b: { [key in Key]: string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])

    if (order !== 0) return order

    return a[1] - b[1]
  })

  return stabilizedThis.map((el) => el[0])
}

type CreateChipProp = {
  message: string
  outlined: boolean
}

const CreateChip: React.FC<CreateChipProp> = ({ message, outlined }) => {
  return (
    <span style={{ marginRight: `4px !important` }}>
      <Chip
        label={message}
        variant={outlined ? 'outlined' : 'outlined'}
        color='primary'
        size='small'
      />
    </span>
  )
}

export type UserRowData = {
  id?: string
  firstname: string
  surname: string
  email: string
  role_name: string
  last_login: string
  views: string
}

function createTableData(user: User) {
  // const projectsList = CreateProjectsList(
  //   user && user.projects ? user.projects : []
  // );

  const result: UserRowData = {
    id: user.id,
    firstname: user.firstname,
    surname: user.surname,
    email: user.email,
    role_name: user.role_id.role_name,
    last_login: user.last_login,
    views: '',
  }

  return result
}

const headCells: HeadCell[] = [
  { id: 'firstname', disablePadding: false, label: 'First name' },
  { id: 'surname', disablePadding: false, label: 'Last name' },
  { id: 'email', disablePadding: false, label: 'Email' },
  { id: 'role_name', disablePadding: false, label: 'Roles' },
  { id: 'views', disablePadding: false, label: 'Actions' },
]
const TableHeadings: React.FC<TableHeadEPIProps> = (
  props: TableHeadEPIProps
) => {
  const { order, orderBy, onRequestSort } = props
  const createSortHandler =
    (property: keyof UserRowData) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  const classes = useStyles()

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          return (
            <TableCell
              key={headCell.id}
              align='left'
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                <Typography variant='body1' color='primary' component='h1'>
                  {headCell.label}
                </Typography>
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

export type TableEPIProps = {
  data: Array<User> | undefined
  page: number
  rowsPerPage: number
}

export type TableBodyEPIProps = {
  isOpen: boolean
  setOpen: (value: boolean) => void
  setUser: (value: User | undefined) => void
  order: Order
  orderBy: string
  isDeleteOpen: boolean
  setDeleteOpen: (value: boolean) => void
} & TableEPIProps

const TableContent: React.FC<TableBodyEPIProps> = ({
  data = [],
  page = 0,
  rowsPerPage = 0,
  setOpen = () => {
    return
  },
  setUser = () => {
    return
  },
  order,
  orderBy,
  isDeleteOpen,
  setDeleteOpen,
}) => {
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage) // in case if last has not enough rows add extra rows
  // const dispatch = useDispatch();
  const users: User[] = useSelector(getUsers)

  function getUserInfo(email = '0') {
    let user = users.find((u) => {
      return u.email === email && u
    })

    user !== undefined ? setUser(user) : setUser(undefined)

    return null
  }

  const rows = data.map((dt, key) => {
    return createTableData(dt)
  })

  const [menuOpen, setMenuOpen] = React.useState(false)

  return (
    <TableBody>
      {stableSort(rows, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, index) => {
          const { firstname, surname, email, role_name } = row

          return (
            <>
              <TableRow key={row.id}>
                <TableCell component='th' scope='row' align='left'>
                  {firstname}
                </TableCell>
                <TableCell component='th' scope='row'>
                  {surname}
                </TableCell>
                <TableCell component='th' scope='row'>
                  {email}
                </TableCell>
                <TableCell
                  style={{ overflow: 'hidden' }}
                  component='th'
                  scope='row'
                >
                  {CreateChip({ message: role_name, outlined: false })}
                </TableCell>
                <TableCell>
                  <TableActionMenu
                    tooltip='Manage User'
                    menuOpen={menuOpen}
                    setMenuOpen={setMenuOpen}
                  >
                    <Tooltip
                      title='View, edit user information and access management'
                      placement='right-end'
                    >
                      <MenuItem
                        onClick={() => {
                          getUserInfo(email)
                          setMenuOpen(false)
                          setOpen(true)
                        }}
                      >
                        <EditIcon /> &nbsp;Edit User
                      </MenuItem>
                    </Tooltip>
                    <Tooltip title='Delete this user' placement='right-end'>
                      <MenuItem
                        onClick={() => {
                          getUserInfo(email)
                          setMenuOpen(false)

                          setDeleteOpen(true)
                        }}
                      >
                        <DeleteIcon /> &nbsp;Delete User
                      </MenuItem>
                    </Tooltip>
                  </TableActionMenu>
                </TableCell>
              </TableRow>
            </>
          )
        })}
      {emptyRows > 0 && (
        <TableRow style={{ height: 53 * emptyRows }}>
          <TableCell colSpan={6} />
        </TableRow>
      )}
    </TableBody>
  )
}

const TableBodyEPI: React.FC<TableEPIProps> = ({ data, page, rowsPerPage }) => {
  const classes = useStyles()
  const [openViewDialog, setOpenViewDialog] = React.useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false)
  const [user, setUser] = React.useState<User | undefined>(undefined)
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof UserRowData>('firstname')
  const updateStatus = useSelector(
    (state: RootState) => state.users.updateUserStatus
  )
  const context = useContext(DocumentContext)
  const { setDisplaySnack, setSnackMessage, setSnackSeverity } = context
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof UserRowData
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  useEffect(() => {
    if (updateStatus === Status.success) {
      setSnackMessage('User update successful')
      setSnackSeverity(SnackType.SnackSuccess as string)
      setDisplaySnack(true)
    } else if (updateStatus === Status.failed) {
      // setIsUpdateSuccess(false);
      // setIsUpdateError(true);
      // dispatch({ type: setUpdateProjectStatus.type, payload: Status.idle })
    }
  }, [updateStatus])

  return (
    <>
      <Table aria-label='collapsible table' className={classes.table}>
        <TableHeadings
          headCells={headCells}
          onRequestSort={handleRequestSort}
          order={order}
          orderBy={orderBy}
          rowCount={data.length}
        />
        <TableContent
          data={data}
          page={page}
          rowsPerPage={rowsPerPage}
          isOpen={openViewDialog}
          setOpen={setOpenViewDialog}
          setUser={setUser}
          order={order}
          orderBy={orderBy}
          isDeleteOpen={openDeleteDialog}
          setDeleteOpen={setOpenDeleteDialog}
        />
      </Table>
      {user && (
        <ViewUserInfoDialog
          isOpen={openViewDialog}
          setOpen={setOpenViewDialog}
          user={user}
        />
      )}
      {user && (
        <DeleteUserDialog
          isOpen={openDeleteDialog}
          setOpen={setOpenDeleteDialog}
          user={user}
        />
      )}
    </>
  )
}

export default TableBodyEPI
