import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { fetchProjectRoles } from 'redux/thunks/projectRolesThunk';
import { ProjectRole } from 'shared/types/project';

const useStyles = makeStyles((theme: Theme) => ({
  dialogMinWidth: {
    minWidth: '500px',
    paddingLeft: theme.spacing(2),
  },
  spacer: {
    padding: theme.spacing(0, 0, 2, 0),
  },
  spacerLeft: {
    padding: theme.spacing(0, 0, 0, 2),
  },
}));

export type UserRowData = {
  id?: string;
  firstname: string;
  surname: string;
  email: string;
  roles: ProjectRole[];
  /* role_name: string */
  views: string;
};

export type CheckboxRoleCheck = {
  user_id: string;
  role: ProjectRole;
  isAssigned: boolean;
};

function mapRoleCheckedToUserRowData(roles: CheckboxRoleCheck[]) {
  let newRoles: ProjectRole[] = [];

  for (let i = 0; i < roles.length; i++) {
    if (roles[i].isAssigned) {
      newRoles.push({
        role_name: roles[i].role.role_name,
        id: roles[i].role.id,
        description: roles[i].role.description,
      });
    }
  }

  if (newRoles && newRoles.length > 0) return newRoles;

  return [{ role_name: 'None', id: '-1', description: '-1' } as ProjectRole];
}

const EditUserAccessManagement: React.FC<{
  currentProjectUserData: UserRowData;
  setCurrentProjectUserData: (role: UserRowData) => void;
  state: CheckboxRoleCheck[];
  setState: (state: CheckboxRoleCheck[]) => void;
}> = ({
  currentProjectUserData,
  setCurrentProjectUserData,
  state,
  setState,
}) => {
  const dispatch = useDispatch();
  // const history = useHistory();
  const classes = useStyles();

  const ArrayOfRoles: Array<ProjectRole> = useSelector(
    (state: RootState) => state.projectRoles.roles
  );

  // function getProjectId() {
  //   let p1 = history.location.pathname.replace('/project/', '');
  //   let p2 = p1.substring(0, p1.indexOf('/'));

  //   return p2;
  // }

  // const projectId = getProjectId();

  // const project = useSelector((state: RootState) =>
  //   getProjectById(state, projectId)
  // );

  const [userId, setUserId] = React.useState('');

  function createListOfRoles() {
    let roleList: CheckboxRoleCheck[] = ArrayOfRoles.map(
      (r, i) =>
        ({
          user_id: userId,
          role: r,
          isAssigned: getRoleById(r.role_name),
        } as CheckboxRoleCheck)
    );

    return roleList;
  }

  React.useEffect(() => {
    const rolesThunk = fetchProjectRoles();
    dispatch(rolesThunk);

    setUserId(currentProjectUserData.id);
    setState(createListOfRoles());
  }, []);

  React.useEffect(() => {
    setCurrentProjectUserData({ ...currentProjectUserData });
  }, [state]);

  function getRoleById(name: string) {
    let role = -1;

    if (currentProjectUserData.roles && currentProjectUserData.roles.length > 0)
      role = currentProjectUserData.roles.findIndex(
        (r) => r.role_name === name
      );

    return role === -1 ? false : true;
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newState = [...state];

    newState.forEach((s, i) => {
      if (s.role.role_name === event.target.name) {
        s.isAssigned = event.target.checked;
      }
    });

    if (event.target.name === 'None' && event.target.checked) {
      newState.forEach((s) => {
        if (s.role.role_name !== 'None' && s.isAssigned === true) {
          s.isAssigned = false;
        }
      });
    } else if (event.target.checked && event.target.name !== 'None') {
      newState.forEach((s) => {
        if (s.isAssigned === true && s.role.role_name === 'None') {
          s.isAssigned = false;
        }
      });
    }

    let somethingChecked = false;

    newState.forEach((s) => {
      if (s.isAssigned === true) {
        somethingChecked = true;
      }
    });

    if (!somethingChecked) {
      newState.forEach((s) => {
        if (s.role.role_name === 'None') {
          s.isAssigned = true;
        }
      });
    }

    setState(newState);
    let newProjectRoles: ProjectRole[] = mapRoleCheckedToUserRowData(state);
    let newData: UserRowData = {
      ...currentProjectUserData,
      roles: newProjectRoles,
    };
    setCurrentProjectUserData(newData);
  };

  return (
    <div className={classes.dialogMinWidth}>
      <FormControl component="fieldset">
        <FormLabel component="legend">User's role in the project:</FormLabel>
        <div className={classes.spacer} />
        <FormGroup>
          {state.map((s) => {
            return (
              s.role.role_name === 'None' && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={s.isAssigned}
                      onChange={handleChange}
                      name={s.role.role_name}
                    />
                  }
                  label={s.role.role_name}
                />
              )
            );
          })}
        </FormGroup>
        <FormGroup>
          {state.map((s) => {
            return (
              s.role.role_name !== 'None' && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={s.isAssigned}
                      onChange={handleChange}
                      name={s.role.role_name}
                    />
                  }
                  label={s.role.role_name}
                />
              )
            );
          })}
        </FormGroup>
      </FormControl>
    </div>
  );
};

export default EditUserAccessManagement;
