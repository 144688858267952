import { Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import SyncIcon from '@material-ui/icons/Refresh'
import { Skeleton } from '@material-ui/lab'
import React, {useState} from 'react'
import { ChapterSettings } from 'shared/components/DocumentEditor/ChapterNavigation/ChapterSettings/ChapterSettings'
import { AddChapterDialog } from 'shared/components/DocumentEditor/ChapterNavigation/ChapterSettings/Dialogs/AddChapterDialog'
import { DocumentContext } from 'shared/components/DocumentEditor/DocumentEditor'
import * as reduxDocumentType from 'shared/types/document'
import { SyncDialog } from '../GlobalToolbar/Dialogs/SyncDialog'
import './ChapterNavigationStyle.css'
import {Country} from "../../../types/country";
import {fetchSpecificDocumentV2} from "../../../../redux/thunks/documentsThunk";
import {useDispatch} from "react-redux";
import GlobalIcon from "@material-ui/icons/Language";
import {ChapterObject} from "shared/types/document";

type RecursiveComponentType = {
  chapter: reduxDocumentType.ChapterObject
  index: string
  setOpenSyncDialog: (open: boolean) => void
  setFinalStep: (open: boolean) => void
  setReloadDocuments? : (reloadDocuments: boolean) => void
}

const isActiveInCountry = (activeCountry: Country, chapter:  reduxDocumentType.ChapterObject) => {
  const section = chapter.sections[0]
  const country = section.countrySpecificContent.find(content => content.country.country_name === activeCountry.country_name)

  if(country === undefined){
    return true
  } else if(country.isChapterActiveInCountry) {
    return true
  }

  return false
}

export const RecursiveComponent: React.FC<RecursiveComponentType> = ({
  chapter,
  index,
  setOpenSyncDialog,
  setFinalStep,
  setReloadDocuments,
}) => {
  // const classes = useStyles();

  const newIndex = index + '.'
  const context = React.useContext(DocumentContext)
  const { activeCountry, activeChapter, setActiveChapter, mode } = context

  const hasChildren = chapter && chapter.subchapters

  const isGlobalChapter = (chapter: ChapterObject) => {
    return chapter.sections.find((section: reduxDocumentType.ChapterSection) => {
      const isGlobal = section.countrySpecificContent.find(
        (countryContent: reduxDocumentType.CountrySpecificContent) => {
          return countryContent.isChapterActiveInCountry === false
        }
      )

      return isGlobal === undefined
    }) !== undefined
  }

  const displaySettings = (chapter: ChapterObject) => {
    const showAddSubchapter = chapter.subchapterCreateAllowed
    const showAddSubchapterTemplate = mode === 'template' && activeCountry.country_name === undefined
    const showAddSubchapterProject = !isGlobalChapter && (chapter.refId === null || chapter.refId === undefined)
    const canAddSubchapter = showAddSubchapter || showAddSubchapterTemplate || showAddSubchapterProject

    if(isGlobalChapter(chapter)) {
      if(mode === 'project') {
        return true
      }

      if(activeCountry.country_name !== undefined) {
        return canAddSubchapter;

      }

        return true
    }

    return true

  }

  let countrySpecificName: any = ''
  chapter.sections.map((section: reduxDocumentType.ChapterSection) => {
    if (
      section.countrySpecificContent &&
      section.countrySpecificContent.length > 0
    ) {
      let currentCountry: reduxDocumentType.CountrySpecificContent =
        section.countrySpecificContent.find(content =>
          content.country.country_name === activeCountry.country_name)

      if (currentCountry) {
        countrySpecificName = currentCountry.chapterCountrySpecificName
      }
    }
  })

  let subChapters: reduxDocumentType.ChapterObject[] = []

  if (hasChildren) {
    subChapters = chapter.subchapters.sort(
      (a, b) => a.chapterOrder - b.chapterOrder
    )
    .filter((chapter) => {
        const isGlobalChapter = chapter.sections.find(
          (section: reduxDocumentType.ChapterSection) => {
            const isGlobal = section.countrySpecificContent.find(
              (countryContent: reduxDocumentType.CountrySpecificContent) => {
                return countryContent.isChapterActiveInCountry === false
              }
            )

            return isGlobal === undefined
          }
        ) !== undefined

        const viewingGlobal = activeCountry.country_name === undefined

        if(viewingGlobal) {
          if(isGlobalChapter) {
            return true
          }

          return false
        }

          return true

    })
    .filter((chapters) => isActiveInCountry(activeCountry, chapters))
  }

   return (
      <li>
        <button
          style={
            activeChapter !== undefined && chapter !== undefined
              ? chapter.id === activeChapter.id
                ? { backgroundColor: '#becddc', color: 'white', borderRadius: 25 }
                : mode === 'template' || chapter.refId === undefined
                ? { backgroundColor: 'transparent' }
                : {
                    backgroundColor: '#dddddd',
                    color: '#545454',
                    borderRadius: 25,
                  }
              : { backgroundColor: 'transparent' }
          }
          className='chapter-list-button'
          onClick={() => setActiveChapter(chapter)}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <div
              style={{
                width: '25px'
              }}
            >
              {isGlobalChapter(chapter) && mode === 'template' && <GlobalIcon style={{
                color: '#001744',
                width: '20px'
              }}/>}
            </div>
            {chapter.treeIndexString ? newIndex : 'na'}{' '}
            {countrySpecificName !== undefined && countrySpecificName !== ''
              ? countrySpecificName
              : chapter.name}
            {chapter.id === activeChapter.id && displaySettings(chapter) &&(
              // (mode === 'template' ||
              //   (mode === 'project' && chapter.refId === undefined)) && (
              <ChapterSettings
                setOpenSyncDialog={setOpenSyncDialog}
                setFinalStep={setFinalStep}
                setReloadDocuments={setReloadDocuments}
              />
            )}

          </div>
        </button>
        {hasChildren && (
          <ul className='chapter-sub-list'>
            {subChapters.map(
              (subchapter: reduxDocumentType.ChapterObject, index: number) => {
                const subChapterIndex = newIndex + (index + 1).toString()

                return (
                  <RecursiveComponent
                    chapter={subchapter}
                    index={subChapterIndex}
                    setOpenSyncDialog={setOpenSyncDialog}
                    setFinalStep={setFinalStep}
                    setReloadDocuments={setReloadDocuments}
                  />
                )
              }
            )}
          </ul>
        )}
      </li>
    )

}

export const ChapterNavigation: React.FC = () => {
  const context = React.useContext(DocumentContext)
  const dispatch = useDispatch()
  const { documentChapters, activeDocument, mode, activeCountry } = context
  const [chapterAddOpen, setChapterAddOpen] = React.useState(false)
  const [openSyncDialog, setOpenSyncDialog] = React.useState<boolean>(false)
  const [finalStep, setFinalStep] = React.useState<boolean>(false)
  const [reloadDocuments, setReloadDocuments] = useState(false)
  const [selectedSyncType, setSelectedSyncType] =
    React.useState<string>('Content')

  if (documentChapters !== undefined) {
    return (
      <ul className='chapter-list'>
        <div
          style={{
            border: '1px dashed grey',
            marginBottom: '8px',
          }}
        >
          {mode !== 'template' && (
            <SyncDialog
              open={openSyncDialog}
              setOpen={setOpenSyncDialog}
              finalStep={finalStep}
              setFinalStep={setFinalStep}
              selectedSyncType={selectedSyncType}
              setSelectedSyncType={setSelectedSyncType}
              reloadDocuments={reloadDocuments}
              setReloadDocuments={setReloadDocuments}
            />
          )}
          {mode !== 'template' && (
            <Button
              fullWidth
              onClick={() => {
                const fetchTemplateDocs = fetchSpecificDocumentV2(activeDocument.refId);
                dispatch(fetchTemplateDocs);
                setReloadDocuments(true)
                setOpenSyncDialog(true)
                setSelectedSyncType('Structure')
                setFinalStep(true)
              }}
            >
              <SyncIcon />
              &nbsp;Sync Structure
            </Button>
          )}
          {mode === 'template' && (
            <Button
              fullWidth
              onClick={() => {
                setChapterAddOpen(true)
              }}
            >
              <AddIcon />
              &nbsp;Add New Chapter
            </Button>
          )}
        </div>
        {documentChapters.filter((chapters) => isActiveInCountry(activeCountry, chapters))
          .sort((a, b) => a.chapterOrder - b.chapterOrder)
          .filter((chapter) => {
            const isGlobalChapter = chapter.sections.find(
              (section: reduxDocumentType.ChapterSection) => {
                const isGlobal = section.countrySpecificContent.find(
                  (countryContent: reduxDocumentType.CountrySpecificContent) => {
                    return countryContent.isChapterActiveInCountry === false
                  }
                )

                return isGlobal === undefined
              }
            ) !== undefined

            const viewingGlobal = activeCountry.country_name === undefined

            if(viewingGlobal) {
              if(isGlobalChapter) {
                return true
              }

              return false
            }

              return true

          })
          .map((chapter: reduxDocumentType.ChapterObject, index: number) => {
            return (
              <RecursiveComponent
                key={chapter.id}
                chapter={chapter}
                index={(index + 1).toString()}
                setOpenSyncDialog={setOpenSyncDialog}
                setFinalStep={setFinalStep}
                setReloadDocuments={setReloadDocuments}
              />
            )
          })}
        {activeDocument.name !== undefined && (
          <div
            style={{
              border: '1px dashed grey',
            }}
          >
            <Button
              fullWidth
              onClick={() => {
                setChapterAddOpen(true)
              }}
            >
              <AddIcon />
              &nbsp;Add New Chapter
            </Button>
            <AddChapterDialog
              isOpen={chapterAddOpen}
              setOpen={setChapterAddOpen}
            />
          </div>
        )}
      </ul>
    )
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        padding: '1em',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Skeleton
        style={{
          margin: 0,
        }}
        variant='rect'
        width={'100%'}
        height={'100%'}
      />
    </div>
  )
}
