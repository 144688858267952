import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ChapterObject, Document, DocumentChange } from 'shared/types/document'
import { Project, ProjectUserRole } from 'shared/types/project'
import { Status } from 'shared/types/status'
import '../../config'
import type { RootState } from '../store'

// Define a type for the slice state

type projectsState = {
  projects: Project[]
  allProjects: Project[]
  addProjectResponseErrorMessage: string
  addProjectStatus: Status
  deleteProjectResponseErrorMessage: string
  deleteProjectStatus: Status
  getProjectResponseErrorMessage: string
  getProjectStatus: Status
  updateProjectResponseMessage: string
  updateProjectStatus: Status
  publishDocsStatus: Status
  publishDocsMessage: string
  editDocumentStatus: Status
  editDocumentMessage: string
  syncNewTemplateDocs: Document[]
  syncNewTemplateChapter: ChapterObject
  syncNewTemplateChapterStructure: DocumentChange
  saveToNewVersionStatus: Status
  saveToNewVersionMessage: string
  currentProjectUserRole: string
}

// Define the initial state using that type
const initialState: projectsState = {
  projects: [],
  allProjects: [],
  addProjectResponseErrorMessage: '',
  addProjectStatus: Status.idle,
  deleteProjectResponseErrorMessage: '',
  deleteProjectStatus: Status.idle,
  getProjectResponseErrorMessage: '',
  getProjectStatus: Status.idle,
  updateProjectResponseMessage: '',
  updateProjectStatus: Status.idle,
  publishDocsStatus: Status.idle,
  publishDocsMessage: '',
  editDocumentStatus: Status.idle,
  editDocumentMessage: '',
  syncNewTemplateDocs: [],
  syncNewTemplateChapter: null,
  syncNewTemplateChapterStructure: null,
  saveToNewVersionStatus: Status.idle,
  saveToNewVersionMessage: '',
  currentProjectUserRole: '',
}

export const projects = createSlice({
  name: 'projects',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    createProjects: (state, action: PayloadAction<Project[]>) => {
      state.projects = action.payload.map((project: Project) => project)
    },
    createAllProjects: (state, action: PayloadAction<Project[]>) => {
      state.allProjects = action.payload.map((project: Project) => project)
    },

    addProject: (state, action: PayloadAction<Project>) => {
      state.projects.push(action.payload)
    },

    removeProject: (state, action: PayloadAction<Project>) => {
      state.projects = state.projects.filter(
        (project) => project.id !== action.payload.id
      )
    },

    editProject: (state, action: PayloadAction<Project>) => {
      let index = state.projects.findIndex((p) => p.id === action.payload.id)

      index === -1 ? { ...state } : (state.projects[index] = action.payload)
    },
    setAddProjectStatus: (state, action: PayloadAction<Status>) => {
      state.addProjectStatus = action.payload
    },
    setAddProjectMessage: (state, action: PayloadAction<string>) => {
      state.addProjectResponseErrorMessage = action.payload
    },
    setDeleteProjectStatus: (state, action: PayloadAction<Status>) => {
      state.deleteProjectStatus = action.payload
    },
    setDeleteProjectMessage: (state, action: PayloadAction<string>) => {
      state.deleteProjectResponseErrorMessage = action.payload
    },
    setGetProjectStatus: (state, action: PayloadAction<Status>) => {
      state.getProjectStatus = action.payload
    },
    setGetProjectMessage: (state, action: PayloadAction<string>) => {
      state.getProjectResponseErrorMessage = action.payload
    },
    setUpdateProjectStatus: (state, action: PayloadAction<Status>) => {
      state.updateProjectStatus = action.payload
    },
    setUpdateProjectMessage: (state, action: PayloadAction<string>) => {
      state.updateProjectResponseMessage = action.payload
    },
    editProjects: (state, action: PayloadAction<Project[]>) => {
      state.projects = action.payload
    },
    editDocumentOnProject: (state, action: PayloadAction<Project>) => {
      let index = state.projects.findIndex((p) => p.id === action.payload.id)

      index === -1
        ? { ...state }
        : state.projects.splice(index, 1, action.payload)
    },
    setPublishDocsStatus: (state, action: PayloadAction<Status>) => {
      state.publishDocsStatus = action.payload
    },
    setPublishDocsMessage: (state, action: PayloadAction<string>) => {
      state.publishDocsMessage = action.payload
    },
    setEditDocumentStatus: (state, action: PayloadAction<Status>) => {
      state.editDocumentStatus = action.payload
    },
    setEditDocumentMessage: (state, action: PayloadAction<string>) => {
      state.editDocumentMessage = action.payload
    },
    setSyncNewTemplateDocs: (state, action: PayloadAction<Document[]>) => {
      state.syncNewTemplateDocs = action.payload
    },
    setSyncNewTemplateChapterStructure: (
      state,
      action: PayloadAction<DocumentChange>
    ) => {
      state.syncNewTemplateChapterStructure = action.payload
    },
    setSyncNewTemplateChapterContent: (
      state,
      action: PayloadAction<ChapterObject>
    ) => {
      state.syncNewTemplateChapter = action.payload
    },
    setSaveToNewVersionStatus: (state, action: PayloadAction<Status>) => {
      state.saveToNewVersionStatus = action.payload
    },
    setSaveToNewVersionMessage: (state, action: PayloadAction<string>) => {
      state.saveToNewVersionMessage = action.payload
    },
    setCurrentProjectUserRole: (state, action: PayloadAction<string>) => {
      state.currentProjectUserRole = action.payload
    },
    addDocumentToProject: (
      state,
      action: PayloadAction<{ doc: Document; projectId: string }>
    ) => {
      let project = state.projects.find(
        (p) => p.id === action.payload.projectId
      )
      let projectIndex = state.projects.findIndex(
        (p) => p.id === action.payload.projectId
      )
      let documentIndex = project.documents.findIndex(
        (d) => (d as unknown as string) === action.payload.doc.id
      )
      state.projects[projectIndex].documents.splice(
        documentIndex,
        1,
        action.payload.doc
      )
      state.projects[projectIndex].documents.sort((a, b) =>
        a.name < b.name ? -1 : 1
      )
    },
    updateDocumentOnProject: (
      state,
      action: PayloadAction<{ docs: Document[]; projectId: string }>
    ) => {
      let project = state.projects.find(
        (p) => p.id === action.payload.projectId
      )
      let projectIndex = state.projects.findIndex(
        (p) => p.id === action.payload.projectId
      )
      action.payload.docs.map((doc) => {
        let documentIndex = project.documents.findIndex(
          (d) => d.id === doc.id
        )
        console.log('DOCINDEXPROJ', documentIndex)
        state.projects[projectIndex].documents.splice(
          documentIndex,
          1,
          doc
        )
      })
      state.projects[projectIndex].documents.sort((a, b) =>
        a.name < b.name ? -1 : 1
      )
    },
    //edit surname
    //editemail
    //edit projects
  },
})

export const {
  createProjects,
  createAllProjects,
  removeProject,
  editProject,
  addProject,
  setAddProjectMessage,
  setAddProjectStatus,
  setDeleteProjectMessage,
  setDeleteProjectStatus,
  setGetProjectMessage,
  setGetProjectStatus,
  setUpdateProjectMessage,
  setUpdateProjectStatus,
  editProjects,
  editDocumentOnProject,
  setPublishDocsStatus,
  setPublishDocsMessage,
  setEditDocumentMessage,
  setEditDocumentStatus,
  setSyncNewTemplateDocs,
  setSyncNewTemplateChapterStructure,
  setSyncNewTemplateChapterContent,
  setSaveToNewVersionStatus,
  setSaveToNewVersionMessage,
  setCurrentProjectUserRole,
  addDocumentToProject,
  updateDocumentOnProject,
} = projects.actions

// Other code such as selectors can use the imported `RootState` type
export const getProjects = (state: RootState) => state.projects.projects
export const getProjectById = (state: RootState, id: string) =>
  state.projects.projects.find((p) => p.id === id && p)
export const getProjectUserRolesById = (
  state: RootState,
  pid: string,
  userId: string
) => {
  let project = state.projects.projects.find((p) => p.id === pid && p)

  let users =
    project && project.users && project.users.length > 0 ? project.users : []

  let user =
    users && users.length > 0 ? users.find((u) => u.user.id === userId) : null

  return user
}

export const getProjectUsers = (state: RootState, pid: string) => {
  let project = state.projects.projects.find((p) => p.id === pid && p)

  let users =
    project && project.users && project.users.length > 0 ? project.users : []

  return users as ProjectUserRole[]
}

export const getProjectDocuments = (state: RootState, pid: string) => {
  let project = state.projects.projects.find((p) => p.id === pid && p)

  let documents =
    project && project.documents && project.documents.length > 0
      ? project.documents
      : []

  return documents as Document[]
}

export default projects.reducer
