import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { useContext } from 'react'
import { DocumentContext } from 'shared/components/DocumentEditor/DocumentEditor'
import {
  deleteDFS,
  reIndexChapters,
} from 'shared/components/DocumentEditor/treeUtils'
import * as reduxDocumentType from 'shared/types/document'
import IndexedDBFunctions from "../../../../../../IndexedDBFunctions";

export type RemoveChapterDialogProps = {
  isOpen: boolean
  setOpen: (value: boolean) => void
}

export const RemoveChapterDialog = ({
  isOpen,
  setOpen,
}: RemoveChapterDialogProps) => {
  const context = useContext(DocumentContext)
  const {
    activeChapter,
    activeDocumentChange,
    documentChapters,
    activeDocumentVersion,
    activeDocument,
    activeDocumentVersions,
    setActiveDocument,
    activeCountryIndex,
    setActiveDocumentChange,
    documents,
    setNumberOfEdits
  } = context

  let finalChapterName = activeChapter.name

  if (activeChapter !== undefined && activeChapter.sections !== undefined) {
    if (activeCountryIndex !== null) {
      let section = activeChapter.sections.find((section, index) => {
        return section.name
      })

      if (section.countrySpecificContent[activeCountryIndex] !== undefined) {
        finalChapterName =
          section.countrySpecificContent[activeCountryIndex]
            .chapterCountrySpecificName
      }
    }

    if (finalChapterName === undefined) {
      finalChapterName = activeChapter.name
    }
  }

  const currentChapterName = finalChapterName

  const removeChapter = () => {
    /* setActiveChapter(newMockObj[0]); */
    deleteDFS(documentChapters, activeChapter.id)
    reIndexChapters(documentChapters, 0, '')
    let newReduxObj: reduxDocumentType.DocumentChange = {
      ...activeDocumentChange,
      chapters: documentChapters,
      id: null,
      version: activeDocumentVersion.latestVersion + 1,
      lastUpdated: new Date().toString(),
      changedBy: {
        firstname: sessionStorage.getItem('firstname'),
        id: sessionStorage.getItem('id'),
        surname: sessionStorage.getItem('surname'),
      },
    }
    // delete newReduxObj.id

    let activeDocDocumentChanges = activeDocumentVersion.changes
    activeDocumentVersion.lastUpdated = new Date().toString()

    if (
      activeDocDocumentChanges[activeDocDocumentChanges.length - 1].id === null
    ) {
      activeDocDocumentChanges[activeDocDocumentChanges.length - 1] =
        newReduxObj
    } else {
      activeDocDocumentChanges.push(newReduxObj)
    }
    // activeDocDocumentChanges.push(newReduxObj);

    let newDocument: reduxDocumentType.Document = {
      ...activeDocument,
      documentVersions: activeDocumentVersions,
      lastUpdated: new Date().toString(),
    }
    Object.assign(activeDocument, newDocument)
    setActiveDocument(newDocument)

    setActiveDocumentChange(newReduxObj)
    //localStorage.setItem('currentDocumentEdit', JSON.stringify(documents))

    IndexedDBFunctions.deleteDocuments()
    IndexedDBFunctions.addDocuments(documents)
    //This is just a work around as we encountered issues with Local Storage
    setNumberOfEdits(Math.random() * 1000)
    localStorage.setItem('ContentIsDirty', 'true')
  }

  const handleCancel = () => {
    setOpen(false)
  }

  const handleRemoveChapter = () => {
    removeChapter()
  }

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth='xs'
      aria-labelledby='confirmation-dialog-title'
      open={isOpen}
    >
      <DialogTitle id='confirmation-dialog-title'>
        Remove Chapter/Subchapter
      </DialogTitle>
      <DialogContent dividers>
        Are you sure you wish to remove the Chapter: {currentChapterName}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color='primary'>
          {`Cancel`}
        </Button>
        <Button
          variant='contained'
          onClick={handleRemoveChapter}
          color='primary'
        >
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  )
}
