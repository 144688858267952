import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import React from 'react'
import { Project } from 'shared/types/project'
import { userRow } from './EditProjectUsers'
import EditProjectDialogInfo from './EditProjectDialogInfo'
import EditProjectUsers from './EditProjectUsers'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0, 2, 0, 2)
  },
  EPIAppBarRoot: {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    padding: '0px !important',
    paddingRight: theme.spacing(1)
  },
  EPITabsRoot: {
    padding: '0px !important'
  },
  EPITabBox: {
    padding: theme.spacing(3, 2, 0, 0)
  }
}))
export type navProp = {
  project: Project | undefined
  projectName: string | undefined
  setProjectName: (name: string) => void
  author: string | undefined
  setAuthor: (author: string) => void
  projectUsers: userRow[] | undefined
  setProjectUsers: (projectUserLise: userRow[]) => void
  projectNameError: boolean
  setProjectNameError: (projectNameError: boolean) => void
  authorError: boolean
  setAuthorError: (authorError: boolean) => void
  projectNameHelperText: string
  authorHelperText: string
}

type TabPanelProps = {
  children?: React.ReactNode
  index: any
  value: any
}
const TabPanel: React.FC<TabPanelProps> = props => {
  const { children, value, index, ...other } = props
  const classes = useStyles()

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ padding: 0 }}
    >
      {value === index && <Box className={classes.EPITabBox}>{children}</Box>}
    </div>
  )
}

function a11yProps (index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

const EditTabNavigation: React.FC<navProp> = props => {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const handleChange = (
    event: React.ChangeEvent<Record<string, never>>,
    newValue: number
  ) => {
    setValue(newValue)
  }

  return (
    <div className={classes.root}>
      <AppBar elevation={0} className={classes.EPIAppBarRoot} position='static'>
        <Tabs
          indicatorColor='primary'
          className={classes.EPITabsRoot}
          value={value}
          onChange={handleChange}
          aria-label='edit user tab'
        >
          <Tab label='Project information' {...a11yProps(0)} />
          {/* <Tab className={classes.EPITab} label="Countries" {...a11yProps(1)} /> */}
          <Tab label='Users' {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <Divider />
      <TabPanel value={value} index={0}>
        <EditProjectDialogInfo
          project={props.project}
          projectName={props.projectName}
          author={props.author}
          setProjectName={props.setProjectName}
          setAuthor={props.setAuthor}
          projectNameError={props.projectNameError}
          setProjectNameError={props.setProjectNameError}
          authorError={props.authorError}
          setAuthorError={props.setAuthorError}
          projectNameHelperText={props.projectNameHelperText}
          authorHelperText={props.authorHelperText}
        />
      </TabPanel>
      {/* <TabPanel value={value} index={1}>
                <EditProjectCountry
                    project={props.project}
                    countries={props.countries ? props.countries : []}
                    setCountries={props.setCountries}
                />
            </TabPanel> */}
      <TabPanel value={value} index={1}>
        <EditProjectUsers
          project={props.project}
          finalRows={props.projectUsers}
          setFinalRows={props.setProjectUsers}
        />
      </TabPanel>
    </div>
  )
}

export default EditTabNavigation
