import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import {
  default as DialogContent,
  default as DialogTitle,
} from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import { cloneDeep } from 'lodash'
import React, {useContext, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getProjectById } from 'redux/slices/projects'
import { RootState } from 'redux/store'
import {
  copyDFSForBlueprint,
  editProjectOnDB,
} from 'redux/thunks/projectsThunk'
import { DocumentContext } from 'shared/components/DocumentEditor/DocumentEditor'
import { Country } from 'shared/types/country'
import * as reduxDocumentType from 'shared/types/document'
import { Project } from 'shared/types/project'
import {fetchDocuments} from "../../../../../../redux/thunks/documentsThunk";

const SyncCountriesDialog: React.FC<{
  open: boolean
  setOpen: (open: boolean) => void
  projectId: string
  clickedButton: boolean
  setClickedButton: any
  busy: boolean
  setBusy: (busy: boolean) => void
}> = ({ open, setOpen, projectId, clickedButton, setClickedButton, busy, setBusy }) => {
  // const classes = useStyles();

  const context = useContext(DocumentContext)
  const { setCopiedDocuments, setProjectDocumentsLoaded } = context
  const dispatch = useDispatch()
  const handleClose = () => {
    setOpen(false)
  }

  const templateDocs = useSelector(
    (state: RootState) => state.documents.Documents
  )

  const project = useSelector((state: RootState) =>
    getProjectById(state, projectId)
  )

  useEffect(() => {
    if(clickedButton === true) {
      onSyncCountryButtonClicked()
      setClickedButton(false)
    }
  }, [templateDocs])

  const onSync = () => {
    setBusy(true)
    setClickedButton(true)
    dispatch(fetchDocuments())
  }

  const onSyncCountryButtonClicked = () => {
    const projectTemp: Project = cloneDeep(project)
    let allCountriesTemp: Country[] = []
    projectTemp.documents.map((projectDoc) => {
      let matchingTemplateDoc = templateDocs.find(
        (templateDoc) => templateDoc.id === projectDoc.refId
      )
      let matchingTemplateDocVersion =
        matchingTemplateDoc.documentVersions[
          matchingTemplateDoc.documentVersions.length - 1
        ]
      let matchingTemplateDocChange =
        matchingTemplateDocVersion.changes[
          matchingTemplateDocVersion.changes.length - 1
        ]
      let syncCountriesList: Country[] = cloneDeep(
        matchingTemplateDocChange.countries
      )
      let projectDocVersion =
        projectDoc.documentVersions[projectDoc.documentVersions.length - 1]

      let newReduxDocumentChange: reduxDocumentType.DocumentChange = {
        ...projectDocVersion.changes[projectDocVersion.changes.length - 1],
        countries: syncCountriesList,
        version: projectDocVersion.latestVersion + 1,
        id: null,
        lastUpdated: new Date().toString(),
        changedBy: {
          firstname: sessionStorage.getItem('firstname'),
          id: sessionStorage.getItem('id'),
          surname: sessionStorage.getItem('surname'),
        },
      }

      syncCountriesList.forEach((country) => {
        if (
          allCountriesTemp.findIndex(
            (cntry) => cntry.country_name === country.country_name
          ) === -1
        ) {
          allCountriesTemp.push(country)
        }
      })

      copyDFSForBlueprint(newReduxDocumentChange, newReduxDocumentChange)

      projectDocVersion.changes.push(newReduxDocumentChange)
      projectDocVersion.lastUpdated = new Date().toString()
    })

    allCountriesTemp.forEach((country) => {
      let projectCountry = projectTemp.countries.find(
        (projectCountry) => projectCountry.country_name === country.country_name
      )

      console.log('PROJECTCOUNTRIES', projectTemp.countries)
      console.log('PROJECTCOUNTRIESMATCH', projectCountry)

      if (projectCountry !== undefined) {
        country.active_status = projectCountry.active_status
      }
      else{
        country.active_status = false
      }
    })

    console.log('PROJECTCOUNTRIESALL', allCountriesTemp)
    let newReduxObj: Project = {
      ...projectTemp,
      countries: allCountriesTemp,
      lastUpdated: new Date().toString(),
    }
    console.log(newReduxObj)
    setCopiedDocuments(newReduxObj.documents)
    const updateDocsOnProjectThunk = editProjectOnDB(newReduxObj)
    dispatch(updateDocsOnProjectThunk)
    setProjectDocumentsLoaded(false)
    handleClose()
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>Sync Countries</DialogTitle>
        <DialogContent>
          <div>
            <Typography>
              Are you sure you want to sync Countries with the Template
              Documents?
            </Typography>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary' disabled={busy}>
            Cancel
          </Button>
          <Button onClick={async ()=> await onSync()} color='primary' disabled={busy}>
            Sync
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default SyncCountriesDialog
