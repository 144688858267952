import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import image from 'media/logo.svg';
import styled from 'styled-components';

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 a100%',
  },
  mainDiv: {
    minHeight: '86vh',
    display: 'flex',
    padding: '2em',
  },
  paperContainer: {
    display: 'flex',
    padding: theme.spacing(9, 2, 9, 2),
    justifyContent: 'center',
  },
  centeredFlex: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: theme.spacing(3),
  },
  form: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    padding: theme.spacing(3, 0),
  },
  formRow: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingTop: theme.spacing(3),
  },
  formFields: {
    paddingTop: theme.spacing(3),
  },
  submit: {},
  gridItemPaperContainer: {
    maxWidth: 600,
  },
  checkbox: {
    paddingTop: theme.spacing(1),
    '& > *': {
      paddingLeft: '0 !important',
    },
  },
  formLink: {
    paddingTop: theme.spacing(1),
  },
  link: {
    textDecoration: 'none !important',
    color: theme.palette.text.primary,
  },
}));

const ForgotPasswordEmailSent = () => {
  const classes = useStyles();

  return (
    <div className={classes.mainDiv}>
      <Grid
        spacing={3}
        container
        className={classes.gridContainer}
        justify="center"
        alignItems="center"
      >
        <Grid className={classes.gridItemPaperContainer} item xs={10}>
          <Paper className={classes.paperContainer}>
            <Container maxWidth="xs" component="main">
              <CssBaseline />
              <div className={classes.centeredFlex}>
                <div className={classes.logoContainer}>
                  <LogoImg src={image} alt="" />
                </div>
                <Typography
                  style={{ textAlign: 'center' }}
                  component="h1"
                  variant="h5"
                  color="textPrimary"
                >
                  Thank you! An email has been sent to you.
                </Typography>
                <Typography
                  style={{ textAlign: 'center', justifyContent: 'stretch' }}
                  variant="subtitle2"
                  color="textPrimary"
                >
                  Please check your email to complete the process
                </Typography>
              </div>
            </Container>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default ForgotPasswordEmailSent;

const LogoImg = styled.img`
  display: block;
  margin: 0 auto;
  margin-bottom: 2em;
`;
