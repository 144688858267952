import GlobalIcon from '@material-ui/icons/Language'
import { Skeleton } from '@material-ui/lab'
import * as lookup from 'country-code-lookup'
import React, { useContext } from 'react'
import ReactCountryFlag from 'react-country-flag'
import { DocumentContext } from 'shared/components/DocumentEditor/DocumentEditor'

// interface ChapterNavigationType {
//   children: React.ReactNode
//   menuOpen: boolean
//   setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
//   tooltip: string
// }

export const ChapterHeader: React.FC = () => {
  const context = useContext(DocumentContext)
  const {
    activeChapter,
    activeCountry,
    activeCountries,
    activeDocument,
    mode,
  } = context
  const returnChapterName = () => {
    if (!activeChapter) {
      return ''
    }

    if (
      activeCountry !== null &&
      activeCountries.find(country => country.country_name === activeCountry.country_name) !== undefined
    ) {
      if (
        activeChapter.sections[0].countrySpecificContent.find(
          (content) =>
            content.country.country_name ===
            activeCountry.country_name
        ) !== undefined
      ) {
        let countrySpecificName =
          activeChapter.sections[0].countrySpecificContent.find(
            (content) =>
              content.country.country_name ===
              activeCountry.country_name
          ).chapterCountrySpecificName

        if (countrySpecificName) {
          return countrySpecificName
        }
      }
    }

    return activeChapter.name
  }

  const returnCountryName = () => {
    if (
      activeChapter &&
      activeChapter.sections &&
      activeChapter.sections.length > 0 &&
      activeChapter.sections[0] &&
      activeChapter.sections[0].countrySpecificContent &&
      activeChapter.sections[0].countrySpecificContent.length > 0 &&
      activeChapter.sections[0].countrySpecificContent.find(content =>
        content.country.country_name === activeCountry.country_name) !== undefined &&
      activeChapter.sections[0].countrySpecificContent.find(content =>
        content.country.country_name === activeCountry.country_name)
        .country.country_name
    ) {
      return activeChapter.sections[0].countrySpecificContent.find(content =>
        content.country.country_name === activeCountry.country_name).country.country_name
    }

    return 'Global'
  }

  if (activeChapter !== undefined && activeChapter.sections !== undefined) {
    const documentName = activeDocument.name
    const chapterName = returnChapterName()
    const countryName = returnCountryName()
    const projectChapter =
      activeChapter.refId === undefined && mode !== 'template'
        ? ' - Project Only Chapter'
        : ''
    const countryCode =
      countryName !== 'Global' ? lookup.byCountry(countryName).iso2 : 'Global'

    return (
      <div style={{ paddingLeft: '3em' }}>
        <h3 style={{ flexGrow: 1, alignItems: 'center', display: 'flex' }}>
          Editing: {documentName} - {chapterName}
          {projectChapter}
          &nbsp;(
          {countryName !== 'Global' ? (
            <ReactCountryFlag countryCode={countryCode} svg />
          ) : (
            <GlobalIcon
              style={{
                width: 16,
              }}
            />
          )}
          &nbsp;
          {countryName !== '' ? ` ${countryName})` : ` (Global)`}
        </h3>
      </div>
    )
  }

  return (
    <div
      style={{
        width: '70vw',
        padding: '1em',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Skeleton
        style={{
          margin: 0,
        }}
        variant='rect'
        width={'100%'}
        height={40}
      />
    </div>
  )
}
