import { createMuiTheme } from '@material-ui/core/styles'

export const EPICENTREMuiTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#001744'
    },
    /* secondary: {
            main: '#01677e',
        }, */
    secondary: {
      main: '#1C1C1C'
    },
    /* secondary: {
            main: '#37017e',
        }, */
    background: {
      paper: '#fff',
      default: '#EEF2F6'
    },
    text: {
      primary: '#262F3E',
      secondary: '#5C5F68',
      disabled: '#fff',
      hint: '#001744'
    },
    success: {
      main: '#00bda5'
    },
    info: {
      main: '#001744'
    },
    error: {
      main: '#CE1443'
    }
  },
  typography: {
    fontFamily: '"Lato","Open Sans", "Helvetica", "Arial", "sans-serif"',
    button: {
      fontFamily: '"Lato","Open Sans", "Helvetica", "Arial", "sans-serif"',
      textTransform: 'none',
      letterSpacing: '0.08em'
    },
    body1: {
      fontFamily: 'Open Sans'
    },
    body2: {
      fontFamily: 'Open Sans'
    },
    subtitle1: {
      fontFamily: 'Lato',
      color: 'var(--prime-color)',
      fontSize: '1rem'
    }
  },
  overrides: {
    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: '#051844',
          color: 'white',
          '& .MuiListItemIcon-root': {
            color: 'white'
          },
          '&:hover': {
            backgroundColor: '#4A8CD9'
          }
        }
      }
    }
  }
})
