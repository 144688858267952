import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import { addDocumentOnDB } from 'redux/thunks/documentsThunk'
import Loader from 'shared/components/Feedback/Loader'
import { Document } from 'shared/types/document'
import { Status } from 'shared/types/status'
import { useStyles } from './StyleCreateDocumentDialog'

export type dialogProps = {
  isOpen: boolean
  setOpen: (value: boolean) => void
}

const CreateDocumentDialog = ({ isOpen, setOpen }: dialogProps) => {
  const classes = useStyles()
  const [DocumentName, setDocumentName] = React.useState<string>('')
  const [author, setAuthor] = React.useState<string>('')
  // const [copyTemplates, setCopyTemplates] = React.useState<boolean>(false);
  const [DocumentNameError, setDocumentNameError] =
    React.useState<boolean>(false)
  const [authorError, setAuthorError] = React.useState<boolean>(false)
  const dispatch = useDispatch()
  // const docs = useSelector((state: RootState) => state.documents.Documents);
  const firstName = sessionStorage.getItem('firstname')
  const surname = sessionStorage.getItem('surname')
  const userId = sessionStorage.getItem('id')

  // const [isError, setIsError] = useState(false);
  // const [isSuccess, setIsSuccess] = useState(false);

  const creationStatus = useSelector(
    (state: RootState) => state.documents.addDocumentStatus
  )
  const businessBlueprintDoc = useSelector(
    (state: RootState) => state.documents.Documents
  ).find((doc) => doc.id === '0')
  const businessBlueprintDocVersion =
    businessBlueprintDoc?.documentVersions[
      businessBlueprintDoc.documentVersions.length - 1
    ]
  const businessBlueprintDocChange =
    typeof businessBlueprintDocVersion !== 'string'
      ? businessBlueprintDocVersion?.changes[
          businessBlueprintDocVersion.changes.length - 1
        ]
      : null

  const handleClose = () => {
    setOpen(false)
  }

  const createDocument = () => {
    let valid = true

    if (DocumentName === '') {
      setDocumentNameError(true)
      valid = false

      return
    }
    setDocumentNameError(false)

    if (author === '') {
      setAuthorError(true)
      valid = false

      return
    }
    setAuthorError(false)

    if (valid) {
      const Document: Document = {
        id: undefined,
        refId: undefined,
        name: DocumentName,
        author,
        lastUpdated: new Date().toString(),
        activeVersion: 0,
        documentVersions: [
          {
            changes: [
              {
                globalTreeIndexCounter: 0,
                lastUpdated: new Date().toString(),
                version: 0,
                activeStatus: true,
                changedBy: {
                  firstname: firstName,
                  surname,
                  id: userId,
                },
                chapters: [],
                sections: [
                  {
                    chapterGlobalContent: {
                      rawHTML: '',
                    },
                    countrySpecificContent: [],
                    isClientEditable: false,
                    isGlobal: true,
                    name: DocumentName,
                    viewing: true,
                    wasChanged: true,
                  },
                ],
                countries: businessBlueprintDocChange.countries,
                users: [],
              },
            ],
            latestVersion: 0,
            lastUpdated: new Date().toString(),
            country: null,
          },
        ],
        enabled: true,
      }
      const addDocumentThunk = addDocumentOnDB(Document)
      dispatch(addDocumentThunk)
      setOpen(false)
    }
  }

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title' className={classes.dialogTitle}>
          Create Document
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <form className={classes.form} noValidate onSubmit={createDocument}>
            <Grid container spacing={0}>
              <Grid xs={12} item>
                <div className={classes.formFields}>
                  <TextField
                    autoComplete='DocumentName'
                    id='DocumentName'
                    label='Document Name'
                    margin='normal'
                    name='DocumentName'
                    variant='outlined'
                    fullWidth
                    required
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setDocumentName(event.target.value)
                    }
                    error={DocumentNameError ? true : false}
                    helperText={
                      DocumentNameError
                        ? 'Please provide a name for the Document'
                        : ''
                    }
                  />
                </div>
              </Grid>
              <Grid xs={12} item>
                <div className={classes.formFields}>
                  <TextField
                    autoComplete='author'
                    id='author'
                    label='Author'
                    margin='normal'
                    name='author'
                    variant='outlined'
                    fullWidth
                    required
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setAuthor(event.target.value)
                    }
                    error={authorError ? true : false}
                    helperText={
                      authorError
                        ? 'Please provide a name for the author of this Document'
                        : ''
                    }
                  />
                </div>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
          <Button onClick={createDocument} color='primary' variant='contained'>
            Create
          </Button>
        </DialogActions>
      </Dialog>
      <Loader open={creationStatus === Status.loading} />
      {/* {
        isSuccess && (
          <EPISnackbar
            message={Message ? Message : 'Project was created'}
            messageType={'success'}
            isOpen={isSuccess}
            setIsOpen={setIsSuccess}
          />
        )
      }
      {
        isError && (
          <EPISnackbar
            message={Message ? Message : 'Unknown error. Project was not created'}
            messageType={'error'}
            isOpen={isError}
            setIsOpen={setIsError}
          />
        )
      } */}
    </div>
  )
}

export default CreateDocumentDialog
