import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core'
import { useContext, useState } from 'react'
import { DocumentContext } from 'shared/components/DocumentEditor/DocumentEditor'
import {
  createChapterOrSubchapter,
  reIndexChapters,
} from 'shared/components/DocumentEditor/treeUtils'
import * as reduxDocumentType from 'shared/types/document'
import IndexedDBFunctions from "../../../../../../IndexedDBFunctions";

export type AddChapterDialogProps = {
  isOpen: boolean
  setOpen: (value: boolean) => void
}

export const AddChapterDialog = ({
  isOpen,
  setOpen,
}: AddChapterDialogProps) => {
  const context = useContext(DocumentContext)
  const {
    activeChapter,
    activeCountries,
    activeDocumentChange,
    documentChapters,
    activeDocumentVersion,
    activeDocument,
    activeDocumentVersions,
    activeCountryIndex,
    sections,
    setActiveChapter,
    setActiveDocumentChange,
    setActiveDocument,
    activeCountry,
    documents,
    setNumberOfEdits
  } = context

  if (activeChapter !== undefined && activeChapter.sections !== undefined) {
    let finalChapterName = activeChapter.name

    if (activeCountryIndex !== null) {
      let section = activeChapter.sections.find((section, index) => {
        return section.name
      })

      if (section.countrySpecificContent[activeCountryIndex] !== undefined) {
        finalChapterName =
          section.countrySpecificContent[activeCountryIndex]
            .chapterCountrySpecificName
      }
    }

    if (finalChapterName === undefined) {
      finalChapterName = activeChapter.name
    }
  }

  const handleCancel = () => {
    setChapterName('')
    setOpen(false)
  }

  const addChapter = (chapterName: any) => {
    const tempCountries = JSON.parse(JSON.stringify(activeCountries))
    tempCountries.map(country => {
      if(activeCountry.country_name !== undefined && country.country_name !== activeCountry.country_name) {
        country.active_status = false
      }
    })
    const newChapter: reduxDocumentType.ChapterObject =
      createChapterOrSubchapter(
        chapterName,
        tempCountries,
        sections,
        activeDocumentChange
      )
    console.log('ACTIVECOUNTRIES', newChapter)
    const documentChaptersCopy = JSON.parse(JSON.stringify(documentChapters))
    documentChaptersCopy.push(newChapter)
    Object.assign(documentChapters, documentChaptersCopy)

    let newReduxObj: reduxDocumentType.DocumentChange = {
      ...activeDocumentChange,
      chapters: documentChaptersCopy,
      id: null,
      lastUpdated: new Date().toString(),
      globalTreeIndexCounter: activeDocumentChange.globalTreeIndexCounter + 1,
      changedBy: {
        firstname: sessionStorage.getItem('firstname'),
        id: sessionStorage.getItem('id'),
        surname: sessionStorage.getItem('surname'),
      },
    }
    let activeDocDocumentChanges = activeDocumentVersion.changes
    activeDocumentVersion.lastUpdated = new Date().toString()
    activeDocDocumentChanges.push(newReduxObj)
    let newDocument: reduxDocumentType.Document = {
      ...activeDocument,
      documentVersions: activeDocumentVersions,
      lastUpdated: new Date().toString(),
    }
    Object.assign(activeDocument, newDocument)
    setActiveDocument(newDocument)
    setActiveChapter(newChapter)
    reIndexChapters(newReduxObj.chapters, 0, '')
    setActiveDocumentChange(newReduxObj)
    //localStorage.setItem('currentDocumentEdit', JSON.stringify(documents))


    IndexedDBFunctions.deleteDocuments()
    IndexedDBFunctions.addDocuments(documents)
    //This is just a work around as we encountered issues with Local Storage
    setNumberOfEdits(Math.random() * 1000)
    localStorage.setItem('ContentIsDirty', 'true')
    // dispatch(editDocument(activeDocument))
    // setActiveDocument(JSON.parse(JSON.stringify(activeDocument)))
  }

  const handleAddChapter = () => {
    // I selected to add a new chapter
    addChapter(chapterName)
    handleCancel()
  }

  const [chapterName, setChapterName] = useState('')

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      fullWidth
      maxWidth='xs'
      aria-labelledby='confirmation-dialog-title'
      open={isOpen}
    >
      <DialogTitle id='confirmation-dialog-title'>
        Add Chapter/Subchapter
      </DialogTitle>
      <DialogContent dividers>
        <TextField
          autoFocus
          id='name'
          label='Chapter name'
          type='text'
          fullWidth
          value={chapterName}
          onChange={(
            e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => {
            setChapterName(e.target.value as string)
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button autoFocus onClick={handleCancel} color='primary'>
          Cancel
        </Button>
        <Button variant='contained' onClick={handleAddChapter} color='primary'>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}
