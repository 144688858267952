import { Divider } from '@material-ui/core'
import { Editor } from '@tinymce/tinymce-react'
import React, { useContext } from 'react'
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer'
import { DocumentContext } from 'shared/components/DocumentEditor/DocumentEditor'
import {findDFSBySharedId} from 'shared/components/DocumentEditor/treeUtils'
import { ChapterObject, ChapterSection } from 'shared/types/document'
import { ComparisonContext } from 'views/Pages/TemplateEnvironment/Home/DocumentHome/Versions/ChangeComparison'

export const ComparisonSections: React.FC = () => {
  const context = useContext(DocumentContext)
  const { activeDocument, activeCountryIndex } = context

  const comparisonContext = useContext(ComparisonContext)
  const {
    prevChangeActiveChapter,
    setPrevChangeActiveChapter,
    viewToggle,
    currentChangeActiveChapter,
    setCurrentChangeActiveChapter,
    prevChange,
    currentChange,
  } = comparisonContext

  console.log(prevChange)
  console.log(currentChange)
  const [activeChapter, setActiveChapter] = React.useState<ChapterObject>(null)

  React.useEffect(() => {
    //
    if (prevChangeActiveChapter !== undefined) {
      if (
        currentChangeActiveChapter !== undefined &&
        prevChangeActiveChapter.sharedId ===
          currentChangeActiveChapter.sharedId
      ) {
        return
      }

      let matchingChapter = findDFSBySharedId(
        currentChange.chapters,
        prevChangeActiveChapter.sharedId
      )
      setCurrentChangeActiveChapter(matchingChapter)

      if (matchingChapter !== undefined) {
        setActiveChapter(matchingChapter)
      } else {
        setActiveChapter(currentChangeActiveChapter)
      }
    }
  }, [prevChangeActiveChapter])

  React.useEffect(() => {
    //
    if (currentChangeActiveChapter !== undefined) {
      if (
        prevChangeActiveChapter !== undefined &&
        currentChangeActiveChapter.sharedId ===
          prevChangeActiveChapter.sharedId
      ) {
        return
      }

      let matchingChapter = findDFSBySharedId(
        prevChange.chapters,
        currentChangeActiveChapter.sharedId
      )
      setPrevChangeActiveChapter(matchingChapter)

      if (matchingChapter !== undefined) {
        setActiveChapter(currentChangeActiveChapter)
      } else {
        setActiveChapter(currentChangeActiveChapter)
      }
    }
  }, [currentChangeActiveChapter])

  React.useEffect(() => {
    // if (prevChange) {
    setActiveChapter(currentChange.chapters[0])
    setPrevChangeActiveChapter(prevChange.chapters[0])
    // }
  }, [])

  console.log(activeChapter)

  return (
    <>
      {activeChapter &&
        activeChapter.sections &&
        activeDocument.name !== 'Wage Type Catalogue' &&
        activeChapter.sections
          .filter((section) => {
            if (activeCountryIndex === null) {
              return section.isGlobal
            }

            return true
          })
          .map((chapterSection: ChapterSection, index) => {
            let oldContent = ''
            let newContent = ''

            if (chapterSection.isGlobal) {
              newContent = chapterSection.chapterGlobalContent.rawHTML
            } else if (chapterSection.isClientEditable) {
              if (activeCountryIndex !== null) {
                newContent =
                  chapterSection.countrySpecificContent[activeCountryIndex]
                    .clientContent.rawHTML
              }
            } else {
              newContent =
                chapterSection.countrySpecificContent[activeCountryIndex]
                  .nonClientContent.rawHTML
            }

            if (prevChangeActiveChapter) {
              let prevSection = prevChangeActiveChapter.sections[index]
              // console.log(prevChangeActiveChapter)

              if (prevSection) {
                if (prevSection.isGlobal) {
                  oldContent = prevSection.chapterGlobalContent.rawHTML
                } else if (prevSection.isClientEditable) {
                  if (activeCountryIndex !== null) {
                    oldContent =
                      prevSection.countrySpecificContent[activeCountryIndex]
                        .clientContent.rawHTML
                  }
                } else {
                  if (activeCountryIndex !== null) {
                    oldContent =
                      prevSection.countrySpecificContent[activeCountryIndex]
                        .nonClientContent.rawHTML
                  }
                }
              } else {
                oldContent = 'Section does not exist on Project Document'
              }
            } else {
              oldContent = ''
            }

            return (
              <div
                style={{
                  paddingTop: '1em',
                  paddingBottom: '1em',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    height: 'auto',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    flexGrow: 1,
                    /* paddingRight: '2em', */
                  }}
                >
                  <h3>Section: {chapterSection.name} </h3>
                </div>

                <div>
                  {!viewToggle && (
                    <ReactDiffViewer
                      oldValue={oldContent}
                      newValue={newContent}
                      splitView={true}
                      compareMethod={DiffMethod.LINES}
                      showDiffOnly={false}
                    />
                  )}
                  {viewToggle && (
                    <div
                      style={{
                        display: 'flex',
                        height: 'auto',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        flexGrow: 1,
                        /* paddingRight: '2em', */
                      }}
                    >
                      <Editor
                        apiKey={
                          'lk8ioa673o60urr91q2n1synl65hxg5def93oobzlaadkwrl'
                        }
                        value={oldContent}
                        disabled={true}
                        init={{
                          height: 500,
                          width: 400,
                          min_height: 200,
                          min_width: 200,
                          resize: 'both',
                          menubar: false,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount table',
                          ],
                          toolbar: false,
                          content_style:
                            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                        }}
                        // onEditorChange={(newState: string) => {
                        //   setInitialState(newState);
                        // }}
                      />
                      <Editor
                        apiKey={
                          'lk8ioa673o60urr91q2n1synl65hxg5def93oobzlaadkwrl'
                        }
                        value={newContent}
                        disabled={true}
                        init={{
                          height: 500,
                          width: 400,
                          min_height: 200,
                          min_width: 200,
                          resize: 'both',
                          menubar: false,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount table',
                          ],
                          toolbar: false,
                          content_style:
                            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                        }}
                        // onEditorChange={(newState: string) => {
                        //   setInitialState(newState);
                        // }}
                      />
                    </div>
                  )}
                </div>
                {/* {!prevChangeActiveChapter && (
                  <h3>New chapter. No content to compare against.</h3>
                )} */}
                <Divider />
              </div>
            )
          })}
    </>
  )
}
