import { Tooltip } from '@material-ui/core'
import * as reduxDocumentType from 'shared/types/document'
import {Country} from "../../../../types/country";
import React from "react";
import {DocumentContext} from "../../DocumentEditor";

type RecursiveComponentType = {
  chapter: reduxDocumentType.ChapterObject
  oldChapter: reduxDocumentType.ChapterObject
  activeCountryIndex: number
  activeChapter: reduxDocumentType.ChapterObject
}

type RecursiveComponentTypes = {
  chapter: reduxDocumentType.ChapterObject
  oldChapter: reduxDocumentType.ChapterObject
  activeCountryIndex: number
  activeChapter: reduxDocumentType.ChapterObject
  index: number
}

const isActiveInCountry = (activeCountry: Country, chapter:  reduxDocumentType.ChapterObject) => {
  const section = chapter.sections[0]
  const country = section.countrySpecificContent.find(content => content.country.country_name === activeCountry.country_name)

  if(country === undefined){
    return true
  } else if(country.isChapterActiveInCountry) {
    return true
  }

  return false
}

export const RecursiveComponent: React.FC<RecursiveComponentTypes> = ({
  chapter,
  oldChapter,
  activeCountryIndex,
  activeChapter,
  index,
}) => {
  // const classes = useStyles();

  const newIndex = index + '.'
  const hasChildren =
    chapter && chapter.subchapters && chapter.subchapters.length > 0

  let countrySpecificName: any = ''
  console.log(chapter)
  chapter.sections.map((section: reduxDocumentType.ChapterSection) => {
    if (
      section.countrySpecificContent &&
      section.countrySpecificContent.length > 0
    ) {
      let currentCountry: reduxDocumentType.CountrySpecificContent =
        section.countrySpecificContent[activeCountryIndex]

      if (currentCountry) {
        countrySpecificName = currentCountry.chapterCountrySpecificName
          ? currentCountry.chapterCountrySpecificName
          : chapter.name
      }
    }
  })

  let oldCountrySpecificName: any = ''

  if (oldChapter) {
    oldChapter.sections.map((section) => {
      if (
        section.countrySpecificContent &&
        section.countrySpecificContent.length > 0
      ) {
        let currentCountry: reduxDocumentType.CountrySpecificContent =
          section.countrySpecificContent[activeCountryIndex]

        if (currentCountry) {
          oldCountrySpecificName = currentCountry.chapterCountrySpecificName
            ? currentCountry.chapterCountrySpecificName
            : oldChapter.name
        }
      }
    })
  }

  let backgroundColorDiff = 'transparent'
  let toolTipText = 'Unchanged Chapter'

  if (oldChapter === undefined) {
    backgroundColorDiff = '#3cc437' //Green for added chapter
    toolTipText = 'New Chapter Added'
  } else {
    if (countrySpecificName !== oldCountrySpecificName) {
      backgroundColorDiff = '#3cc437'
      toolTipText = 'Chapter Name Changed'
    }
  }

  let subChapters: reduxDocumentType.ChapterObject[] = []

  if (hasChildren) {
    subChapters = chapter.subchapters.sort(
      (a, b) => a.chapterOrder - b.chapterOrder
    )
  }

  return (
    <li>
      <Tooltip title={toolTipText}>
        <button
          style={
            activeChapter
              ? chapter.id === activeChapter.id
                ? {
                    backgroundColor: backgroundColorDiff,
                    paddingTop: '12px',
                    borderRadius: 25,
                  }
                : {
                    backgroundColor: backgroundColorDiff,
                    paddingTop: '12px',
                    borderRadius: 25,
                  }
              : {
                  backgroundColor: backgroundColorDiff,
                  paddingTop: '12px',
                  borderRadius: 25,
                }
          }
          className='chapter-list-button'
          // onClick={() => changeChapter(chapter)}
        >
          {chapter.treeIndexString ? newIndex : 'na'}{' '}
          {countrySpecificName !== '' ? countrySpecificName : chapter.name}
        </button>
      </Tooltip>
      {hasChildren && (
        <ul className='chapter-sub-list'>
          {subChapters.map(
            (subchapter: reduxDocumentType.ChapterObject, index) => {
              let oldSubchapter = undefined

              if (oldChapter !== undefined) {
                oldSubchapter = oldChapter.subchapters.find(
                  (oldsChapter) => oldsChapter.name === subchapter.name
                )
              }

              return (
                <RecursiveComponent
                  chapter={subchapter}
                  oldChapter={oldSubchapter}
                  activeCountryIndex={activeCountryIndex}
                  activeChapter={activeChapter}
                  index={index+1}
                />
              )
            }
          )}
        </ul>
      )}
    </li>
  )
}

export const SyncStructureComparisonNewChapters: React.FC<{
  mockObject: reduxDocumentType.ChapterObject[]
  oldChapters: reduxDocumentType.ChapterObject[]
  recursiveObj: RecursiveComponentType
}> = ({ mockObject, oldChapters, recursiveObj }) => {
  const context = React.useContext(DocumentContext)
  const { activeCountry } = context

  let newMockObject = mockObject.sort((a, b) => a.chapterOrder - b.chapterOrder)

  return (
    <ul className='chapter-list'>
      {newMockObject.filter((chapter) => isActiveInCountry(activeCountry, chapter)).map(
        (chapter: reduxDocumentType.ChapterObject, index: any) => {
          let oldChapter = oldChapters.find(
            (oldChapter) => oldChapter.sharedId === chapter.sharedId
          )

          return (
            <RecursiveComponent
              key={chapter.id}
              chapter={chapter}
              oldChapter={oldChapter}
              activeCountryIndex={recursiveObj.activeCountryIndex}
              activeChapter={recursiveObj.activeChapter}
              index={index+1}
            />
          )
        }
      )}
    </ul>
  )
}
