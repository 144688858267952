import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import React from 'react';
import InviteUsersTabs from './InviteUsersTabs';

const InviteMainDialog: React.FC<{
  open: boolean;
  setOpen: (o: boolean) => void;
  updateRows: () => void;
  setIsSuccess: (s: boolean) => void;
}> = ({ open, setOpen, updateRows, setIsSuccess }) => {
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    updateRows();
    setIsSuccess(true);
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent style={{ padding: '32px' }}>
          <InviteUsersTabs handleClose={handleClose} updateRows={updateRows} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default InviteMainDialog;
