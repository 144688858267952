import { FormControlLabel, Grid, Switch } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import RefreshIcon from '@material-ui/icons/Refresh'
import React, {useContext, useState} from 'react'
import {useDispatch} from 'react-redux'
import { fetchDocumentsV2 } from 'redux/thunks/documentsThunk'
import CreateDocumentDialog from '../Dialogs/CreateDocumentDialog'
import TableSelectorGroup, {
  TableSelectorGroupProp,
} from './DocumentsTableSelectorGroup'
import {DocumentContext} from "../../../../../../../../shared/components/DocumentEditor/DocumentEditor";

const useStyles = makeStyles((theme: Theme) => ({
  titleDiv: {
    display: 'grid',
    gridTemplateColumns: '2fr  1fr',
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  left: {
    gridRows: '2/3',
  },
  right: {
    gridRows: '2/4',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
  },
  formSpan: {
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(4),
    flex: '1 1 15px',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      flex: '1 1 25ch',
    },
  },
  icon1: { marginRight: '8px !important' },
  button: {},
}))

const TableHeader: React.FC<TableSelectorGroupProp> = ({
  version,
  setVersion,
  role,
  setRole,
  search,
  setSearch,
  showDisabled,
  setShowDisabled,
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()

  const context = useContext(DocumentContext)
  const {projectDocumentsLoaded, setProjectDocumentsLoaded, documents} = context

  React.useEffect(() => {
    if (documents.find((doc) => typeof doc !== 'string') === undefined) {
      setProjectDocumentsLoaded(false)
    } else {
      setProjectDocumentsLoaded(true)
    }
  }, [documents])

  const handleChange = () => {
    if (showDisabled) {
      setShowDisabled(false)
    } else {
      setShowDisabled(true)
    }
  }

  return (
    <div>
      <div
        style={{
          flexGrow: 1,
        }}
      >
        <Grid container spacing={1} justify='space-between' alignItems='center'>
          <Grid item xs={3}>
            <Typography variant='h5' color='textPrimary' component='h1'>
              Document Management
            </Typography>
            <Typography variant='body2' color='textSecondary' component='p'>
              Create, edit or remove Documents of EPI-CENTRE
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <TableSelectorGroup
              version={version}
              setVersion={setVersion}
              role={role}
              setRole={setRole}
              search={search}
              setSearch={setSearch}
              showDisabled={showDisabled}
              setShowDisabled={setShowDisabled}
            />
          </Grid>
          <Grid container xs={6} alignItems='center' justify='flex-end'>
            <FormControlLabel
              control={
                <Switch
                  checked={showDisabled}
                  onChange={handleChange}
                  value='disabled'
                />
              }
              label='Show Disabled'
            />
            <Button
              className={classes.button}
              style={{
                backgroundColor: '#2c8cd8',
                color: 'white',
              }}
              startIcon={<RefreshIcon className={classes.icon1} />}
              variant='contained'
              onClick={() => {
                setProjectDocumentsLoaded(false)
                const fetchDocumentsThunk = fetchDocumentsV2()
                dispatch(fetchDocumentsThunk)
              }}
            >
              Refresh
            </Button>
            <Button
              style={{
                marginLeft: '0.25em',
              }}
              className={classes.button}
              color='primary'
              startIcon={<AddIcon className={classes.icon1} />}
              variant='contained'
              disabled={!projectDocumentsLoaded || documents.find((doc) => typeof doc.documentVersions[0].changes !== 'string') === undefined}
              onClick={() => setOpen(true)}
            >
              Create Document
            </Button>
          </Grid>
        </Grid>
      </div>
      <CreateDocumentDialog isOpen={open} setOpen={setOpen} />
    </div>
  )
}

export default TableHeader
