import {
  Button,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import SaveIcon from '@material-ui/icons/Save'
import { Skeleton } from '@material-ui/lab'
import * as lookup from 'country-code-lookup'
import { cloneDeep } from 'lodash'
import React, { useEffect } from 'react'
import ReactCountryFlag from 'react-country-flag'
import { useDispatch, useSelector } from 'react-redux'
import { setUpdateDocumentStatus } from 'redux/slices/documents'
import { RootState } from 'redux/store'
import {editDocumentOnDB, fetchDocumentsV2} from 'redux/thunks/documentsThunk'
import { DocumentContext } from 'shared/components/DocumentEditor/DocumentEditor'
import { SnackType } from 'shared/components/Feedback/EPISnackBar'
import Loader from 'shared/components/Feedback/Loader'
import {ChapterObject, Content, CountrySpecificContent, DocumentChange} from 'shared/types/document'
import { Status } from 'shared/types/status'
import AddCountryDialog from 'views/Pages/TemplateEnvironment/Home/DocumentHome/ManageCountries/AddCountryDialog'
import {Country} from "../../../../../../shared/types/country";
import IndexedDBFunctions from "../../../../../../IndexedDBFunctions";

const ManageCountries = () => {
  const context = React.useContext(DocumentContext)
  const {
    setActiveCountries,
    activeCountries,
    activeDocumentChange,
    setActiveDocumentChange,
    documents,
    setActiveDocument,
    activeDocument,
    setSnackMessage,
    setSnackSeverity,
    setDisplaySnack,
    setContentIsDirty,
    setNumberOfEdits
  } = context
  const dispatch = useDispatch()

  const handleDelete = (countryToDelete: string) => () => {
    console.log('COUNTRIES CHANGED')
    let newCountries = activeCountries.filter(
      (country) => country.country_name !== countryToDelete
    )
    setActiveCountries(JSON.parse(JSON.stringify(newCountries)))
    setActiveDocumentChange(JSON.parse(JSON.stringify(activeDocumentChange)))
  }

  const [openAddCountryDialog, setOpenAddCountryDialog] = React.useState(false)
  const [busy, setBusy] = React.useState(false)

  const onAddCountryButtonClicked = () => {
    //Some syncing stuff
    setOpenAddCountryDialog(true)
  }

  const publishDocsStatus = useSelector(
    (state: RootState) => state.documents.updateDocumentStatus
  )

  useEffect(() => {
    if (publishDocsStatus === Status.success) {
      setSnackMessage('Document Countries updated successfully')
      setSnackSeverity(SnackType.SnackSuccess as string)
      setDisplaySnack(true)
      // const fetchSpecificDocThunk = fetchSpecificDocumentV2(activeDocument.id)
      // dispatch(fetchSpecificDocThunk)
      setBusy(false)
      dispatch({ type: setUpdateDocumentStatus.type, payload: Status.idle })
      dispatch(fetchDocumentsV2())
    } else if (publishDocsStatus === Status.failed) {
      // setIsUpdateSuccess(false);
      // setIsUpdateError(true);
      // dispatch({ type: setUpdateProjectStatus.type, payload: Status.idle })
      dispatch({ type: setUpdateDocumentStatus.type, payload: Status.idle })
      setBusy(false)
    }
  }, [publishDocsStatus])

  const getNewCountries = (chapter: ChapterObject, countries: Country[]) => {
    let newCountries: Country[] = []
    countries.map((country) => {
      if(chapter.sections[0].countrySpecificContent.find(
        (content) => content.country.country_name === country.country_name) === undefined) {
        newCountries.push(country)
      }
    })

    return newCountries
  }

  const isCountrySpecific = (chapter: ChapterObject) => {
    if(chapter.sections[0].countrySpecificContent.find((content) => !content.isChapterActiveInCountry) !== undefined) {
      return true
    }

    return false
  }

  const addNewCountrySpecificContent = (chapter: ChapterObject, newCountries: Country[], chapterName: string ,chapterInCountry: boolean) => {
    chapter.sections.map((section) => {
      newCountries.map((country) => {
        const newnonClientContent: Content = {
          rawHTML: `This is ${country.country_name} content!`,
        }
        const newClientContent: Content = {
          rawHTML: `This is ${country.country_name} content!`,
        }
        const isActive = chapterInCountry
        country.active_status = true

        let newCountrySpecificContent: CountrySpecificContent = {
          country,
          clientContent: newClientContent,
          chapterCountrySpecificName: undefined,
          nonClientContent: newnonClientContent,
          isChapterActiveInCountry: isActive,
        }
        section.countrySpecificContent.push(newCountrySpecificContent)
      })
    })

    return chapter.sections
  }

  const updateSections = (chapters: ChapterObject[], newCountries: Country[]) => {
    chapters.map((chapter) => {
      if(!isCountrySpecific(chapter)){
        chapter.sections = addNewCountrySpecificContent(chapter, newCountries, chapter.name, true)
      } else {
        chapter.sections = addNewCountrySpecificContent(chapter, newCountries, chapter.name, false)
      }

      if(chapter.subchapters && chapter.subchapters.length > 0) {
        updateSections(chapter.subchapters, newCountries)
      }
    })

    return chapters
  }

  const updateCountries = () => {
    let tempActiveDocumentChange: DocumentChange =
      cloneDeep(activeDocumentChange)
    tempActiveDocumentChange.countries = activeCountries
    tempActiveDocumentChange.lastUpdated = new Date().toString()
    tempActiveDocumentChange.changedBy = {
      firstname: sessionStorage.getItem('firstname'),
      id: sessionStorage.getItem('id'),
      surname: sessionStorage.getItem('surname'),
    }

    let newCountries: Country[] = getNewCountries(tempActiveDocumentChange.chapters[0], tempActiveDocumentChange.countries)
    tempActiveDocumentChange.chapters = updateSections(tempActiveDocumentChange.chapters, newCountries)

    Object.assign(activeDocumentChange, tempActiveDocumentChange)
    setActiveDocumentChange(tempActiveDocumentChange)

    let tempActiveDocument = activeDocument
    let tempActiveDocumentVersion =
      tempActiveDocument.documentVersions[
        tempActiveDocument.documentVersions.length - 1
      ]
    tempActiveDocumentVersion.changes[
      tempActiveDocumentVersion.changes.length - 1
    ] = activeDocumentChange
    setActiveDocument(tempActiveDocument)

    //localStorage.setItem('currentDocumentEdit', JSON.stringify(documents))

    IndexedDBFunctions.deleteDocuments()
    IndexedDBFunctions.addDocuments(documents)
    //This is just a work around as we encountered issues with Local Storage
    setNumberOfEdits(Math.random() * 1000)

    const editDocumentThunk = editDocumentOnDB(activeDocument)
    dispatch(editDocumentThunk)
    localStorage.setItem('ContentIsDirty', 'false')
    setContentIsDirty(false)
    setBusy(true)
  }

  useEffect(() => {
    //
  }, [activeCountries])

  if (activeCountries !== undefined) {
    return (
      <>
        {busy === true && <Loader open={true} />}
        <div style={{ padding: '1em' }}>
          <Paper
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '1em',
            }}
          >
            <h3>Manage Countries</h3>
            <div>
              <Button
                style={{
                  marginLeft: '0.5em',
                }}
                color='primary'
                variant='contained'
                onClick={onAddCountryButtonClicked}
                disabled={busy}
              >
                <AddIcon />
                &nbsp;Add Country
              </Button>
              <Button
                style={{
                  minWidth: 100,
                  marginLeft: '0.5em',
                }}
                variant='contained'
                color='primary'
                onClick={() => {
                  updateCountries()
                }}
                disabled={busy}
              >
                <SaveIcon />
                &nbsp;Save
              </Button>
            </div>
          </Paper>
          <br />
          <Paper
            style={{
              display: 'flex',
              padding: '1em',
            }}
          >
            <List
              dense
              style={{
                width: '100%',
              }}
            >
              {activeCountries.sort((countryA, countryB) =>
                (countryA.country_name < countryB.country_name ? -1 : 1)
              ).map((country) => {
                const countryCode = lookup.byCountry(country.country_name).iso2

                return (
                  <ListItem
                    style={{
                      padding: '1em',
                      borderBottom: '1px solid rgba(0,0,0,0.15)',
                    }}
                  >
                    <ListItemText
                      primary={
                        <>
                          <ReactCountryFlag countryCode={countryCode} svg />
                          &nbsp;{country.country_name}
                        </>
                      }
                    />
                    <ListItemSecondaryAction>
                      <Button
                        onClick={handleDelete(country.country_name)}
                        variant='contained'
                        style={{
                          backgroundColor: '#f44336',
                          color: 'white',
                          marginLeft: '0.25em',
                        }}
                      >
                        <DeleteIcon />
                        &nbsp;Delete
                      </Button>
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              })}
            </List>
          </Paper>

          <AddCountryDialog
            open={openAddCountryDialog}
            setOpen={setOpenAddCountryDialog}
          />
        </div>
      </>
    )
  }

  return (
    <>
      <Loader open={true} />

      <div
        style={{
          width: '100%',
          height: '100%',
          padding: '1em',
        }}
      >
        <Skeleton
          style={{
            margin: 0,
          }}
          variant='rect'
          width={'100%'}
          height={'100%'}
        />
      </div>
    </>
  )
}

export default ManageCountries
