import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import * as EmailValidator from 'email-validator';
import image from 'media/logo.svg';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setForgotPasswordStatus } from 'redux/slices/authorisation';
import { forgotPassword } from 'redux/thunks/authorisationThunk';
import { Status } from 'shared/types/status';
import styled from 'styled-components';

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 a100%',
  },
  mainDiv: {
    minHeight: '86vh',
    display: 'flex',
    padding: '2em',
  },
  paperContainer: {
    display: 'flex',
    padding: theme.spacing(9, 2, 9, 2),
    justifyContent: 'center',
  },
  centeredFlex: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: theme.spacing(3),
  },
  form: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    padding: theme.spacing(3, 0),
  },
  formRow: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingTop: theme.spacing(3),
  },
  formFields: {
    paddingTop: theme.spacing(3),
  },
  submit: {},
  gridItemPaperContainer: {
    maxWidth: 600,
  },
  checkbox: {
    paddingTop: theme.spacing(1),
    '& > *': {
      paddingLeft: '0 !important',
    },
  },
  formLink: {
    paddingTop: theme.spacing(1),
  },
  link: {
    textDecoration: 'none !important',
    color: theme.palette.text.primary,
  },
}));

type SupportEmail = {
  email: string;
  children: string;
  subject: string;
  body: string;
};

const ForgotPassword = () => {
  const [emailInput, setEmailInput] = React.useState<string>('');
  const [emailValidation, setEmailValidation] = React.useState<boolean>(false);
  const [emailHelperText, setEmailHelperText] = React.useState<string>('');

  const dispatch = useDispatch();

  // const errorMessage = useSelector(
  //   (state: RootState) => state.authorisation.forgotPasswordResponseErrorMessage
  // );
  // const forgotPasswordStatus = useSelector(
  //   (state: RootState) => state.authorisation.forgotPasswordStatus
  // );

  const sendDetailsToServer = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let formValidation = true;

    if (emailInput === '') {
      setEmailHelperText('Email can not be empty');
      setEmailValidation(true);
      formValidation = false;
    } else {
      if (EmailValidator.validate(emailInput)) {
        setEmailValidation(false);
      } else {
        setEmailHelperText('Invalid email');
        setEmailValidation(true);
        formValidation = false;
      }
    }
    //do post with axios
    // wait for response
    // if success store in redux
    //if fail show error pop up

    if (formValidation) {
      const forgotPasswordThunk = forgotPassword(emailInput);
      dispatch(forgotPasswordThunk);
      dispatch({ type: setForgotPasswordStatus.type, payload: Status.loading });
      history.push('/forgotPassEmailSent');
    }
  };

  const Mailto = ({
    email,
    children,
    subject = '',
    body = '',
  }: SupportEmail) => {
    let params = subject || body ? '?' : '';

    if (subject) params += `subject=${encodeURIComponent(subject)}`;

    if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

    return <a href={`mailto:${email}${params}`}>{children}</a>;
  };

  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.mainDiv}>
      <Grid
        spacing={3}
        container
        className={classes.gridContainer}
        justify="center"
        alignItems="center"
      >
        <Grid className={classes.gridItemPaperContainer} item xs={10}>
          <Paper className={classes.paperContainer}>
            <Container maxWidth="sm" component="main">
              <CssBaseline />
              <div className={classes.centeredFlex}>
                <div className={classes.logoContainer}>
                  <LogoImg src={image} alt="" />
                </div>
                <Typography
                  style={{ textAlign: 'center' }}
                  component="h1"
                  variant="h5"
                  color="textPrimary"
                >
                  Reset your password
                </Typography>
                <form
                  noValidate
                  className={classes.form}
                  onSubmit={sendDetailsToServer}
                >
                  <div className={classes.formFields}>
                    <TextField
                      required
                      id="email"
                      label="Email address"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      error={emailValidation}
                      helperText={emailHelperText}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setEmailInput(event.target.value)
                      }
                    />
                  </div>

                  <div className={classes.formRow}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="default"
                      onClick={() => {
                        history.push('/signin');
                      }}
                      // disableElevation
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      // disableElevation
                      className={classes.submit}
                      // onClick={() => {
                      //     //submit email
                      //     history.push('/forgotPassEmailSent')
                      // }}
                    >
                      Send
                    </Button>
                  </div>
                </form>
                <Typography
                  style={{ textAlign: 'center', justifyContent: 'normal' }}
                  variant="subtitle2"
                  color="textPrimary"
                >
                  You will receive an email that explains how to reset your
                  password.
                  <br />
                  Click on cancel to return to the Sign In page.
                  <br />
                  <br />
                  If you forgot your email address, or no longer have access to
                  your email address, please contact
                  <br />
                </Typography>
                <div className={classes.centeredFlex}>
                  <Mailto
                    email="support@epiuse.com"
                    subject="Forgot Email"
                    body="Forgot email"
                  >
                    support@epiuse.com
                  </Mailto>
                </div>
              </div>
            </Container>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default ForgotPassword;

const LogoImg = styled.img`
  display: block;
  margin: 0 auto;
  margin-bottom: 2em;
`;
