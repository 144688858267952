import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { Role_Id } from 'shared/types/user';

const useStyles = makeStyles((theme: Theme) => ({
  dialogMinWidth: {
    minWidth: '552px',
  },
  spacer: {
    padding: theme.spacing(0, 0, 2, 0),
  },
  spacerLeft: {
    padding: theme.spacing(0, 0, 0, 2),
  },
}));

const EditUserAccessManagement: React.FC<{
  role: Role_Id;
  setRole: (role: Role_Id) => void;
}> = ({ role, setRole }) => {
  const [value, setValue] = React.useState(role.role_name);

  React.useEffect(() => {
    //
  }, []);

  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);

    if ((event.target as HTMLInputElement).value === 'Guest') {
      setRole({
        can_create: false,
        id: '1',
        role_name: 'Guest',
      });
    } else if ((event.target as HTMLInputElement).value === 'Administrator') {
      setRole({
        can_create: true,
        id: '2',
        role_name: 'Administrator',
      });
    }
  };

  return (
    <div className={classes.dialogMinWidth}>
      <FormControl component="fieldset">
        <FormLabel component="legend">
          User's role in template environment:
        </FormLabel>
        <div className={classes.spacer} />
        <RadioGroup
          aria-label="role"
          name="role"
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel
            value="Guest"
            control={<Radio color="primary" />}
            label="Guest"
          />
          <FormHelperText className={classes.spacerLeft}>
            {'Cannot create templates'}
          </FormHelperText>
          <div className={classes.spacer} />
          <FormControlLabel
            value="Administrator"
            control={<Radio color="primary" />}
            label="Administrator"
          />
          <FormHelperText className={classes.spacerLeft}>
            {'Can create templates'}
          </FormHelperText>
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default EditUserAccessManagement;
