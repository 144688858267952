import {
  Divider,
  FormControlLabel,
  Switch,
  Typography,
} from '@material-ui/core'
import { Editor } from '@tinymce/tinymce-react'
import React, { useContext } from 'react'
import ReactDiffViewer from 'react-diff-viewer'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import { DocumentContext } from 'shared/components/DocumentEditor/DocumentEditor'
import { ChapterObject, ChapterSection } from 'shared/types/document'
import {findDFSBySharedId} from '../../treeUtils'
import { CountrySelector } from '../CountrySelector/CountrySelector'

export const SyncCountryContentComparison: React.FC = () => {
  const context = useContext(DocumentContext)
  const { activeChapter, activeDocument, activeCountry } =
    context
  const templateDocuments = useSelector(
    (state: RootState) => state.documents.Documents
  )
  const templateDocument = templateDocuments.find(
    (tempDoc) => tempDoc.name === activeDocument.name
  )
  const templateDocumentVersion =
    templateDocument.documentVersions[
      templateDocument.documentVersions.length - 1
    ]
  const templateDocumentChange =
    templateDocumentVersion.changes[templateDocumentVersion.changes.length - 1]
  const templateChapter: ChapterObject = findDFSBySharedId(
    templateDocumentChange.chapters,
    activeChapter.sharedId
  )
  const [diffControlToggle, setDiffControlToggle] =
    React.useState<boolean>(false)

  console.log('TEMPLATE CHAPTER', templateChapter)

  return (
    <>
      <div>
        <h3 style={{ flexGrow: 1, alignItems: 'center', display: 'flex' }}>
          Syncing: {activeDocument.name} - {activeChapter.name} Country Content
          with Template
        </h3>
        <CountrySelector />
        <FormControlLabel
          control={
            <Switch
              checked={diffControlToggle}
              onChange={() => setDiffControlToggle(!diffControlToggle)}
              name='checkedB'
              color='primary'
            />
          }
          label='View in Editor'
        />
      </div>

      {templateChapter.sections &&
        activeDocument.name !== 'Wage Type Catalogue' &&
        templateChapter.sections
          .filter((section) => !section.isGlobal && !section.isClientEditable)
          .map((chapterSection: ChapterSection, index) => {
            let oldContent = ''
            let newContent = ''
            let countryExists = true

            if (
              templateDocumentChange.countries.find(
                (country) => country.country_name === activeCountry.country_name
              ) === undefined
            ) {
              countryExists = false
            }

            if (activeCountry !== null && countryExists) {
              newContent =
                chapterSection.countrySpecificContent.find(content =>
                  content.country.country_name === activeCountry.country_name)
                  .nonClientContent.rawHTML
              console.log('setting new content', newContent)
            }

            console.log('INDEX', index)

            let prevSection = activeChapter.sections
              .filter((section) => !section.isGlobal && !section.isClientEditable)[index]
            console.log(activeChapter)

            if (prevSection) {
              if (activeCountry !== null && countryExists) {
                oldContent =
                  prevSection.countrySpecificContent.find(content =>
                    content.country.country_name === activeCountry.country_name)
                    .nonClientContent.rawHTML
                console.log('setting old content', oldContent)
              }
            } else {
              oldContent = 'Section does not exist on Project Document'
            }

            return (
              <div
                style={{
                  paddingTop: '1em',
                  paddingBottom: '1em',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    height: 'auto',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    flexGrow: 1,
                    /* paddingRight: '2em', */
                  }}
                >
                  <h3>Section: {chapterSection.name} </h3>
                </div>

                {templateChapter ? (
                  <div>
                    {!diffControlToggle && (
                      <>
                        {newContent !== oldContent || !countryExists ? (
                          !countryExists ? (
                            <Typography>
                              This country has been removed in the template
                              document
                            </Typography>
                          ) : (
                            <ReactDiffViewer
                              oldValue={oldContent}
                              newValue={newContent}
                              splitView={true}
                            />
                          )
                        ) : (
                          <Typography>Sections are identical</Typography>
                        )}
                      </>
                    )}
                    {diffControlToggle && (
                      <div
                        style={{
                          display: 'flex',
                          height: 'auto',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          flexGrow: 1,
                          /* paddingRight: '2em', */
                        }}
                      >
                        <Editor
                          apiKey={
                            'lk8ioa673o60urr91q2n1synl65hxg5def93oobzlaadkwrl'
                          }
                          value={oldContent}
                          disabled={true}
                          init={{
                            height: 500,
                            width: '40%',
                            min_height: 200,
                            min_width: 200,
                            resize: 'both',
                            menubar: false,
                            plugins: [
                              'advlist autolink lists link image charmap print preview anchor',
                              'searchreplace visualblocks code fullscreen',
                              'insertdatetime media table paste code help wordcount table',
                              'autoresize',
                            ],
                            toolbar: false,
                            content_style:
                              'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                          }}
                          // onEditorChange={(newState: string) => {
                          //   setInitialState(newState);
                          // }}
                        />
                        <Editor
                          apiKey={
                            'lk8ioa673o60urr91q2n1synl65hxg5def93oobzlaadkwrl'
                          }
                          value={newContent}
                          disabled={true}
                          init={{
                            height: 500,
                            width: '40%',
                            min_height: 200,
                            min_width: 200,
                            resize: 'both',
                            menubar: false,
                            plugins: [
                              'advlist autolink lists link image charmap print preview anchor',
                              'searchreplace visualblocks code fullscreen',
                              'insertdatetime media table paste code help wordcount table',
                              'autoresize',
                            ],
                            toolbar: false,
                            content_style:
                              'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                          }}
                          // onEditorChange={(newState: string) => {
                          //   setInitialState(newState);
                          // }}
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <Typography>
                    New chapter. No content to compare against.
                  </Typography>
                )}
                <Divider />
              </div>
            )
          })}
    </>
  )
}
