import { cloneDeep } from 'lodash'
import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import {
  Document,
  DocumentChange,
  DocumentVersion,
} from '../../../../types/document'
import { DocumentContext } from '../../DocumentEditor'
import { SyncStructureComparisonNewChapters } from './SyncStructureComparisonNewChapters'
import { SyncStructureComparisonOldChapters } from './SyncStructureComparisonOldChapters'

export const SyncStructureDialog: React.FC = () => {
  const context = useContext(DocumentContext)
  const {
    documentChapters,
    activeDocument,
    activeCountryIndex,
    activeChapter,
  } = context
  const templateDocuments = useSelector(
    (state: RootState) => state.documents.Documents
  )
  const [templateDocument, setTemplateDocument] =
    React.useState<Document>(undefined)
  const [templateDocumentVersion, setTemplateDocumentVersion] =
    React.useState<DocumentVersion>(undefined)
  const [templateDocumentChange, setTemplateDocumentChange] =
    React.useState<DocumentChange>(undefined)
  //const [templateChapter, setTemplateChapter] = React.useState<ChapterObject>(undefined)

  useEffect(() => {
    if (templateDocuments && templateDocuments.length > 0) {
      setTemplateDocument(
        templateDocuments.find((tempDoc) => tempDoc.id === activeDocument.refId)
      )
      let tempDoc = templateDocuments.find(
        (tempDoc) => tempDoc.id === activeDocument.refId
      )
      console.log(templateDocument)

      if (typeof tempDoc.documentVersions[0] !== 'string') {
        setTemplateDocumentVersion(
          tempDoc.documentVersions[tempDoc.documentVersions.length - 1]
        )

        if (templateDocumentVersion !== undefined) {
          setTemplateDocumentChange(
            templateDocumentVersion.changes[
              templateDocumentVersion.changes.length - 1
            ]
          )
        }
      }
    }
  }, [
    templateDocuments,
    templateDocument,
    templateDocumentVersion,
    templateDocumentChange,
  ])

  return (
    <>
      <h3 style={{ flexGrow: 1, alignItems: 'center', display: 'flex' }}>
        Syncing: {activeDocument.name} - {activeChapter.name} Structure with
        Template
      </h3>
      <div
        style={{
          display: 'flex',
          height: 'auto',
          flexDirection: 'row',
          justifyContent: 'center',
          flexGrow: 1,
        }}
      >
        {/* <FormControlLabel
                    control={
                      <Switch
                        checked={diffControlToggle}
                        onChange={() =>
                          setDiffControlToggle(!diffControlToggle)
                        }
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="View in Editor"
                  /> */}
        <div
          style={{
            display: 'flex',
            height: 'auto',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            flexGrow: 1,
          }}
        >
          <h3 style={{ alignItems: 'center', display: 'flex' }}>
            Current Chapters
          </h3>
          {templateDocumentChange !== undefined && (
            <SyncStructureComparisonOldChapters
              mockObject={documentChapters}
              oldChapters={cloneDeep(templateDocumentChange.chapters)}
              recursiveObj={{
                chapter: null,
                activeCountryIndex,
                activeChapter,
                oldChapter: activeChapter,
              }}
            />
          )}
        </div>
        <div
          style={{
            display: 'flex',
            height: 'auto',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            flexGrow: 1,
          }}
        >
          <h3 style={{ alignItems: 'center', display: 'flex' }}>
            Template Chapters
          </h3>
          {templateDocumentChange !== undefined && (
            <SyncStructureComparisonNewChapters
              mockObject={cloneDeep(templateDocumentChange.chapters)}
              oldChapters={documentChapters}
              recursiveObj={{
                chapter: null,
                activeCountryIndex,
                activeChapter,
                oldChapter: activeChapter,
              }}
            />
          )}
        </div>
      </div>
    </>
  )
}
