import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    bottom: 0,
    width: '100vw',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    height: '50px',
  },
  links: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    '& > *': {},
  },
  button: {
    fontSize: '12px',
    fontFamily: 'Open Sans',
    letterSpacing: 0.1,
    color: theme.palette.text.secondary,
  },
}));

const FooterNav = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container className={classes.footerContainer} maxWidth="sm">
        <div className={classes.links}>
          <Link to="/privacy_policy">
            <Button className={classes.button}>Privacy Policy</Button>
          </Link>
          <span> |</span>
          <Link to="/terms_and_conditions">
            <Button className={classes.button}> Terms and Conditions</Button>
          </Link>
          <span> |</span>
          <Link to="/copyright">
            <Button className={classes.button}> Copyright </Button>
          </Link>
          <span> |</span>
          <Link to="/disclaimer">
            <Button className={classes.button}>Disclaimer </Button>
          </Link>
        </div>
      </Container>
    </footer>
  );
};

export default FooterNav;
