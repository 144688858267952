import { MenuItem, TableSortLabel } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setDeleteProjectStatus,
  setUpdateProjectStatus,
} from 'redux/slices/projects'
import { RootState } from 'redux/store'
import { fetchProjects } from 'redux/thunks/projectsThunk'
import { DocumentContext } from 'shared/components/DocumentEditor/DocumentEditor'
import { SnackType } from 'shared/components/Feedback/EPISnackBar'
import Loader from 'shared/components/Feedback/Loader'
import { TableActionMenu } from 'shared/components/TableActionMenu/TableActionMenu'
import { Project } from 'shared/types/project'
import { Status } from 'shared/types/status'
import DeleteProjectConfirmDialog from '../Dialogs/DeleteProjectConfirmDialog'
import ViewProjectInfoDialog from '../Dialogs/EditProjectDialog'

export type ProjectRow = {
  id: string | undefined
  name: string | undefined
  author: string
  lastUpdated: string
  actions: null
  delete: null
}
export type HeadCell = {
  disablePadding: boolean
  id: keyof ProjectRow
  label: string
}

export type ProjectTableHeadProps = {
  headCells: Array<HeadCell>
  order: Order
  orderBy: string
  rowCount: number
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof ProjectRow
  ) => void
}

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    overflow: 'auto',
  },

  chips: {
    '& > *': {
      marginRight: `${theme.spacing(0.5)}px !important`,
    },
  },

  buttonSpan: {
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(1),
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }

  if (b[orderBy] > a[orderBy]) {
    return 1
  }

  return 0
}

type Order = 'asc' | 'desc'

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: string }, b: { [key in Key]: string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])

    if (order !== 0) return order

    return a[1] - b[1]
  })

  return stabilizedThis.map((el) => el[0])
}

// type EnhancedTableProps = {
//   classes: ReturnType<typeof useStyles>;
//   numSelected: number;
//   onRequestSort: (
//     event: React.MouseEvent<unknown>,
//     property: keyof ProjectRow
//   ) => void;
//   onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
//   order: Order;
//   orderBy: string;
//   rowCount: number;
// };

// type CreateChipProp = {
//   message: string;
//   outlined: boolean;
// };

// const CreateChip: React.FC<CreateChipProp> = ({ message, outlined }) => {
//   const classes = useStyles();

//   return (
//     <span className={classes.chips}>
//       <Chip
//         label={message}
//         variant={outlined ? 'outlined' : 'default'}
//         color="primary"
//         size="small"
//       />
//     </span>
//   );
// };

// const CreateCountriesList: React.FC<Country2[] | undefined> = (countries) => {
//   if (countries !== undefined && countries.length > 0) {
//     return (
//       <Paper
//         elevation={0}
//         style={{ paddingBottom: '15px', overflow: 'auto', maxWidth: '200px' }}
//       >
//         {countries.map((country, i) => {
//           if (i == 2) {
//             return <span style={{ paddingTop: '15px' }}></span>;
//           } else if (i < 2)
//             return (
//               <span>
//                 <CreateChip message={country.country_name} outlined={false} />
//               </span>
//             );
//         })}
//       </Paper>
//     );
//   }

//   return (
//     <p style={{ paddingBottom: '10px' }}>
//       <CreateChip message="Global" outlined={false} />
//     </p>
//   );
// };

// function retrieveArrayofArrayRows(projects: Project[]) {
//   let arrayOfArray: Array<Array<Role>> | null = [];

//   if (projects && projects.length > 0) {
//     projects.map((project) => {
//       if (project.roles && project.roles.length > 0) {
//         arrayOfArray?.push(project.roles);
//       }
//     });
//   }

//   return arrayOfArray !== null && arrayOfArray.length > 0 ? arrayOfArray : 0;
// }

// function createListOfUsers(roles: Role_Id[] | undefined) {
//   if (roles !== undefined && roles.length > 0) {
//     return (
//       <Paper
//         elevation={0}
//         style={{ paddingBottom: '15px', overflow: 'auto', maxWidth: '200px' }}
//       >
//         {roles.map((role, i) => {
//           if (i == 2) {
//             return <span style={{ paddingTop: '15px' }}></span>;
//           } else if (i < 2)
//             return (
//               <span>
//                 <CreateChip message={role.role_name} outlined={false} />
//               </span>
//             );
//         })}
//       </Paper>
//     );
//   }

//   return (
//     <p style={{ paddingBottom: '10px' }}>
//       <CreateChip message="None" outlined={false} />
//     </p>
//   );
// }

function createTableData(project: Project) {
  // const countriesList = CreateCountriesList(
  //   project && project.countries ? project.countries : []
  // );
  // const activeRoles = createListOfUsers(project.roles);

  const result: ProjectRow = {
    id: project.id,
    name: project.name,
    author: project.author,
    lastUpdated: project.lastUpdated,
    actions: null,
    delete: null,
  }

  return result
}

const headCells: HeadCell[] = [
  { id: 'name', disablePadding: false, label: 'Project Name' },
  { id: 'author', disablePadding: false, label: 'Author' },
  { id: 'lastUpdated', disablePadding: false, label: 'Last Updated' },
  { id: 'actions', disablePadding: false, label: 'Actions' },
]
const TableHeadings: React.FC<ProjectTableHeadProps> = (
  props: ProjectTableHeadProps
) => {
  const { order, orderBy, onRequestSort } = props
  const classes = useStyles()

  const createSortHandler =
    (property: keyof ProjectRow) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          return (
            <TableCell
              key={headCell.id}
              align={
                headCell.id === 'actions' || headCell.id === 'delete'
                  ? 'right'
                  : 'left'
              }
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                <Typography variant='body1' color='primary' component='h1'>
                  {headCell.label}
                </Typography>
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

export type ProjectsTableProps = {
  data: Array<Project> | undefined
  page: number
  rowsPerPage: number
}

export type ProjectTableBodyProps = {
  isEditOpen: boolean
  setEditOpen: (value: boolean) => void
  isDeleteOpen: boolean
  setDeleteOpen: (value: boolean) => void
  setProject: (value: Project | undefined) => void
  order: Order
  orderBy: string
} & ProjectsTableProps

const TableContent: React.FC<ProjectTableBodyProps> = ({
  data = [],
  page = 0,
  rowsPerPage = 0,
  setEditOpen = () => {
    return
  },
  setDeleteOpen = () => {
    return
  },
  setProject = () => {
    return
  },
  order,
  orderBy,
}) => {
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage) // in case if last has not enough rows add extra rows
  // const classes = useStyles();
  // const dispatch = useDispatch();
  const projects: Project[] = useSelector(
    (state: RootState) => state.projects.allProjects
  )
  // const history = useHistory();

  function getProjectInfo(id = '0') {
    let project = projects.find((p) => {
      return p.id === id && p
    })

    project !== undefined ? setProject(project) : setProject(undefined)

    return null
  }

  // const openTemplateEditor = (id: any) => {
  //   history.push(`/template/${id}`);
  // };

  const rows = data.map((dt, key) => {
    return createTableData(dt)
  })

  const [menuOpen, setMenuOpen] = React.useState(false)

  return (
    <TableBody>
      {stableSort(rows, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, index) => {
          return (
            <TableRow key={row.id}>
              <TableCell component='th' scope='row' align='left'>
                {row.name}
              </TableCell>
              <TableCell component='th' scope='row'>
                {row.author}
              </TableCell>
              <TableCell component='th' scope='row'>
                {row.lastUpdated}
              </TableCell>

              <TableCell component='th' scope='row' align='right'>
                <TableActionMenu
                  tooltip='Manage Project'
                  menuOpen={menuOpen}
                  setMenuOpen={setMenuOpen}
                >
                  <Tooltip
                    title="Edit project's name and assign users to the project"
                    placement='right-end'
                  >
                    <MenuItem
                      onClick={() => {
                        getProjectInfo(row.id)
                        setMenuOpen(false)

                        setEditOpen(true)
                      }}
                    >
                      <EditIcon /> &nbsp;Manage Project
                    </MenuItem>
                  </Tooltip>
                  <Tooltip title='Remove this project'>
                    <MenuItem
                      onClick={() => {
                        getProjectInfo(row.id)
                        setMenuOpen(false)

                        setDeleteOpen(true)
                      }}
                    >
                      <DeleteIcon /> &nbsp;Delete Project
                    </MenuItem>
                  </Tooltip>
                </TableActionMenu>
              </TableCell>
            </TableRow>
          )
        })}
      {emptyRows > 0 && (
        <TableRow style={{ height: 53 * emptyRows }}>
          <TableCell colSpan={6} />
        </TableRow>
      )}
    </TableBody>
  )
}

const ProjectsTableBody: React.FC<ProjectsTableProps> = ({
  data,
  page,
  rowsPerPage,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const context = useContext(DocumentContext)
  const { setDisplaySnack, setSnackMessage, setSnackSeverity } = context
  const [openEditViewDialog, setOpenEditViewDialog] = useState(false)
  const [openDeleteViewDialog, setOpenDeleteViewDialog] = useState(false)
  const [project, setProject] = useState<Project | undefined>(undefined)
  // const deleteMessage = useSelector(
  //   (state: RootState) => state.projects.deleteProjectResponseErrorMessage
  // );
  const deleteStatus = useSelector(
    (state: RootState) => state.projects.deleteProjectStatus
  )
  // const [isDeleteError, setIsDeleteError] = useState(false);
  // const [isDeleteSuccess, setIsDeleteSuccess] = useState(false);
  // const updateMessage = useSelector(
  //   (state: RootState) => state.projects.updateProjectResponseMessage
  // );
  const updateStatus = useSelector(
    (state: RootState) => state.projects.updateProjectStatus
  )
  // const [isUpdateError, setIsUpdateError] = useState(false);
  // const [isUpdateSuccess, setIsUpdateSuccess] = useState(false);
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof ProjectRow>('name')

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof ProjectRow
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  useEffect(() => {
    if (deleteStatus === Status.success) {
      // setIsDeleteSuccess(true);
      // setIsDeleteError(false);
      dispatch({ type: setDeleteProjectStatus.type, payload: Status.idle })
      const fetchProjectsThunk = fetchProjects()
      dispatch(fetchProjectsThunk)
    } else if (deleteStatus === Status.failed) {
      // setIsDeleteSuccess(false);
      // setIsDeleteError(true);
    }
  }, [deleteStatus])

  useEffect(() => {
    if (updateStatus === Status.success) {
      const fetchProjectsThunk = fetchProjects()
      dispatch(fetchProjectsThunk)
      dispatch({ type: setUpdateProjectStatus.type, payload: Status.idle })
      setSnackMessage('Project update successful')
      setSnackSeverity(SnackType.SnackSuccess as string)
      setDisplaySnack(true)
    } else if (updateStatus === Status.failed) {
      // setIsUpdateSuccess(false);
      // setIsUpdateError(true);
      dispatch({ type: setUpdateProjectStatus.type, payload: Status.idle })
    }
  }, [updateStatus])

  React.useEffect(() => {
    dispatch({ type: setUpdateProjectStatus.type, payload: Status.idle })
  }, [])

  return (
    <>
      <Table aria-label='collapsible table' className={classes.table}>
        <TableHeadings
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          rowCount={data.length}
        />
        <TableContent
          data={data}
          page={page}
          rowsPerPage={rowsPerPage}
          isEditOpen={openEditViewDialog}
          setEditOpen={setOpenEditViewDialog}
          isDeleteOpen={openDeleteViewDialog}
          setDeleteOpen={setOpenDeleteViewDialog}
          setProject={setProject}
          order={order}
          orderBy={orderBy}
        />
      </Table>
      <Loader
        open={
          deleteStatus === Status.loading || updateStatus === Status.loading
        }
      />
      {project && (
        <ViewProjectInfoDialog
          isOpen={openEditViewDialog}
          setOpen={setOpenEditViewDialog}
          project={project}
        />
      )}
      {project && (
        <DeleteProjectConfirmDialog
          isOpen={openDeleteViewDialog}
          setOpen={setOpenDeleteViewDialog}
          project={project}
        />
      )}
      {/* {displaySnack && (
        <EPISnackBar
          message={snackMessage}
          messageType={snackSeverity as string}
          isOpen={displaySnack}
          setIsOpen={setDisplaySnack}
        />
      )} */}
    </>
  )
}

export default ProjectsTableBody
