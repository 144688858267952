import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import { setDocumentVersions } from 'redux/slices/documents'
import { RootState } from 'redux/store'
import {
  fetchDocumentsV2,
  fetchSpecificDocumentV2,
} from 'redux/thunks/documentsThunk'
import {
  DocumentContext,
  DocumentContextType,
} from 'shared/components/DocumentEditor/DocumentEditor'
import { DocumentToolbar } from 'shared/components/DocumentEditor/DocumentToolbar/DocumentToolbar'
import {
  findDFSBySharedId,
  findDFSByTreeIndexString,
  reIndexChapters,
} from 'shared/components/DocumentEditor/treeUtils'
import EPISnackBar, { SnackType } from 'shared/components/Feedback/EPISnackBar'
import Loader from 'shared/components/Feedback/Loader'
import { Country } from 'shared/types/country'
import * as reduxDocumentType from 'shared/types/document'
import { ChapterSection, Filter } from 'shared/types/document'
import { TemplateDocumentHomeContent } from 'views/Pages/TemplateEnvironment/Home/Content/TemplateDocumentHomeContent'
import { TemplateHomeContent } from 'views/Pages/TemplateEnvironment/Home/Content/TemplateHomeContent'
import { SideNav } from 'views/Pages/TemplateEnvironment/Home/SideNav/SideNav'
import * as XLSX from 'xlsx'
import IndexedDBFunctions from "../../../../IndexedDBFunctions";
import {useIndexedDB} from "react-indexed-db";

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export const TemplateEnvironmentHome: React.FC<{
  children?: React.ReactNode
}> = ({ children }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  let query = useQuery()
  const section = query.get('section')
  console.log(section)

  function getDocId() {
    let p1 = history.location.pathname.split('/document/')[1]

    if (p1 === undefined) {
      return null
    }

    let p2 = p1.split('/country/')[0]

    return p2
  }

  function getCountry() {
    let p1 = history.location.pathname.split('/country/')[1]

    return p1 as undefined as string
  }

  const [contentIsDirty, setContentIsDirty] = React.useState(false)
  const [sections, setSections] = React.useState<ChapterSection[]>([])

  //const [docs, setDocs] = React.useState<reduxDocumentType.Document[]>(project ? project.documents : []);
  const documents = useSelector((state: RootState) => state.documents.Documents)
  // const documentsVersions = useSelector((state: RootState) =>
  //   getDocumentVersions(state)
  // )
  const [copiedDocuments, setCopiedDocuments] = React.useState<
    reduxDocumentType.Document[]
  >([] as reduxDocumentType.Document[])

  const [activeDocumentIndex, setActiveDocumentIndex] = React.useState(null)
  const [activeCountryIndex, setActiveCountryIndex] = React.useState(null)
  const [activeCountry, setActiveCountry] = React.useState<Country>(
    {} as Country
  )
  const [activeChapter, setActiveChapter] =
    React.useState<reduxDocumentType.ChapterObject>(
      {} as reduxDocumentType.ChapterObject
    )
  const [activeDocument, setActiveDocument] =
    React.useState<reduxDocumentType.Document>({} as reduxDocumentType.Document)
  const [numberOfEdits, setNumberOfEdits] =
    React.useState<number>(0)
  const [documentChapters, setDocumentChapters] = React.useState<
    Array<reduxDocumentType.ChapterObject>
  >([])
  const [activeDocumentVersions, setActiveDocumentVersions] = React.useState<
    Array<reduxDocumentType.DocumentVersion>
  >({} as Array<reduxDocumentType.DocumentVersion>)
  const [activeDocumentVersion, setActiveDocumentVersion] =
    React.useState<reduxDocumentType.DocumentVersion>(
      {} as reduxDocumentType.DocumentVersion
    )

  const [activeDocumentChange, setActiveDocumentChange] =
    React.useState<reduxDocumentType.DocumentChange>(
      {} as reduxDocumentType.DocumentChange
    )
  const [activeCountries, setActiveCountries] = React.useState<Array<Country>>()

  const [extractedWorksheet, setExtractedWorksheet] =
    React.useState<reduxDocumentType.WageCatalogueSheet>(null)
  const [uploadedFile, setUploadedFile] = React.useState<File>(null)
  const [wb, setWb] = React.useState<XLSX.WorkBook>(null)

  const [selectedFilter, setSelectedFilter] = React.useState<Filter>({
    name: null,
    columns: null,
  })

  const [displaySnack, setDisplaySnack] = React.useState<boolean>(false)
  const [snackSeverity, setSnackSeverity] = React.useState<SnackType>(null)
  const [snackMessage, setSnackMessage] = React.useState<string>('')
  const [displayLoader, setDisplayLoader] = React.useState<boolean>(false)
  const [activeCountriesOnProject, setActiveCountriesOnProject] = React.useState<boolean>(false)
  const [projectDocumentsLoaded, setProjectDocumentsLoaded] = React.useState<boolean>(false)
  const [cachedDocuments, setCachedDocuments] = React.useState(null)

  React.useEffect(() => {
    // localStorage.removeItem('currentDocumentEdit')

    if (documents.length === 0) {
      const getAllDocuments = async (): Promise<void> => {
        await dispatch(fetchDocumentsV2())
      }

      getAllDocuments().then(() => {
        // getProjects().then(() => {
        //   console.log('Done');
        // });
      })
    }

    const {getAll} = useIndexedDB('Documents');

    getAll().then(docs => {
      if(docs[0]) {
        setCachedDocuments(docs[0].document);
      }
    });
  }, [])

  /**
   * Effect that changes the initial state of the system when documents are first fetched
   * Logic:
   * 1. Pull the ID of the document from the URL
   * 2. Use that to find the current active document (i.e. Business Blueprint)
   * 3. Find the latest version of the selected document
   * 4. Find the latest change on the selected document
   * 5. Find the country specific content for the currently selected country on the change
   *    5.1 change > chapters > sections > countrySpecificContent > clientContent | nonClientContent
   */

  React.useEffect(() => {
    if (
      documents !== undefined &&
      documents.find((document) => {
        return document.documentVersions.find(
          (version) => typeof version !== 'string'
        )
      }) === undefined
    ) {
      documents.map((document) => {
        const fetchSpecificDocThunk = fetchSpecificDocumentV2(document.id)
        dispatch(fetchSpecificDocThunk)
      })
    }

    if (
      documents !== undefined &&
      documents.find((document) => {
        return document.documentVersions.find(
          (version) => typeof version === 'string'
        )
      }) === undefined
    ) {
      if (documents !== undefined && documents.length > 0) {
        if (cachedDocuments !== null && cachedDocuments.length !== 0) {
          setCopiedDocuments(cachedDocuments)
        } else {
          setCopiedDocuments(JSON.parse(JSON.stringify(documents)))
        }
      }
    }
  }, [documents, cachedDocuments])

  // React.useEffect(() => {
  //   console.log('LOADING', documentsVersions)

  //   if (
  //     documents !== undefined &&
  //     documents.find((document) => {
  //       return document.documentVersions.find(
  //         (version) => typeof version === 'string'
  //       )
  //     }) === undefined
  //   ) {
  //     console.log('ALL VERSIONS LOADED')

  //     if (documents !== undefined && documents.length > 0) {
  //       const cachedDocuments = JSON.parse(
  //         localStorage.getItem('currentDocumentEdit')
  //       )
  //       console.log('CACHED', cachedDocuments)

  //       if (cachedDocuments !== null) {
  //         console.log('SET FROM CACHE')
  //         setCopiedDocuments(cachedDocuments)
  //       } else {
  //         console.log('SET FROM REDUX')
  //         setCopiedDocuments(JSON.parse(JSON.stringify(documents)))
  //       }
  //     }
  //   }
  // }, [documentsVersions])

  React.useEffect(() => {
    if (copiedDocuments !== undefined && copiedDocuments.length > 0) {
      let currentDocumentID: string = getDocId()

      copiedDocuments.forEach((doc) => {
        const docVersion = doc.documentVersions[doc.documentVersions.length - 1]
        const docChange = docVersion.changes[docVersion.changes.length - 1]
        docChange.globalTreeIndexCounter = reIndexChapters(
          docChange.chapters,
          0,
          ''
        )
      })

      // if (currentDocumentID === null) {
      //   currentDocumentID = '0'
      // }

      let activeDocument = copiedDocuments.find(
        (doc) => doc.id === currentDocumentID
      )

      if (activeDocument === undefined) {
        activeDocument = copiedDocuments[0]
      }

      if (activeDocument !== undefined) {
        const activeDocIndex = copiedDocuments.findIndex(
          (doc) => doc.id === currentDocumentID
        )
        const activeDocumentVersion =
          activeDocument.documentVersions[
            activeDocument.documentVersions.length - 1
          ]
        const latestChange =
          activeDocumentVersion.changes[
            activeDocumentVersion.changes.length - 1
          ]

        setActiveDocumentVersions(activeDocument.documentVersions)
        setActiveDocumentVersion(activeDocumentVersion)
        setActiveDocument(activeDocument)
        setDocumentChapters(latestChange?.chapters)

        if (localStorage.getItem('activeChapterIndexString') !== null) {
          let currentActiveChapterTreeIndex = localStorage.getItem('activeChapterIndexString')

          let findDFSResult = findDFSByTreeIndexString(
            latestChange.chapters,
            currentActiveChapterTreeIndex
          )

          if (findDFSResult === undefined) {
            findDFSResult = latestChange.chapters[0]
            setSnackMessage(
              'No Matching Chapter found. Defaulting to first Chapter.'
            )
            setSnackSeverity(SnackType.SnackWarning)
            setDisplaySnack(true)
          }
          setActiveChapter(findDFSResult)
        }
        // setActiveChapter(
        //   latestChange?.chapters[latestChange.chapters.length - 1]
        // )
        setActiveCountries(latestChange?.countries)

        if (getCountry() !== undefined) {
          setActiveCountry(
            latestChange.countries.find(
              (country) => country.country_name === getCountry()
            )
          )
          setActiveCountryIndex(
            latestChange.countries.findIndex(
              (country) => country.country_name === getCountry()
            )
          )
        }
        setActiveDocumentChange(latestChange)
        setSections(latestChange?.sections)
        setActiveDocumentIndex(activeDocIndex)
      }
    }
  }, [copiedDocuments])

  React.useEffect(() => {
    if (copiedDocuments !== undefined && copiedDocuments.length > 0) {
      let activeDocument = copiedDocuments[activeDocumentIndex]

      if (activeDocument === undefined) {
        activeDocument = copiedDocuments[0]
      }

      if (activeDocument !== undefined) {
        const activeDocumentVersion =
          activeDocument.documentVersions[
            activeDocument.documentVersions.length - 1
          ]
        const latestChange =
          activeDocumentVersion.changes[
            activeDocumentVersion.changes.length - 1
          ]

        if (activeChapter !== undefined && activeChapter.sharedId !== null) {
          let currentActiveChapterSharedId = activeChapter.sharedId
          let findDFSResult = findDFSBySharedId(
            latestChange.chapters,
            currentActiveChapterSharedId
          )

          if (findDFSResult === undefined) {
            findDFSResult = latestChange.chapters[0]
          }
          setActiveChapter(findDFSResult)
        } else {
          setActiveChapter(latestChange.chapters[0])
        }

        const activeDocIndex = copiedDocuments.findIndex(
          (doc) => doc.id === activeDocument.id
        )

        setActiveDocumentVersions(activeDocument.documentVersions)
        setActiveDocumentVersion(activeDocumentVersion)
        setActiveDocument(activeDocument)
        setDocumentChapters(latestChange.chapters)
        setActiveCountries(latestChange.countries)
        setActiveDocumentChange(latestChange)
        setActiveDocumentIndex(activeDocIndex)

        if (getCountry() !== undefined) {
          let country = latestChange.countries.find(
            (country) => country.country_name === getCountry()
          )

          if (country !== undefined) {
            setActiveCountry(
              latestChange.countries.find(
                (country) => country.country_name === getCountry()
              )
            )
            setActiveCountryIndex(
              latestChange.countries.findIndex(
                (country) => country.country_name === getCountry()
              )
            )
          } else {
            setActiveCountry(latestChange.countries[0])
            setActiveCountryIndex(0)
            setSnackMessage(
              'No Matching Country found. Defaulting to first Country.'
            )
            setSnackSeverity(SnackType.SnackWarning)
            setDisplaySnack(true)
          }
        }
      }
    }
    dispatch({ type: setDocumentVersions.type, payload: [] })

  }, [activeDocumentIndex])

  React.useEffect(() => {
    if (copiedDocuments !== undefined && copiedDocuments.length > 0) {
      let activeDocument = copiedDocuments[activeDocumentIndex]

      if (activeDocument === undefined) {
        activeDocument = copiedDocuments[0]
      }

      if (activeDocument !== undefined) {
        const activeDocumentVersion =
          activeDocument.documentVersions[
            activeDocument.documentVersions.length - 1
          ]
        const latestChange =
          activeDocumentVersion.changes[
            activeDocumentVersion.changes.length - 1
          ]

        if (activeChapter !== undefined) {
          let currentActiveChapterTreeIndex = activeChapter.sharedId
          let findDFSResult = findDFSBySharedId(
            latestChange.chapters,
            currentActiveChapterTreeIndex
          )

          if (findDFSResult === undefined) {
            findDFSResult = latestChange.chapters[0]
          }
          setActiveChapter(findDFSResult)
        }

        const activeDocIndex = copiedDocuments.findIndex(
          (doc) => doc.id === activeDocument.id
        )

        setActiveDocumentVersions(activeDocument.documentVersions)
        setActiveDocumentVersion(activeDocumentVersion)
        setActiveDocument(activeDocument)
        setDocumentChapters(latestChange.chapters)
        setActiveCountries(latestChange.countries)
        setActiveDocumentChange(latestChange)
        setActiveDocumentIndex(activeDocIndex)

        if (getCountry() !== undefined) {
          setActiveCountry(
            latestChange.countries.find(
              (country) => country.country_name === getCountry()
            )
          )
          setActiveCountryIndex(
            latestChange.countries.findIndex(
              (country) => country.country_name === getCountry()
            )
          )
        }
      }
    }

    if (!history.location.pathname.includes('/document/')) {
      //localStorage.removeItem('currentDocumentEdit')
      IndexedDBFunctions.deleteDocuments()
      localStorage.removeItem('activeChapterIndex')
      localStorage.removeItem('ContentIsDirty')
      localStorage.removeItem('DocumentEdits')
    }
  }, [history.location.pathname])

  React.useEffect(() => {
    if (
      activeChapter.treeIndexString !== undefined &&
      history.location.pathname.includes('/document/')
    ) {
      localStorage.setItem(
        'activeChapterIndexString',
        activeChapter.treeIndexString
      )
    }
  }, [activeChapter])

  React.useEffect(() => {
    if (displayLoader) {
      setTimeout(() => {
        setDisplayLoader(false)
      }, 1000)
    }
  }, [displayLoader])

  React.useEffect(() => {
    //
  }, [activeDocument])

  const contextPopulate: DocumentContextType = {
    mode: 'template',
    project: null,
    documents: copiedDocuments,
    activeCountryIndex,
    activeDocumentIndex,
    documentChapters,
    activeCountry,
    activeChapter,
    activeDocument,
    activeCountries,
    contentIsDirty,
    activeDocumentVersions,
    activeDocumentChange,
    activeDocumentVersion,
    sections,
    uploadedFile,
    wb,
    activeCountriesOnProject,
    projectDocumentsLoaded,
    extractedWorksheet,
    selectedFilter,
    displaySnack,
    snackSeverity,
    snackMessage,
    numberOfEdits,
    setSections,
    setActiveDocumentVersion,
    setActiveDocumentChange,
    setActiveDocumentVersions,
    setContentIsDirty,
    setActiveCountries,
    setActiveDocumentIndex,
    setActiveCountryIndex,
    setActiveDocument,
    setActiveChapter,
    setActiveCountry,
    setDocumentChapters,
    setUploadedFile,
    setWb,
    setExtractedWorksheet,
    setSelectedFilter,
    setCopiedDocuments,
    setProject: null,
    setDisplaySnack,
    setSnackSeverity,
    setSnackMessage,
    setDisplayLoader,
    setActiveCountriesOnProject,
    setProjectDocumentsLoaded,
    setNumberOfEdits
  }

  return (
    <DocumentContext.Provider value={contextPopulate}>
      <div
        style={{
          display: 'flex',
          flex: 1,
          height: '100%',
          flexDirection: 'column',
        }}
      >
        <Loader open={displayLoader} />
        {documents !== null && (
          <div style={{ flex: 0.05, position: 'relative', zIndex: 1 }}>
            <DocumentToolbar />
          </div>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flex: 0.95,
            position: 'relative',
            zIndex: 0,
            maxHeight: '88vh',
          }}
        >
          <div
            style={{
              width: '15%',
            }}
          >
            <SideNav />{' '}
          </div>

          <div
            style={{
              width: '100%',
            }}
          >
            <TemplateDocumentHomeContent />
            <TemplateHomeContent />
          </div>
        </div>
      </div>
      {displaySnack && (
        <EPISnackBar
          message={snackMessage}
          messageType={snackSeverity as string}
          isOpen={displaySnack}
          setIsOpen={setDisplaySnack}
        />
      )}
    </DocumentContext.Provider>
  )
}
