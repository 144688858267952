import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import React from 'react'
import { Country } from 'shared/types/country'
import { ChapterSection, Document } from 'shared/types/document'
import EditDocumentDialogInfo from './EditDocumentDialogInfo'
import EditDocumentCountry from './EditDocumentCountry'
import EditDocumentSections from './EditDocumentSections'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0, 2, 0, 2)
  },
  EPIAppBarRoot: {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    padding: '0px !important',
    paddingRight: theme.spacing(1)
  },
  EPITabsRoot: {
    padding: '0px !important'

    /*         '& span.PrivateTabIndicator-root-75': {
            visibility: 'hidden !important',
        }, */
  },
  EPITab: {
    /* padding: '0px !important', */
  },

  EPITabBox: {
    padding: theme.spacing(3, 2, 0, 0)
  }
}))
export type navProp = {
  Document: Document | undefined
  DocumentName: string | undefined
  setDocumentName: (name: string) => void
  author: string | undefined
  setAuthor: (author: string) => void
  countries: Country[] | undefined
  sections: ChapterSection[] | undefined
  setSections: (sectionList: ChapterSection[]) => void
  setCountries: (countryList: Country[]) => void
}

type TabPanelProps = {
  children?: React.ReactNode
  index: any
  value: any
}
const TabPanel: React.FC<TabPanelProps> = props => {
  const { children, value, index, ...other } = props
  const classes = useStyles()

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ padding: 0 }}
    >
      {value === index && <Box className={classes.EPITabBox}>{children}</Box>}
    </div>
  )
}

function a11yProps (index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

const EditTabNavigation: React.FC<navProp> = props => {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const handleChange = (
    event: React.ChangeEvent<Record<string, never>>,
    newValue: number
  ) => {
    setValue(newValue)
  }

  return (
    <div className={classes.root}>
      <AppBar elevation={0} className={classes.EPIAppBarRoot} position='static'>
        <Tabs
          indicatorColor='primary'
          className={classes.EPITabsRoot}
          value={value}
          onChange={handleChange}
          aria-label='edit user tab'
        >
          <Tab
            className={classes.EPITab}
            label='Document information'
            {...a11yProps(0)}
          />
          <Tab
            className={classes.EPITab}
            label='Countries'
            {...a11yProps(1)}
            disabled={props.DocumentName !== 'Business Blueprint'}
          />
          <Tab className={classes.EPITab} label='Sections' {...a11yProps(2)} />
          {/* <Tab className={classes.EPITab} label="Users" {...a11yProps(2)} /> */}
        </Tabs>
      </AppBar>
      <Divider />
      <TabPanel value={value} index={0}>
        <EditDocumentDialogInfo
          Document={props.Document}
          DocumentName={props.DocumentName}
          author={props.author}
          setDocumentName={props.setDocumentName}
          setAuthor={props.setAuthor}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <EditDocumentCountry
          Document={props.Document}
          countries={props.countries ? props.countries : []}
          setCountries={props.setCountries}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <EditDocumentSections
          Document={props.Document}
          sections={props.sections ? props.sections : []}
          setSections={props.setSections}
        />
      </TabPanel>
    </div>
  )
}

export default EditTabNavigation
