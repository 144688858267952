import React from "react"
import { SyncCountryContentComparison } from "./SyncCountryContentComparison"
import { SyncGlobalContentComparison } from "./SyncGlobalContentComparison"

export const SyncContentDialog: React.FC<{
    selectedSyncType: string
}> = ({
    selectedSyncType
}) => {

    // const context = useContext(DocumentContext)
    // const { 
    //     activeDocument,
    //     activeChapter,
    // } = context

    
    // const [diffControlToggle, setDiffControlToggle] = React.useState<boolean>(false)

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    height: 'auto',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    flexGrow: 1,
                }}
            >
                {/* <FormControlLabel
                    control={
                      <Switch
                        checked={diffControlToggle}
                        onChange={() =>
                          setDiffControlToggle(!diffControlToggle)
                        }
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="View in Editor"
                  /> */}
                    <div
                        style={{
                            display: 'flex',
                            height: 'auto',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            flexGrow: 1,
                        }}
                    >
                        {selectedSyncType === 'Global Content' ? (
                            <SyncGlobalContentComparison/>
                        ) : (
                            <SyncCountryContentComparison/>
                        )}
                        

                    </div>
                    

            </div>
        </>
    )
}


