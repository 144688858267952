import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import { Skeleton } from '@material-ui/lab'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { cloneDeep } from 'lodash'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import Loader from 'shared/components/Feedback/Loader'
import { DocumentChange, DocumentVersion } from 'shared/types/document'
import ChangeComparison from 'views/Pages/ProjectEnvironment/Home/DocumentHome/Versions/ChangeComparison'
const ChangesList: React.FC<{
  version: DocumentVersion
}> = ({ version }) => {
  // const dispatch = useDispatch()

  const stateDocVersions = useSelector(
    (state: RootState) => state.documents.DocumentVersions
  )

  // React.useEffect(() => {
  //   const fetchChangesThunk = fetchChanges(version.id)
  //   dispatch(fetchChangesThunk)
  // }, [])

  const [clonedChanges, setClonedChanges] = React.useState<DocumentChange[]>([])
  const [showChanges, setShowChanges] = React.useState(false)
  const [oldChange, setOldChange] = React.useState({} as DocumentChange)
  const [currentChange, setCurrentChange] = React.useState({} as DocumentChange)
  const [currentChangeIndex, setCurrentChangeIndex] = useState(0)
  React.useEffect(() => {
    if (currentChange.countries !== undefined) {
      setShowChanges(true)
    }
  }, [currentChangeIndex])

  React.useEffect(() => {
    let versionTemp = stateDocVersions.find((vers) => vers.id === version.id)

    if (versionTemp.changes) {
      let sortedChanges = cloneDeep(versionTemp.changes)
      sortedChanges.sort((a, b) => new Date(a.lastUpdated).getTime() - new Date(b.lastUpdated).getTime())

      setClonedChanges(sortedChanges)
    }
  }, [stateDocVersions])

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {showChanges === true ? (
        <>
          <Button
            onClick={() => {
              setShowChanges(false)
            }}
          >
            <ArrowBackIcon />
            &nbsp;Go back
          </Button>
          <ChangeComparison
            oldChange={oldChange}
            curChange={currentChange}
            index={currentChangeIndex}
          />
        </>
      ) : (
        <>
          {clonedChanges.length > 0 ? (
            <List dense>
              {clonedChanges
                .map((change, index) => {
                  // if (index === 0) return null;
                  const labelId = `checkbox-list-secondary-label-${change.version}`

                  let datestring
                  let regExp = /[a-zA-Z]/g

                  if (regExp.test(change.lastUpdated)) {
                    datestring = change.lastUpdated
                  } else {
                    datestring = change.lastUpdated
                  }

                  const who = change.changedBy
                    ? change.changedBy.surname +
                      ', ' +
                      change.changedBy.firstname
                    : 'System'

                  if (index === 0) {
                    return (
                      <ListItem button>
                        <ListItemText
                          primary={
                            <>
                              <b>
                                Initial Change
                                <br />
                              </b>
                              <div>
                                Author: <b>{who}</b>
                              </div>
                            </>
                          }
                          secondary={
                            <>
                              Created: <b>{datestring}</b>
                            </>
                          }
                        />
                      </ListItem>
                    )
                  }

                  return (
                    <ListItem key={index} button>
                      <ListItemText
                        id={labelId}
                        primary={
                          <>
                            <b>
                              Change {index + 1} <br />
                            </b>
                            <div>
                              Author: <b>{who}</b>
                            </div>
                          </>
                        }
                        secondary={
                          <>
                            Created: <b>{datestring}</b>
                          </>
                        }
                      />
                      <ListItemSecondaryAction>
                        <Button
                          variant={'contained'}
                          onClick={() => {
                            // if (index - 1 === -1) {
                            //   console.log('Initial Change', versions[0].changes[0])
                            //   setOldChange(versions[0].changes[0])
                            // } else {
                            setOldChange(clonedChanges[index - 1])
                            // }
                            //
                            setCurrentChange(change)
                            setCurrentChangeIndex(index + 1)
                            setShowChanges(true)
                          }}
                          disabled={
                            typeof change === 'string' ||
                            typeof clonedChanges[index - 1] === 'string'
                          }
                        >
                          <Loader
                            open={
                              typeof change === 'string' ||
                              typeof clonedChanges[index - 1] === 'string'
                            }
                          />
                          View Changes
                        </Button>
                      </ListItemSecondaryAction>
                    </ListItem>
                  )
                })
                .reverse()}
            </List>
          ) : (
            <div
              style={{
                width: '100%',
                padding: '1em',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Loader open={true} />
              <Skeleton
                style={{
                  margin: 0,
                }}
                variant='rect'
                width={'100%'}
                height={100}
              />
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default ChangesList
