import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import { Document } from 'shared/types/document';

export type formProp = {
  Document: Document | undefined;
  DocumentName: string | undefined;
  setDocumentName: (name: string) => void;
  author: string | undefined;
  setAuthor: (author: string) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: '100%',
    padding: theme.spacing(0, 0, 0, 0),
  },
  EPIGridSpacingLeft: {
    paddingLeft: '0px !important',
  },
  EPIGridSpacingLeftMobile: {
    paddingLeft: '0px !important',
  },
}));

const EditDocumentDialogInfo = ({
  DocumentName,
  setDocumentName,
  author,
  setAuthor,
}: formProp) => {
  const classes = useStyles();

  return (
    <form className={classes.form} noValidate>
      <Grid spacing={3} container>
        <Grid className={classes.EPIGridSpacingLeft} sm={6} xs={12} item>
          <TextField
            autoComplete="DocumentName"
            id="DocumentName"
            label="Document Name"
            name="DocumentName"
            variant="outlined"
            fullWidth
            required
            value={DocumentName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setDocumentName(event.target.value)
            }
          ></TextField>
        </Grid>
        <Grid className={classes.EPIGridSpacingLeft} sm={6} xs={12} item>
          <TextField
            autoComplete="author"
            id="author"
            label="Author"
            name="author"
            variant="outlined"
            value={author}
            fullWidth
            required
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setAuthor(event.target.value)
            }
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default EditDocumentDialogInfo;
