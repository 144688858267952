import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import { NavigationData } from './CardData'
import Button from '@material-ui/core/Button'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) => ({
    card: {
        width: '100%',
        padding: theme.spacing(2),
        /*  display: 'flex',
        minHeight: '250px',
        maxWidth: '350px',
        [theme.breakpoints.down('md')]: {
            minHeight: '300px',
            width: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            minHeight: '250px',
        },
        padding: theme.spacing(4), */
    },
    cardContent: {
        /* display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center', */
    },
    title: {
        paddingBottom: theme.spacing(2),
        textAlign: 'center',
    },
    content: {},
    media: {
        /* height: 140, */
    },
    cardButton: {
        padding: theme.spacing(4, 0, 0, 0),
        width: '100%',
    },
}))

const HomeCard: React.FC<NavigationData> = ({ content, link, role }) => {
    const classes = useStyles()
    const history = useHistory()

    return (
        <Grid xs={12} item>
            {
                <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                        <Typography className={classes.title} color="textPrimary" variant="h5" component="h2">
                            {content?.title}
                        </Typography>
                        <Typography className={classes.content} color="textSecondary" gutterBottom>
                            {content?.body}
                        </Typography>
                        {link && (
                            <CardActions className={classes.cardButton}>
                                <Button
                                    focusRipple
                                    color="primary"
                                    /* color="secondary" */
                                    variant="outlined"
                                    /* variant="contained" */
                                    onClick={() => history.push(link)}
                                    fullWidth
                                >
                                    Get started
                                </Button>
                            </CardActions>
                        )}
                    </CardContent>
                </Card>
            }
            {/*             <Card className={classes.card}>
                <CardContent>
                    <Typography variant="h5" component="h2" className={classes.title}>
                        {content?.title}
                    </Typography>
                    <Typography variant="body2" component="p">
                        {content?.body}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button size="small">Learn More</Button>
                </CardActions>
            </Card> */}
        </Grid>
    )
}

export default HomeCard
