import { Paper } from '@material-ui/core';
import React, {useContext, useEffect} from 'react';
import { DocumentContext } from 'shared/components/DocumentEditor/DocumentEditor';
import { DocumentSections } from 'shared/components/DocumentEditor/EditorContainer/DocumentSections/DocumentSections';
import { WageTypeCatalogueContainer } from 'shared/components/DocumentEditor/WageTypeCatalogueContainer/WageTypeCatalogueContainer';
import IndexedDBFunctions from "../../../../IndexedDBFunctions";

// interface ChapterNavigationType {
//   children: React.ReactNode
//   menuOpen: boolean
//   setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
//   tooltip: string
// }

export const EditorContainer: React.FC = () => {
  const context = useContext(DocumentContext);
  const { activeDocument } = context;

  const pageRefConf = () => {
    //localStorage.removeItem('currentDocumentEdit')
    IndexedDBFunctions.deleteDocuments()
    localStorage.setItem('ContentIsDirty', 'false')
  }
  useEffect(() => {
    window.addEventListener('beforeunload', pageRefConf)

    return () => {
      window.removeEventListener('beforeunload', pageRefConf)
    }
  }, [])

  if (activeDocument !== undefined && activeDocument.name !== undefined) {
    return (
      <Paper
        style={{
          width: '100%',
          height: '80vh',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
        }}
      >
        {activeDocument.name !== 'Wage Type Catalogue' ? (
          <div
            style={{
              paddingTop: '1em',
            }}
          >
            {/* <DocumentToolbar /> */}
            {/* <ChapterHeader /> */}
            <DocumentSections />
          </div>
        ) : (
          <div
            style={{
              paddingTop: '1em',
            }}
          >
            {/* <DocumentToolbar /> */}
            <WageTypeCatalogueContainer />
          </div>
        )}
      </Paper>
    );
  }

  return null;
};
