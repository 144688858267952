import {
  Button, CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { cloneDeep } from 'lodash'
import React, { useContext, useEffect } from 'react'
import {useSelector} from 'react-redux'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import { RootState } from 'redux/store'
import { SnackType } from 'shared/components/Feedback/EPISnackBar'
import {
  ChapterObject,
  Document,
  DocumentChange,
  DocumentVersion
} from 'shared/types/document'
import { Project } from 'shared/types/project'
import * as reduxDocumentType from '../../../../types/document'
import { DocumentContext } from '../../DocumentEditor'
import {
  findDFSBySharedIdAndUpdateChapter,
  findDFSBySharedIdSyncAll,
  reIndexChapters, findDFSBySharedId
} from '../../treeUtils'
import { SyncAllDialog } from './SyncAllDialog'
import { SyncContentDialog } from './SyncContentDialog'
import { SyncStructureDialog } from './SyncStructureDialog'
import {Country} from "../../../../types/country";
import {SyncUnableDialog} from "./SyncUnableDialog";
import IndexedDBFunctions from "../../../../../IndexedDBFunctions";

const isActiveInCountry = (activeCountry: Country, chapter:  reduxDocumentType.ChapterObject) => {
  const section = chapter.sections[0]
  const country = section.countrySpecificContent.find(content => content.country.country_name === activeCountry.country_name)

  if(country === undefined){
    return true
  } else if(country.isChapterActiveInCountry) {
    return true
  }

  return false
}

export const SyncDialog: React.FC<{
  open: boolean
  setOpen: (open: boolean) => void
  finalStep: boolean
  setFinalStep: (open: boolean) => void
  selectedSyncType: string
  setSelectedSyncType: (open: string) => void
  reloadDocuments?: boolean
  setReloadDocuments?: (reloadDocuments: boolean) => void
}> = ({
  open,
  setOpen,
  finalStep,
  setFinalStep,
  selectedSyncType,
  setSelectedSyncType,
  reloadDocuments,
  setReloadDocuments,
}) => {
  const context = useContext(DocumentContext)
  const {
    activeDocumentVersions,
    activeDocumentVersion,
    activeDocumentChange,
    activeCountry,
    documentChapters,
    activeDocument,
    activeChapter,
    documents,
    // project,
    setCopiedDocuments,
    setDocumentChapters,
    setDisplayLoader,
    setActiveCountryIndex,
    setActiveCountry,
    project,
    setSnackSeverity,
    setSnackMessage,
    setDisplaySnack,
    setContentIsDirty,
    setNumberOfEdits
  } = context

  const history = useHistory()

  const handleSyncChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedSyncType((event.target as HTMLInputElement).value)
  }

  const templateDocuments = useSelector(
    (state: RootState) => state.documents.Documents
  )
  const [templateDocument, setTemplateDocument] = React.useState<Document>(
    undefined
  )
  const [templateDocumentVersion, setTemplateDocumentVersion] = React.useState<
    DocumentVersion
  >(undefined)
  const [templateDocumentChange, setTemplateDocumentChange] = React.useState<
    DocumentChange
  >(undefined)
  const [templateChapter, setTemplateChapter] = React.useState<ChapterObject>(
    undefined
  )

  useEffect(() => {
    if(reloadDocuments){
      console.log('RELOAD')
      localStorage.setItem('latestChanges', 'false')
      //dispatch({ type: createDocuments.type, payload: undefined })
      setTemplateDocumentChange(undefined)
      //setTemplateDocument(undefined)
      setReloadDocuments(false)
    }

  },[reloadDocuments])

  useEffect(() => {
    console.log('Triggered the setchange')

    if (templateDocuments && templateDocuments.length > 0) {
      setTemplateDocument(
        templateDocuments.find(tempDoc => tempDoc.id === activeDocument.refId)
      )

      console.log('Template Document', templateDocument)
      let tempDoc = templateDocuments.find(
        tempDoc => tempDoc.id === activeDocument.refId
      )

      if (typeof tempDoc.documentVersions[0] !== 'string') {
        setTemplateDocumentVersion(
          tempDoc.documentVersions[tempDoc.documentVersions.length - 1]
        )

        if (templateDocumentVersion !== undefined) {
          setTemplateDocumentChange(
            templateDocumentVersion.changes[
              templateDocumentVersion.changes.length - 1
            ]
          )
          setTemplateChapter(
            findDFSBySharedId(
              templateDocumentVersion.changes[
                templateDocumentVersion.changes.length - 1
              ].chapters,
              activeChapter.sharedId
            )
          )
        }
      }
    }
  }, [
    templateDocuments,
    templateDocument,
    templateDocumentVersion,
    templateDocumentChange,
    activeChapter
  ])

  function isCountryActiveInTemplate () {
    if (templateDocuments && templateDocuments.length > 0) {
      let tempDoc = templateDocuments.find(
        tempDoc => tempDoc.id === activeDocument.refId
      )

      if(tempDoc) {
        let tempVersion = tempDoc.documentVersions[tempDoc.documentVersions.length - 1]
        let tempChange = tempVersion.changes[tempVersion.changes.length - 1]

        if (tempChange.countries.find(country => country.country_name === activeCountry.country_name) !== undefined) {
          return true
        }
      }
    }

    return false
  }

  function getCountry () {
    let p1 = history.location.pathname.split('/country/')[1]

    return (p1 as undefined) as string
  }

  function useQuery () {
    return new URLSearchParams(useLocation().search)
  }

  let query = useQuery()
  const section = query.get('section')

  const checkActiveCountry = (documentChange: DocumentChange) => {
    let tempCountry = project.countries.find(
      country => country.country_name === getCountry()
    )
    let tempCountryIndex = project.countries.findIndex(
      country => country.country_name === getCountry()
    )

    if (
      documentChange !== undefined &&
      documentChange.countries &&
      documentChange.countries.length > 0 &&
      documentChange.countries.find(
        country => country.country_name === getCountry()
      ) !== undefined
    ) {
      setActiveCountry(
        tempCountry !== undefined ? tempCountry : project.countries[0]
      )
      setActiveCountryIndex(tempCountryIndex !== -1 ? tempCountryIndex : 0)
    } else {
      setActiveCountry(
        documentChange.countries !== undefined
          ? documentChange.countries[0]
          : project.countries[0]
      )
      setActiveCountryIndex(0)

      if (documentChange.countries !== undefined) {
        setSnackSeverity(SnackType.SnackWarning)
        setSnackMessage(
          'Active Country was removed by sync. Defaulting to first country.'
        )
        setDisplaySnack(true)
        history.push(
          `/projectEnvironment/project/${project.id}/document/${activeDocument.id}/country/${documentChange.countries[0]}?section=${section}`
        )
      }
    }
  }

  const syncAllContent = (newChapterStructure: ChapterObject[]) => {
    let templateChange = cloneDeep(templateDocumentChange)
    findDFSBySharedIdSyncAll(
      newChapterStructure,
      templateChange,
      templateDocumentChange.countries,
      activeCountry
    )
  }

  const syncContent = (selectedSyncType): Document[] => {
    let newChapter: ChapterObject = cloneDeep(templateChapter)
    let docChapters: ChapterObject[] = documentChapters
    console.log('ACTIVE CHAPTER', activeChapter)
    findDFSBySharedIdAndUpdateChapter(
      docChapters,
      newChapter.sharedId,
      newChapter,
      activeChapter,
      templateDocumentChange.countries,
      selectedSyncType,
      activeCountry
    )

    let syncedChapter = docChapters.find(chapter => chapter.sharedId === newChapter.sharedId)

    if(syncedChapter){
      syncedChapter.wasChanged = true
    }

    let newReduxDocumentChange: DocumentChange = {
      ...activeDocumentChange,
      chapters: docChapters,
      countries: templateDocumentChange.countries,
      version: activeDocumentVersion.latestVersion + 1,
      lastUpdated: new Date().toString(),
      id: null
    }

    let activeDocDocumentChanges: DocumentChange[] =
      activeDocumentVersion.changes

    activeDocumentVersion.lastUpdated = new Date().toString()

    if (
      activeDocDocumentChanges[activeDocDocumentChanges.length - 1].id === null
    ) {
      activeDocDocumentChanges[
        activeDocDocumentChanges.length - 1
      ] = newReduxDocumentChange
    } else {
      activeDocDocumentChanges.push(newReduxDocumentChange)
    }

    let newDocument: Document = {
      ...activeDocument,
      documentVersions: activeDocumentVersions,
      lastUpdated: new Date().toString()
    }
    let docs = documents
    let newDocs: Document[] = documents
    console.log(newDocs)

    let index = docs.findIndex(d => d.id === activeDocument.id)

    if (index !== -1) {
      newDocs[index] = newDocument
    }

    Object.assign(documents, newDocs)
    checkActiveCountry(newReduxDocumentChange)
    setCopiedDocuments(JSON.parse(JSON.stringify(newDocs)))
    //localStorage.setItem('currentDocumentEdit', JSON.stringify(newDocs))

    IndexedDBFunctions.deleteDocuments()
    IndexedDBFunctions.addDocuments(newDocs)
    //This is just a work around as we encountered issues with Local Storage
    setNumberOfEdits(Math.random() * 1000)

    return newDocs
  }

  const syncStructure = (
    projectDocumentChapters: ChapterObject[],
    templateDocumentChapters: ChapterObject[]
  ) => {
    let newChapterStructure: ChapterObject[] = []
    let disjointedChapters: ChapterObject[] = []
    projectDocumentChapters.sort((a, b) => a.chapterOrder - b.chapterOrder)
    templateDocumentChapters.sort((a, b) => a.chapterOrder - b.chapterOrder)

    templateDocumentChapters.filter((chapter) => isActiveInCountry(activeCountry, chapter) ||
      projectDocumentChapters.find(projectChapter => projectChapter.sharedId === chapter.sharedId) !== undefined)
      .map((newChapter, index) => {
      let matchingOriginalChapter = projectDocumentChapters.find(
        originalChapter => originalChapter.sharedId === newChapter.sharedId
      )

      if (matchingOriginalChapter) {
        matchingOriginalChapter.subchapterCreateAllowed = newChapter.subchapterCreateAllowed

        if (!matchingOriginalChapter.refId) {
          disjointedChapters.push(matchingOriginalChapter)
          newChapter.refId = newChapter.id
          newChapter.wasChanged = true

          newChapterStructure.push(newChapter)
        } else {
          let countrySpecificName: any = ''
          newChapter.sections.map(
            (section: reduxDocumentType.ChapterSection) => {
              if (
                section.countrySpecificContent &&
                section.countrySpecificContent.length > 0
              ) {
                let currentCountry: reduxDocumentType.CountrySpecificContent = section.countrySpecificContent.find(
                  countryContent =>
                    countryContent.country.country_name ===
                    activeCountry.country_name
                )

                if (currentCountry) {
                  countrySpecificName = currentCountry.chapterCountrySpecificName
                    ? currentCountry.chapterCountrySpecificName
                    : newChapter.name
                }
              }
            }
          )

          let oldCountrySpecificName: any = ''
          matchingOriginalChapter.sections.map(
            (section: reduxDocumentType.ChapterSection) => {
              if (
                section.countrySpecificContent &&
                section.countrySpecificContent.length > 0
              ) {
                let currentCountry: reduxDocumentType.CountrySpecificContent = section.countrySpecificContent.find(
                  countryContent =>
                    countryContent.country.country_name ===
                    activeCountry.country_name
                )

                if (currentCountry) {
                  oldCountrySpecificName = currentCountry.chapterCountrySpecificName
                    ? currentCountry.chapterCountrySpecificName
                    : matchingOriginalChapter.name
                }
              }
            }
          )

          if (oldCountrySpecificName !== countrySpecificName) {
            matchingOriginalChapter.sections.map(section => {
              let currentCountry = section.countrySpecificContent.find(
                countryContent =>
                  countryContent.country.country_name ===
                  activeCountry.country_name
              )

              if (currentCountry) {
                currentCountry.chapterCountrySpecificName = countrySpecificName
                newChapter.wasChanged = true
              }
            })
          }

          if (matchingOriginalChapter.name !== newChapter.name) {
            matchingOriginalChapter.name = newChapter.name
            newChapter.wasChanged = true
          }

          if (matchingOriginalChapter.refId !== newChapter.id) {
            matchingOriginalChapter.refId = newChapter.id
          }

          if (
            matchingOriginalChapter.chapterOrder !== newChapter.chapterOrder
          ) {
            matchingOriginalChapter.chapterOrder = newChapter.chapterOrder
          }

          if (
            newChapter.subchapters.length > 0 ||
            matchingOriginalChapter.subchapters.length > 0
          ) {
            matchingOriginalChapter.subchapters = syncStructure(
              matchingOriginalChapter.subchapters,
              newChapter.subchapters
            )
          }

          newChapterStructure.push(matchingOriginalChapter)
        }
      } else {
        newChapter.refId = newChapter.id

        if (newChapter.subchapters.length > 0) {
          newChapter.subchapters = syncStructure([], newChapter.subchapters)
        }
        newChapter.wasChanged = true
        newChapterStructure.push(newChapter)
      }
    })

    projectDocumentChapters.map(projectChapter => {
      if (!projectChapter.refId) {
        newChapterStructure.push(projectChapter)
      }
    })

    disjointedChapters.map(chapter => {
      chapter.chapterOrder = newChapterStructure.length + 1
      newChapterStructure.push(chapter)
    })

    return newChapterStructure
  }

  return (
    <>
      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth={
          !finalStep ? 'xs' : selectedSyncType === 'Content' ? 'md' : 'sm'
        }
      >
        <DialogTitle id='form-dialog-title'>Sync Selection</DialogTitle>
        <DialogContent>
          {templateDocumentChange !== undefined && localStorage.getItem('latestChanges') === 'true' ? (
            <>
              {!isCountryActiveInTemplate() ? (
                <>
                  <SyncUnableDialog/>
                </>
              ) :
              !finalStep ? (
                <>
                  <FormControl component='fieldset'>
                    <FormLabel component='legend'>
                      Please select a sync type:
                    </FormLabel>
                    <RadioGroup
                      aria-label='sync'
                      defaultValue='Content'
                      name='radio-buttons-group'
                      onChange={handleSyncChange}
                    >
                      <FormControlLabel
                        value='Global Content'
                        control={<Radio />}
                        label='Sync Global Content'
                      />
                      <FormControlLabel
                        value='Country Content'
                        control={<Radio />}
                        label='Sync Country Content'
                      />
                    </RadioGroup>
                  </FormControl>
                </>
              ) : (
                <>
                  {selectedSyncType === 'Global Content' ||
                  selectedSyncType === 'Country Content' ? (
                    <>
                      <SyncContentDialog selectedSyncType={selectedSyncType} />
                    </>
                  ) : (
                    <>
                      {selectedSyncType === 'Structure' ? (
                        <>
                          <SyncStructureDialog />
                        </>
                      ) : (
                        <>
                          <SyncAllDialog />
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
              <CircularProgress />
              <h3>Scanning Template Document for changes...</h3>
              <Skeleton variant='rect' width={'100%'} height={100} />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          {!finalStep ? (
            <>
              <Button
                onClick={() => {
                  setOpen(false)
                }}
                color='primary'
              >
                Cancel
              </Button>
              {isCountryActiveInTemplate() && (<Button
                onClick={() => {
                  setFinalStep(true)
                }}
                color='primary'
                disabled={selectedSyncType === ''}
              >
                Next
              </Button>)}
            </>
          ) : (
            <>
              {(selectedSyncType === 'Global Content' ||
                selectedSyncType === 'Country Content') && (
                <Button
                  onClick={() => {
                    setFinalStep(false)
                  }}
                  color='primary'
                >
                  Back
                </Button>
              )}
              {(selectedSyncType === 'All' ||
                selectedSyncType === 'Structure') && (
                <Button
                  onClick={() => {
                    setFinalStep(false)
                    setOpen(false)
                  }}
                  color='primary'
                >
                  Cancel
                </Button>
              )}
              {isCountryActiveInTemplate() && (<Button
                onClick={() => {
                  if (
                    selectedSyncType === 'Global Content' ||
                    selectedSyncType === 'Country Content'
                  ) {
                    let newDocs = syncContent(selectedSyncType)
                    let newReduxObj: Project = {
                      ...project,
                      documents: newDocs,
                      lastUpdated: new Date().toString()
                    }
                    Object.assign(project, newReduxObj)
                  } else if (selectedSyncType === 'Structure') {
                    let newChapterStructure = syncStructure(
                      documentChapters,
                      cloneDeep(templateDocumentChange.chapters)
                    )
                    console.log(documentChapters)
                    console.log(newChapterStructure)
                    reIndexChapters(newChapterStructure, 0, '')
                    console.log(newChapterStructure)
                    setDocumentChapters(
                      JSON.parse(JSON.stringify(newChapterStructure))
                    )
                    console.log('documents', documents)

                    let newReduxObj: Project = {
                      ...project,
                      documents,
                      lastUpdated: new Date().toString()
                    }

                    let newReduxDocumentChange: DocumentChange = {
                      ...activeDocumentChange,
                      chapters: newChapterStructure,
                      lastUpdated: new Date().toString(),
                      id: null
                    }

                    let activeDocDocumentChanges: DocumentChange[] =
                      activeDocumentVersion.changes

                    activeDocumentVersion.lastUpdated = new Date().toString()

                    if (
                      activeDocDocumentChanges[
                        activeDocDocumentChanges.length - 1
                      ].id === null
                    ) {
                      activeDocDocumentChanges[
                        activeDocDocumentChanges.length - 1
                      ] = newReduxDocumentChange
                    } else {
                      activeDocDocumentChanges.push(newReduxDocumentChange)
                    }

                    console.log(activeDocument)
                    console.log(activeDocumentVersion.changes)

                    Object.assign(project, newReduxObj)
                    // localStorage.setItem(
                    //   'currentDocumentEdit',
                    //   JSON.stringify(documents)
                    // )
                    IndexedDBFunctions.deleteDocuments()
                    IndexedDBFunctions.addDocuments(documents)
                    //This is just a work around as we encountered issues with Local Storage
                    setNumberOfEdits(Math.random() * 1000)
                  } else {
                    let newChapterStructure = syncStructure(
                      documentChapters,
                      cloneDeep(templateDocumentChange.chapters)
                    )
                    console.log("newChapterStructure",newChapterStructure)
                    reIndexChapters(newChapterStructure, 0, '')
                    syncAllContent(newChapterStructure)
                    setDocumentChapters(
                      JSON.parse(JSON.stringify(newChapterStructure))
                    )
                    let newReduxDocumentChange: DocumentChange = {
                      ...activeDocumentChange,
                      chapters: newChapterStructure,
                      countries: templateDocumentChange.countries,
                      version: activeDocumentVersion.latestVersion + 1,
                      lastUpdated: new Date().toString(),
                      id: null
                    }

                    let activeDocDocumentChanges: DocumentChange[] =
                      activeDocumentVersion.changes

                    activeDocumentVersion.lastUpdated = new Date().toString()

                    if (
                      activeDocDocumentChanges[
                        activeDocDocumentChanges.length - 1
                      ].id === null
                    ) {
                      activeDocDocumentChanges[
                        activeDocDocumentChanges.length - 1
                      ] = newReduxDocumentChange
                    } else {
                      activeDocDocumentChanges.push(newReduxDocumentChange)
                    }

                    let newDocument: Document = {
                      ...activeDocument,
                      documentVersions: activeDocumentVersions,
                      lastUpdated: new Date().toString()
                    }
                    let docs = documents
                    let newDocs: Document[] = documents
                    console.log(newDocs)

                    let index = docs.findIndex(d => d.id === activeDocument.id)

                    if (index !== -1) {
                      newDocs[index] = newDocument
                    }

                    checkActiveCountry(newReduxDocumentChange)
                    setCopiedDocuments(JSON.parse(JSON.stringify(newDocs)))

                    let newReduxObj: Project = {
                      ...project,
                      documents: newDocs,
                      lastUpdated: new Date().toString()
                    }
                    Object.assign(project, newReduxObj)
                    // localStorage.setItem(
                    //   'currentDocumentEdit',
                    //   JSON.stringify(newDocs)
                    // )
                    IndexedDBFunctions.deleteDocuments()
                    IndexedDBFunctions.addDocuments(documents)
                    //This is just a work around as we encountered issues with Local Storage
                    setNumberOfEdits(Math.random() * 1000)
                  }
                  localStorage.setItem('ContentIsDirty', 'true')
                  setContentIsDirty(true)
                  setDisplayLoader(true)
                  setOpen(false)
                }}
                color='primary'
              >
                Sync
              </Button>)}
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}
