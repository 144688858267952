import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Status } from 'shared/types/status'
import '../../config'

// interface RegisterUser {
//     firstName?: string
//     lastName?: string
//     email?: string
//     password?: string
// }

type registrationState = {
    registrationStatus: Status
    registrationMessage: string
}

const initialState: registrationState = {
    registrationStatus: Status.idle,
    registrationMessage: '',
}

export const registration = createSlice({
    name: 'registration',
    initialState,
    reducers: {
        setRegistrationStatus: (state, action: PayloadAction<Status>) => {
            state.registrationStatus = action.payload
        },
        setRegistrationMessage: (state, action: PayloadAction<string>) => {
            state.registrationMessage = action.payload
        },
    },
})

export const { setRegistrationStatus, setRegistrationMessage } = registration.actions

// Other code such as selectors can use the imported `RootState` type

export default registration.reducer
