import * as reduxDocumentType from 'shared/types/document'
import * as XLSX from 'xlsx'
import { TableData } from './WageTypeCatalogue'

export async function getWorkbookFromFile2 (excelFile: File) {
  return new Promise<XLSX.WorkBook>((resolve, reject) => {
    let reader = new FileReader()

    reader.onload = (event: any) => {
      let data = event.target.result

      let workbook = XLSX.read(data, { type: 'binary' })

      // console.log(workbook.SheetNames);
      resolve(workbook)
    }
    reader.readAsBinaryString(excelFile)
  })
}

export const uploadWageTypeCatalogue = async (
  event: React.ChangeEvent<HTMLInputElement>,
  setUploadedFile,
  setWb,
  setExtractedWorksheet
) => {
  const target: DataTransfer = (event.target as unknown) as DataTransfer
  const wb: XLSX.WorkBook = await getWorkbookFromFile2(target.files[0])
  setUploadedFile(target.files[0])
  setWb(wb)
  convertWorksheetToTableFormat(wb, setExtractedWorksheet)
}

export const convertWorksheetToTableFormat = (
  wb: XLSX.WorkBook,
  setExtractedWorksheet
) => {
  wb.SheetNames.filter(
    sheetName => sheetName === 'Wage Type Catalogue'
  ).forEach((sheetName, index) => {
    let wsname: string = sheetName
    let ws: XLSX.WorkSheet = wb.Sheets[wsname]
    let data = XLSX.utils.sheet_to_json(ws, { defval: '' })
    let newTableData: TableData = formatJsonDataToWageCatalogueType(data)

    let extractedWorksheet: reduxDocumentType.WageCatalogueSheet = {
      worksheetName: wsname,
      columns: newTableData.columns,
      parsedData: newTableData.data,
      filters: []
    }
    setExtractedWorksheet(extractedWorksheet)
  })
}

export const formatJsonDataToWageCatalogueType = (json: any): TableData => {
  //

  // console.log("INSIDE FORMATTER")
  // console.log(json)
  let tempColumns = []
  let tempTableData = []

  for (let i = 0; i != json.length; ++i) {
    let newRow: any = {}

    if (i !== 0) {
      newRow['index'] = i
    }

    if (i === 0) {
      let newColumn: reduxDocumentType.MaterialColumnObject = {
        title: 'Index',
        field: 'index',
        customFilter: null,
        customSort: null,
        colIndex: 0,
        hidden: false
      }

      tempColumns.push(newColumn)
      // setTableColumns(tempColumns)
    }

    let j = 1

    for (let field in json[i]) {
      if (i === 0) {
        let newColumn: reduxDocumentType.MaterialColumnObject = {
          title: field,
          field:
            field !== '' ? field.toLowerCase().replace(/\s/g, '') : 'NONAME',
          customFilter: null,
          customSort: null,
          colIndex: j,
          hidden: j < 6 ? false : true
        }
        tempColumns.push(newColumn)
        // setTableColumns(tempColumns)
      }

      if (i !== 0) {
        // console.log(tableColumns)

        let column = tempColumns.find(col => col.colIndex === j)

        if (column === undefined) {
          // console.log(field)
          // console.log(tableColumns)
          // console.log(json)
        }
        newRow[column.field] = json[i][field]
      }
      j++
    }

    if (i !== 0) {
      tempTableData.push(newRow)
    }
  }
  // console.log(setTableData)
  // setTableData(tempTableData)

  // console.log(tableData)
  return {
    columns: tempColumns,
    data: tempTableData
  }
}
