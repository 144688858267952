import {
  Checkbox,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText, Tooltip,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { CompareArrows } from '@material-ui/icons'
import ExportIcon from '@material-ui/icons/SaveAlt'
import { Skeleton } from '@material-ui/lab'
import PublishIcon from '@mui/icons-material/Publish'
import FileSaver from 'file-saver'
import htmlDocx from 'html-docx-js/dist/html-docx'
import { cloneDeep } from 'lodash'
import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import { fetchVersionsAndChanges} from 'redux/thunks/documentsThunk'
import { saveToNewVersion } from 'redux/thunks/projectsThunk'
import { DocumentContext } from 'shared/components/DocumentEditor/DocumentEditor'
import { CountrySelector } from 'shared/components/DocumentEditor/GlobalToolbar/CountrySelector/CountrySelector'
import Loader from 'shared/components/Feedback/Loader'
import { Country } from 'shared/types/country'
import {ChapterObject, Document, DocumentChange, DocumentVersion} from 'shared/types/document'
import { Status } from 'shared/types/status'
import ChangesDialog from 'views/Pages/ProjectEnvironment/Home/DocumentHome/Versions/Dialogs/ChangesDialog'

function compileHTMLThroughDFS(
  chapters: ChapterObject[],
  htmlContent: string,
  depth: number,
  country: Country
): string {
  if (chapters) {
    let tempChapters: ChapterObject[] = chapters
    tempChapters.sort((a, b) => a.chapterOrder - b.chapterOrder)
    tempChapters.map((chapter) => {
      if (depth <= 6) {
        htmlContent = htmlContent.concat(
          `<h${depth}>${chapter.name}</h${depth}><br/>`
        )
      } else {
        htmlContent = htmlContent.concat(`<h6>${chapter.name}</h6><br/>`)
      }

      if (chapter.sections && chapter.sections.length > 0) {
        chapter.sections.map((section) => {
          if (country === null || country === undefined) {
            //
            if (section.isGlobal) {
              if (depth <= 5) {
                htmlContent = htmlContent.concat(
                  `<h${depth}>${section.name}</h${depth}><br/>`
                )
              } else {
                htmlContent = htmlContent.concat(
                  `<h6>${section.name}</h6><br/>`
                )
              }
              htmlContent = htmlContent.concat(
                `<p>${section.chapterGlobalContent.rawHTML}</p><br/>`
              )
            }
          } else {
            section.countrySpecificContent
              .filter(
                (countryContent) =>
                  countryContent.country.country_name === country.country_name
              )
              .map((exportedCountry) => {
                let countrySpecificName =
                  exportedCountry.chapterCountrySpecificName
                    ? exportedCountry.chapterCountrySpecificName
                    : chapter.name

                if (depth <= 5) {
                  htmlContent = htmlContent.concat(
                    `<h${depth}>${countrySpecificName} - ${section.name}</h${depth}><br/>`
                  )
                } else {
                  htmlContent = htmlContent.concat(
                    `<h6>${countrySpecificName} - ${section.name}</h6><br/>`
                  )
                }

                if (section.isGlobal) {
                  htmlContent = htmlContent.concat(
                    `<h6>${countrySpecificName} - ${section.name}</h6>`
                  )
                  htmlContent = htmlContent.concat(
                    `<p>${section.chapterGlobalContent.rawHTML}</p><br/>`
                  )
                } else if (section.isClientEditable) {
                  htmlContent = htmlContent.concat(
                    `<h6>${countrySpecificName} - ${section.name}</h6>`
                  )
                  htmlContent = htmlContent.concat(
                    `<p>${exportedCountry.clientContent.rawHTML}</p><br/>`
                  )
                } else {
                  htmlContent = htmlContent.concat(
                    `<h6>${countrySpecificName} - ${section.name}</h6>`
                  )
                  htmlContent = htmlContent.concat(
                    `<p>${exportedCountry.nonClientContent.rawHTML}</p><br/>`
                  )
                }
              })
          }
        })
      }

      if (chapter.subchapters.length > 0) {
        htmlContent = compileHTMLThroughDFS(
          chapter.subchapters,
          htmlContent,
          depth + 1,
          country
        )
      }
    })

    return htmlContent
  }
}

const onExportButtonClicked = async (
  doc: DocumentVersion,
  activeDocument: Document,
  activeCountry: Country,
  index
): Promise<void> => {
  let htmlString = ''
  let latestDocVersion = cloneDeep(doc.changes[doc.changes.length - 1])
  htmlString = htmlString.concat(`<h1>${activeDocument.name}</h1><br/>`)
  htmlString = compileHTMLThroughDFS(
    latestDocVersion.chapters,
    htmlString,
    1,
    activeCountry
  )
  let converted = htmlDocx.asBlob(htmlString) as Blob
  FileSaver.saveAs(converted, `${activeDocument.name}.docx`)
}

const Versions: React.FC = () => {
  const dispatch = useDispatch()
  const context = useContext(DocumentContext)
  const { activeDocument, project, activeCountry, setActiveCountriesOnProject } = context

  const saveToNewVersionStatus = useSelector(
    (state: RootState) => state.projects.saveToNewVersionStatus
  )

  const stateDocVersions = useSelector(
    (state: RootState) => state.documents.DocumentVersions
  )

  const [filteredVersions, setFilteredVersions] =
    React.useState<DocumentVersion[]>(stateDocVersions)

  React.useEffect(() => {
    if(project && project.countries.find((country) => country.active_status) !== undefined) {
      setActiveCountriesOnProject(true)
    }
  })

  React.useEffect(() => {
    if (activeDocument && project && project.documents && project.documents.find(
      (proj) => typeof proj === 'string'
    ) === undefined) {
      console.log('Trigger the fetch for versions')
      const fetchVersionsThunk = fetchVersionsAndChanges(false, activeDocument.id)
      dispatch(fetchVersionsThunk)
    }
  }, [activeDocument])

  React.useEffect(() => {
    if (saveToNewVersionStatus === Status.success) {
      const fetchVersionsThunk = fetchVersionsAndChanges(false, activeDocument.id)
      dispatch(fetchVersionsThunk)
    }
  }, [saveToNewVersionStatus])

  React.useEffect(() => {
    setFilteredVersions(
      stateDocVersions.filter((version) => {
        if (
          version.country === null ||
          version.country === undefined ||
          version.country.country_name === activeCountry.country_name
        ) {
          return true
        }

        return false
      })
    )
  }, [stateDocVersions])

  const [activeVersionIndex, setActiveVersionIndex] = React.useState(0)
  const [dialogMode, setDialogMode] = React.useState('changes')
  const [oldVersion, setOldVersion] = React.useState<DocumentVersion>(undefined)
  const [newVersion, setNewVersion] = React.useState<DocumentVersion>(undefined)
  const [oldIndex, setOldIndex] = React.useState<number>(0)
  const [newIndex, setNewIndex] = React.useState<number>(0)
  const [open, setOpen] = React.useState(false)
  const [twoSelected, setTwoSelected] = React.useState(false)
  const [compareVersions, setCompareVersions] = React.useState<DocumentVersion[]>([])
  const handleClickOpen = (mode) => {
    filteredVersions.sort((a,b) => a.latestVersion < b.latestVersion? -1 : 1)
    let versions = compareVersions
    versions.sort((a,b) => a.latestVersion < b.latestVersion? -1 : 1)
    setOldVersion(versions[0])
    setNewVersion(versions[1])
    setOldIndex(filteredVersions.indexOf(versions[0]) + 1)
    filteredVersions.indexOf(versions[1]) === -1
      ? setNewIndex(filteredVersions.length)
      : setNewIndex(filteredVersions.indexOf(versions[1]) + 1)
    setActiveVersionIndex(versions[0].latestVersion)
    setDialogMode(mode)
    setOpen(true)
  }

  const handleClose = (value) => {
    setOpen(false)
  }

  const isChecked = (version: DocumentVersion) => {
    return compareVersions.find(v => v.id === version.id) !== undefined
  }

  const isSelectedVersionsReady = (change: DocumentChange) => {
    if(change && change.chapters) {
      if(change.chapters.find(chapter => typeof chapter === 'string') === undefined) {
        return true
      }
    }

    return false
  }

  const handleChange = (version: DocumentVersion) => {
    let versionArray = compareVersions

    if(versionArray.find(v => v.id === version.id) !== undefined) {
      const index = versionArray.map(e => e.id).indexOf(version.id);
      versionArray.splice(index, 1)

      if(versionArray.length < 2){
        setTwoSelected(false)
      }
    } else {
      if(compareVersions.length < 2) {
        versionArray.push(version)

        if(versionArray.length === 2){
          setTwoSelected(true)
        }
      } else {
        setTwoSelected(true)
      }
    }
    setCompareVersions(versionArray)
    console.log('Version Array for Comparison', compareVersions)
    console.log('Version Array for Comparison filtered versions', filteredVersions)
  }

  const getHR = () => (
    <div
      style={{
        width: '100%',
        height: '1px',
        border: 'none',
        opacity: 0.1,
        backgroundColor: '#001744',
      }}
    />
  )

  if (activeDocument.id !== undefined && filteredVersions.length > 0) {
    let templateVersion = JSON.parse(JSON.stringify(stateDocVersions[0]))
    templateVersion.changes = [
      templateVersion.changes.filter((change, index) => {
        return index === 0
      }),
    ]

    return (
      <div
        style={{
          padding: '1em',
          // padding: '1em',
          //  margin: '0 auto',
          width: '100%',
          // height: '85.5vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Paper
          style={{
            padding: '1em',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            overflow: 'auto',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <CountrySelector />
            <h3 style={{ marginLeft: '1em' }}>
              Versions ({project.name} - {activeDocument.name})
            </h3>
          </div>
          <div>
            <Button
              variant={'contained'}
              style={{ marginLeft: '5px' }}
              color='primary'
              onClick={() => {
                handleClickOpen('compare')
              }}
              disabled={compareVersions.length < 2}
            >
              <CompareArrows />
              &nbsp;Compare Selected Versions
            </Button>
            <Button
              style={{ marginLeft: '5px' }}
              variant={'contained'}
              color={'primary'}
              onClick={() => {
                const saveToVersionThunk = saveToNewVersion(
                  activeDocument.id,
                  stateDocVersions[0].id,
                  activeCountry
                )
                dispatch(saveToVersionThunk)
              }}
            >
              <PublishIcon />
              &nbsp;Publish New Version
            </Button>
          </div>
        </Paper>
        {getHR()}
        <br />
        <Paper
          style={{
            padding: '1em',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <br />
          <div style={{ overflow: 'auto' }}>
            <List dense>
              <ListItem>
                <ListItemText
                  primary={
                    <>
                      <b>Compare Versions</b>
                    </>
                  }
                  secondary={
                    <>
                      Select 2 versions to be compared.
                    </>
                  }
                />
              </ListItem>
              <ListItem button>
                <Tooltip title={'Check version to be compared'}>
                  <Checkbox
                    disabled={
                      twoSelected
                      && !isChecked(stateDocVersions[0])
                      || stateDocVersions[0].changes.find(
                        (change) => typeof change !== 'string'
                      ) === undefined
                      || !isSelectedVersionsReady(stateDocVersions[0].changes[stateDocVersions[0].changes.length - 1])
                    }
                    onChange={() => {
                      handleChange(stateDocVersions[0])
                    }}
                  />
                </Tooltip>
                <ListItemText
                  primary={
                    <>
                      <b>Version {filteredVersions.length} (Working Version)</b>
                    </>
                  }
                  secondary={
                    <>
                      Author: <b>System</b>
                    </>
                  }
                />
                <ListItemSecondaryAction>
                  <Button
                    variant={'contained'}
                    style={{ marginLeft: '5px' }}
                    disabled={
                      stateDocVersions[0].changes.find(
                        (change) => typeof change !== 'string'
                      ) === undefined
                      || !isSelectedVersionsReady(stateDocVersions[0].changes[stateDocVersions[0].changes.length - 1])
                    }
                    onClick={() => {
                      onExportButtonClicked(
                        stateDocVersions[0],
                        activeDocument,
                        activeCountry,
                        filteredVersions.length - 1
                      )
                    }}
                    color='primary'
                  >
                    <ExportIcon />
                    <Loader
                      open={
                        stateDocVersions[0].changes.find(
                          (change) => typeof change !== 'string'
                        ) === undefined
                      }
                    />
                    &nbsp;Export
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>

              {filteredVersions
                .sort((a,b) => a.latestVersion > b.latestVersion? -1 : 1)
                .map((doc, index) => {
                  if (index === 0) {
                    return null
                  }

                  const labelId = `checkbox-list-secondary-label-${doc.latestVersion}`

                  let datestring = doc.lastUpdated
                  const who = doc.changes[doc.changes.length - 1].changedBy
                    ? doc.changes[doc.changes.length - 1].changedBy.surname +
                    ', ' +
                    doc.changes[doc.changes.length - 1].changedBy.firstname
                    : 'System'

                  return (
                    <ListItem key={index} button>
                      <Tooltip title={'Check version to be compared'}>
                        <Checkbox
                          disabled={
                            twoSelected
                            && !isChecked(doc)
                            || doc.changes.find((change) => typeof change !== 'string') === undefined
                            || !isSelectedVersionsReady(doc.changes[doc.changes.length - 1])
                          }
                          onChange={() => {
                            handleChange(doc)
                          }}
                        />
                      </Tooltip>
                      <ListItemText
                        id={labelId}
                        primary={
                          <>
                            <b>
                              Version {filteredVersions.length - index}
                              <br />
                            </b>
                            <div>
                              Author: <b>{who}</b>
                            </div>
                          </>
                        }
                        secondary={
                          <>
                            Created: <b>{datestring}</b>
                          </>
                        }
                      />
                      <ListItemSecondaryAction>
                        <Button
                          variant={'contained'}
                          style={{ marginLeft: '5px' }}
                          disabled={
                            doc.changes.find(
                              (change) => typeof change !== 'string'
                            ) === undefined
                            || !isSelectedVersionsReady(doc.changes[doc.changes.length - 1])
                          }
                          onClick={() => {
                            onExportButtonClicked(
                              doc,
                              activeDocument,
                              activeCountry,
                              index
                            )
                          }}
                          color='primary'
                        >
                          <ExportIcon />
                          <Loader
                            open={
                              doc.changes.find(
                                (change) => typeof change !== 'string'
                              ) === undefined
                            }
                          />
                          &nbsp;Export
                        </Button>
                      </ListItemSecondaryAction>
                    </ListItem>
                  )
                })}
            </List>
          </div>
          <ChangesDialog
            oldVersion={oldVersion}
            newVersion={newVersion}
            open={open}
            onClose={handleClose}
            mode={dialogMode}
            versionIndex={activeVersionIndex}
            oldIndex={oldIndex}
            newIndex={newIndex}
          />
        </Paper>
        {saveToNewVersionStatus === Status.loading && (
          <Loader open={saveToNewVersionStatus === Status.loading} />
        )}
      </div>
    )
  }

  return (
    <div style={{ padding: '1em', display: 'flex', flexDirection: 'column' }}>
      <Skeleton variant='rect' width={'100%'} height={118} />
      <Loader open={true} />
      <br />
      <Skeleton variant='rect' width={'100%'} height={650} />
    </div>
  )
}

export default Versions
