import { Button, Paper } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import SaveIcon from '@material-ui/icons/Save'
import { cloneDeep } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  editDocumentOnDB,
  fetchSpecificDocumentV2
} from 'redux/thunks/documentsThunk'
import { DocumentContext } from 'shared/components/DocumentEditor/DocumentEditor'
import Loader from 'shared/components/Feedback/Loader'
import { Document } from 'shared/types/document'
import { setUpdateDocumentStatus } from '../../../../../../redux/slices/documents'
import { RootState } from '../../../../../../redux/store'
import { SnackType } from '../../../../../../shared/components/Feedback/EPISnackBar'
import { Status } from '../../../../../../shared/types/status'
import IndexedDBFunctions from "../../../../../../IndexedDBFunctions";
const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: '100%',
    padding: theme.spacing(0, 0, 0, 0),
  },
  EPIGridSpacingLeft: {
    paddingLeft: '0px !important',
  },
  EPIGridSpacingLeftMobile: {
    paddingLeft: '0px !important',
  },
}))

const DocumentInformation = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const context = useContext(DocumentContext)
  const {
    activeDocument,
    documents,
    setActiveDocument,
    setCopiedDocuments,
    activeDocumentIndex,
    setSnackMessage,
    setSnackSeverity,
    setDisplaySnack,
    setContentIsDirty,
    setNumberOfEdits
  } = context
  const [localName, setLocalName] = useState(activeDocument.name)
  const [localAuthor, setLocalAuthor] = useState(activeDocument.author)
  const [busy, setBusy] = useState(false)
  console.log(localName, localAuthor)

  const publishDocsStatus = useSelector(
    (state: RootState) => state.documents.updateDocumentStatus
  )

  useEffect(() => {
    if (publishDocsStatus === Status.success) {
      setSnackMessage('Document Information updated successfully')
      setSnackSeverity(SnackType.SnackSuccess as string)
      setDisplaySnack(true)
      const fetchSpecificDocThunk = fetchSpecificDocumentV2(activeDocument.id)
      dispatch(fetchSpecificDocThunk)
      setBusy(false)
      dispatch({ type: setUpdateDocumentStatus.type, payload: Status.idle })
    } else if (publishDocsStatus === Status.failed) {
      // setIsUpdateSuccess(false);
      // setIsUpdateError(true);
      // dispatch({ type: setUpdateProjectStatus.type, payload: Status.idle })
      dispatch({ type: setUpdateDocumentStatus.type, payload: Status.idle })
      setBusy(false)
    }
  }, [publishDocsStatus])

  const updateInfo = () => {
    let tempActiveDocument: Document = cloneDeep(activeDocument)
    tempActiveDocument.lastUpdated = new Date().toString()
    tempActiveDocument.name = localName
    tempActiveDocument.author = localAuthor

    Object.assign(activeDocument, tempActiveDocument)
    setActiveDocument(tempActiveDocument)
    //localStorage.setItem('currentDocumentEdit', JSON.stringify(documents))

    IndexedDBFunctions.deleteDocuments()
    IndexedDBFunctions.addDocuments(documents)
    //This is just a work around as we encountered issues with Local Storage
    setNumberOfEdits(Math.random() * 1000)
    const editDocumentThunk = editDocumentOnDB(activeDocument)
    dispatch(editDocumentThunk)
    localStorage.setItem('ContentIsDirty', 'false')
    setContentIsDirty(false)
    documents.splice(activeDocumentIndex, 1, activeDocument)
    setCopiedDocuments(JSON.parse(JSON.stringify(documents)))
    setBusy(true)
  }

  useEffect(() => {
    setLocalName(activeDocument.name)
    setLocalAuthor(activeDocument.author)
  }, [activeDocument])

  return (
    <>
      {busy === true && <Loader open={true} />}
      <div
        style={{
          padding: '1em',
        }}
      >
        <Paper style={{ padding: '1em' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <h3>Document Information</h3>
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                updateInfo()
              }}
              disabled={busy}
            >
              <SaveIcon />
              &nbsp;Save
            </Button>
          </div>
        </Paper>
        <br />

        <Paper
          style={{
            height: '85%',
            padding: '2em',
          }}
        >
          <form className={classes.form} noValidate>
            <TextField
              autoComplete='DocumentName'
              id='DocumentName'
              label='Document Name'
              name='DocumentName'
              variant='outlined'
              fullWidth
              focused={false}
              value={localName}
              onChange={
                (event: React.ChangeEvent<HTMLInputElement>) =>
                  setLocalName(event.target.value)
                // localName = "newvalue"
              }
            >
              {localName}
            </TextField>
            <br />
            <br />
            <TextField
              focused={false}
              id='author'
              label='Author'
              name='author'
              variant='outlined'
              value={localAuthor}
              fullWidth
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setLocalAuthor(event.target.value)
              }
            >
              {localAuthor}
            </TextField>
          </form>
        </Paper>
      </div>
    </>
  )
}

export default DocumentInformation
