import { createStyles, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Delete from '@material-ui/icons/Delete';
import React from 'react';
import { ChapterSection, Document } from 'shared/types/document';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      listStyle: 'none',
      '& > *': {
        margin: theme.spacing(0.5),
      },
      padding: theme.spacing(1, 1),
    },
    list: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      flexWrap: 'wrap',
      listStyle: 'none',
      '& > *': {
        margin: theme.spacing(0.5),
      },
      padding: theme.spacing(1, 1),
    },
    chip: {
      margin: theme.spacing(0.5),
      padding: theme.spacing(1, 2, 1, 2),
    },
  })
);

export type sectionsProp = {
  Document: Document | undefined;
  sections: ChapterSection[];
  setSections: (sections: ChapterSection[]) => void;
};

const DocumentSectionsSelector = ({
  Document,
  sections,
  setSections,
}: sectionsProp) => {
  const classes = useStyles();
  const [editability, setEditability] = React.useState([])
  const handleChange = (event, index) => {

    const newEditability = editability;
    newEditability[index] = event.target.value
    setEditability(newEditability);
    let newSections: Array<ChapterSection> = sections.map(
      (x) => Object.assign({}, x)
    );
    const sectionNew = newSections[index];

    if (editability[index] === "0") {
      sectionNew.isGlobal = true;
      sectionNew.isClientEditable = false;
    }
    else if (editability[index] === "1") {
      sectionNew.isClientEditable = true;
      sectionNew.isGlobal = false;

    }
    else if (editability[index] === "3") {
      sectionNew.isClientEditable = false;
      sectionNew.isGlobal = false;
    }
    setSections(newSections);
  };
  // const [sections, setsections] = React.useState<Sections[]>(DocumentSectionsList)

  // const addSectionsToDocument = (
  //   event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  // ) => {
  //   //   aaaa
  // };

  // const handleDelete = (sectionsToDelete: string) => () => {
  //   let newSections = sections.filter(
  //     (sections) => sections.name !== sectionsToDelete
  //   );
  //   setSections(newSections);
  // };

  return (
    <div>
      <div className={classes.list}>
        <List
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ width: '40%' }}>Section Name</div>
                <div style={{ width: '20%' }}>Global</div>
                <div style={{ width: '20%' }}>Country</div>
                <div style={{ width: '20%' }}>Client-Editable</div>
                <div style={{ width: '20%', textAlign: 'center' }}>Delete</div>
              </div>
            </ListSubheader>
          }
          dense={true}
        >
          {sections.map((section, index) => {
            let sectionEditability = editability[index];

            if (sectionEditability === null || sectionEditability === undefined) {
              const newEditability = editability;
              
              if (section.isGlobal === true) {
                newEditability.push("0")
              }
              else if (section.isClientEditable === true) {
                newEditability.push("1")
              }
              else {
                newEditability.push("3")

              }
              setEditability(newEditability)
            }
            sectionEditability = editability[index];
            
            return (
              <ListItem>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <div style={{ width: '50%' }}>
                    <TextField
                      fullWidth
                      value={`${section.name}`}
                      id="standard-basic"
                      label=""
                      onChange={(newValue: any) => {
                        let newSections: Array<ChapterSection> = sections.map(
                          (x) => Object.assign({}, x)
                        );
                        const sectionNew = newSections[index];
                        sectionNew.name = newValue.target.value;
                        setSections(newSections);
                      }}
                    />
                  </div>
                  <RadioGroup style={{ paddingLeft: '5em', paddingRight: '9em', width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }} aria-label="gender" name="gender1" value={sectionEditability} onChange={(e) => {
                    handleChange(e, index)
                  }}>

                    <div style={{ width: '12%', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                      <FormControlLabel value="0" control={<Radio />} label="" />
                    </div>
                    <div style={{ width: '20%', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                      <FormControlLabel value="3" control={<Radio />} label="" />
                    </div>
                    <div style={{ width: '20%', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                      <FormControlLabel value="1" control={<Radio />} label="" />
                    </div>
                  </RadioGroup>
                  <div style={{ width: '20%' }}>
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        let newSections: Array<ChapterSection> = sections.map(
                          (x) => Object.assign({}, x)
                        );
                        newSections.splice(index, 1);
                        setSections(newSections);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </div>
                </div>
              </ListItem>
            );
          })}
        </List>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant={'outlined'}
          style={{
            width: '100%',
          }}
          onClick={() => {
            let newSections: Array<ChapterSection> = sections.map((x) =>
              Object.assign({}, x)
            );

            const newSection: ChapterSection = {
              name: 'New Section',
              isGlobal: false,
              isClientEditable: false,
              chapterGlobalContent: {
                rawHTML: '',
              },
              countrySpecificContent: [],
              viewing: false,
              wasChanged: true,
            };
            newSections.push(newSection);
            setSections(newSections);
          }}
        >
          + Add Section
        </Button>
      </div>
    </div>
  );
};

export default DocumentSectionsSelector;
