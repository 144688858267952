import { Grid, TextField } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

export type formProp = {
  firstName: string | undefined;
  setFirstName: (name: string) => void;
  lastName: string | undefined;
  setLastName: (surname: string) => void;
  emailInput: string | undefined;
  setEmailInput: (email: string) => void;
  firstNameError: boolean;
  firstNameHelperText: string;
  surnameError: boolean;
  surnameHelperText: string;
  emailError: boolean;
  emailHelperText: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: '100%',
    padding: theme.spacing(0, 0, 0, 0),
  },
  EPIGridSpacingLeft: {
    paddingLeft: '0px !important',
  },
  EPIGridSpacingLeftMobile: {
    paddingLeft: '0px !important',
  },
}));

const EditUserDialogInfo = ({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  emailInput,
  setEmailInput,
  firstNameError,
  firstNameHelperText,
  surnameError,
  surnameHelperText,
  emailError,
  emailHelperText,
}: formProp) => {
  const classes = useStyles();

  return (
    <form className={classes.form} noValidate>
      <Grid spacing={3} container>
        <Grid className={classes.EPIGridSpacingLeft} sm={6} xs={12} item>
          <TextField
            autoComplete="fname"
            id="firstName"
            label="First Name"
            name="firstName"
            variant="outlined"
            fullWidth
            required
            value={firstName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setFirstName(event.target.value)
            }
            error={firstNameError}
            helperText={firstNameHelperText}
          ></TextField>
        </Grid>
        <Grid className={classes.EPIGridSpacingLeft} sm={6} xs={12} item>
          <TextField
            autoComplete="lname"
            id="lastName"
            label="Last Name"
            name="lastName"
            variant="outlined"
            value={lastName}
            fullWidth
            required
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setLastName(event.target.value)
            }
            error={surnameError}
            helperText={surnameHelperText}
          />
        </Grid>
        <Grid className={classes.EPIGridSpacingLeft} xs={12} item>
          <TextField
            autoComplete="email"
            id="email"
            label="Email Address"
            name="email"
            variant="outlined"
            fullWidth
            required
            value={emailInput}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setEmailInput(event.target.value)
            }
            error={emailError}
            helperText={emailHelperText}
          ></TextField>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditUserDialogInfo;
