import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { setDeleteProjectStatus } from 'redux/slices/projects';
import { removeProjectOnDB } from 'redux/thunks/projectsThunk';
import { Project } from 'shared/types/project';
import { Status } from 'shared/types/status';

export type DeleteProjectConfirmProps = {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
  project: Project;
};

const DeleteProjectConfirmDialog = ({
  isOpen,
  setOpen,
  project,
}: DeleteProjectConfirmProps) => {
  const dispatch = useDispatch();
  const handleCancel = () => {
    setOpen(false);
  };

  const handleOk = () => {
    if (project) {
      const deleteProjectThunk = removeProjectOnDB(project);
      dispatch(deleteProjectThunk);
    }
    dispatch({ type: setDeleteProjectStatus, payload: Status.loading });
    handleCancel();
  };

  return (
    <div>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={isOpen}
      >
        <DialogTitle id="confirmation-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent dividers>
          <Typography
            style={{ textAlign: 'center', justifyContent: 'stretch' }}
            variant="body1"
            color="textPrimary"
          >
            Are you sure you want to delete this project? <br />
            (This action is permanent)
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleOk} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteProjectConfirmDialog;
