import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles, Theme } from '@material-ui/core/styles';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import RefreshIcon from '@material-ui/icons/Refresh';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchProjectsByUser } from 'redux/thunks/projectsThunk';
import InviteMainDialog from '../Dialogs/InviteMainDialog';
import TableSelectorGroup from './TableSelectorGroup';

// const InviteUserDialog = React.lazy(
//   () => import('../Dialogs/InviteUserDialog')
// );

const useStyles = makeStyles((theme: Theme) => ({
  titleDiv: {
    display: 'grid',
    gridTemplateColumns: '2fr  1fr',
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  left: {
    gridRows: '2/3',
  },
  right: {
    gridRows: '2/4',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
  },
  formSpan: {
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(4),
    flex: '0 1 15px',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      flex: '1 1 25ch',
    },
  },
  icon1: { marginRight: '8px !important' },
  button: {},
}));
const TableHeader: React.FC<{
  updateRows: () => void;
  version: string;
  setVersion: (version: string) => void;
  role: string;
  setRole: (role: string) => void;
  search: string;
  setSearch: (search: string) => void;
  setIsSuccess: (s: boolean) => void;
}> = ({
  version,
  setVersion,
  role,
  setRole,
  search,
  setSearch,
  updateRows,
  setIsSuccess,
}) => {
  // const history = useHistory();

  // const projectId = history.location.pathname.replace('/project/', '');

  // const projects = useSelector((state: RootState) => state.projects.projects);
  // const currentProject = useSelector((state: RootState) =>
  //   getProjectById(state, projectId)
  // );
  const dispatch = useDispatch();

  // React.useEffect(() => {
  //   if (currentProject === undefined) {
  //     let fetchProjThunk = fetchProjects();
  //     dispatch(fetchProjThunk);
  //   }
  // }, []);

  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <div>
      <div
        style={{
          flexGrow: 1,
        }}
      >
        <Grid
          container
          spacing={3}
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item xs>
            <h3
              style={{
                margin: 0,
              }}
            >
              User Management
            </h3>
            Invite users to the project, configure users roles
          </Grid>
          <Grid item xs>
            <TableSelectorGroup
              version={version}
              setVersion={setVersion}
              role={role}
              setRole={setRole}
              search={search}
              setSearch={setSearch}
            />
          </Grid>
          <Grid container xs justify="flex-end" alignItems="center">
            <Button
              className={classes.button}
              style={{
                backgroundColor: '#2c8cd8',
                color: 'white',
              }}
              startIcon={<RefreshIcon className={classes.icon1} />}
              variant="contained"
              onClick={() => {
                const fetchProjectsThunk = fetchProjectsByUser(
                  sessionStorage.getItem('id')
                );
                dispatch(fetchProjectsThunk);
              }}
            >
              Refresh
            </Button>
            <Button
              className={classes.button}
              color="primary"
              startIcon={<PersonAddIcon className={classes.icon1} />}
              variant="contained"
              style={{
                marginLeft: '0.25em',
              }}
              onClick={() => setOpen(true)}
            >
              Invite
            </Button>
            <InviteMainDialog
              open={open}
              setOpen={setOpen}
              updateRows={updateRows}
              setIsSuccess={setIsSuccess}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default TableHeader;
