import { IconButton, Menu, MenuItem } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DownIcon from '@material-ui/icons/ArrowDownward'
import UpIcon from '@material-ui/icons/ArrowUpward'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import SyncIcon from '@material-ui/icons/Refresh'
import SettingsIcon from '@material-ui/icons/Settings'
import React, { useContext } from 'react'
import { AddSubchapterDialog } from 'shared/components/DocumentEditor/ChapterNavigation/ChapterSettings/Dialogs/AddSubchapterDialog'
import { RemoveChapterDialog } from 'shared/components/DocumentEditor/ChapterNavigation/ChapterSettings/Dialogs/RemoveChapterDialog'
import { RenameChapterDialog } from 'shared/components/DocumentEditor/ChapterNavigation/ChapterSettings/Dialogs/RenameChapterDialog'
import { Document, DocumentChange} from 'shared/types/document'
import { DocumentContext } from '../../DocumentEditor'
import { findChaptersBySharedId } from '../../treeUtils'
import { useDispatch } from 'react-redux'
import { fetchSpecificDocumentV2 } from '../../../../../redux/thunks/documentsThunk'
import * as reduxDocumentType from 'shared/types/document'

type ChapterSettingsType = {
  setOpenSyncDialog: (open: boolean) => void
  setFinalStep: (open: boolean) => void
  setReloadDocuments?: (reloadDocuments: boolean) => void
}

export const ChapterSettings: React.FC<ChapterSettingsType> = ({
  setOpenSyncDialog,
  setFinalStep,
  setReloadDocuments
}) => {
  const context = useContext(DocumentContext)
  const {
    activeDocument,
    documentChapters,
    activeCountry,
    activeChapter,
    activeDocumentChange,
    activeDocumentVersion,
    activeDocumentVersions,
    activeCountryIndex,
    setDocumentChapters,
    mode,
    documents,
    setCopiedDocuments
  } = context
  //const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const dispatch = useDispatch()

  const handleClose = () => {
    setAnchorEl(null)
  }

  const [chapterTypeSelectOpen, setChapterTypeSelectOpen] = React.useState(
    false
  )
  const [chapterRenameOpen, setChapterRenameOpen] = React.useState(false)
  const [chapterRemoveOpen, setChapterRemoveOpen] = React.useState(false)

  const getChapterName = () => {
    if (activeChapter !== undefined && activeChapter.name !== undefined) {
      let finalChapterName = activeChapter.name

      if (activeCountryIndex !== null) {
        let section = activeChapter.sections.find((section, index) => {
          return section.name
        })

        if (section.countrySpecificContent[activeCountryIndex] !== undefined) {
          finalChapterName =
            section.countrySpecificContent[activeCountryIndex]
              .chapterCountrySpecificName
        }
      }

      if (finalChapterName === undefined) {
        finalChapterName = activeChapter.name
      }

      return finalChapterName
    }
  }

  const [chapterName, setChapterName] = React.useState('')

  React.useEffect(() => {
    setChapterName(getChapterName())
  }, [activeChapter, activeCountryIndex])

  React.useEffect(() => {
    setChapterName(getChapterName())
  }, [activeChapter, activeCountryIndex])

  const moveChapterUpDown = (movement: 'up' | 'down') => {
    let chapters = findChaptersBySharedId(
      documentChapters,
      activeChapter.sharedId
    )
    let index = chapters.findIndex(obj => obj.id === activeChapter.id)
    let temp = chapters[index]
    let newDocumentChange: DocumentChange = null
    let swapChapter =
      movement === 'up' ? chapters[index - 1] : chapters[index + 1]

    if (index !== -1) {
      if (swapChapter !== undefined) {
        if (movement === 'up') {
          chapters[index] = chapters[index - 1]
          chapters[index - 1] = temp
          let tempOrder = chapters[index].chapterOrder
          chapters[index].chapterOrder = chapters[index - 1].chapterOrder
          chapters[index - 1].chapterOrder = tempOrder
          newDocumentChange = {
            ...activeDocumentChange,
            chapters: documentChapters,
            version: activeDocumentVersion.latestVersion + 1,
            lastUpdated: new Date().toString(),
            changedBy: {
              firstname: sessionStorage.getItem('firstname'),
              id: sessionStorage.getItem('id'),
              surname: sessionStorage.getItem('surname')
            }
          }
        } else {
          chapters[index] = chapters[index + 1]
          chapters[index + 1] = temp
          let tempOrder = chapters[index].chapterOrder
          chapters[index].chapterOrder = chapters[index + 1].chapterOrder
          chapters[index + 1].chapterOrder = tempOrder
          newDocumentChange = {
            ...activeDocumentChange,
            chapters: documentChapters,
            version: activeDocumentVersion.latestVersion + 1,
            lastUpdated: new Date().toString(),
            changedBy: {
              firstname: sessionStorage.getItem('firstname'),
              id: sessionStorage.getItem('id'),
              surname: sessionStorage.getItem('surname')
            }
          }
        }

        setDocumentChapters(documentChapters)

        let activeDocDocumentChanges = activeDocumentVersion.changes
        activeDocumentVersion.lastUpdated = new Date().toString()

        if (
          activeDocDocumentChanges[activeDocDocumentChanges.length - 1].id ===
          null
        ) {
          activeDocDocumentChanges[
            activeDocDocumentChanges.length - 1
          ] = newDocumentChange
        } else {
          activeDocDocumentChanges.push(newDocumentChange)
        }

        let newDocument: Document = {
          ...activeDocument,
          documentVersions: activeDocumentVersions,
          lastUpdated: new Date().toString()
        }

        const docIndex = documents.findIndex(doc => doc.id === newDocument.id)
        documents.splice(docIndex, 1, newDocument)

        const newDocs = []
        Object.assign(newDocs, documents)
        setCopiedDocuments(newDocs)
      }

      localStorage.setItem('ContentIsDirty', 'true')
    }
  }

  const showAddSubchapter = activeChapter.subchapterCreateAllowed
  const showAddSubchapterTemplate =
    mode === 'template' && activeCountry.country_name === undefined

  const isGlobalChapter =
    activeChapter.sections.find((section: reduxDocumentType.ChapterSection) => {
      const isGlobal = section.countrySpecificContent.find(
        (countryContent: reduxDocumentType.CountrySpecificContent) => {
          return countryContent.isChapterActiveInCountry === false
        }
      )

      return isGlobal === undefined
    }) !== undefined

  const showAddSubchapterProject =
    !isGlobalChapter &&
    (activeChapter.refId === null || activeChapter.refId === undefined)

  const canAddSubchapter =
    showAddSubchapter || showAddSubchapterTemplate || showAddSubchapterProject

  return (
    <div>
      <IconButton
        style={{
          paddingLeft: 50
        }}
        size='small'
        id='basic-button'
        aria-controls='basic-menu'
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <SettingsIcon
          style={{
            color: 'white'
          }}
        />
      </IconButton>

      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {' '}
        {mode !== 'project' && (
          <MenuItem
            onClick={() => {
              moveChapterUpDown('up')
              handleClose()
            }}
            disabled={isGlobalChapter && activeCountry.country_name !== undefined && mode !== 'project'}
          >
            <UpIcon />
            &nbsp;Move Up
          </MenuItem>
        )}
        {(mode === 'template' || mode === 'project') && (
          <MenuItem
            disabled={!canAddSubchapter}
            onClick={() => {
              setChapterTypeSelectOpen(true)
              handleClose()
            }}
          >
            <AddIcon />
            Add Subchapters
          </MenuItem>
        )}
        {(mode === 'template' ||
          (mode === 'project' && activeChapter.refId === undefined)) && (
          <MenuItem
            onClick={() => {
              setChapterRenameOpen(true)
              handleClose()
            }}
            disabled={isGlobalChapter && activeCountry.country_name !== undefined && mode !== 'project'}
          >
            <EditIcon />
            &nbsp;Chapter Settings
          </MenuItem>
        )}
        {(mode === 'template' ||
          (mode === 'project' && activeChapter.refId === undefined)) && (
          <MenuItem
            onClick={() => {
              setChapterRemoveOpen(true)
              handleClose()
            }}
            disabled={isGlobalChapter && activeCountry.country_name !== undefined && mode !== 'project'}
          >
            <DeleteIcon />
            Remove Chapter
          </MenuItem>
        )}
        {mode === 'project' && activeChapter.refId !== undefined && (
          <MenuItem
            onClick={() => {
              const fetchTemplateDocs = fetchSpecificDocumentV2(
                activeDocument.refId
              )
              dispatch(fetchTemplateDocs)
              setReloadDocuments(true)
              setOpenSyncDialog(true)
              setFinalStep(false)
              handleClose()
            }}
          >
            <SyncIcon />
            &nbsp;Sync Content
          </MenuItem>
        )}
        {mode !== 'project' && (
          <MenuItem
            onClick={() => {
              moveChapterUpDown('down')
              handleClose()
            }}
            disabled={isGlobalChapter && activeCountry.country_name !== undefined}
          >
            <DownIcon />
            &nbsp;Move Down
          </MenuItem>
        )}
      </Menu>
      <AddSubchapterDialog
        isOpen={chapterTypeSelectOpen}
        setOpen={setChapterTypeSelectOpen}
      />
      <RenameChapterDialog
        chapterName={chapterName}
        isOpen={chapterRenameOpen}
        setOpen={setChapterRenameOpen}
      />
      <RemoveChapterDialog
        isOpen={chapterRemoveOpen}
        setOpen={setChapterRemoveOpen}
      />
    </div>
  )
}
