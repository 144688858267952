import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'grid',
    gridTemplateRows: 'auto auto auto auto',
    width: '100%',
    height: 'auto',
  },
  titleContainer: {
    width: '100%',
    /* overflow: 'auto', */
  },
  bodyContainer: {
    width: '100%',
    paddingTop: theme.spacing(3),
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '0px !important',
      height: '0px !important',
      padding: '0px !important',
      visibility: 'collapse',
    },
  },
  bodyContainerMobile: {
    width: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  footerContainer: {
    width: '100%',
    paddingTop: theme.spacing(3),
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '0px !important',
      height: '0px !important',
      padding: '0px !important',
      visibility: 'collapse',
    },
  },
  paper: {
    padding: theme.spacing(3),
    height: 'auto',
  },
  paperBody: {
    padding: theme.spacing(3),
    height: '100%',
  },
  tableContainer: {
    /* maxHeight: '350px', */
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      minWidth: 700,
      overflowX: 'auto',
    },
    [theme.breakpoints.up('xl')]: {
      maxHeight: 'auto',
    },
  },
  tableContent: {
    [theme.breakpoints.down('sm')]: {
      visibility: 'collapse',
    },
    [theme.breakpoints.up('md')]: {
      visibility: 'visible',
    },
  },
  listContent: {
    [theme.breakpoints.between('sm', 'xl')]: {
      visibility: 'collapse',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      visibility: 'visible',
    },
  },
}));
