import { TableContainer } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import { makeStyles, Theme } from '@material-ui/core/styles'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import { fetchProjects } from 'redux/thunks/projectsThunk'
import Loader from 'shared/components/Feedback/Loader'
import { Project } from 'shared/types/project'
import { Status } from 'shared/types/status'
import TablePaginationEPI from 'views/Pages/TemplateEnvironment/Home/TemplateHome/TablePaginationEPI'
import ProjectsTableBody from './ProjectsTableFilter/ProjectsTableBody'
import ProjectHeader from './ProjectsTableFilter/ProjectsTableHead'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  titleContainer: {
    width: '100%',
    /* overflow: 'auto', */
  },
  bodyContainer: {
    width: '100%',
    paddingTop: theme.spacing(3),
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '0px !important',
      height: '0px !important',
      padding: '0px !important',
      visibility: 'collapse',
    },
  },
  bodyContainerMobile: {
    width: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  footerContainer: {
    width: '100%',
    paddingTop: theme.spacing(3),
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '0px !important',
      height: '0px !important',
      padding: '0px !important',
      visibility: 'collapse',
    },
  },
  paper: {
    padding: theme.spacing(3),
    height: 'auto',
  },
  paperBody: {
    padding: theme.spacing(3),
    height: '100%',
  },
  tableContainer: {
    /* maxHeight: '350px', */
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      minWidth: 700,
      overflowX: 'auto',
    },
    [theme.breakpoints.up('xl')]: {
      maxHeight: 'auto',
    },
  },
  tableContent: {
    [theme.breakpoints.down('sm')]: {
      visibility: 'collapse',
    },
    [theme.breakpoints.up('md')]: {
      visibility: 'visible',
    },
  },
  listContent: {
    [theme.breakpoints.between('sm', 'xl')]: {
      visibility: 'collapse',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      visibility: 'visible',
    },
  },
}))

type DesktopViewProp = {
  data: Array<Project> | undefined
  page: number
  rowsPerPage: number
  setPage: (num: number) => void
  setRowsPerPage: (num: number) => void
  totalRowsPerPage: number
}

const DesktopView: React.FC<DesktopViewProp> = (props) => {
  const classes = useStyles()

  return (
    <>
      <div /*  className={classes.bodyContainer} */>
        <Paper className={classes.paperBody}>
          <div className={classes.tableContent}>
            <TableContainer className={classes.tableContainer}>
              {
                <ProjectsTableBody
                  data={props.data}
                  page={props.page}
                  rowsPerPage={props.rowsPerPage}
                />
              }
            </TableContainer>
          </div>
        </Paper>
      </div>
      <div className={classes.footerContainer}>
        <Paper className={classes.paper}>
          <TablePaginationEPI
            totalRows={props.data ? props.data.length : 0}
            page={props.page}
            setPage={props.setPage}
            rowsPerPage={props.rowsPerPage}
            setRowsPerPage={props.setRowsPerPage}
            totalRowsPerPage={props.totalRowsPerPage}
          />
        </Paper>
      </div>
    </>
  )
}

// type MobileViewProp = {
//   data: Array<User>;
// };

// const MobileView: React.FC<MobileViewProp> = ({ data }) => {
//   const classes = useStyles();

//   return (
//     <div className={classes.bodyContainerMobile}>
//       <Paper className={classes.paperBody}>
//         <div className={classes.listContent}>
//           {/* <EPIListView data={data} /> */}
//         </div>
//       </Paper>
//     </div>
//   );
// };

const ProjectManagement = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [project, setProject] = React.useState('')
  const [role, setRole] = React.useState('')
  const [search, setSearch] = React.useState('')
  const [page, setPage] = React.useState(0)
  const totalRowsPerPage = 10
  // const matches = useMediaQuery('(min-width:960px)');
  const rows = useSelector((state: RootState) => state.projects.allProjects)
  const getProjectsStatus = useSelector(
    (state: RootState) => state.projects.getProjectStatus
  )
  const [rowsPerPage, setRowsPerPage] = React.useState(totalRowsPerPage)
  const [rowsData, setRowsData] = React.useState<Project[]>(rows)
  // const [screenWidth, setScreenWidth] = React.useState<boolean>(matches);
  const [isNewProject, setIsNewProject] = React.useState<boolean>(false)

  const searchTable = () => {
    if (search !== '') {
      if (rows && rows.length > 0) {
        const filteredRows = rows.filter((row, key) => {
          if (
            row.name.toLowerCase().includes(search.toLowerCase()) ||
            row.author.toLowerCase().includes(search.toLowerCase())
          )
            return row
        })
        setRowsData(filteredRows)
        setPage(0)
      }
    } else {
      setRowsData(rows)
      setPage(0)
    }
  }

  React.useEffect(() => {
    if (rows.length === 0) {
      const fetchProjectsThunk = fetchProjects()
      dispatch(fetchProjectsThunk)
    }
  }, [])

  React.useEffect(() => {
    if (isNewProject) {
      // console.log(isNewProject)
      // const fetchProjectsThunk = fetchProjects();
      // dispatch(fetchProjectsThunk);
      // setRowsData(rows)
      // setIsNewProject(false)
    }
  }, [isNewProject])

  React.useEffect(() => {
    setRowsData(rows)
  }, [rows])

  React.useEffect(() => {
    //
    searchTable()
  }, [search])

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <Paper className={classes.paper}>
          <ProjectHeader
            version={project}
            setVersion={setProject}
            role={role}
            setRole={setRole}
            search={search}
            setSearch={setSearch}
            setIsNewProject={setIsNewProject}
          />
        </Paper>
      </div>
      <div className={classes.bodyContainer}>
        <DesktopView
          data={rowsData}
          page={page}
          rowsPerPage={rowsPerPage}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          totalRowsPerPage={totalRowsPerPage}
        />
      </div>
      <Loader open={getProjectsStatus === Status.loading} />
    </div>
  )
}

export default ProjectManagement
