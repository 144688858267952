import { Skeleton } from '@material-ui/lab'
import React from 'react'
import { DocumentContext } from 'shared/components/DocumentEditor/DocumentEditor'
import * as reduxDocumentType from 'shared/types/document'
import './ChapterNavigationStyle.css'
import {Country} from "../../../types/country";

type RecursiveComponentType = {
  chapter: reduxDocumentType.ChapterObject
  index: string
  activeChapter: reduxDocumentType.ChapterObject
  setActiveChapter: React.Dispatch<
    React.SetStateAction<reduxDocumentType.ChapterObject>
  >
  chapterMode: string
  otherDocumentChapters?: reduxDocumentType.ChapterObject[]
}

const isActiveInCountry = (activeCountry: Country, chapter:  reduxDocumentType.ChapterObject) => {
  const section = chapter.sections[0]
  const country = section.countrySpecificContent.find(content => content.country.country_name === activeCountry.country_name)

  if(country === undefined){
    return true
  } else if(country.isChapterActiveInCountry) {
    return true
  }

  return false
}

const differenceInChapters = (
  chapter: reduxDocumentType.ChapterObject,
  otherChapter: reduxDocumentType.ChapterObject,
  activeCountry: Country
): boolean => {
  let isDifferent = false

  if(otherChapter !== undefined){
    chapter.sections.map(section => {
      const matchingSection = otherChapter.sections.find(otherSection =>
        otherSection.name.localeCompare(section.name) === 0
      )

      if(matchingSection){
        const sectionCountry = section.countrySpecificContent.find((country) =>
          country.country.country_name === activeCountry.country_name
        )
        const otherSectionCountry = matchingSection.countrySpecificContent.find((country) =>
          country.country.country_name === activeCountry.country_name
        )

        if(section.isGlobal) {
          if(sectionCountry.globalContent && otherSectionCountry.globalContent) {
            if(sectionCountry.globalContent.rawHTML.localeCompare(otherSectionCountry.globalContent.rawHTML) !== 0) {
              isDifferent = true
            }
          } else if(sectionCountry.globalContent && !otherSectionCountry.globalContent) {
            if(sectionCountry.globalContent.rawHTML.localeCompare(matchingSection.chapterGlobalContent.rawHTML) !== 0) {
              isDifferent = true
            }
          } else if(!sectionCountry.globalContent && otherSectionCountry.globalContent) {
            if(section.chapterGlobalContent.rawHTML.localeCompare(otherSectionCountry.globalContent.rawHTML) !== 0) {
              isDifferent = true
            }
          } else {
            if(section.chapterGlobalContent.rawHTML.localeCompare(matchingSection.chapterGlobalContent.rawHTML) !== 0){
              isDifferent = true
            }
          }
        } else if (section.isClientEditable && !section.isGlobal){
          if(sectionCountry.clientContent.rawHTML.localeCompare(otherSectionCountry.clientContent.rawHTML) !== 0) {
            isDifferent = true
          }
        } else if(!section.isClientEditable && !section.isGlobal){
          if(sectionCountry.nonClientContent.rawHTML.localeCompare(otherSectionCountry.nonClientContent.rawHTML) !== 0) {
            isDifferent = true
          }
        }
      }
    })
  }

  return isDifferent
}

export const RecursiveComponent: React.FC<RecursiveComponentType> = ({
  chapter,
  index,
  activeChapter,
  setActiveChapter,
  chapterMode,
  otherDocumentChapters
}) => {
  // const classes = useStyles();

  const newIndex = index + '.'
  const context = React.useContext(DocumentContext)
  const { activeCountry } = context

  const hasChildren = chapter && chapter.subchapters

  let countrySpecificName: any = ''
  chapter.sections.map((section: reduxDocumentType.ChapterSection) => {
    if (
      section.countrySpecificContent &&
      section.countrySpecificContent.length > 0
    ) {
      let currentCountry: reduxDocumentType.CountrySpecificContent =
        section.countrySpecificContent.find(content =>
          content.country.country_name === activeCountry.country_name)

      if (currentCountry) {
        countrySpecificName = currentCountry.chapterCountrySpecificName
      }
    }
  })

  let subChapters: reduxDocumentType.ChapterObject[] = []

  if (hasChildren) {
    subChapters = chapter.subchapters.sort(
      (a, b) => a.chapterOrder - b.chapterOrder
    )
  }

  let isDifferent = false
  let matchingChapter: reduxDocumentType.ChapterObject = undefined

  if(otherDocumentChapters !== undefined && chapterMode === 'current') {
    matchingChapter = otherDocumentChapters.find(otherChapter => otherChapter.sharedId === chapter.sharedId)

    if(matchingChapter !== undefined) {
      const difference = differenceInChapters(chapter, matchingChapter, activeCountry)

      if(difference) {
        isDifferent = true
      } else {
        isDifferent = false
      }
    } else {
      isDifferent = true
    }
  }

  return (
    <li>
      <button
        style={
          activeChapter !== undefined && chapter !== undefined
            ? chapter.id === activeChapter.id
              ? { backgroundColor: '#becddc', color: 'white', borderRadius: 25 }
              : isDifferent && chapterMode !== 'previous'
                ? { backgroundColor: '#62c762', color: 'white', borderRadius: 25 }
                : { backgroundColor: 'transparent' }
            : { backgroundColor: 'transparent' }
        }
        className='chapter-list-button'
        onClick={() => setActiveChapter(chapter)}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            position: 'relative',
          }}
        >
          {chapter.treeIndexString ? chapter.treeIndexString : 'na'}{' '}
          {countrySpecificName !== undefined && countrySpecificName !== ''
            ? countrySpecificName
            : chapter.name}
        </div>
      </button>
      {hasChildren && (
        <ul className='chapter-sub-list'>
          {subChapters.map(
            (subchapter: reduxDocumentType.ChapterObject, index: number) => {
              const subChapterIndex = newIndex + (index + 1).toString()

              return (
                <RecursiveComponent
                  chapter={subchapter}
                  otherDocumentChapters={matchingChapter ? matchingChapter.subchapters : undefined}
                  index={subChapterIndex}
                  activeChapter={activeChapter}
                  setActiveChapter={setActiveChapter}
                  chapterMode={chapterMode}
                />
              )
            }
          )}
        </ul>
      )}
    </li>
  )
}

export const ComparisonChapterNavigation: React.FC<{
  documentChapters: reduxDocumentType.ChapterObject[]
  otherDocumentChapters?: reduxDocumentType.ChapterObject[]
  activeChapter: reduxDocumentType.ChapterObject
  setActiveChapter: React.Dispatch<
    React.SetStateAction<reduxDocumentType.ChapterObject>
  >
  chapterMode: string
}> = ({ documentChapters, otherDocumentChapters, activeChapter, setActiveChapter, chapterMode }) => {
  const context = React.useContext(DocumentContext)
  const { activeCountry } = context

  if (documentChapters !== undefined) {
    return (
      <ul className='chapter-list'>
        {documentChapters
          .sort((a, b) => a.chapterOrder - b.chapterOrder)
          .filter((chapter) => isActiveInCountry(activeCountry, chapter))
          .map((chapter: reduxDocumentType.ChapterObject, index: number) => {
            return (
              <RecursiveComponent
                key={chapter.id}
                chapter={chapter}
                index={(index + 1).toString()}
                activeChapter={activeChapter}
                setActiveChapter={setActiveChapter}
                chapterMode={chapterMode}
                otherDocumentChapters={otherDocumentChapters}
              />
            )
          })}
      </ul>
    )
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        padding: '1em',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Skeleton
        style={{
          margin: 0,
        }}
        variant='rect'
        width={'100%'}
        height={'100%'}
      />
    </div>
  )
}
