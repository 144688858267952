import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
} from '@material-ui/core'
import DocumentIcon from '@material-ui/icons/Assignment'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter'
import DescriptionIcon from '@material-ui/icons/Description'
import ExitIcon from '@material-ui/icons/ExitToApp'
//import VersionIcon from '@material-ui/icons/History'
import CountryIcon from '@material-ui/icons/Language'
import GeneralIcon from '@material-ui/icons/Settings'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import SectionsIcon from '@material-ui/icons/ViewHeadline'
import { useContext, useState } from 'react'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import { DocumentContext } from 'shared/components/DocumentEditor/DocumentEditor'
import { WarningDialog } from 'views/Pages/TemplateEnvironment/Home/SideNav/WarningDialog'
import {fetchDocumentsV2} from "../../../../../redux/thunks/documentsThunk";
import {useDispatch} from "react-redux";

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export const SideNav = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const context = useContext(DocumentContext)
  const { activeDocument } = context
  let query = useQuery()
  const section = query.get('section')
  // const context = useContext(DocumentContext)
  // const { project } = context
  // console.log(project)

  const [isWarningDialogOpen, setIsWarningDialogOpen] = useState<boolean>(false)
  const [dropdown, setDropdown] = useState<boolean>(false)

  const ProjectHomeLinks = () => {
    if (
      history.location.pathname.includes('/templateEnvironment') &&
      !history.location.pathname.includes('/document/')
    ) {
      return (
        <>
          <ListItem
            button
            selected={section === 'Documents'}
            onClick={() => {
              history.push('?section=Documents')
            }}
          >
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>

            <ListItemText primary='Document Management' />
          </ListItem>
          <ListItem
            button
            selected={section === 'Users'}
            onClick={() => {
              history.push('?section=Users')
            }}
          >
            <ListItemIcon>
              <SupervisorAccountIcon />
            </ListItemIcon>
            <ListItemText primary='User Management' />
          </ListItem>
          <ListItem
            button
            selected={section === 'Projects'}
            onClick={() => {
              history.push('?section=Projects')
            }}
          >
            <ListItemIcon>
              <BusinessCenterIcon />
            </ListItemIcon>
            <ListItemText primary='Project Management' />
          </ListItem>
        </>
      )
    }

    return null
  }

  const DocumentHomeLinks = () => {
    if (history.location.pathname.includes('/document/')) {
      return (
        <>
          <ListItem
            button
            selected={section === 'Content'}
            onClick={() => {
              history.push('?section=Content')
            }}
          >
            <ListItemIcon>
              <DocumentIcon />
            </ListItemIcon>
            <ListItemText primary='Manage Content' />
          </ListItem>

          {activeDocument.name !== 'Wage Type Catalogue' && (
            <>
              <ListItem
                button
                selected={section === 'Sections'}
                onClick={() => {
                  history.push('?section=Sections')
                }}
              >
                <ListItemIcon>
                  <SectionsIcon />
                </ListItemIcon>
                <ListItemText primary='Manage Sections' />
              </ListItem>

              <ListItem
                button
                selected={section === 'General'}
                onClick={() => {
                  history.push('?section=General')
                }}
              >
                <ListItemIcon>
                  <GeneralIcon />
                </ListItemIcon>
                <ListItemText primary='Manage Information' />
              </ListItem>
              {/*<ListItem*/}
              {/*  button*/}
              {/*  selected={section === 'Versions'}*/}
              {/*  onClick={() => {*/}
              {/*    history.push('?section=Versions')*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <ListItemIcon>*/}
              {/*    <VersionIcon />*/}
              {/*  </ListItemIcon>*/}
              {/*  <ListItemText primary='View History' />*/}
              {/*</ListItem>*/}
            </>
          )}
          <ListItem
            button
            selected={section === 'Countries'}
            onClick={() => {
              history.push('?section=Countries')
            }}
          >
            <ListItemIcon>
              <CountryIcon />
            </ListItemIcon>
            <ListItemText primary='Manage Countries' />
          </ListItem>

          <ListItem
            button
            onClick={() => {
              if (localStorage.getItem('ContentIsDirty')) {
                if (JSON.parse(localStorage.getItem('ContentIsDirty'))) {
                  setIsWarningDialogOpen(true)
                } else {
                  history.push('/templateEnvironment/?section=Documents')
                  const fetchDocumentsThunk = fetchDocumentsV2()
                  dispatch(fetchDocumentsThunk)
                }
              } else {
                history.push('/templateEnvironment/?section=Documents')
                const fetchDocumentsThunk = fetchDocumentsV2()
                dispatch(fetchDocumentsThunk)
              }
            }}
          >
            <ListItemIcon>
              <ExitIcon style={{ color: 'red' }} />
            </ListItemIcon>
            <ListItemText style={{ color: 'red' }} primary='Leave Document' />
          </ListItem>
        </>
      )
    }

    return null
  }

  return (
    <>
      <Paper
        style={{
          flex: 1,
          height: '100%',
          backgroundColor: 'white',
          width: '100%',
        }}
      >
        <List component='nav' aria-label='main mailbox folders'>
          <DocumentHomeLinks />
          <ProjectHomeLinks />
        </List>
      </Paper>
      <WarningDialog
        isOpen={isWarningDialogOpen}
        setOpen={setIsWarningDialogOpen}
        setDropDown={setDropdown}
        dropDown={dropdown}
        pathValue={''}
      />
    </>
  )
}
