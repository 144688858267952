import { IconButton, InputAdornment } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles, Theme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import * as EmailValidator from 'email-validator'
import image from 'media/logo.svg'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { RootState } from 'redux/store'
import { login } from 'redux/thunks/authorisationThunk'
import Loader from 'shared/components/Feedback/Loader'
import { Status } from 'shared/types/status'
import { User } from 'shared/types/user'
import styled from 'styled-components'

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 100%'
  },
  mainDiv: {
    flex: 1,
    display: 'flex'
  },
  paperContainer: {
    display: 'flex',
    padding: theme.spacing(9, 2, 9, 2),
    justifyContent: 'center'
  },
  paper: {
    dispaly: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  form: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    padding: theme.spacing(3, 0)
  },
  formFields: {
    paddingTop: theme.spacing(2)
  },
  submit: {},
  gridItemPaperContainer: {
    maxWidth: 600
  },
  checkbox: {
    paddingTop: theme.spacing(1),
    '& > *': {
      paddingLeft: '0 !important'
    }
  },
  formLink: {
    paddingTop: theme.spacing(1)
  },
  link: {
    textDecoration: 'none !important',
    color: theme.palette.text.primary
  }
}))

const LogoImg = styled.img`
  display: block;
  margin: 0 auto;
  margin-bottom: 2em;
`

const Login = () => {
  const [emailInput, setEmailInput] = React.useState<string>('')
  const [passwordInput, setPasswordInput] = React.useState<string>('')
  const [emailValidation, setEmailValidation] = React.useState<boolean>(false)
  const [passwordValidation, setPasswordValidation] = React.useState<boolean>(
    false
  )
  const [emailHelperText, setEmailHelperText] = React.useState<string>('')
  const [passwordHelperText, setPasswordHelperText] = React.useState<string>('')
  const [passwordShown, setPasswordShown] = useState(false)
  const [rememberMe, setRememberMe] = useState(false)
  const [isAdmin, setIsAdmin] = React.useState(false)

  const dispatch = useDispatch()
  const errorMessage = useSelector(
    (state: RootState) => state.authorisation.loginResponseErrorMessage
  )
  const loggingInStatus = useSelector(
    (state: RootState) => state.authorisation.loginStatus
  )
  const user: User | any = useSelector(
    (state: RootState) => state.authorisation.user
  )

  React.useEffect(() => {
    if (user.id !== '') {
      console.log('user', user)

      if (user.role_id.role_name !== 'Administrator') {
        console.log('User')
        setIsAdmin(false)
      } else {
        console.log('Admin')
        setIsAdmin(true)
      }
    }
  }, [user])

  React.useEffect(() => {
    if (user.id !== '' && isAdmin === false) {
      history.push('/projectEnvironment')
    }
  }, [isAdmin])

  const classes = useStyles()
  const history = useHistory()

  const sendDetailsToServer = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    let formValidation = true

    if (emailInput === '') {
      setEmailHelperText('Email can not be empty')
      setEmailValidation(true)
      formValidation = false
    } else {
      if (EmailValidator.validate(emailInput)) {
        setEmailHelperText('')
        setEmailValidation(false)
      } else {
        setEmailHelperText('Invalid email')
        setEmailValidation(true)
        formValidation = false
      }
    }

    if (passwordInput === '') {
      setPasswordHelperText('Password can not be empty')
      setPasswordValidation(true)
      formValidation = false
    }

    if (formValidation) {
      const loginThunk = login(emailInput, passwordInput, rememberMe)
      dispatch(loginThunk)
    }

    // encrypt
    // do post with axios
    // wait for response
    // if success store in redux
    // if fail show error pop up
  }

  if (loggingInStatus === Status.success) {
    // const name: string = useSelector((state: RootState) => state.authorisation.loginResponseErrorMessage)
    let resUser = user as User
    localStorage.setItem('firstname', resUser.firstname)
    localStorage.setItem('surname', resUser.surname)
    localStorage.setItem('email', resUser.email)
    localStorage.setItem('role_name', resUser.role_id.role_name)
    sessionStorage.setItem('project_roles', '')
    localStorage.setItem(
      'can_create',
      (resUser.role_id.can_create as unknown) as string
    )
  }

  return (
    <div className={classes.mainDiv}>
      <Grid
        alignItems='center'
        className={classes.gridContainer}
        justify='center'
        spacing={3}
        container
      >
        <Grid className={classes.gridItemPaperContainer} xs={10} item>
          <Paper className={classes.paperContainer}>
            <Container component='main' maxWidth='xs'>
              <CssBaseline />
              <div className={classes.paper}>
                <Loader open={loggingInStatus === Status.loading}></Loader>
                <div className={classes.logoContainer}>
                  <LogoImg alt='' src={image} />
                </div>
                {user.id === '' && (
                  <Typography
                    color='textPrimary'
                    component='h1'
                    style={{ textAlign: 'center' }}
                    variant='h5'
                  >
                    Sign in
                  </Typography>
                )}

                {user.id === '' && (
                  <form
                    className={classes.form}
                    noValidate
                    onSubmit={sendDetailsToServer}
                  >
                    <div className={classes.formFields}>
                      <TextField
                        autoComplete='email'
                        id='email'
                        label='Email address'
                        margin='normal'
                        name='email'
                        variant='outlined'
                        autoFocus
                        fullWidth
                        required
                        error={emailValidation}
                        helperText={emailHelperText}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => setEmailInput(event.target.value)}
                      />
                    </div>
                    <div className={classes.formFields}>
                      <TextField
                        autoComplete='current-password'
                        id='password'
                        label='Password'
                        margin='normal'
                        name='password'
                        type={passwordShown ? 'text' : 'password'}
                        variant='outlined'
                        fullWidth
                        required
                        error={passwordValidation}
                        helperText={passwordHelperText}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => setPasswordInput(event.target.value)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <IconButton
                                aria-label='toggle password visibility'
                                onClick={() => setPasswordShown(!passwordShown)}
                              >
                                {passwordShown ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </div>
                    {loggingInStatus === Status.failed && errorMessage && (
                      <Typography variant='subtitle2' color='error'>
                        <br />*{errorMessage}
                      </Typography>
                    )}

                    <FormControlLabel
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          color='primary'
                          value='rememberMe'
                          onClick={() => setRememberMe(!rememberMe)}
                        />
                      }
                      label='Remember me'
                    />

                    <div className={classes.formFields}>
                      <Button
                        className={classes.submit}
                        color='primary'
                        type='submit'
                        variant='contained'
                        fullWidth
                      >
                        Sign in
                      </Button>
                    </div>

                    <Grid className={classes.formLink} container>
                      <Grid item xs>
                        <Link className={classes.link} to='/forgotPass'>
                          Forgot password?
                        </Link>
                      </Grid>
                    </Grid>
                  </form>
                )}
                {user.id !== '' && (
                  <div
                    style={{
                      textAlign: 'center'
                    }}
                  >
                    <h3>
                      Welcome,{' '}
                      <b>
                        {user.firstname} {user.surname}!
                      </b>
                    </h3>
                    <p>Choose an environment to log into:</p>
                    <br />
                    <div>
                      {isAdmin === true && (<Button
                        onClick={() => {
                          history.push('/templateEnvironment')
                        }}
                        style={{ marginRight: '1em' }}
                        variant='contained'
                        color='secondary'
                      >
                        Template Environment
                      </Button>)}
                      <Button
                        onClick={() => {
                          history.push('/projectEnvironment')
                        }}
                        variant='contained'
                        color='primary'
                      >
                        Project Environment
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </Container>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default Login
