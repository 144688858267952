import { MenuItem, Select } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import {
  default as DialogContent,
  default as DialogTitle,
} from '@material-ui/core/DialogTitle';
import CountryRegionData from 'country-region-data';
import React, { useContext } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { DocumentContext } from 'shared/components/DocumentEditor/DocumentEditor';

const AddCountryDialog: React.FC<{
  open: boolean;
  setOpen: (open: boolean) => void;
}> = ({ open, setOpen }) => {
  // const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  const [selectedCountry, setSelectedCountry] = React.useState('');

  const context = useContext(DocumentContext);
  const { activeCountries, setActiveCountries } = context;
  const addCountryToDocument = () => {
    let newcountries = [...activeCountries];
    newcountries.push({
      country_name: selectedCountry,
      id: Date.now().toString(),
      active_status: true,
    });
    setActiveCountries(newcountries);
    setOpen(false);
    setSelectedCountry('');
  };

  const handleChangeSelectedCountry = (event) => {
    setSelectedCountry(event.target.value);
  };

  const [selectValue, setSelectValue] = React.useState(
    selectedCountry.length > 0 ? selectedCountry : 'none'
  );
  React.useEffect(() => {
    setSelectValue(selectedCountry.length > 0 ? selectedCountry : 'none');
  }, [selectedCountry]);

  return (
    <div>
      <Dialog
        maxWidth={'lg'}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add Country</DialogTitle>
        <DialogContent style={{ minWidth: 500 }}>
          <Select
            fullWidth
            value={selectValue}
            onChange={handleChangeSelectedCountry}
          >
            <MenuItem value={'none'}>
              <em>Select Country</em>
            </MenuItem>
            {CountryRegionData.filter((country) => {
              const existing = activeCountries.find((activeCountry) => {
                return activeCountry.country_name === country.countryName;
              });

              return existing === undefined;
            }).map((country) => (
              <MenuItem value={country.countryName}>
                <ReactCountryFlag countryCode={country.countryShortCode} svg />
                &nbsp; {country.countryName}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={addCountryToDocument} color="primary">
            Add Country
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddCountryDialog;
