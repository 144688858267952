export const DBConfig = {
  name: 'MyDocumentDB',
  version: 1,
  objectStoresMeta: [
    {
      store: 'Documents',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'document', keypath: 'document', options: { unique: false } }
      ]
    }
  ]
};
