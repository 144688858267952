import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column' /* 
        paddingRight: theme.spacing( 5 ),
        paddingLeft: theme.spacing( 5 ), */,
  },
  topContainer: {
    width: '100%',
    height: 100,
    display: 'flex',
    /* [ theme.breakpoints.down( 'sm' ) ]: {
            paddingBottom: theme.spacing( 3 ),
        }, */
  },
  container: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    /* paddingBottom: theme.spacing(5), */
  },
  clientRoot: {
    width: '100%',
    paddingTop: theme.spacing(3),
  },
  mainContent: {
    display: 'grid',
    gridGap: '1rem',
    gridTemplateColumns: 'repeat(auto-fit, minmax(15px, 200px))',
    gridTemplateRows: 'auto auto auto auto',
    width: '100%',
    height: 'auto',
  },
  smallSpacer: {
    /*  padding: theme.spacing( 1, 0 ), */
  },
  spacer: {
    /* padding: theme.spacing( 3, 0 ), */
  },
  noItemsContainer: {
    minHeight: '150px',
    display: 'grid',
    placeItems: 'center',
  },
  documentPaper: {
    width: '180px',
    height: '250px',
    display: 'grid',
    gridTemplateRows: '1fr auto',
    placeItems: 'center',
  },
  newProjectIcon: {
    gridRowStart: 1,
  },
  documentName: {
    justifySelf: 'center',
    alignSelf: 'start',
    gridTemplateRows: '75% 25% auto',
    gridRowStart: 1,
    height: '100%',
    width: '100%',
    display: 'grid' /* 
        alignItems: 'start',
        justifyContent: 'center', */,
    backgroundColor: theme.palette.background.paper,
  },
  documentVersion: {
    textAlign: 'center',
    maxWidth: '120px',
    alignSelf: 'start',
    justifySelf: 'center',
  },
  documentNameText: {
    textAlign: 'center',
    maxWidth: '120px',
    alignSelf: 'center',
    justifySelf: 'center',
  },
  documentNameWordProject: {
    fontSize: '16px',
    color: theme.palette.text.secondary,
  },
  button1: {
    justifySelf: 'center',
    alignSelf: 'center',
    gridRowStart: 3,
    height: '100%',
    borderRadius: '0',
  },
  documentLogo: {
    width: '50px',
  },
  icon1: { marginRight: '8px !important' },
}));
