import { Divider } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 a100%',
  },
  mainDiv: {
    minHeight: '86vh',
    display: 'flex',
    padding: '2em',
  },
  paperContainer: {
    display: 'flex',
    padding: theme.spacing(9, 2, 9, 2),
    justifyContent: 'center',
  },
  paper: {
    dispaly: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: theme.spacing(3),
  },
  form: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    padding: theme.spacing(3, 0),
  },
  formFields: {
    paddingTop: theme.spacing(3),
  },
  submit: {},
  gridItemPaperContainer: {
    maxWidth: '55%',
  },
  checkbox: {
    paddingTop: theme.spacing(1),
    '& > *': {
      paddingLeft: '0 !important',
    },
  },
  formLink: {
    paddingTop: theme.spacing(1),
  },
  link: {
    textDecoration: 'none !important',
    color: theme.palette.text.primary,
  },
}));

const PrivacyPolicy = () => {
  const classes = useStyles();

  return (
    <div className={classes.mainDiv}>
      <Grid
        spacing={3}
        container
        className={classes.gridContainer}
        justify="center"
        alignItems="center"
      >
        <Grid className={classes.gridItemPaperContainer} item xs={10}>
          <Paper className={classes.paperContainer}>
            <Container maxWidth="md" component="main">
              <CssBaseline />
              <div className={classes.paper}>
                <Typography
                  style={{ textAlign: 'left' }}
                  variant="h4"
                  color="textPrimary"
                >
                  EPI-USE Privacy Policy
                  <br />
                </Typography>
                <Typography
                  style={{ textAlign: 'left' }}
                  variant="subtitle1"
                  color="textPrimary"
                >
                  Effective: 25 May 2018
                  <br />
                  <br />
                </Typography>
                <Divider variant="middle" />
                <Typography style={{ textAlign: 'left' }} color="textPrimary">
                  <br />
                  <h3>Introduction</h3>
                  <br />
                  <p>
                    EPI-USE and its subsidiary companies respect the right of
                    privacy of online visitors and recognize the importance of
                    protecting the information collected from you. We have
                    adopted an all-encompassing, corporate Online Privacy Policy
                    that guides how we collect, store and use the information
                    you provide online.
                    <br />
                    <br />
                    This policy applies only to sites maintained by EPI-USE and
                    its subsidiaries, and not to websites maintained by other
                    related companies or organizations to which we link. If you
                    wish to be removed from our database, send an email to
                    remove@labs.epiuse.com and your information will be removed
                    promptly.
                    <br />
                    <br />
                    <b>
                      PLEASE READ THE FOLLOWING TERMS AND POLICIES CAREFULLY.
                      WHEN YOU USE THE EPI-USE WEBSITE YOU ACKNOWLEDGE THAT YOU
                      HAVE READ, UNDERSTOOD AND AGREE TO BE BOUND BY THESE.
                    </b>
                    <br />
                    <br />
                    If you have questions or concerns regarding this statement,
                    contact EPI-USE at the email or postal address specified on
                    our homepage.
                    <br />
                  </p>
                  <br />
                  <h3>Who are we?</h3>
                  <br />
                  <h3>Our company and subsidiaries</h3>
                  <br />
                  <p>
                    EPI-USE Inc. is a registered company headquartered in
                    Atlanta, USA. We operate in many countries with the support
                    of numerous representatives and subsidiaries. Please view an
                    updated list of office locations here.
                    <br />
                    <br />
                    Our passion at EPI-USE is to empower companies to maximise
                    the performance and management of their SAP® systems,
                    allowing them to make faster, more accurate business
                    decisions. Our software, value-added solutions and services
                    create better, more powerful SAP systems, saving companies
                    time, money and resources. Some of the processing services
                    that we supply relates to cloud based hosting, landscape
                    management and payroll management solutions.
                    <br />
                    <br /> All of our directly employed representatives, as well
                    as the employees of our subsidiaries, may have access to all
                    or some of your personal information that we process.This
                    information is gathered through online forms, emails we send
                    that you read and click, the pages you visit on our website
                    after clicking emails.
                    <br />
                    <br /> For a current register of our representatives and
                    subsidiaries, please refer to our Contact Us page.
                  </p>
                  <br />
                  <h3>Data Protection Officer</h3>
                  <br />
                  <p>
                    As a multinational company that delivers services that
                    include the storage and processing of information for
                    clients, EPI-USE understands the impact of the new European
                    Union General Data Protection Regulation. This means that in
                    our ongoing efforts towards compliance we looked at the
                    requirements of the regulation and have appointed a Data
                    Protection Officer to look after the security and processing
                    of data for the group of companies. For further information
                    please send request/ inquiry to
                    datasecurity@groupelephant.com.
                  </p>
                  <br />
                  <h3>Why are we collecting personal information?</h3>
                  <br />
                  <p>
                    Our primary goal in collecting personally identifiable
                    information is to provide you with the products and services
                    made available through our website. This includes, but is
                    not limited to, communication between ourselves and yourself
                    and relates to the management of your account as a
                    registered user, if you are one.
                    <br />
                    <br /> EPI-USE collects personal information only to fulfil
                    the purpose of the visitor’s interaction with the company.
                    We use consent (recitals 32, 42, 43; Article 6(1)(a)) and
                    legitimate interest (recitals 47, 48; Article 6(1)(f)) as
                    the GDPR basis to store and process your data. This consent
                    must be exclusive, discretionary, positive and freely given.
                    The principle of “opt-in” is obligatory, meaning no
                    processing can take place until consent is assured.
                    Regarding legitimate interest, examples include certain
                    client or service relationships between you and EPI-USE
                    (with limitations regarding employment contracts and public
                    authorities). It also includes processes for preventing
                    fraud, as well as transmitting personal data within EPI-USE’
                    undertakings or institutions affiliated with a central body
                    for internal administrative purposes.
                    <br />
                    <br /> EPI-USE takes reasonable steps to ensure that the
                    personal information it processes is accurate, complete and
                    current, but is dependent on users to update or correct
                    their personal information whenever necessary. If you wish
                    to update and or change your information, please send a
                    request to support@labs.epiuse.com.
                  </p>
                  <br />
                  <h3>How are we using personal information?</h3>
                  <br />
                  <h3>Automated profiling</h3>
                  <br />
                  <p>
                    EPI-USE may make use of automated profiling systems to
                    assess your interest in our products and services using
                    different sources of data such as page visits, download
                    requests and other interactions. We use such systems to
                    ensure that you receive the most relevant product
                    information from us to suit your needs. If you no longer
                    wish to receive targeted marketing information from us,
                    please send an email to remove@labs.epiuse.com to opt out or
                    follow the instruction at the bottom of any of our emails.
                  </p>
                  <br />
                  <h3>Behavioral remarketing</h3>
                  <br />
                  <p>
                    EPI-USE uses remarketing services to advertise on third
                    party websites to you after you visited our website. We, and
                    our third party vendors, use cookies to inform, optimize and
                    serve ads based on your past visits to our Service.
                    <br />
                    <br /> Google Google AdWords remarketing service is provided
                    by Google Inc. You can opt-out of Google Analytics for
                    Display Advertising and customize the Google Display Network
                    ads by visiting the Google Ads Settings page:
                    http://www.google.com/settings/ads Google also recommends
                    installing the Google Analytics Opt-out Browser Add-on –
                    https://tools.google.com/dlpage/gaoptout – for your web
                    browser. Google Analytics Opt-out Browser Add-on provides
                    visitors with the ability to prevent their data from being
                    collected and used by Google Analytics. For more information
                    on the privacy practices of Google, please visit the Google
                    Privacy Terms web page:
                    http://www.google.com/intl/en/policies/privacy/
                  </p>
                  <br />
                  <h3>Log data</h3>
                  <br />
                  <p>
                    When you visit our website, our servers automatically record
                    information that your browser sends as Log Data . This Log
                    Data may include information such as your IP address,
                    browser type or the domain from which you are visiting, the
                    websites you visit and the search terms you use. For most
                    users, accessing the Internet from an Internet service
                    provider, the IP address will be different every time you
                    log on. We use Log Data to monitor the use of the site and
                    our service, as well as for the site’s technical
                    administration. We do not associate your IP address with any
                    other personally identifiable information to identify you
                    personally, except in case of violation of the Terms and
                    Conditions.
                  </p>
                  <br />
                  <h3>Aggregate data</h3>
                  <br />
                  <p>
                    EPI-USE may monitor use of the Service by customers and use
                    the data gathered anonymously. You agree that EPI-USE may
                    use and publish such information, provided that such
                    information does not incorporate any personally identifiable
                    information and/or identify you.
                  </p>
                  <br />
                  <h3>Communications</h3>
                  <br />
                  <p>
                    We may use your personal Information to contact you with
                    newsletters, marketing or promotional materials and other
                    information that may be of interest to you. You may opt out
                    of receiving any, or all, of these communications from us by
                    following the unsubscribe link or instructions provided in
                    any email we send.
                  </p>
                  <br />
                  <h3>Who else relies on some of this information?</h3>
                  <br />
                  <h3>Public forums</h3>
                  <br />
                  <p>
                    Our website offers community forums that are open to viewing
                    by the public and accessible for posting to registered
                    users. Be aware that any information you provide in these
                    forums may be read, collected and used by others customers
                    of EPI-USE who access them. To request removal of your
                    personal information from our blog or community forum,
                    contact us at support@labs.epiuse.com. In some cases, we may
                    not be able to remove your personal information, in which
                    case we will let you know if we are unable to do so and why.
                    Please understand that when information has been published
                    on the internet, it is non-retractable.
                    <br />
                    <br /> EPI-USE posts testimonials, comments and reviews from
                    customers on the Client Central website. These may contain
                    personally identifiable information but we do obtain the
                    customer’s consent via email prior to posting the
                    testimonial in order to place their name alongside their
                    testimonial. If you wish to have your testimonial removed,
                    please contact us at: support@labs.epiuse.com
                  </p>
                  <br />
                  <h3>Service providers</h3>
                  <br />
                  <p>
                    We engage certain trusted third parties to perform functions
                    and provide services for us. These include hosting and
                    maintenance, customer relationship, database storage and
                    management, and direct marketing campaigns. We do share your
                    personally identifiable information with these third
                    parties, but only to the extent necessary to enable them to
                    perform these functions and services, and only pursuant to
                    binding contractual obligations requiring such third parties
                    to maintain the privacy and security of your data.
                  </p>
                  <br />
                  <h3>Law enforcement</h3>
                  <br />
                  <p>
                    We cooperate with government and law enforcement officials
                    or private parties to enforce and comply with the law. We
                    may disclose any information about you to government or law
                    enforcement officials or private parties as we, in our sole
                    discretion, believe necessary or appropriate. Prevalent
                    reasons for doing this are in response to claims, in
                    compliance with legal process (including subpoenas), to
                    protect the property and rights of EPI-USE or a third party,
                    to ensure the safety of the public or any person, and to
                    prevent or stop any illegal, unethical or legally actionable
                    activity, or to comply with the law.
                  </p>
                  <br />
                  <h3>Business transfers</h3>
                  <br />
                  <p>
                    EPI-USE may sell, transfer or otherwise distribute some or
                    all of its assets, including your personally identifiable
                    information, in the event of a merger, acquisition,
                    reorganization or sale of assets, or bankruptcy. You will
                    have the opportunity to opt out of any such transfer if the
                    new entity’s planned processing of your information differs
                    materially from that set forth in this Privacy Policy. What
                    type of personal information are we collecting?
                  </p>
                  <br />
                  <h3>Information collected upon registration</h3>
                  <br />
                  <p>
                    If you desire access to certain restricted sections of our
                    website, you will be required to become a registered user
                    and submit certain personally identifiable information to
                    EPI-USE. This happens in a number of instances, such as when
                    you sign up for our services, or if you desire to receive
                    marketing materials and information. Personally identifiable
                    information may include your IP address, full user name,
                    password, email address, city, time zone, telephone number,
                    and other information that you may choose to provide or
                    include in your public profile. EPI-USE collects such
                    information only to fulfil the purpose of the visitor’s
                    interaction with the company. EPI-USE takes reasonable steps
                    to ensure that the personal information it processes is
                    accurate, complete and current, but is dependent on users to
                    update or correct their personal information whenever
                    necessary.
                  </p>
                  <br />
                  <h3>Sensitive personal data</h3>
                  <br />
                  <p>
                    EPI-USE does not require any sensitive personal data to
                    operate and provide services to you. We will therefore not
                    collect any data on the following topics:
                    <br />
                    <br />
                    Racial or ethnic origin <br />
                    Political opinions <br />
                    Religious or philosophical beliefs <br />
                    Trade union membership Genetic data <br />
                    Biometric data for the purpose of uniquely identifying a
                    natural person <br />
                    Data concerning health or a natural person’s sex life and/or
                    sexual orientation <br />
                    <br />
                    Should any such sensitive personal data be requested via one
                    of our websites, services or other means of communications,
                    please contact the data protection officer
                    (datasecurity@groupelephant.com) with a formal complaint.
                  </p>
                  <br />
                  <h3>Children’s privacy</h3>
                  <br />
                  <p>
                    Our Service does not address anyone under the age of 18
                    (“Children”). We do not knowingly collect personally
                    identifiable information from children under 18. If you are
                    a parent or guardian and you are aware that your Children
                    has provided us with Personal Information, please contact
                    us. If we become aware that we have collected Personal
                    Information from a child under age 18 without verification
                    of parental consent, we take steps to remove that
                    information from our servers.
                  </p>
                  <br />
                  <h3>Specific exclusion of personal email addresses</h3>
                  <br />
                  <p>
                    EPI-USE does not require personal email addresses to operate
                    or provide services. When providing a personal email address
                    you will do so willingly. Where possible we explicitly
                    reject personal email addresses from known free email
                    service providers.
                    <br />
                    <br /> Please only provide a company email address when
                    submitting information to us.
                  </p>
                  <br />
                  <h3>Do we transfer data to other countries?</h3>
                  <br />
                  <p>
                    Your information, including Personal Information, may be
                    transferred to — and maintained on — computers located
                    outside of your state, province, country or other
                    governmental jurisdiction where the data protection laws may
                    differ than those from your jurisdiction.
                    <br />
                    <br /> Your consent to this Privacy Policy followed by your
                    submission of such information represents your agreement to
                    that transfer.
                  </p>
                  <br />
                  <h3>How long do we keep personal information?</h3>
                  <br />
                  <p>
                    We retain personal information for as long as it is required
                    for the fulfillment of our business activities or to align
                    with legal requirements where applicable. We remove data
                    from our system under the following situations:
                    <br />
                    <br /> Where an individual contact requests removal of their
                    data, and no legal requirements prohibit such removal
                    <br />
                    Where our automated decision making system indicates that an
                    individual isn’t actively engaging with our communications
                    <br /> Where data has deteriorated and all attempts to
                    update the information has failed (e.g. your email address
                    expired and we were unable to confirm a new one)
                  </p>
                  <br />
                  <h3>What are your rights?</h3>
                  <br />
                  <h3>Disclosure of your personal information</h3>
                  <br />
                  <p>
                    You have the right to request a full report on the
                    information we hold on you. Such a report will be made
                    available to you within 30 days from request. Please send
                    your request to datasecurity@groupelephant.com.
                  </p>
                  <br />
                  <h3>Withdrawal of consent to process your data</h3>
                  <br />
                  <p>
                    Where you have provided explicit consent to use for the
                    processing of your personal data, you have the right to
                    withdraw such consent insofar it does not affect the
                    performance of a contract or other legal requirements,
                    including the delivery of our services to you.
                    <br />
                    <br /> You may also withdraw your consent to be contacted by
                    us for marketing purposes by following the unsubscribe link
                    or other instructions on our email communications.
                  </p>
                  <br />
                  <h3>Lodging a complaint</h3>
                  <br />
                  <p>
                    You have the right to lodge a complaint with the applicable
                    supervisory body of your legal jurisdiction where you
                    believe that there was an infringement of the GDPR or other
                    applicable regulations in the processing of your personal
                    information.
                    <br />
                    <br /> Before lodging such a complaint we kindly request
                    that you contact us with any concerns you may have.
                  </p>
                  <br />
                  <h3>Is your data secure?</h3>
                  <br />
                  <p>
                    We make every reasonable effort to ensure that your data is
                    safe and secure, however, due to the fallibility of even the
                    most secure system we cannot make any guarantees regarding
                    data breaches.
                    <br />
                    <br /> In the unlikely event of a data breach a response
                    protocol is followed. Request a copy of our security breach
                    protocol by contacting datasecurity@groupelephant.com.
                  </p>
                  <br />
                  <h3>Changes to the Privacy Policy</h3>
                  <br />
                  <p>
                    Although most changes are likely to be minor, EPI-USE may
                    change its Privacy Policy from time to time, at EPI-USE’
                    sole discretion. If EPI-USE makes any material changes we
                    will notify you by email (sent to the email address
                    specified in your account) or by means of a notice on the
                    Client Central website where applicable, prior to the change
                    becoming effective.
                  </p>
                  <br />
                  <h3>Contact Us</h3>
                  <br />
                  <p>
                    You can contact us in the following ways:
                    <br />
                    <br /> Office locations and addresses Data protection
                    officer:
                    <br /> datasecurity@groupelephant.com
                  </p>
                  <br />
                </Typography>
              </div>
            </Container>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default PrivacyPolicy;

// const LogoImg = styled.img`
//   display: block;
//   margin: 0 auto;
//   margin-bottom: 2em;
// `;
