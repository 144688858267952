import { makeStyles, Theme } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {},
  dialogTitle: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
  dialogContent: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    minHeight: '200px',
    overflow: 'hidden',
  },
  dialogContentText: {},
  form: {
    height: '100%',
  },
  dialogActions: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  button: {},
}));
