import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { cloneDeep } from 'lodash'
import React from 'react'
import { useDispatch } from 'react-redux'
import { editDocumentOnDB } from 'redux/thunks/documentsThunk'
import { Country } from 'shared/types/country'
import {
  ChapterSection,
  Document,
  DocumentChange,
  DocumentVersion
} from 'shared/types/document'
import EditDocumentTabNav from './EditDocumentTabNavigation'

export type dialogProps = {
  isOpen: boolean
  setOpen: (value: boolean) => void
  document: Document
}

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {},
  dialogTitle: { padding: theme.spacing(5, 5, 0, 5) },
  dialogContent: {
    /* overflow: 'hidden', */
  },
  dialogContentText: { padding: theme.spacing(0, 5, 5, 2) },
  dialogActions: { padding: theme.spacing(5, 6, 5, 5) },
  button: {
    paddingLeft: '2rem'
  }
}))

const EditDocumentDialog: React.FC<dialogProps> = ({
  isOpen,
  setOpen,
  document
}: dialogProps) => {
  const latestDocumentVersion =
    document.documentVersions[document.documentVersions.length - 1]
  const latestDocumentChange =
    latestDocumentVersion.changes[latestDocumentVersion.changes.length - 1]

  const [DocumentName, setDocumentName] = React.useState<string>(
    document && document.name ? document.name : ''
  )
  const [author, setAuthor] = React.useState<string>(
    document && document.author ? document.author : ''
  )
  const [countryList, setCountryList] = React.useState<Country[]>(
    latestDocumentChange && latestDocumentChange.countries
      ? latestDocumentChange.countries
      : []
  )

  const [sections, setSectionsList] = React.useState<ChapterSection[]>(
    latestDocumentChange && latestDocumentChange.sections
      ? latestDocumentChange.sections
      : []
  )

  React.useEffect(() => {
    if (isOpen) {
      setDocumentName(document && document.name ? document.name : '')
      setAuthor(document && document.author ? document.author : '')
      setCountryList(
        latestDocumentChange && latestDocumentChange.countries
          ? latestDocumentChange.countries
          : []
      )
      setSectionsList(
        latestDocumentChange && latestDocumentChange.sections
          ? latestDocumentChange.sections
          : []
      )
    }
  }, [isOpen])

  const handleClose = () => {
    setOpen(false)
  }

  const classes = useStyles()
  const dispatch = useDispatch()

  const onSaveButtonClicked = () => {
    if (DocumentName === '') {
      //empy
    }

    if (author === '') {
      //empy
    } else {
      let latestChange =
        latestDocumentVersion.changes[latestDocumentVersion.changes.length - 1]
      let newChange: DocumentChange = {
        ...latestChange,
        sections,
        countries: countryList,
        lastUpdated: new Date().toString(),
        id: null,
        changedBy: {
          firstname: sessionStorage.getItem('firstname'),
          id: sessionStorage.getItem('id'),
          surname: sessionStorage.getItem('surname')
        }
      }
      // delete newChange.id;

      let newChanges = cloneDeep(latestDocumentVersion.changes)

      if (newChanges[newChanges.length - 1].id === null) {
        newChanges[newChanges.length - 1] = newChange
      } else {
        newChanges.push(newChange)
      }
      // newChanges.push(newChange);

      let activeDocDocumentVersions: DocumentVersion[] = cloneDeep(
        document.documentVersions
      )
      activeDocDocumentVersions[
        document.documentVersions.length - 1
      ].changes = newChanges
      activeDocDocumentVersions[
        document.documentVersions.length - 1
      ].lastUpdated = new Date().toString()

      let newDocument: Document = {
        ...document,
        name: DocumentName,
        author,
        documentVersions: activeDocDocumentVersions,
        lastUpdated: new Date().toString()
        /* email: emailInput, */
      }

      const editDocumentThunk = editDocumentOnDB(newDocument)
      dispatch(editDocumentThunk)
      handleClose()
    }
  }

  return (
    <div>
      <Dialog
        aria-labelledby='form-dialog-title'
        className={classes.gridContainer}
        open={isOpen}
        maxWidth='lg'
        fullWidth
        /* onClose={handleClose} */
      >
        <DialogTitle className={classes.dialogTitle} id='form-dialog-title'>
          Edit Document
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText className={classes.dialogContentText}>
            View/change Document name, and author.
          </DialogContentText>
          <div>
            <div>
              <EditDocumentTabNav
                Document={document}
                DocumentName={DocumentName}
                author={author}
                setDocumentName={setDocumentName}
                setAuthor={setAuthor}
                sections={sections}
                countries={countryList}
                setCountries={setCountryList}
                setSections={setSectionsList}
              />
            </div>
            <div></div>
          </div>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button color='primary' onClick={handleClose}>
            Cancel
          </Button>
          <span className={classes.button} />
          <Button
            color='primary'
            variant='contained'
            onClick={onSaveButtonClicked}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default EditDocumentDialog
