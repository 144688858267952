import { makeStyles, Theme } from '@material-ui/core/styles'
import React from 'react'

import DocumentManagementTable from './DocumentManagement/DocumentManagement'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column'
    /* paddingRight: theme.spacing( 5 ),
        paddingLeft: theme.spacing( 5 ), */
  },
  topContainer: {
    width: '100%',
    height: 100,
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(3)
    }
  },
  container: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    paddingBottom: theme.spacing(5)
  }
}))

const Container: React.FC = () => {
  const classes = useStyles()

  return (
    <main
      style={{
        flexGrow: 1,
        padding: '1em'
      }}
    >
      <div className={classes.root}>
        {/* <div className={classes.topContainer}>
                <TopNav />
            </div> */}
        <div className={classes.container}>
          <DocumentManagementTable />
        </div>
      </div>
    </main>
  )
}

export default Container
