import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { TemplateDocumentEditor } from 'views/Pages/TemplateEnvironment/Home/DocumentHome/Content/TemplateDocumentEditor'
import DocumentInformation from 'views/Pages/TemplateEnvironment/Home/DocumentHome/Information/DocumentInformation'
import ManageCountries from 'views/Pages/TemplateEnvironment/Home/DocumentHome/ManageCountries/ManageCountries'
import Sections from 'views/Pages/TemplateEnvironment/Home/DocumentHome/Sections/Sections'
import TemplateChanges from 'views/Pages/TemplateEnvironment/Home/DocumentHome/Versions/TemplateChanges'

export const TemplateDocumentHomeContent: React.FC = () => {
  function useQuery() {
    return new URLSearchParams(useLocation().search)
  }

  let query = useQuery()
  const section = query.get('section')
  const history = useHistory()

  if (history.location.pathname.includes('/document/')) {
    return (
      <>
        {section === 'Content' && <TemplateDocumentEditor />}
        {section === 'General' && (
          <div>
            <DocumentInformation />
          </div>
        )}
        {section === 'Sections' && <Sections />}
        {section === 'Versions' && <TemplateChanges />}
        {section === 'Countries' && <ManageCountries />}
      </>
    )
  }

  return null
}
