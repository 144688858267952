import { Grid } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import MenuItem from '@material-ui/core/MenuItem'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Select from '@material-ui/core/Select'
import { makeStyles, Theme } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import clsx from 'clsx'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  inputWrapper: { display: 'flex', flexWrap: 'wrap', flexDirection: 'row' },
  formSpan: {
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(4),
    flex: '0 1 15px',
    [theme.breakpoints.down('sm')]: {
      flex: '1 1 25ch'
    }
  },
  formControl: {
    width: '20ch',
    // height: '3ch',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  inputLabel: { top: '-2px' },
  searchField: {
    width: '38ch',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }
}))

export type TableSelectorGroupProp = {
  version: string
  setVersion: (version: string) => void
  role: string
  setRole: (role: string) => void
  search: string
  setSearch: (search: string) => void
}

const TableSelectorGroup: React.FC<TableSelectorGroupProp> = ({
  version,
  setVersion,
  role,
  setRole,
  search,
  setSearch
}) => {
  const classes = useStyles()

  // const handlesetVersionChange = (
  //   event: React.ChangeEvent<{ value: unknown }>
  // ) => {
  //   setVersion(event.target.value as string);
  // };

  const handleRoleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setRole(event.target.value as string)
  }

  // const handlesetSearchChange = (
  //   event: React.ChangeEvent<{ value: unknown }>
  // ) => {
  //   setSearch(event.target.value as string);
  // };

  return (
    <div
      style={{
        flexGrow: 1,
        flexDirection: 'row'
      }}
    >
      <Grid
        container
        spacing={1}
        direction='row'
        justify='space-between'
        alignItems='center'
      >
        <Grid>
          <span className={classes.formSpan}>
            <FormControl
              size='small'
              variant='outlined'
              className={classes.formControl}
            >
              {/* <InputLabel className={classes.inputLabel} id="roles-select-label">
                Roles
              </InputLabel> */}
              <Select
                labelId='roles-select-label'
                id='roles-select'
                value={role}
                onChange={handleRoleChange}
                label='Roles'
              >
                <MenuItem selected value={'AllRoles'}>
                  All Roles
                </MenuItem>
                <MenuItem value={'Guest'}>Guest</MenuItem>
                <MenuItem value={'Administrator'}>Administrator</MenuItem>
              </Select>
            </FormControl>
          </span>
        </Grid>
        <Grid item xs>
          <span className={classes.formSpan} style={{ width: '100%' }}>
            <FormControl
              style={{ width: '100%' }}
              size='small'
              className={clsx(classes.searchField)}
              variant='outlined'
            >
              <OutlinedInput
                fullWidth
                id='outlined-adornment-weight'
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton edge='end'>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
                aria-describedby='outlined-weight-helper-text'
                inputProps={{
                  'aria-label': 'weight'
                }}
                labelWidth={0}
                placeholder='Search...'
                value={search}
                onChange={event => setSearch(event.target.value as string)}
              />
            </FormControl>
          </span>
        </Grid>
      </Grid>
    </div>
  )
}

export default TableSelectorGroup
