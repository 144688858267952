import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { logout } from 'redux/thunks/authorisationThunk'
import {fetchDocumentsV2} from "../../../../../redux/thunks/documentsThunk";
import IndexedDBFunctions from "../../../../../IndexedDBFunctions";

export type dialogProps = {
  isOpen: boolean
  setOpen: (value: boolean) => void
  dropDown: boolean
  setDropDown: (value: boolean) => void
  pathValue: string
  setEnvironment?: (value: string) => void
}

export const WarningDialog = ({
  isOpen,
  setOpen,
  dropDown,
  setDropDown,
  pathValue,
  setEnvironment,
}: dialogProps) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const handleClose = () => {
    setDropDown(false)

    if (setEnvironment) {
      setEnvironment(
        location.pathname.includes('templateEnvironment')
          ? 'templateEnvironment'
          : 'projectEnvironment'
      )
    }
    setOpen(false)
  }

  const handleCloseandNavAway = () => {
    setOpen(false)

    if (dropDown) {
      history.push('/' + pathValue)

      if(pathValue.includes('templateEnvironment')){
        const fetchDocumentsThunk = fetchDocumentsV2()
        dispatch(fetchDocumentsThunk)
      }
    } else if (pathValue === '/') {
      history.push('/')
    } else if (pathValue === '/signin') {
      const logoutAction = logout()
      dispatch(logoutAction)
      history.push('/signin')
    } else {
      history.push('/templateEnvironment/?section=Documents')
      const fetchDocumentsThunk = fetchDocumentsV2()
      dispatch(fetchDocumentsThunk)
    }

    //localStorage.removeItem('currentDocumentEdit')
    IndexedDBFunctions.deleteDocuments()
    localStorage.removeItem('activeChapterIndex')
    localStorage.removeItem('ContentIsDirty')
    localStorage.removeItem('DocumentEdits')
    setDropDown(false)
  }

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>{'Unpublished Changes!'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to leave? You have unpublished changes.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          Cancel
        </Button>
        <Button onClick={handleCloseandNavAway} color='primary'>
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  )
}
