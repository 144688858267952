import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { ContentInfo } from 'shared/types/introduction';

const useStyles = makeStyles((theme: Theme) => ({
  paperContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.only('md')]: {
      /* maxWidth: '80%', */
    },
    [theme.breakpoints.down('sm')]: {
      /* maxWidth: '100%', */
    },
  },
  innerDiv: {
    maxWidth: '900px',
  },
  headingContent: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    textAlign: 'center',
  },
  bodyContent: {
    paddingBottom: theme.spacing(10),
    fontFamily: 'Open Sans',
    lineHeight: '2.5rem',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}));

const InformationField: React.FC<ContentInfo> = ({ content }) => {
  const classes = useStyles();

  return (
    <Grid xs={12} item>
      <Paper className={classes.paperContent} elevation={0} square>
        <div className={classes.innerDiv}>
          <Typography className={classes.headingContent} variant="h5">
            {content?.title}
          </Typography>
          <Typography className={classes.bodyContent} variant="body1">
            {content?.body}
          </Typography>
        </div>
      </Paper>
    </Grid>
  );
};

export default InformationField;
