import { cloneDeep } from 'lodash'
import { createChapterSection } from 'redux/thunks/documentsThunk'
import { Country } from 'shared/types/country'
import * as reduxDocumentType from 'shared/types/document'
import {
  ChapterObject,
  ChapterSection,
  DocumentChange,
} from 'shared/types/document'
import { v4 as uuidv4 } from 'uuid'

export function reIndexChapters(
  chapters: ChapterObject[],
  globalIndexCounter: number,
  treeIndexString: string
) {
  let counter = globalIndexCounter
  chapters = chapters.sort((a, b) => a.chapterOrder - b.chapterOrder)
  chapters.map((chapter, index) => {
    counter++
    let newTreeIndexString = treeIndexString + (index + 1).toString() + '.'
    chapter.treeIndexString = newTreeIndexString
    chapter.chapterOrder = index

    if (chapter.subchapters.length > 0) {
      counter = reIndexChapters(
        chapter.subchapters,
        counter,
        newTreeIndexString
      )
    }
  })

  return counter
}

export function findDFSBySharedId(objects: any, sharedId: string) {
  for (let [i, o] of objects.entries() || []) {
    console.log(i)

    if (o.sharedId == sharedId) {
      return o
    }

    if (o.subchapters.length > 0) {
      const o_ = findDFSBySharedId(o.subchapters, sharedId)

      if (o_) {
        return o_
      }
    }
  }
}

export function findDFSByTreeIndex(objects: any, treeIndex: number) {
  for (let [i, o] of objects.entries() || []) {
    console.log(i)

    if (o.treeIndex == treeIndex) {
      return o
    }

    if (o.subchapters.length > 0) {
      const o_ = findDFSByTreeIndex(o.subchapters, treeIndex)

      if (o_) {
        return o_
      }
    }
  }
}

export function findDFSByArrayAndID(objects: any, id: string) {
  for (let [i, o] of objects.entries() || []) {
    console.log(i)

    if (o.sharedId == id) {

      return o
    }

    if (o.subchapters.length > 0) {
      const o_ = findDFSByArrayAndID(o.subchapters, id)

      if (o_) {
        return o_
      }
    }
  }
}

export function findDFSByID(objects: any, id: string) {
  for (let [i, o] of objects.entries() || []) {
    console.log(i)

    if (o.id == id) {
      return o
    }

    if (o.subchapters.length > 0) {
      const o_ = findDFSByID(o.subchapters, id)

      if (o_) {
        return o_
      }
    }
  }
}

export function findDFSByTreeIndexString(
  objects: any,
  treeIndexString: string
) {
  for (let [i, o] of objects.entries() || []) {
    console.log(i)

    if (o.treeIndexString == treeIndexString) {
      return o
    }

    if (o.subchapters.length > 0) {
      const o_ = findDFSByTreeIndexString(o.subchapters, treeIndexString)

      if (o_) {
        return o_
      }
    }
  }
}

export function findChaptersBySharedId(objects: any, sharedId: string) {
  for (let [i, o] of objects.entries() || []) {
    console.log(i)

    if (o.sharedId == sharedId) {
      return objects
    }

    if (o.subchapters.length > 0) {
      const o_ = findChaptersBySharedId(o.subchapters, sharedId)

      if (o_) {
        return o_
      }
    }
  }
}

export function findDFSBySharedIdSyncAll(
  objects: ChapterObject[],
  templateDocumentChange: DocumentChange,
  countries: Country[],
  activeCountry: Country
) {
  objects.map((o) => {
    const templateChapter = findDFSBySharedId(
      templateDocumentChange.chapters,
      o.sharedId
    )

    if (templateChapter) {
      let projectChapter: ChapterObject = o
      updateExistingContentInChapterForManualSync(
        templateChapter.sections,
        projectChapter,
        templateChapter,
        0,
        countries,
        o,
        'Global Content',
        activeCountry
      )
      updateExistingContentInChapterForManualSync(
        templateChapter.sections,
        projectChapter,
        templateChapter,
        0,
        countries,
        o,
        'Country Content',
        activeCountry
      )
    }

    if (o.subchapters.length > 0) {
      return findDFSBySharedIdSyncAll(
        o.subchapters,
        templateDocumentChange,
        countries,
        activeCountry
      )
    }

    return o
  })
}

export function findDFSBySharedIdAndUpdateChapter(
  objects: ChapterObject[],
  sharedId: string,
  templateChapter: ChapterObject,
  projectChapter: ChapterObject,
  countries: Country[],
  selectedSyncType: string,
  activeCountry?: Country
) {
  objects.map((o) => {
    // console.log('FINDDFSUPDATE', o)
    // console.log('FINDDFSUPDATETREE', treeIndex)

    if (o.sharedId == sharedId) {
      updateExistingContentInChapterForManualSync(
        templateChapter.sections,
        projectChapter,
        templateChapter,
        0,
        countries,
        o,
        selectedSyncType,
        activeCountry
      )

      return
    }

    if (o.subchapters.length > 0) {
      return findDFSBySharedIdAndUpdateChapter(
        o.subchapters,
        sharedId,
        templateChapter,
        projectChapter,
        countries,
        selectedSyncType,
        activeCountry
      )
    }
  })
}

export function findDFSByTreeIndexAndUpdateChapterWasChanged(
  objects: ChapterObject[]
) {
  objects.map((o) => {
    if (o.subchapters && o.subchapters.length > 0) {
      findDFSByTreeIndexAndUpdateChapterWasChanged(o.subchapters)
    }
    o.wasChanged = false
  })
}

export const createChapterOrSubchapter = (
  chapterName: string,
  countries: Array<Country>,
  sections: Array<ChapterSection>,
  documentVersion: DocumentChange,
  parentChapter?: ChapterObject
) => {
  const newSections: Array<ChapterSection> = []

  if (sections && sections.length > 0)
    sections.map((section: any) => {
      newSections.push(
        createChapterSection(
          undefined,
          section.name,
          section.isGlobal,
          section.isClientEditable,
          {
            rawHTML: `This is Global content!`,
          },
          JSON.parse(JSON.stringify(countries))
        )
      )
    })

  if (parentChapter) {
    const newObject: ChapterObject = {
      id: Math.floor(Math.random() * Date.now()).toString(),
      refId: undefined,
      sharedId: uuidv4(),
      wasChanged: true,
      parentId: parentChapter.id,
      name: chapterName,
      subchapters: [],
      assignedProjectUsers: [],
      sections: newSections,
      treeIndex: documentVersion.globalTreeIndexCounter + 1,
      chapterOrder: parentChapter.subchapters.length + 1,
    }

    parentChapter.subchapters.push(newObject)

    return newObject
  }

  const newChapter: ChapterObject = {
    id: Math.floor(Math.random() * Date.now()).toString(),
    sharedId: uuidv4(),
    name: chapterName,
    subchapters: [],
    sections: newSections,
    assignedProjectUsers: [],
    treeIndex: documentVersion.globalTreeIndexCounter + 1,
    chapterOrder: documentVersion.chapters.length + 1,
    parentId: null,
    refId: undefined,
    wasChanged: true,
  }

  return newChapter
}

export function deleteDFS(
  objects: reduxDocumentType.ChapterObject[],
  id: string
) {
  let splicedObjects = true
  objects.map((o, i) => {
    if (o.id == id) {
      objects.splice(i, 1)
      splicedObjects = true
    }

    if (o.subchapters.length > 0) {
      const o_ = deleteDFS(o.subchapters, id)

      if (o_ !== undefined) {
        return o_
      }
    }
  })

  if (splicedObjects) {
    objects.forEach((splicedObject, index) => {
      splicedObject.chapterOrder = index + 1
    })
  }

  return objects
}

export function updateExistingContentInChapterForManualSync(
  sectionTemplates: ChapterSection[],
  originalDocChapter: ChapterObject,
  newDocChapter: ChapterObject,
  index: number,
  countries: Country[],
  o: ChapterObject,
  selectedSyncType: string,
  activeCountry?: Country
) {
  let createdSections: ChapterSection[] = []
  // console.log('SECTION TEMPLATES', sectionTemplates)
  sectionTemplates.map((sectionTemplate, sectionTemplateIndex) => {
    if (selectedSyncType === 'Global Content' && sectionTemplate.isGlobal) {
      updateSection(
        sectionTemplates,
        originalDocChapter,
        newDocChapter,
        index,
        countries,
        o,
        selectedSyncType,
        sectionTemplate,
        sectionTemplateIndex,
        createdSections,
        activeCountry
      )
    } else if (
      selectedSyncType === 'Country Content' &&
      !sectionTemplate.isGlobal &&
      !sectionTemplate.isClientEditable
    ) {
      updateSection(
        sectionTemplates,
        originalDocChapter,
        newDocChapter,
        index,
        countries,
        o,
        selectedSyncType,
        sectionTemplate,
        sectionTemplateIndex,
        createdSections,
        activeCountry
      )
    } else if (sectionTemplate.isClientEditable) {
      updateSection(
        sectionTemplates,
        originalDocChapter,
        newDocChapter,
        index,
        countries,
        o,
        selectedSyncType,
        sectionTemplate,
        sectionTemplateIndex,
        createdSections,
        activeCountry
      )
    } else {
      if (
        originalDocChapter.sections.find(
          (section) => section.name === sectionTemplate.name
        ) !== undefined
      ) {
        createdSections.push(
          originalDocChapter.sections.find(
            (section) => section.name === sectionTemplate.name
          )
        )
      } else {
        createdSections.push(sectionTemplate)
      }
    }
  })
  o.sections = createdSections
  // console.log('SECTIONS: ', o.sections)
  o.name = originalDocChapter.name
}

export function updateSection(
  sectionTemplates: ChapterSection[],
  originalDocChapter: ChapterObject,
  newDocChapter: ChapterObject,
  index: number,
  countries: Country[],
  o: ChapterObject,
  selectedSyncType: string,
  sectionTemplate: ChapterSection,
  sectionTemplateIndex: number,
  createdSections: ChapterSection[],
  activeCountry?: Country
) {
  // const originalChapterSection =
  let originalDocSectionsIndex = sectionTemplateIndex
  let hasChangedChapterName = originalDocChapter && originalDocChapter.name

  let newChapterName = hasChangedChapterName
    ? originalDocChapter.name
    : newDocChapter.name
  originalDocChapter.sections.map((originalSection, index) => {
    if (sectionTemplate.name === originalSection.name) {
      originalDocSectionsIndex = index
    }
  })
  let syncOriginalDocument =
    originalDocChapter.sections[originalDocSectionsIndex]
  let createdSection: ChapterSection

  if (syncOriginalDocument) {
    let templateGlobalContent = sectionTemplate.chapterGlobalContent
    let projectGlobalContent = syncOriginalDocument.chapterGlobalContent
    console.log('GLOBAL CONTENT TEMPLATE', templateGlobalContent)
    console.log('GLOBAL CONTENT PROJECT', projectGlobalContent)
    let countryContent = syncOriginalDocument.countrySpecificContent
    createdSection = createChapterSection(
      newChapterName,
      sectionTemplate.name,
      sectionTemplate.isGlobal,
      sectionTemplate.isClientEditable,
      projectGlobalContent,
      countries,
    )

    if (countryContent.length > 0) {
      // console.log('OVERWRITTING CREATED SECTION')
      // createdSection.countrySpecificContent = countryContent
      countryContent.map((countryCont) => {
        createdSection.countrySpecificContent.map(
          (countrySpecificCont, index) => {
            if (
              countryCont.country.country_name ===
              countrySpecificCont.country.country_name
            ) {
              if(selectedSyncType === 'Global Content' && syncOriginalDocument.isGlobal){
                if(countryCont.country.country_name === activeCountry.country_name &&
                countrySpecificCont.country.country_name === activeCountry.country_name) {
                  countryCont.globalContent = templateGlobalContent
                }
              }
              createdSection.countrySpecificContent[index] = countryCont
            }
          }
        )
      })
    }
    countries.map((country) => {
      let index = createdSection.countrySpecificContent.findIndex(
        (csc) => csc.country.country_name === country.country_name
          && country.country_name === activeCountry.country_name
      )

      if (index !== -1) {
        createdSection.countrySpecificContent[index].nonClientContent =
          sectionTemplate.countrySpecificContent.find(
            (stcsc) => stcsc.country.country_name === country.country_name
          ).nonClientContent
      }
    })
  } else {
    createdSection = createChapterSection(
      newDocChapter.name,
      sectionTemplate.name,
      sectionTemplate.isGlobal,
      sectionTemplate.isClientEditable,
      sectionTemplate.chapterGlobalContent,
      countries
    )
  }
  createdSections.push(createdSection)
}

export function copyDFSForProjectOnlySections(
  localStateDocument: DocumentChange,
  doc: DocumentChange
) {
  let newDocumentChapters: ChapterObject[]

  newDocumentChapters = cloneDeep(localStateDocument.chapters)
  deleteSectionsDFS(newDocumentChapters)
  //CLONE BUSINESS BLUEPRINT STRUCTURE OVER TO NEW DOCUMENT AND KEEP EXISTING DOCUMENT CONTENT
  const chaptersToCopy = cloneDeep(doc.chapters)
  const sectionsToCopy = cloneDeep(doc.sections)
  const countriesToCopy = cloneDeep(doc.countries)

  copyDFS(newDocumentChapters, chaptersToCopy, sectionsToCopy, countriesToCopy)

  doc.chapters = cloneDeep(newDocumentChapters)
}

// function isMasterBlueprintDocument(document: Document){
//   if(document.id === '0'){
//     return true
//   }

//     return false

// }

export function copyDFS(
  newDocChapters: ChapterObject[],
  originalDocChapters: ChapterObject[],
  sectionTemplates: ChapterSection[],
  countries: Country[]
) {
  newDocChapters.sort((a, b) => a.chapterOrder - b.chapterOrder)
  originalDocChapters.sort((a, b) => a.chapterOrder - b.chapterOrder)

  if (newDocChapters) {
    newDocChapters.map((newDocChapter: ChapterObject, index: number) => {
      //FOUND MY OBJECT
      //
      //

      let matchingOriginalChapter = originalDocChapters.find(
        (originalChapter) =>
          originalChapter.sharedId === newDocChapter.sharedId
      )

      if (matchingOriginalChapter.refId === undefined) {
        doCopy(
          matchingOriginalChapter,
          index,
          sectionTemplates,
          newDocChapter,
          countries
        )
      }

      if (newDocChapter.subchapters.length == 0) {
        return
      }

      if (
        newDocChapter.subchapters &&
        matchingOriginalChapter &&
        matchingOriginalChapter.subchapters
      ) {
        copyDFS(
          newDocChapter.subchapters,
          originalDocChapters[index].subchapters,
          sectionTemplates,
          countries
        )
      }
    })
  }
}

function deleteSectionsDFS(newDocChapters: ChapterObject[]) {
  if (newDocChapters) {
    newDocChapters.map((newDocChapter: ChapterObject) => {
      //FOUND MY OBJECT
      if (newDocChapter.subchapters.length == 0) {
        if (newDocChapter.refId === undefined) {
          newDocChapter.sections = []
        }

        return
      }

      if (newDocChapter.refId === undefined) {
        newDocChapter.sections = []
      }

      deleteSectionsDFS(newDocChapter.subchapters)
    })
  }
}

function doCopy(
  originalDocChapter: ChapterObject,
  index: number,
  sectionTemplates: ChapterSection[],
  newDocChapter: ChapterObject,
  countries: Country[]
) {
  let updatedSectionContent = originalDocChapter && originalDocChapter.sections

  //UPDATED SECTION
  if (originalDocChapter && updatedSectionContent) {
    updateProjectOnlyChapter(
      sectionTemplates,
      originalDocChapter,
      newDocChapter,
      index,
      countries
    )
  }
  //NEW SECTION CONTENT
  else {
    createNewContentFromTemplates(sectionTemplates, newDocChapter, countries)
  }
}

function createNewContentFromTemplates(
  sectionTemplates,
  newDocChapter,
  countries
) {
  let createdSections: ChapterSection[] = []
  sectionTemplates.map((sectionTemplate) => {
    let createdSection: ChapterSection
    createdSection = createChapterSection(
      newDocChapter.name,
      sectionTemplate.name,
      sectionTemplate.isGlobal,
      sectionTemplate.isClientEditable,
      sectionTemplate.chapterGlobalContent,
      countries
    )
    createdSections.push(createdSection)
  })
  newDocChapter.sections = createdSections
}

function updateProjectOnlyChapter(
  sectionTemplates: ChapterSection[],
  originalDocChapter: ChapterObject,
  newDocChapter: ChapterObject,
  index: number,
  countries: Country[]
) {
  let createdSections: ChapterSection[] = []
  sectionTemplates.map((sectionTemplate, sectionTemplateIndex) => {
    // const originalChapterSection =
    let originalDocSectionsIndex = sectionTemplateIndex
    let hasChangedChapterName = originalDocChapter && originalDocChapter.name

    console.log(originalDocChapter.name)
    console.log(newDocChapter.name)
    console.log(hasChangedChapterName)
    let newChapterName = hasChangedChapterName
      ? originalDocChapter.name
      : newDocChapter.name
    originalDocChapter.sections.map((originalSection, index) => {
      if (sectionTemplate.name === originalSection.name) {
        originalDocSectionsIndex = index
      }
    })
    let syncOriginalDocument =
      originalDocChapter.sections[originalDocSectionsIndex]
    let createdSection: ChapterSection

    if (syncOriginalDocument) {
      let globalContent = syncOriginalDocument.chapterGlobalContent
      let countryContent = syncOriginalDocument.countrySpecificContent
      // console.log(countries);
      createdSection = createChapterSection(
        newChapterName,
        sectionTemplate.name,
        sectionTemplate.isGlobal,
        sectionTemplate.isClientEditable,
        globalContent,
        countries
      )
      // console.log(createdSection);

      if (countryContent.length > 0) {
        // console.log('OVERWRITTING CREATED SECTION');
        // createdSection.countrySpecificContent = countryContent
        countryContent.map((countryCont) => {
          createdSection.countrySpecificContent.map(
            (countrySpecificCont, index) => {
              if (
                countryCont.country.country_name ===
                countrySpecificCont.country.country_name
              ) {
                createdSection.countrySpecificContent[index] = countryCont
              }
            }
          )
        })
      }
      // countries.map((country) => {
      //   let index = createdSection.countrySpecificContent.findIndex((csc) => csc.country.name === country.name)
      //   if(index !== -1) {
      //     createdSection.countrySpecificContent[index].nonClientContent = sectionTemplate.countrySpecificContent.find((stcsc) => stcsc.country.name === country.name).nonClientContent
      //   }
      // })
    } else {
      console.log(countries)
      createdSection = createChapterSection(
        newDocChapter.name,
        sectionTemplate.name,
        sectionTemplate.isGlobal,
        sectionTemplate.isClientEditable,
        sectionTemplate.chapterGlobalContent,
        countries
      )
    }
    createdSections.push(createdSection)
  })
  newDocChapter.sections = createdSections
  newDocChapter.name = originalDocChapter.name
}
