import { Divider } from '@material-ui/core'
import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import MaterialTable, { MTableCell, MTableToolbar } from 'material-table'
import React, { forwardRef, useContext } from 'react'
import { DocumentContext } from '../../DocumentEditor'
const tableIcons = {
  Add: forwardRef<SVGSVGElement>((props, ref) => <AddBox />),
  Check: forwardRef<SVGSVGElement>((props, ref) => <Check />),
  Clear: forwardRef<SVGSVGElement>((props, ref) => <Clear />),
  Delete: forwardRef<SVGSVGElement>((props, ref) => <DeleteOutline />),
  DetailPanel: forwardRef<SVGSVGElement>((props, ref) => <ChevronRight />),
  Edit: forwardRef<SVGSVGElement>((props, ref) => <Edit />),
  Export: forwardRef<SVGSVGElement>((props, ref) => <SaveAlt />),
  Filter: forwardRef<SVGSVGElement>((props, ref) => <FilterList />),
  FirstPage: forwardRef<SVGSVGElement>((props, ref) => <FirstPage />),
  LastPage: forwardRef<SVGSVGElement>((props, ref) => <LastPage />),
  NextPage: forwardRef<SVGSVGElement>((props, ref) => <ChevronRight />),
  PreviousPage: forwardRef<SVGSVGElement>((props, ref) => <ChevronLeft />),
  ResetSearch: forwardRef<SVGSVGElement>((props, ref) => <Clear />),
  Search: forwardRef<SVGSVGElement>((props, ref) => <Search />),
  SortArrow: forwardRef<SVGSVGElement>((props, ref) => <ArrowDownward />),
  ThirdStateCheck: forwardRef<SVGSVGElement>((props, ref) => <Remove />),
  ViewColumn: forwardRef<SVGSVGElement>((props, ref) => <ViewColumn />),
}

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     formControl: {
//       margin: theme.spacing(1),
//       minWidth: 120,
//       maxWidth: 700
//     },
//     chips: {
//       display: 'flex',
//       flexWrap: 'wrap'
//     },
//     chip: {
//       margin: 2
//     },
//     button: {
//       margin: theme.spacing(1)
//     }
//   })
// )

export default function AppTable({
  data,
  columns,
  tableHeading,
  setColumns,
  tableColumns,
  setTableColumns,
  ...rest
}) {
  // const classes = useStyles()
  const context = useContext(DocumentContext)
  const { selectedFilter } = context
  // const showHideColumn = (target: string[]) => {
  //   let tempColumns: MaterialColumnObject[] = cloneDeep(columns)
  //   let newTableColumns: MaterialColumnObject[] = []
  //   tempColumns.map(newColumn => {
  //     let newHiddenColumn = target.find(c => c === newColumn.title)

  //     if (newHiddenColumn !== undefined) {
  //       newColumn.hidden = false
  //     } else {
  //       newColumn.hidden = true
  //     }

  //     newTableColumns.push(newColumn)
  //   })
  //   setTableColumns(newTableColumns)
  // }

  // const [visibleTableColumns, setVisibleTableColumns] = React.useState<
  //   string[]
  // >([])

  // const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
  //   setVisibleTableColumns(event.target.value as string[])
  //   showHideColumn(event.target.value as string[])
  // }

  // const ITEM_HEIGHT = 48
  // const ITEM_PADDING_TOP = 8
  // const MenuProps = {
  //   PaperProps: {
  //     style: {
  //       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
  //       width: 250
  //     }
  //   }
  // }

  React.useEffect(() => {
    setTableColumns(columns)
  }, [columns])

  React.useEffect(() => {
    let visColumns = []
    tableColumns.map((tableColumn) => {
      if (!tableColumn.hidden) {
        visColumns.push(tableColumn.title)
      }
    })
    // setVisibleTableColumns(visColumns)
  }, [tableColumns])

  React.useEffect(() => {
    if (selectedFilter === null) {
      setTableColumns(columns)
    } else {
      setTableColumns(selectedFilter.columns)
    }
  }, [selectedFilter])

  return (
    <>
      <MaterialTable
        {...rest}
        icons={tableIcons}
        columns={tableColumns}
        data={data}
        title={tableHeading}
        options={{
          headerStyle: {
            backgroundColor: '#e0e0e0',
            color: '#000',
          },
          sorting: true,
          pageSize: 10,
          pageSizeOptions: [5, 10, 20, 30, 50, 100],
          showTitle: false,
        }}
        components={{
          Toolbar: (props) => (
            <div>
              <div
                style={{
                  padding: '0.5em 0.5em',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'right',
                  flexGrow: 1,
                }}
              >
                <MTableToolbar {...props} />
              </div>
              <Divider />
            </div>
          ),
          Cell: (props) => (
            <MTableCell
              style={{
                borderRightWidth: 1,
                borderRightColor: '#f1f1f1',
                borderRightStyle: 'solid',
              }}
              {...props}
            />
          ),
        }}
      />
    </>
  )
}
