import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProjectRole } from 'shared/types/project'
import type { RootState } from '../store'

type projectRoleState = {
    roles: ProjectRole[]
}

const initialState: projectRoleState = {
   roles: []
}

const projectRolesSlice = createSlice({
    name: 'projectRoles',
    initialState,
    reducers: {
                getRolesForProject:(state, action: PayloadAction<ProjectRole[]>) => {
            state.roles = action.payload
        },

    },
})

export const {
     getRolesForProject
} = projectRolesSlice.actions

export const getProjectRoles = (state: RootState) => state.authorisation.user

export default projectRolesSlice.reducer
