import { TableContainer } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import { makeStyles, Theme } from '@material-ui/core/styles'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setAddDocumentMessage,
  setAddDocumentStatus,
  setUpdateDocumentMessage,
  setUpdateDocumentStatus,
} from 'redux/slices/documents'
import { RootState } from 'redux/store'
import { fetchDocumentsV2 } from 'redux/thunks/documentsThunk'
import { Document } from 'shared/types/document'
import { Status } from 'shared/types/status'
import TablePaginationEPI from 'views/Pages/TemplateEnvironment/Home/TemplateHome/TablePaginationEPI'
import DocumentsTableBody from './DocumentsTableFilter/DocumentsTableBody'
import DocumentHeader from './DocumentsTableFilter/DocumentsTableHead'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'grid',
    gridTemplateRows: 'auto auto auto auto',
    width: '100%',
    height: 'auto',
  },
  titleContainer: {
    width: '100%',
    /* overflow: 'auto', */
  },
  bodyContainer: {
    width: '100%',
    paddingTop: theme.spacing(3),
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '0px !important',
      height: '0px !important',
      padding: '0px !important',
      visibility: 'collapse',
    },
  },
  bodyContainerMobile: {
    width: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  footerContainer: {
    width: '100%',
    paddingTop: theme.spacing(3),
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '0px !important',
      height: '0px !important',
      padding: '0px !important',
      visibility: 'collapse',
    },
  },
  paper: {
    padding: theme.spacing(3),
    height: 'auto',
  },
  paperBody: {
    padding: theme.spacing(3),
    height: '100%',
  },
  tableContainer: {
    /* maxHeight: '350px', */
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      minWidth: 700,
      overflowX: 'auto',
    },
    [theme.breakpoints.up('xl')]: {
      maxHeight: 'auto',
    },
  },
  tableContent: {
    [theme.breakpoints.down('sm')]: {
      visibility: 'collapse',
    },
    [theme.breakpoints.up('md')]: {
      visibility: 'visible',
    },
  },
  listContent: {
    [theme.breakpoints.between('sm', 'xl')]: {
      visibility: 'collapse',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      visibility: 'visible',
    },
  },
}))

type DesktopViewProp = {
  data: Array<Document> | undefined
  page: number
  rowsPerPage: number
  setPage: (num: number) => void
  setRowsPerPage: (num: number) => void
  totalRowsPerPage: number
}

const DesktopView: React.FC<DesktopViewProp> = (props) => {
  const classes = useStyles()

  return (
    <>
      <div /* className={classes.bodyContainer} */>
        <Paper className={classes.paperBody}>
          <div className={classes.tableContent}>
            <TableContainer className={classes.tableContainer}>
              {
                <DocumentsTableBody
                  data={props.data}
                  page={props.page}
                  rowsPerPage={props.rowsPerPage}
                />
              }
            </TableContainer>
          </div>
        </Paper>
      </div>
      <div className={classes.footerContainer}>
        <Paper className={classes.paper}>
          <TablePaginationEPI
            totalRows={props.data ? props.data.length : 0}
            page={props.page}
            setPage={props.setPage}
            rowsPerPage={props.rowsPerPage}
            setRowsPerPage={props.setRowsPerPage}
            totalRowsPerPage={props.totalRowsPerPage}
          />
        </Paper>
      </div>
    </>
  )
}

// type MobileViewProp = {
//   data: Array<User>;
// };

// const MobileView: React.FC<MobileViewProp> = ({ data }) => {
//   const classes = useStyles();

//   return (
//     <div className={classes.bodyContainerMobile}>
//       <Paper className={classes.paperBody}>
//         <div className={classes.listContent}>
//           {/* <EPIListView data={data} /> */}
//         </div>
//       </Paper>
//     </div>
//   );
// };

const DocumentManagement = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [Document, setDocument] = React.useState('')
  const [role, setRole] = React.useState('')
  const [search, setSearch] = React.useState('')
  const [page, setPage] = React.useState(0)
  const totalRowsPerPage = 10
  // const matches = useMediaQuery('(min-width:960px)');
  const rows = useSelector((state: RootState) => state.documents.Documents)
  const [rowsPerPage, setRowsPerPage] = React.useState(totalRowsPerPage)
  const [rowsData, setRowsData] = React.useState<Document[]>(rows)
  const [showDisabled, setShowDisabled] = useState(false)
  // const [screenWidth, setScreenWidth] = React.useState<boolean>(matches);
  // const errorMessage = useSelector(
  //   (state: RootState) => state.documents.updateDocumentResponseMessage
  // );
  const updateStatus = useSelector(
    (state: RootState) => state.documents.updateDocumentStatus
  )
  const addStatus = useSelector(
    (state: RootState) => state.documents.addDocumentStatus
  )

  const searchTable = () => {
    if (search !== '') {
      if (rows && rows.length > 0) {
        const filteredRows = rows.filter((row, key) => {
          if (
            row.name.toLowerCase().includes(search.toLowerCase()) ||
            row.author.toLowerCase().includes(search.toLowerCase()) ||
            row.lastUpdated.toLowerCase().includes(search.toLowerCase())
          )
            return row
        })

        setRowsData(filteredRows)
        setPage(0)
      }
    } else {
      setRowsData(rows)
      setPage(0)
    }
  }

  const filterByDisabled = () => {
    if (rows && rows.length > 0) {
      if (!showDisabled) {
        const filteredRows = rows.filter((row, key) => {
          if (row.enabled) {
            return row
          }
        })
        setRowsData(filteredRows)
        setPage(0)
      } else {
        setRowsData(rows)
        setPage(0)
      }
    }
  }

  React.useEffect(() => {
    if (updateStatus === Status.success) {
      // console.log("DOC MANAGEMENT UPDATESTATUS")
      const fetchDocumentsThunk = fetchDocumentsV2()
      dispatch(fetchDocumentsThunk)
      dispatch({ type: setUpdateDocumentStatus.type, payload: Status.idle })
      dispatch({ type: setUpdateDocumentMessage.type, payload: '' })
    }
  }, [updateStatus])

  React.useEffect(() => {
    if (addStatus === Status.success) {
      // console.log("DOC MANAGEMENT addStatus")
      const fetchDocumentsThunk = fetchDocumentsV2()
      dispatch(fetchDocumentsThunk)
      dispatch({ type: setAddDocumentStatus.type, payload: Status.idle })
      dispatch({ type: setAddDocumentMessage.type, payload: '' })
    }
  }, [addStatus])

  React.useEffect(() => {
    //
    searchTable()
  }, [search])

  React.useEffect(() => {
    setRowsData(rows)
    filterByDisabled()
  }, [rows])

  React.useEffect(() => {
    filterByDisabled()
  }, [showDisabled])

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <Paper className={classes.paper}>
          <DocumentHeader
            version={Document}
            setVersion={setDocument}
            role={role}
            setRole={setRole}
            search={search}
            setSearch={setSearch}
            showDisabled={showDisabled}
            setShowDisabled={setShowDisabled}
          />
        </Paper>
      </div>
      <div className={classes.bodyContainer}>
        <DesktopView
          data={rowsData}
          page={page}
          rowsPerPage={rowsPerPage}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          totalRowsPerPage={totalRowsPerPage}
        />
      </div>
    </div>
  )
}

export default DocumentManagement
