import { Button, Paper } from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'
import SyncIcon from '@material-ui/icons/Sync'
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setPublishDocsStatus,
  setUpdateProjectStatus
} from 'redux/slices/projects'
import { RootState } from 'redux/store'
import {
  fetchProjectsByUserV2,
  publishDocumentsToProjectsOnDB,
  editProjectOnDB
} from 'redux/thunks/projectsThunk'
import { DocumentContext } from 'shared/components/DocumentEditor/DocumentEditor'
import { CountrySelector } from 'shared/components/DocumentEditor/GlobalToolbar/CountrySelector/CountrySelector'
import { SyncDialog } from 'shared/components/DocumentEditor/GlobalToolbar/Dialogs/SyncDialog'
import { SnackType } from 'shared/components/Feedback/EPISnackBar'
import Loader from 'shared/components/Feedback/Loader'
import { Status } from 'shared/types/status'
import {
  ChapterObject,
  ChapterSection,
  Document, DocumentEdit,
  DocumentVersion
} from 'shared/types/document'

import { ChapterHeader } from '../EditorContainer/ChapterHeader/ChapterHeader'
import { WageTypeHeaders } from '../WageTypeCatalogueContainer/WageTypeHeaders/WageTypeHeaders'
import {
  fetchDocumentsV2,
  fetchSpecificDocumentV2
} from '../../../../redux/thunks/documentsThunk'
import { CountrySpecificContent, DocumentChange } from 'shared/types/document'
import { Project } from 'shared/types/project'
import {Country} from "../../../types/country";
import * as reduxDocumentType from "../../../types/document";
import {findDFSByArrayAndID} from "../treeUtils";
import {useIndexedDB} from "react-indexed-db";

export const GlobalToolbar: React.FC = () => {
  const context = useContext(DocumentContext)
  const dispatch = useDispatch()
  const {
    setContentIsDirty,
    activeDocument,
    mode,
    project,
    setDisplaySnack,
    setSnackMessage,
    setSnackSeverity,
    activeCountry,
    numberOfEdits
  } = context

  if(!project && mode != 'template'){
    return <div></div>
  }

  const reduxProjects = useSelector((state: RootState) => state.projects.projects)
  const reduxActiveProject = reduxProjects.length > 0 && project ? reduxProjects?.find((proj: Project) => {
    return proj.id === project.id
  }) : undefined
  let reduxDocs: Document[] = []

  if(reduxActiveProject) {
    reduxDocs = reduxActiveProject.documents
  } else {
    reduxDocs = useSelector((state: RootState) => state.documents.Documents)
  }

  const [busy, setBusy] = useState(false)
  const [reloadDocuments, setReloadDocuments] = useState(false)
  const [openSyncDialog, setOpenSyncDialog] = React.useState<boolean>(false)
  const [finalStep, setFinalStep] = React.useState<boolean>(false)
  const [selectedSyncType, setSelectedSyncType] = React.useState<string>(
    'Content'
  )
  const [cachedDocuments, setCachedDocuments] = React.useState(null)

  const replaceChapterContent = (chapter: ChapterObject) => {
    const activeCountryName = activeCountry.country_name
    const reduxDocuments = JSON.parse(JSON.stringify(reduxDocs))

    const reduxDocChapters = findChaptersForDoc(reduxDocuments)
    const chapterSections = chapter.sections
    chapterSections.forEach((section: ChapterSection) => {
      // For all active countries except the selected country
      const nonSelectedCountriesContent = section.countrySpecificContent.filter(
        (countryContent: CountrySpecificContent) => {
          return countryContent.country.country_name !== activeCountryName
        }
      )

      const activeReduxDocChapter = findDFSByArrayAndID(reduxDocChapters, chapter.sharedId)

      if (activeReduxDocChapter) {
        const activeReduxDocChapterSection = activeReduxDocChapter.sections.find(
          (reduxSection: ChapterSection) => {
            return reduxSection.name === section.name
          }
        )

        nonSelectedCountriesContent.forEach(
          (notSelectedCountryContent: CountrySpecificContent) => {
            const reduxCountryContent = activeReduxDocChapterSection.countrySpecificContent.find(
              (countryContent: CountrySpecificContent) => {
                return (
                  countryContent.country.country_name ===
                    notSelectedCountryContent.country.country_name &&
                  notSelectedCountryContent.country.active_status === true
                )
              }
            )

            if (reduxCountryContent) {
              Object.assign(notSelectedCountryContent, reduxCountryContent)
            }
          }
        )
      }
    })

    return chapter
  }

  const replaceActiveCountryChanges = (cachedDocChapters: ChapterObject[]) => {
    cachedDocChapters.forEach((chapter: ChapterObject) => {
      Object.assign(chapter, replaceChapterContent(chapter))
      replaceActiveCountryChanges(chapter.subchapters)
    })

    return cachedDocChapters
  }

  // const findLatestChangeOnDoc = (someDocuments: Document[]) => {
  //   const findActiveDoc: Document = someDocuments.find((doc: Document) => {
  //     return doc.id === activeDocument.id
  //   })
  //   const latestDocVersion: DocumentVersion =
  //     findActiveDoc.documentVersions[findActiveDoc.documentVersions.length - 1]
  //   const latestChange: DocumentChange =
  //     latestDocVersion.changes[latestDocVersion.changes.length - 1]
  //
  //   return latestChange
  // }

  const findChaptersForDoc = (someDocuments: Document[]) => {
    const findActiveDoc: Document = someDocuments.find((doc: Document) => {
      return doc.id === activeDocument.id
    })
    const latestDocVersion: DocumentVersion =
      findActiveDoc.documentVersions[findActiveDoc.documentVersions.length - 1]
    const latestChange: DocumentChange =
      latestDocVersion.changes[latestDocVersion.changes.length - 1]
    const documentChapters: ChapterObject[] = latestChange.chapters

    return documentChapters
  }

  const isActiveInCountry = (activeCountry: Country, chapter:  reduxDocumentType.ChapterObject) => {
    const section = chapter.sections[0]
    const country = section.countrySpecificContent.find(content => content.country.country_name === activeCountry.country_name)

    if(country === undefined){
      return true
    } else if(country.isChapterActiveInCountry) {
      return true
    }

    return false
  }

  const filterOutActiveChapters = (chapters: ChapterObject[], reduxDocChapters: ChapterObject[]) => {
    const filteredChapters = chapters.filter((chapter: ChapterObject) => {
      const foundChapterInRedux = reduxDocChapters.find((reduxChapter: ChapterObject) => reduxChapter.sharedId === chapter.sharedId)
      const isInActiveCountry = isActiveInCountry(activeCountry, chapter)

      return isInActiveCountry || (foundChapterInRedux !== undefined && foundChapterInRedux !== null)
    })

    filteredChapters.forEach((chapter: ChapterObject) => {
      let reduxChapter = reduxDocChapters.find((reduxChapter: ChapterObject) => {
        return reduxChapter.sharedId === chapter.sharedId
      })

      if(chapter.subchapters !== undefined && chapter.subchapters.length > 0 && reduxChapter) {
        chapter.subchapters = filterOutActiveChapters(chapter.subchapters, reduxChapter.subchapters)
      }
    })

    return filteredChapters
  }

  const getFinalDocumentsToPublish = (): Document[] => {
    const reduxDocChapters = findChaptersForDoc(reduxDocs)
    const findActiveDoc: Document = cachedDocuments.find((doc: Document) => {
      return doc.id === activeDocument.id
    })
    const latestDocVersion: DocumentVersion =
      findActiveDoc.documentVersions[findActiveDoc.documentVersions.length - 1]
    const latestChange: DocumentChange =
      latestDocVersion.changes[latestDocVersion.changes.length - 1]
    const cachedDocChapters: ChapterObject[] = latestChange.chapters

    let newCachedDocumentChapters = filterOutActiveChapters(cachedDocChapters, reduxDocChapters)
    latestChange.chapters = newCachedDocumentChapters
    latestChange.chapters = replaceActiveCountryChanges(newCachedDocumentChapters)
    let findInCachedDocuments = cachedDocuments.filter((document: Document) => {
      return document.id !== activeDocument.id
    })
    findInCachedDocuments.push(findActiveDoc)

    return cachedDocuments
  }

  const publish = async (): Promise<void> => {
    setBusy(true)

    if(localStorage.getItem('DocumentEdits')) {
      let currentDocumentEdit: DocumentEdit[] = JSON.parse(localStorage.getItem('DocumentEdits'))
      let findDoc = currentDocumentEdit.find(edit => edit.document === activeDocument.name)

      if(findDoc) {
        let actCountry = activeCountry.country_name ? activeCountry.country_name : 'Global'
        let findCountry = findDoc.countries.find((country) => country === actCountry)

        if(findCountry) {
          //let actCountry = activeCountry.country_name ? activeCountry.country_name : 'Global'
          let global = findDoc.countries.find((country) => country === 'Global')

          if(global) {
            findDoc.countries = findDoc.countries.filter((country) => country !== actCountry && country !== global)
          } else {
            findDoc.countries = findDoc.countries.filter((country) => country !== actCountry)
          }
          localStorage.setItem('DocumentEdits', JSON.stringify(currentDocumentEdit))
        }

        if(findDoc.countries.length < 1) {
          currentDocumentEdit = currentDocumentEdit.filter((doc) => doc.document !== activeDocument.name)
          localStorage.setItem('DocumentEdits', JSON.stringify(currentDocumentEdit))
        }

        if(currentDocumentEdit.length < 1) {
          localStorage.setItem('ContentIsDirty', 'false')
          localStorage.removeItem('DocumentEdits')
        }
      }
    }

    if (mode === 'template') {
      const publishThunk = publishDocumentsToProjectsOnDB(
        getFinalDocumentsToPublish()
      )
      dispatch(publishThunk)
      setContentIsDirty(false)
    } else {
      if (project) {
        const newProject: Project = JSON.parse(JSON.stringify(project))
        newProject.documents = getFinalDocumentsToPublish()
        const editThunk = editProjectOnDB(newProject)
        dispatch(editThunk)
        setContentIsDirty(false)
      }
    }
  }

  const publishDocsStatus = useSelector(
    (state: RootState) => state.projects.publishDocsStatus
  )

  const updateProjectStatus = useSelector(
    (state: RootState) => state.projects.updateProjectStatus
  )

  useEffect(() => {
    const {getAll} = useIndexedDB('Documents');

    getAll().then(docs => {
      if(docs[0]) {
        setCachedDocuments(docs[0].document);
      }
    });
  },[])

  useEffect(() => {
    const {getAll} = useIndexedDB('Documents');

    getAll().then(docs => {
      if(docs[0]) {
        setCachedDocuments(docs[0].document);
      }
    });
  },[numberOfEdits])

  useEffect(() => {
    if (publishDocsStatus === Status.success) {
      setSnackMessage('Changes published successfully')
      setSnackSeverity(SnackType.SnackSuccess as string)
      setDisplaySnack(true)

      setBusy(false)
      dispatch({ type: setPublishDocsStatus.type, payload: Status.idle })
      dispatch(fetchDocumentsV2())
    } else if (publishDocsStatus === Status.failed) {

      dispatch({ type: setPublishDocsStatus.type, payload: Status.idle })
      setBusy(false)
    }
  }, [publishDocsStatus])

  useEffect(() => {
    if (updateProjectStatus === Status.success) {
      setSnackMessage('Project published successfully')
      setSnackSeverity(SnackType.SnackSuccess as string)
      setDisplaySnack(true)
      setBusy(false)

      dispatch({ type: setUpdateProjectStatus.type, payload: Status.idle })
      let fetchProjThunk = fetchProjectsByUserV2(sessionStorage.getItem('id'))
      dispatch(fetchProjThunk)
    } else if (updateProjectStatus === Status.failed) {

      dispatch({ type: setUpdateProjectStatus.type, payload: Status.idle })
      setBusy(false)
    }
  }, [updateProjectStatus])

  return (
    <>
      {busy === true && <Loader open={true} />}

      <Paper
        style={{
          padding: '1em',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        {activeDocument.name !== 'Wage Type Catalogue' ? (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div>
                <CountrySelector />
              </div>
              <div style={{ marginLeft: '2em' }}>
                {/* <h3>{activeDocument.name}</h3> */}
                <ChapterHeader />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              {mode === 'project' && activeDocument.name !== undefined && (
                <Button
                  onClick={() => {
                    const fetchTemplateDocs = fetchSpecificDocumentV2(
                      activeDocument.refId
                    )
                    dispatch(fetchTemplateDocs)
                    setReloadDocuments(true)
                    setOpenSyncDialog(true)
                    setSelectedSyncType('All')
                    setFinalStep(true)
                  }}
                  style={{
                    marginLeft: '0.25em'
                  }}
                  variant='contained'
                  color='primary'
                  disabled={busy}
                >
                  <SyncIcon />
                  &nbsp;Sync All
                </Button>
              )}
              {activeDocument.name !== undefined && (
                <Button
                  onClick={async (): Promise<void> => {
                    //localStorage.removeItem('currentDocumentEdit')
                    await publish()
                  }}
                  style={{
                    marginLeft: '0.25em'
                  }}
                  variant='contained'
                  color='primary'
                  disabled={busy}
                >
                  <SaveIcon />
                  &nbsp;Publish Changes
                </Button>
              )}
            </div>
          </>
        ) : (
          <>
            <WageTypeHeaders setBusy={setBusy} />
          </>
        )}
      </Paper>
      {openSyncDialog && (
        <SyncDialog
          open={openSyncDialog}
          setOpen={setOpenSyncDialog}
          selectedSyncType={selectedSyncType}
          setSelectedSyncType={setSelectedSyncType}
          finalStep={finalStep}
          setFinalStep={setFinalStep}
          reloadDocuments={reloadDocuments}
          setReloadDocuments={setReloadDocuments}
        />
      )}
    </>
  )
}
