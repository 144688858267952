import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar'
import { Alert } from '@material-ui/lab'
import React from 'react'

export type State = {
  open: boolean
} & SnackbarOrigin

export enum SnackType {
  SnackSuccess = 'success',
  SnackError = 'error',
  SnackInfo = 'info',
  SnackWarning = 'warning',
}

// interface Prop {
//   message: string;
//   messageType: string;
//   isOpen: boolean;
//   setIsOpen: (open: boolean) => void;
// }

// const useStyles = makeStyles((theme: Theme) => ({
//   successSnack: {
//     '& >*': { backgroundColor: theme.palette.success.main },
//   },
//   errorSnack: {
//     '& >*': { backgroundColor: theme.palette.error.main },
//   },
//   infoSnack: {
//     '& >*': { backgroundColor: theme.palette.info.main },
//   },
// }))

const EPISnackBar: React.FC<{
  message: string
  messageType: string
  isOpen: boolean
  setIsOpen: (open: boolean) => void
}> = ({ message, messageType, isOpen, setIsOpen }) => {
  // const classes = useStyles()

  const [state] = React.useState<State>({
    open: isOpen,
    vertical: 'top',
    horizontal: 'center',
  })
  const { vertical, horizontal } = state

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setIsOpen(false)
  }

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={isOpen}
        onClose={handleClose}
        // message={message}
        key={vertical + horizontal}
        // className={clsx(
        //   messageType === (SnackType.SnackSuccess as string)
        //     ? classes.successSnack
        //     : messageType === (SnackType.SnackError as string)
        //     ? classes.errorSnack
        //     : classes.infoSnack
        // )}
        // autoHideDuration={5000}
      >
        <>
          {messageType === (SnackType.SnackSuccess as string) && (
            <Alert
              onClose={handleClose}
              severity='success'
              style={{ width: '100%' }}
              variant='filled'
              elevation={10}
            >
              {message}
            </Alert>
          )}
          {messageType === (SnackType.SnackError as string) && (
            <Alert
              onClose={handleClose}
              severity='error'
              style={{ width: '100%' }}
              variant='filled'
              elevation={10}
            >
              {message}
            </Alert>
          )}
          {messageType === (SnackType.SnackInfo as string) && (
            <Alert
              onClose={handleClose}
              severity='info'
              style={{ width: '100%' }}
              variant='filled'
              elevation={10}
            >
              {message}
            </Alert>
          )}
          {messageType === (SnackType.SnackWarning as string) && (
            <Alert
              onClose={handleClose}
              severity='warning'
              style={{ width: '100%' }}
              variant='filled'
              elevation={10}
            >
              {message}
            </Alert>
          )}
        </>
      </Snackbar>
    </div>
  )
}

export default EPISnackBar
