import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import React from 'react'
import { Role_Id, User } from 'shared/types/user'
import { formProp } from './EditUserDialogInfo'
import EditUserProjects from './EditUserProject/EditUserProjects'
import EditUserDialogInfo from './EditUserDialogInfo'
import EditUserAccessManagement from './EditUserAccessManagement'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0, 2, 0, 2)
  },
  EPIAppBarRoot: {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    padding: '0px !important',
    paddingRight: theme.spacing(1)
  },
  EPITabsRoot: {
    padding: '0px !important'

    /*         '& span.PrivateTabIndicator-root-75': {
            visibility: 'hidden !important',
        }, */
  },
  EPITab: {
    /* padding: '0px !important', */
  },

  EPITabBox: {
    padding: theme.spacing(3, 2, 0, 0)
  }
}))

type TabPanelProps = {
  children?: React.ReactNode
  index: any
  value: any
}
const TabPanel: React.FC<TabPanelProps> = props => {
  const { children, value, index, ...other } = props
  const classes = useStyles()

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ padding: 0 }}
    >
      {value === index && <Box className={classes.EPITabBox}>{children}</Box>}
    </div>
  )
}

function a11yProps (index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

type newFormProp = {
  roleInput: Role_Id
  setRoleInput: (role: Role_Id) => void
  user: User
} & formProp

const EditTabNavigation: React.FC<newFormProp> = props => {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const handleChange = (
    event: React.ChangeEvent<Record<string, never>>,
    newValue: number
  ) => {
    setValue(newValue)
  }

  return (
    <div className={classes.root}>
      <AppBar elevation={0} className={classes.EPIAppBarRoot} position='static'>
        <Tabs
          indicatorColor='primary'
          className={classes.EPITabsRoot}
          value={value}
          onChange={handleChange}
          aria-label='edit user tab'
        >
          <Tab
            className={classes.EPITab}
            label='Personal information'
            {...a11yProps(0)}
          />
          {/* <Tab className={classes.EPITab} label="Projects" {...a11yProps(1)} /> */}
          <Tab
            className={classes.EPITab}
            label='Access management'
            {...a11yProps(1)}
          />
          <Tab
            className={classes.EPITab}
            label='User Projects'
            {...a11yProps(2)}
          />
        </Tabs>
      </AppBar>
      <Divider />
      <TabPanel value={value} index={0}>
        <EditUserDialogInfo
          firstName={props.firstName}
          lastName={props.lastName}
          setFirstName={props.setFirstName}
          setLastName={props.setLastName}
          setEmailInput={props.setEmailInput}
          emailInput={props.emailInput}
          firstNameError={props.firstNameError}
          firstNameHelperText={props.firstNameHelperText}
          surnameError={props.surnameError}
          surnameHelperText={props.surnameHelperText}
          emailError={props.emailError}
          emailHelperText={props.emailHelperText}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <EditUserAccessManagement
          role={props.roleInput}
          setRole={props.setRoleInput}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <EditUserProjects user={props.user} />
      </TabPanel>
    </div>
  )
}

export default EditTabNavigation
