import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import DocumentManagement from 'views/Pages/TemplateEnvironment/Home/TemplateHome/Documents/DocumentManagement';
import ProjectManagement from 'views/Pages/TemplateEnvironment/Home/TemplateHome/Projects/ProjectManagement';
import UserManagement from 'views/Pages/TemplateEnvironment/Home/TemplateHome/Users/UserManagement';

export const TemplateHomeContent: React.FC = () => {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();
  const section = query.get('section');
  const history = useHistory();

  if (
    history.location.pathname.includes('/template') &&
    !history.location.pathname.includes('/document/')
  ) {
    return (
      <>
        {section === undefined || (section === null && <DocumentManagement />)}
        {section === 'Documents' && <DocumentManagement />}
        {section === 'Projects' && <ProjectManagement />}
        {section === 'Users' && <UserManagement />}
      </>
    );
  }

  return null;
};
