import Paper from '@material-ui/core/Paper'
import TableContainer from '@material-ui/core/TableContainer'
import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootState } from 'redux/store'
import { fetchProjects } from 'redux/thunks/projectsThunk'
import { DocumentContext } from 'shared/components/DocumentEditor/DocumentEditor'
import { ProjectRole, ProjectUserRole } from 'shared/types/project'
import { Status } from 'shared/types/status'
import { useStyles } from './styles'
import TableBodyEPI from './TableEPI'
import Head from './TableFilters/TableHeader'
import TablePaginationEPI from './TablePaginationEPI'
const Loader = React.lazy(() => import('shared/components/Feedback/Loader'))
// const EPISnackbar = React.lazy(
//   () => import('shared/components/Feedback/EPISnackBar')
// );

const Table: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const context = useContext(DocumentContext)
  const { project } = context

  const totalRowsPerPage = 10

  // const projects: Project[] = useSelector(getProjects);

  function getProjectId () {
    let p1 = history.location.pathname.replace(
      '/projectEnvironment/project/',
      ''
    )
    let p2 = p1.substring(0, p1.indexOf('/'))

    if (p2 === '') {
      if (p1 === '/projectEnvironment' || p1 === '/projectEnvironment/') {
        return undefined
      }

      return p1
    }

    return p2
  }

  function getUsersOfProject () {
    // let project = state.projects.projects.find((p) => p.id === pid && p);

    let users =
      project && project.users && project.users.length > 0 ? project.users : []

    return users as ProjectUserRole[]
  }

  const projectId = getProjectId()
  // const currentProject = useSelector((state: RootState) =>
  //   getProjectById(state, projectId)
  // );

  const usersOfProjectWithRoles: ProjectUserRole[] = getUsersOfProject()
  console.log(projectId)
  const projects = useSelector((state: RootState) => state.projects.projects)
  //const [usersOfProjectWithRoles, setUsersOfProjectWithRoles] = React.useState<ProjectUserRole[]>(currentProject && currentProject.users ? [...currentProject.users] : [])
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(totalRowsPerPage)
  const classes = useStyles()
  const [role, setRole] = React.useState('')
  const [search, setSearch] = React.useState('')
  const [rowsData, setRowsData] = React.useState<ProjectUserRole[]>(
    usersOfProjectWithRoles
  )
  // const [renderFlag, setRenderFlag] = React.useState(true);

  // const [invitationSuccess, setInvitationSuccess] = React.useState<Status>(
  //   Status.idle
  // );
  // const [editSuccess, setEditSuccess] = React.useState<Status>(Status.idle);
  const [openInvitationSuccess, setOpenInvitationSuccess] = React.useState(
    false
  )
  // const [openEditSuccess, setOpenEditSuccess] = React.useState(false);

  // const Message = useSelector(
  //   (state: RootState) => state.projects.updateProjectResponseMessage
  // );
  const theInvitationStatus = useSelector(
    (state: RootState) => state.projects.updateProjectStatus
  )
  const fetchingProjectsStatus = useSelector(
    (state: RootState) => state.projects.getProjectStatus
  )

  function updateRows () {
    setRowsData(usersOfProjectWithRoles)
  }
  React.useEffect(() => {
    if (projects.length === 0) {
      let fetchProjThunk = fetchProjects()
      dispatch(fetchProjThunk)
    }

    setRowsData(usersOfProjectWithRoles)
  }, [])

  React.useEffect(() => {
    setRowsData(usersOfProjectWithRoles)
  }, [usersOfProjectWithRoles])

  React.useEffect(() => {
    searchRoles()
  }, [role])

  React.useEffect(() => {
    searchTable()
  }, [search])

  // React.useEffect(() => {
  //   setRowsData(usersOfProjectWithRoles);

  //   if (theInvitationStatus === Status.success) {
  //     // setOpenInvitationSuccess(true)
  //   } else if (theInvitationStatus === Status.failed) {
  //     //
  //   }
  // }, [theInvitationStatus]);

  // React.useEffect(() => {
  //   //console.log(openInvitationSuccess)
  // }, [openInvitationSuccess]);

  function isInArray (arr: ProjectUserRole[], user: ProjectUserRole) {
    let isIn = false

    if (arr.length > 0) {
      arr.forEach(data => {
        if (data.user.id === user.user.id) {
          isIn = true

          return
        }
      })
    }

    return isIn
  }

  const searchRoles = () => {
    if (role !== '' && role !== 'AllRoles') {
      let res: ProjectUserRole[] = []

      if (usersOfProjectWithRoles && usersOfProjectWithRoles.length > 0) {
        usersOfProjectWithRoles.map((row, i) => {
          if (row.role && row.role.length > 0) {
            row.role.map(r => {
              if (r.role_name === role) {
                !isInArray(res, row) && res.push(row)
              }
            })
          }
        })
      }

      setRowsData(res)
    } else {
      setRowsData(usersOfProjectWithRoles)
    }
  }

  function isMatch (str1: string | undefined, str2: string) {
    if (str1 === undefined) str1 = ''
    str1 = str1.toLowerCase()
    str2 = str2.toLowerCase()

    if (str1.indexOf(str2) === -1) return false

    return true
  }

  function isRolesMatching (roles: ProjectRole[], str2: string) {
    let flag = false

    if (roles && roles.length > 0) {
      roles.map(r => {
        let str1 = r.role_name

        if (str1.indexOf(str2) !== -1) {
          flag = true
        }
      })
    }

    // console.log(flag);

    return flag
  }

  const searchTable = () => {
    if (search.length > 0) {
      // let res: ProjectUserRole[] = [];

      if (usersOfProjectWithRoles && usersOfProjectWithRoles.length > 0) {
        const fiteredRows = usersOfProjectWithRoles.filter((row, key) => {
          if (
            isMatch(row.user.firstname, search) ||
            isMatch(row.user.surname, search) ||
            isMatch(row.user.email, search) ||
            isRolesMatching(row.role, search)
          )
            return row
        })

        setRowsData(fiteredRows)
        setPage(0)
      }
    } else {
      setRowsData(usersOfProjectWithRoles)
      setPage(0)
    }
  }

  console.log(openInvitationSuccess)

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <Paper className={classes.paper}>
          <Head
            version={''}
            setVersion={(v: string) => {
              return
            }}
            role={role}
            setRole={setRole}
            search={search}
            setSearch={setSearch}
            updateRows={updateRows}
            setIsSuccess={setOpenInvitationSuccess}
          />
        </Paper>
      </div>
      <div className={classes.bodyContainer}>
        <Paper className={classes.paperBody}>
          <div className={classes.tableContent}>
            <TableContainer className={classes.tableContainer}>
              {rowsData && rowsData.length > 0 && (
                <TableBodyEPI
                  data={rowsData}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  updateRows={updateRows}
                  setIsSuccess={setOpenInvitationSuccess}
                />
              )}
            </TableContainer>
          </div>
        </Paper>
      </div>
      <div className={classes.footerContainer}>
        <Paper className={classes.paper}>
          <TablePaginationEPI
            totalRows={rowsData ? rowsData.length : 0}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            totalRowsPerPage={totalRowsPerPage}
          />
        </Paper>
      </div>
      <Loader open={theInvitationStatus === Status.loading} />
      <Loader open={fetchingProjectsStatus === Status.loading} />
      {/* { theInvitationStatus === Status.success && (
        <EPISnackbar
          message={'Succesful update'}
          messageType={'success'}
          isOpen={openInvitationSuccess}
          setIsOpen={setOpenInvitationSuccess}
        />
      )}
      {theInvitationStatus === Status.failed && (
        <EPISnackbar
          message={Message ? Message : 'Error occured'}
          messageType={'error'}
          isOpen={openInvitationSuccess}
          setIsOpen={setOpenInvitationSuccess}
        />
      )} */}
    </div>
  )
}

export default Table
