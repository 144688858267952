import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import RootReducer from './rootReducer';

const store = configureStore({
  reducer: RootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(thunkMiddleware).concat(logger),
});

export type RootState = ReturnType<typeof store.getState>;

export type EPIDispatch = typeof store.dispatch;

export default store;
