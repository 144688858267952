import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { setDeleteStatus } from 'redux/slices/usersSlice';
import { deleteUserOnDB } from 'redux/thunks/usersSliceThunk';
import { Status } from 'shared/types/status';
import { User } from 'shared/types/user';

export type DeleteUserConfirmProps = {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
  user: User;
};

const DeleteUserConfirmDialog = ({
  isOpen,
  setOpen,
  user,
}: DeleteUserConfirmProps) => {
  const dispatch = useDispatch();
  const handleCancel = () => {
    setOpen(false);
  };

  const handleOk = () => {
    if (user) {
      const deleteUserThunk = deleteUserOnDB(user);
      dispatch(deleteUserThunk);
    }
    dispatch({ type: setDeleteStatus, payload: Status.loading });
    handleCancel();
  };

  return (
    <div>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={isOpen}
      >
        <DialogTitle id="confirmation-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent dividers>
          <Typography
            style={{ textAlign: 'center', justifyContent: 'stretch' }}
            variant="body1"
            color="textPrimary"
          >
            This user will be deleted and removed from all Projects. <br/>
            Are you sure you want to delete this user? <br />
            (This action is permanent)
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleOk} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteUserConfirmDialog;
