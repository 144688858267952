import { Switch } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import Paper from '@material-ui/core/Paper'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import EditIcon from '@material-ui/icons/Edit'
import { Skeleton } from '@material-ui/lab'
import React, {ReactElement, useContext, useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { RootState } from 'redux/store'
import {editDocumentOnDB, fetchDocumentsV2} from 'redux/thunks/documentsThunk'
import { DocumentContext } from 'shared/components/DocumentEditor/DocumentEditor'
import Loader from 'shared/components/Feedback/Loader'
import { Country } from 'shared/types/country'
import { Document } from 'shared/types/document'
import { Status } from 'shared/types/status'
import ViewDocumentInfoDialog from '../Dialogs/EditDocumentDialog'

export type DocumentRow = {
  id: string | undefined
  name: string | undefined
  author: string
  lastUpdated: string
  enabled: boolean
  countries: ReactElement<any, any> | null
  actions: null
  manageVersions: null
  manageTemplates: null
}
export type HeadCell = {
  disablePadding: boolean
  id: keyof DocumentRow
  label: string
}

export type DocumentTableHeadProps = {
  headCells: Array<HeadCell>
}

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    overflow: 'auto',
  },

  chips: {
    '& > *': {
      marginRight: `${theme.spacing(0.5)}px !important`,
    },
  },

  buttonSpan: {
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(2),
    },
  },
}))

type CreateChipProp = {
  message: string
  outlined: boolean
}

const CreateChip: React.FC<CreateChipProp> = ({ message, outlined }) => {
  const classes = useStyles()

  return (
    <span className={classes.chips}>
      <Chip
        label={message}
        variant={outlined ? 'outlined' : 'default'}
        color='primary'
        size='small'
      />
    </span>
  )
}

const CreateCountriesList: React.FC<Country[] | undefined> = (countries) => {
  if (countries !== undefined && countries.length > 0) {
    return (
      <Paper
        elevation={0}
        style={{ paddingBottom: '15px', overflow: 'auto', maxWidth: '200px' }}
      >
        {countries.map((country, i) => {
          if (i == 2) {
            return <span style={{ paddingTop: '15px' }}></span>
          } else if (i < 2)
            return (
              <span>
                <CreateChip message={country.country_name} outlined={false} />
              </span>
            )
        })}
      </Paper>
    )
  }

  return (
    <p style={{ paddingBottom: '10px' }}>
      <CreateChip message='Global' outlined={false} />
    </p>
  )
}

// function createListOfUsers(roles: Role_Id[] | undefined) {
//   if (roles !== undefined && roles.length > 0) {
//     return (
//       <Paper
//         elevation={0}
//         style={{ paddingBottom: '15px', overflow: 'auto', maxWidth: '200px' }}
//       >
//         {roles.map((role, i) => {
//           if (i == 2) {
//             return <span style={{ paddingTop: '15px' }}></span>;
//           } else if (i < 2)
//             return (
//               <span>
//                 <CreateChip message={role.role_name} outlined={false} />
//               </span>
//             );
//         })}
//       </Paper>
//     );
//   }

//   return (
//     <p style={{ paddingBottom: '10px' }}>
//       <CreateChip message="None" outlined={false} />
//     </p>
//   );
// }

function createTableData(document: Document) {
  let docCountries: Country[] = []

  if (document.documentVersions && document.documentVersions.length > 0) {
    let latestDocumentVersion =
      document.documentVersions[document.documentVersions.length - 1]

    if (
      latestDocumentVersion.changes &&
      latestDocumentVersion.changes.length > 0
    ) {
      let latestDocumentChange =
        latestDocumentVersion.changes[latestDocumentVersion.changes.length - 1]
      docCountries = latestDocumentChange.countries
    }
  }

  const countriesList = CreateCountriesList(docCountries)

  const result: DocumentRow = {
    id: document.id,
    name: document.name,
    author: document.author,
    lastUpdated: document.lastUpdated,
    enabled: document.enabled,
    countries: countriesList,
    actions: null,
    manageVersions: null,
    manageTemplates: null,
  }

  return result
}

const headCells: HeadCell[] = [
  { id: 'name', disablePadding: false, label: 'Document Name' },
  { id: 'author', disablePadding: false, label: 'Author' },
  { id: 'lastUpdated', disablePadding: false, label: 'Last Updated' },
  { id: 'enabled', disablePadding: false, label: 'Active' },
  { id: 'manageVersions', disablePadding: false, label: 'Actions' },
]
const TableHeadings: React.FC<DocumentTableHeadProps> = ({
  headCells = [],
}) => {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          return (
            <TableCell
              key={headCell.id}
              align='left'
              padding={headCell.disablePadding ? 'none' : 'default'}
            >
              <Typography variant='body1' color='primary' component='h1'>
                {headCell.label}
              </Typography>
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

export type DocumentsTableProps = {
  data: Array<Document> | undefined
  page: number
  rowsPerPage: number
}

export type DocumentTableBodyProps = {
  isOpen: boolean
  setOpen: (value: boolean) => void
  setDocument: (value: Document | undefined) => void
} & DocumentsTableProps

const TableContent: React.FC<DocumentTableBodyProps> = ({
  data = [],
  page = 0,
  rowsPerPage = 0,
  setOpen = () => {
    return
  },
  setDocument = () => {
    return
  },
}) => {
  function useQuery() {
    return new URLSearchParams(useLocation().search)
  }

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage) // in case if last has not enough rows add extra rows
  // const classes = useStyles()
  // const dispatch = useDispatch();
  // const Documents: Document[] = useSelector(getDocuments);
  const history = useHistory()
  const dispatch = useDispatch()
  let query = useQuery()
  const section = query.get('section')
  const context = useContext(DocumentContext)
  const { activeCountryIndex, activeCountry } = context
  // function getDocumentInfo(id = '0') {
  //   let Document = Documents.find((p) => {
  //     return p.id === id && p;
  //   });

  //   Document !== undefined ? setDocument(Document) : setDocument(undefined);

  //   return null;
  // }
  const documents = useSelector((state: RootState) => state.documents.Documents)
  const getDocumentStatus = useSelector((state: RootState) => state.documents.getDocumentStatus)
  const [docId, setDocId] = useState<string>('')
  const [editContent, setEditContent] = useState<boolean>(false)
  const [navigate, setNavigate] = useState<boolean>(false)

  useEffect(() => {
    if(getDocumentStatus === Status.success && editContent){
      setNavigate(true)
    }
  }, [getDocumentStatus])

  useEffect(()=> {
    if (navigate && documents !== undefined && documents.find((document) => {
        return document.documentVersions.find(
          (version) => typeof version === 'string'
        )
      }) !== undefined
    ) {
      if (section === 'Home' || section === 'Countries') {
        if (activeCountryIndex === null) {
          history.push(`/templateEnvironment/document/${docId}?section=Content`)
        } else {
          history.push(
            `/templateEnvironment/document/${docId}/country/${activeCountry.country_name}?section=Content`
          )
        }
      } else {
        if (activeCountryIndex === null) {
          history.push(`/templateEnvironment/document/${docId}?section=Content`)
        } else {
          history.push(
            `/templateEnvironment/document/${docId}/country/${activeCountry.country_name}?section=Content`
          )
        }
      }
      setNavigate(false)
      setEditContent(false)
    }
  }, [documents, navigate])

  const openTemplateEditor = (id: any) => {
    dispatch(fetchDocumentsV2())
    setDocId(id)
    setEditContent(true)
  }

  const changeChecked = (id: string) => {
    const tempRows = [...rows]
    let rowIndex = tempRows.findIndex((row) => row.id === id)

    if (rowIndex > -1) {
      tempRows[rowIndex].enabled = !tempRows[rowIndex].enabled
    }

    let dataTemp = data.find((row) => row.id === id)
    let tempDoc = JSON.parse(JSON.stringify(dataTemp))

    if (dataTemp !== undefined) {
      // let tempDoc = JSON.parse(JSON.stringify(dataTemp))
      tempDoc.enabled = !tempDoc.enabled
    }

    const editDocumentThunk = editDocumentOnDB(tempDoc)
    dispatch(editDocumentThunk)
    setRows(tempRows)
  }

  const [rows, setRows] = React.useState(
    data.map((dt, key) => {
      return createTableData(dt)
    })
  )

  useEffect(() => {
    setRows(
      data.map((dt, key) => {
        return createTableData(dt)
      })
    )
  }, [data])
  // const [menuOpen, setMenuOpen] = React.useState(false);

  return (
    <TableBody
      style={{
        width: '100%',
        position: 'relative',
      }}
    >
      {rows.length === 0 && (
        <Skeleton
          style={{
            margin: 0,
            position: 'absolute',
          }}
          variant='rect'
          width={'100%'}
          height={'50vh'}
        />
      )}
      {rows &&
        rows.length > 0 &&
        rows
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row, index) => (
            <TableRow key={row.id}>
              <TableCell component='th' scope='row' align='left'>
                {row.name}
              </TableCell>
              <TableCell component='th' scope='row'>
                {row.author}
              </TableCell>
              <TableCell component='th' scope='row'>
                {row.lastUpdated}
              </TableCell>
              <TableCell>
                <Switch
                  checked={row.enabled}
                  onChange={() => {
                    changeChecked(row.id)
                  }}
                  disabled={editContent}
                  name={row.name}
                  color='primary'
                />
              </TableCell>
              <TableCell component='th' scope='row' align='left'>
                {/* <span className={classes.buttonSpan}></span> */}

                <Tooltip
                  title='Edit structure and content of templates'
                  placement='right-end'
                >
                  <Button
                    size='small'
                    style={{
                      color: '#001744',
                      padding: '1em',
                    }}
                    onClick={() => {
                      openTemplateEditor(row.id)
                    }}
                    disabled={editContent}
                  >
                    <EditIcon />
                    &nbsp;Edit Content
                  </Button>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
      {emptyRows > 0 && (
        <TableRow style={{ height: 53 * emptyRows }}>
          <TableCell colSpan={6} />
        </TableRow>
      )}
    </TableBody>
  )
}

const DocumentsTableBody: React.FC<DocumentsTableProps> = ({
  data,
  page,
  rowsPerPage,
}) => {
  const classes = useStyles()
  const [openViewDialog, setOpenViewDialog] = React.useState(false)
  const [Document, setDocument] =
    React.useState<Document | undefined>(undefined)

  const fetchStatus = useSelector(
    (state: RootState) => state.documents.getDocumentStatus
  )

  return (
    <>
      <Table aria-label='collapsible table' className={classes.table}>
        <TableHeadings headCells={headCells} />
        <TableContent
          data={data}
          page={page}
          rowsPerPage={rowsPerPage}
          isOpen={openViewDialog}
          setOpen={setOpenViewDialog}
          setDocument={setDocument}
        />
      </Table>
      <Loader open={fetchStatus === Status.loading} />
      {Document && (
        <ViewDocumentInfoDialog
          isOpen={openViewDialog}
          setOpen={setOpenViewDialog}
          document={Document}
        />
      )}
    </>
  )
}

export default DocumentsTableBody
