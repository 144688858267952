import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { featureData } from './CardData';
import HomeCard from './DisplayerCard';



const useStyles = makeStyles((theme: Theme) => ({
  root: {
    /*  display: 'flex',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(5, 2),
        },
        [theme.breakpoints.up('lg')]: {
             padding: theme.spacing(5, 18), 
        },
        justifyContent: 'space-around',*/
  },
  cardContainer: {
    /*  display: 'flex',
        padding: theme.spacing(3),
        '& > *': {
            [theme.breakpoints.down('md')]: {
                flexBasis: '100%',
            },
            flex: '1 1 50%', 
        },*/
    display: 'grid',
    width: '100%',
    padding: theme.spacing(5, 3, 5, 2),
    gridTemplateColumns: '450px 450px',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
    },
  },
}));

const HomeCards: React.FC<{ token: string | undefined | null }> = ({
  token,
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid
        alignItems="center"
        className={classes.cardContainer}
        justify="center"
        spacing={3}
        container
      >
        {featureData.map((data, index) => {
          return <HomeCard key={index} {...data} />;
        })}
      </Grid>
    </>
  );
};

export default HomeCards;
