import { Paper } from '@material-ui/core'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import { Skeleton } from '@material-ui/lab'
import React, {useContext} from 'react'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import { DocumentContext } from 'shared/components/DocumentEditor/DocumentEditor'
import IndexedDBFunctions from "../../../../IndexedDBFunctions";

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export const DocumentToolbar: React.FC = () => {
  const context = useContext(DocumentContext)
  const {
    documents,
    activeDocument,
    activeCountry,
    activeCountryIndex,
    project,
    activeCountriesOnProject,
    projectDocumentsLoaded,
    setNumberOfEdits
  } = context
  let query = useQuery()

  const section = query.get('section')

  /* const [ value, setValue ] = React.useState( ( docs[ 0 ].id as undefined ) as number ) */

  const history = useHistory()

  const displayTopDocumentBar = () => {
    if(history.location.pathname.includes('templateEnvironment')){
      return true
    } else if(activeCountriesOnProject) {
      return activeCountriesOnProject
    }

    return false
  }

  const getCountryName = () => {
    if(history.location.pathname.includes('projectEnvironment')) {
      if (activeCountry) {
        if (activeCountry.active_status) {
          return activeCountry.country_name
        }

        let firstActiveCountry = project.countries.find(country => country.active_status)

        if (firstActiveCountry) {
          return firstActiveCountry.country_name
        }
      }
    }

    return undefined
  }

  function getProjectId() {
    let p1 = history.location.pathname.replace(
      '/projectEnvironment/project/',
      ''
    )
    let p2 = p1.substring(0, p1.indexOf('/'))

    if (p2 === '') {
      if (p1 === '/projectEnvironment' || p1 === '/projectEnvironment/') {
        return undefined
      }

      return p1
    }

    return p2
  }

  React.useEffect(() => {
    //
    console.log(' ')
  }, [documents])

  React.useEffect(() => {
    //
    console.log(' ')
  }, [activeDocument])

  return (
    <Paper
      style={{
        paddingTop: '0.5em',
        paddingBottom: '0.5em',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {documents.length > 0 ? (
        displayTopDocumentBar() ? (<Tabs
          style={{
            flexGrow: 0,
          }}
          value={
            !history.location.pathname.includes('/document/')
              ? -1
              : activeDocument.id
          }
          indicatorColor='primary'
          textColor='primary'
          variant='scrollable'
          scrollButtons='on'
          aria-label='scrollable auto tabs example'
        >
          {documents
            .filter((document) => {
              if (!document.enabled) {
                return false
              }

              if (
                activeCountryIndex !== null &&
                document.documentVersions !== undefined &&
                document.documentVersions[0].changes !== undefined
              ) {
                let latestVersion =
                  document.documentVersions[
                    document.documentVersions.length - 1
                  ]

                let latestChange =
                  latestVersion.changes[latestVersion.changes.length - 1]

                if (activeCountry && activeCountry.country_name !== undefined &&
                  latestChange.countries.find(
                    (country) =>
                      country.country_name === activeCountry.country_name
                  ) !== undefined
                ) {
                  return true
                }

                return false
              }

              return true
            })
            .map((d) => {
              return (
                <Tab
                  onClick={() => {
                    // localStorage.setItem(
                    //   'currentDocumentEdit',
                    //   JSON.stringify(documents)
                    // )
                    IndexedDBFunctions.deleteDocuments()
                    IndexedDBFunctions.addDocuments(documents)
                    //This is just a work around as we encountered issues with Local Storage
                    setNumberOfEdits(Math.random() * 1000)

                    if (
                      history.location.pathname.includes('/projectEnvironment/')
                    ) {
                      if (section === 'Home' || section === 'Countries') {
                        history.push(
                          `/projectEnvironment/project/${getProjectId()}/document/${
                            d.id
                          }/country/${
                            getCountryName()
                          }?section=Content`
                        )
                      } else {
                        history.push(
                          `/projectEnvironment/project/${getProjectId()}/document/${
                            d.id
                          }/country/${
                            getCountryName()
                          }?section=Content`
                        )
                      }
                    } else {
                      if (section === 'Home' || section === 'Countries') {
                        if (activeCountryIndex === null) {
                          history.push(
                            `/templateEnvironment/document/${d.id}?section=Content`
                          )
                        } else {
                          history.push(
                            `/templateEnvironment/document/${d.id}/country/${activeCountry.country_name}?section=Content`
                          )
                        }
                      } else {
                        if (activeCountryIndex === null) {
                          history.push(
                            `/templateEnvironment/document/${d.id}?section=Content`
                          )
                        } else {
                          history.push(
                            `/templateEnvironment/document/${d.id}/country/${activeCountry.country_name}?section=Content`
                          )
                        }
                      }
                    }
                  }}
                  label={d.name}
                  value={d.id}
                  disabled={history.location.pathname.includes('/projectEnvironment/') ? !projectDocumentsLoaded : false}
                  style={{
                    backgroundColor:
                      history.location.pathname.includes('/document/') &&
                      d.id === activeDocument.id
                        ? '#001744'
                        : undefined,
                    color:
                      history.location.pathname.includes('/document/') &&
                      d.id === activeDocument.id
                        ? 'white'
                        : undefined,
                    borderRadius: 25,
                  }}
                />
              )
            })}
        </Tabs>)
          : (<div
            style={{
              width: '100%',
              padding: '1em',
              display: 'flex',
              flexDirection: 'column',
            }}>
            Please activate a Country (Manage Countries) to view available Documents.
          </div>)
      ) : (
        <div
          style={{
            width: '100%',
            padding: '1em',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Skeleton
            style={{
              margin: 0,
            }}
            variant='rect'
            width={'100%'}
            height={20}
          />
        </div>
      )}
    </Paper>
  )
}
