import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setAddProjectStatus } from 'redux/slices/projects'
import { RootState } from 'redux/store'
import { addProjectOnDB, fetchProjects } from 'redux/thunks/projectsThunk'
import Loader from 'shared/components/Feedback/Loader'
import { Country } from 'shared/types/country'
import { Project } from 'shared/types/project'
import { Status } from 'shared/types/status'
import { useStyles } from './StyleCreateProjectDialog'
export type dialogProps = {
  isOpen: boolean
  setOpen: (value: boolean) => void
  setIsNewProject: (b: boolean) => void
}

const CreateProjectDialog = ({
  isOpen,
  setOpen,
  setIsNewProject,
}: dialogProps) => {
  const classes = useStyles()
  const [projectName, setProjectName] = useState<string>('')
  const [author, setAuthor] = useState<string>('')
  const [projectNameError, setProjectNameError] = useState<boolean>(false)
  const [authorError, setAuthorError] = useState<boolean>(false)
  // const [isError, setIsError] = useState(false);
  // const [isSuccess, setIsSuccess] = useState(false);
  const dispatch = useDispatch()
  // const Message = useSelector(
  //   (state: RootState) => state.projects.addProjectResponseErrorMessage
  // );
  const creationStatus = useSelector(
    (state: RootState) => state.projects.addProjectStatus
  )
  const documents = useSelector((state: RootState) => state.documents.Documents)

  const handleClose = () => {
    setAuthor('')
    setProjectName('')
    setOpen(false)
  }

  useEffect(() => {
    if (creationStatus === Status.success) {
      // setIsSuccess(true);
      // setIsError(false);
      setOpen(false)
      setAuthor('')
      setProjectName('')
      dispatch({ type: setAddProjectStatus.type, payload: Status.idle })
      const fetchProjectsThunk = fetchProjects()
      dispatch(fetchProjectsThunk)
    } else if (creationStatus === Status.failed) {
      // setIsSuccess(false);
      // setIsError(true);
    }
  }, [creationStatus])

  const createProject = () => {
    let valid = true

    if (projectName === '') {
      setProjectNameError(true)
      valid = false

      return
    }
    setProjectNameError(false)

    if (author === '') {
      setAuthorError(true)
      valid = false

      return
    }
    setAuthorError(false)

    if (valid) {
      let allCountriesTemp: Country[] = []

      documents.forEach((document) => {
        let matchingTemplateDocVersion =
          document.documentVersions[document.documentVersions.length - 1]
        let matchingTemplateDocChange =
          matchingTemplateDocVersion.changes[
            matchingTemplateDocVersion.changes.length - 1
          ]
        let syncCountriesList = matchingTemplateDocChange.countries
        syncCountriesList.forEach((country) => {
          if (
            allCountriesTemp.findIndex(
              (cntry) => cntry.country_name === country.country_name
            ) === -1
          ) {
            let projectCountry: Country = JSON.parse(JSON.stringify(country))
            projectCountry.active_status = false
            allCountriesTemp.push(projectCountry)
          }
        })
      })

      const project: Project = {
        id: '',
        name: projectName,
        author,
        value: '',
        lastUpdated: new Date().toString(),
        countries: allCountriesTemp,
        users: [],
      }
      // dispatch({ type: setAddProjectStatus.type, payload: Status.loading })
      const addProjectThunk = addProjectOnDB(project)
      dispatch(addProjectThunk)

      setIsNewProject(true)
      setOpen(false)
    }
  }

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle className={classes.dialogTitle} id='form-dialog-title'>
          Create Project
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <form className={classes.form} noValidate onSubmit={createProject}>
            <Grid container>
              <Grid xs={12} item>
                <div className={classes.formFields}>
                  <TextField
                    autoComplete='projectName'
                    id='projectName'
                    label='Project Name'
                    margin='normal'
                    name='projectName'
                    variant='outlined'
                    fullWidth
                    required
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setProjectName(event.target.value)
                    }
                    error={projectNameError ? true : false}
                    helperText={
                      projectNameError
                        ? 'Please provide a name for the project'
                        : ''
                    }
                  />
                </div>
              </Grid>
              <Grid xs={12} item>
                <div className={classes.formFields}>
                  <TextField
                    autoComplete='author'
                    id='author'
                    label='Author'
                    margin='normal'
                    name='author'
                    variant='outlined'
                    fullWidth
                    required
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setAuthor(event.target.value)
                    }
                    error={authorError ? true : false}
                    helperText={
                      authorError
                        ? 'Please provide a name for the author of this project'
                        : ''
                    }
                  />
                </div>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
          <Button onClick={createProject} variant='contained' color='primary'>
            Create
          </Button>
        </DialogActions>
      </Dialog>
      <Loader open={creationStatus === Status.loading} />
      {/* {isSuccess && (
                <EPISnackbar
                    message={Message ? Message : 'Project was created'}
                    messageType={'success'}
                    isOpen={isSuccess}
                    setIsOpen={setIsSuccess}
                />
            )}
            {isError && (
                <EPISnackbar
                    message={Message ? Message : 'Unknown error. Project was not created'}
                    messageType={'error'}
                    isOpen={isError}
                    setIsOpen={setIsError}
                />
            )} */}
    </div>
  )
}

export default CreateProjectDialog
