import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import image from 'media/logo.svg';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 a100%',
  },
  mainDiv: {
    minHeight: '86vh',
    display: 'flex',
    padding: '2em',
  },
  paperContainer: {
    display: 'flex',
    padding: theme.spacing(9, 2, 9, 2),
    justifyContent: 'center',
  },
  paper: {
    dispaly: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: theme.spacing(3),
  },
  gridItemPaperContainer: {
    maxWidth: 600,
  },

  formLink: {
    paddingTop: theme.spacing(2),
  },
  link: {
    textDecoration: 'none !important',
    color: theme.palette.text.primary,
    fontSize: 18,
  },
}));

const RegistrationCompletion = () => {
  const classes = useStyles();

  return (
    <div className={classes.mainDiv}>
      <Grid
        alignItems="center"
        className={classes.gridContainer}
        justify="center"
        spacing={3}
        container
      >
        <Grid className={classes.gridItemPaperContainer} xs={10} item>
          <Paper className={classes.paperContainer}>
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <div className={classes.paper}>
                <div className={classes.logoContainer}>
                  <LogoImg alt="" src={image} />
                </div>
              </div>
              <Typography
                color="textSecondary"
                component="p"
                style={{ textAlign: 'center' }}
                variant="body1"
              >
                You have successfully completed the registration
              </Typography>
              <Typography
                color="textPrimary"
                component="h2"
                style={{ textAlign: 'center' }}
                variant="h5"
                className={classes.formLink}
              >
                <Link className={classes.link} to="/signin">
                  Sign in
                </Link>
              </Typography>
            </Container>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default RegistrationCompletion;

const LogoImg = styled.img`
  display: block;
  margin: 0 auto;
  margin-bottom: 2em;
`;
