import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { Skeleton } from '@material-ui/lab'
import CompareArrowsIcon from '@mui/icons-material/CompareArrows'
import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import { fetchChangeById, fetchVersions } from 'redux/thunks/documentsThunk'
import { DocumentContext } from 'shared/components/DocumentEditor/DocumentEditor'
import { CountrySelector } from 'shared/components/DocumentEditor/GlobalToolbar/CountrySelector/CountrySelector'
import Loader from 'shared/components/Feedback/Loader'
import { DocumentChange } from 'shared/types/document'
import { Status } from 'shared/types/status'
import ChangesDialog from 'views/Pages/TemplateEnvironment/Home/DocumentHome/Versions/Dialogs/ChangesDialog'

const TemplateChanges: React.FC = () => {
  const dispatch = useDispatch()
  const context = useContext(DocumentContext)
  const {
    activeDocument,
    activeDocumentVersion,
    activeCountryIndex,
    activeCountry,
  } = context

  const saveToNewVersionStatus = useSelector(
    (state: RootState) => state.projects.saveToNewVersionStatus
  )

  const stateDocVersions = useSelector(
    (state: RootState) => state.documents.DocumentVersions
  )

  const [copiedChanges, setCopiedChanges] = React.useState<DocumentChange[]>([])
  const [filterChanges, setFilteredChanges] =
    React.useState<DocumentChange[]>(copiedChanges)

  // let documentChanges = copiedChanges.reverse()
  console.log(copiedChanges)

  React.useEffect(() => {
    if (activeDocument) {
      const fetchVersionsThunk = fetchVersions(true, activeDocument.id)
      dispatch(fetchVersionsThunk)
    }
  }, [activeDocument])

  React.useEffect(() => {
    if (
      stateDocVersions[stateDocVersions.length - 1] !== undefined &&
      stateDocVersions[stateDocVersions.length - 1].changes.find(
        (change) => typeof change !== 'string'
      ) === undefined
    ) {
      stateDocVersions[stateDocVersions.length - 1].changes.map((change) => {
        const fetchChangesThunk = fetchChangeById(
          activeDocumentVersion.id,
          change as unknown as string
        )
        dispatch(fetchChangesThunk)
      })
    }

    if (
      stateDocVersions[stateDocVersions.length - 1] !== undefined &&
      stateDocVersions[stateDocVersions.length - 1].changes.find(
        (change) => typeof change === 'string'
      ) === undefined
    ) {
      if (
        stateDocVersions[stateDocVersions.length - 1].changes !== undefined &&
        stateDocVersions[stateDocVersions.length - 1].changes.length > 0
      ) {
        setCopiedChanges(
          JSON.parse(
            JSON.stringify(
              stateDocVersions[stateDocVersions.length - 1].changes
            )
          ).sort(
            (a, b) =>
              new Date(a.lastUpdated).getTime() -
              new Date(b.lastUpdated).getTime()
          )
        )
      }
    }
  }, [stateDocVersions])

  React.useEffect(() => {
    if (activeCountryIndex !== null) {
      setFilteredChanges(
        copiedChanges.filter((change) => {
          if (
            change.countries.find(
              (country) => country.country_name === activeCountry.country_name
            ) !== undefined
          ) {
            return true
          }

          return false
        })
      )
    } else {
      setFilteredChanges(copiedChanges)
    }
  }, [copiedChanges])

  const [oldChange, setOldChange] = React.useState({} as DocumentChange)
  const [currentChange, setCurrentChange] = React.useState({} as DocumentChange)
  const [dialogMode, setDialogMode] = React.useState('compare')
  const [open, setOpen] = React.useState(false)
  const [index, setIndex] = React.useState<number>(0)
  const handleClickOpen = (prevChange, curChange, index, mode = 'changes') => {
    setOldChange(prevChange)
    setCurrentChange(curChange)
    setDialogMode(mode)
    setIndex(index)
    setOpen(true)
  }

  const handleClose = (value) => {
    setOpen(false)
  }

  const getHR = () => (
    <div
      style={{
        width: '100%',
        height: '1px',
        border: 'none',
        opacity: 0.1,
        backgroundColor: '#001744',
      }}
    />
  )

  if (activeDocument.id !== undefined && filterChanges.length > 0) {
    // let templateChanges: DocumentChange[] = JSON.parse(
    //   JSON.stringify(copiedChanges)
    // );
    // let templateChange = templateChanges.find((change, index) => {
    //   return index === 0;
    // });
    // const who = templateChange.changedBy
    //   ? templateChange.changedBy.surname +
    //     ', ' +
    //     templateChange.changedBy.firstname
    //   : 'System';

    return (
      <div
        style={{
          padding: '1em',
          // padding: '1em',
          //  margin: '0 auto',
          width: '100%',
          // height: '85.5vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Paper
          style={{
            padding: '1em',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            overflow: 'auto',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div>
              <CountrySelector />
            </div>
            <div style={{ marginLeft: '2em' }}>
              {/* <h3>{activeDocument.name}</h3> */}
              <h3 style={{ marginLeft: '1em' }}>
                All Template Changes ({activeDocument.name})
              </h3>
            </div>
          </div>
          {/* <Button
            style={{ marginLeft: '5px' }}
            variant={'contained'}
            color={'primary'}
            onClick={() => {
              const saveToVersionThunk = saveToNewVersion(
                activeDocument.id,
                copiedChanges[copiedChanges.length - 1].id
              );
              dispatch(saveToVersionThunk);
            }}
          >
            Publish New Version
          </Button> */}
        </Paper>
        {getHR()}
        <br />
        <Paper
          style={{
            padding: '1em',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <br />
          <div style={{ overflow: 'auto' }}>
            <List dense>
              {filterChanges
                .map((change, index) => {
                  const labelId = `checkbox-list-secondary-label-${change.version}`

                  let datestring = change.lastUpdated
                  const who = change.changedBy
                    ? change.changedBy.surname +
                      ', ' +
                      change.changedBy.firstname
                    : 'System'

                  let prevChange = filterChanges[index - 1]

                  if (prevChange === undefined) {
                    prevChange = filterChanges[filterChanges.length - 1]
                  }

                  if (index === 0) {
                    return (
                      <ListItem button>
                        <ListItemText
                          primary={
                            <>
                              <b>
                                Initial Change
                                <br />
                              </b>
                              <div>
                                Author: <b>{who}</b>
                              </div>
                            </>
                          }
                          secondary={
                            <>
                              Created: <b>{datestring}</b>
                            </>
                          }
                        />
                      </ListItem>
                    )
                  }

                  return (
                    <ListItem key={index} button>
                      <ListItemText
                        id={labelId}
                        primary={
                          <>
                            <b>
                              {index === 0 ? 'Current ' : ''}Change {index}
                              <br />
                            </b>
                            <div>
                              Author: <b>{who}</b>
                            </div>
                          </>
                        }
                        secondary={
                          <>
                            Created: <b>{datestring}</b>
                          </>
                        }
                      />
                      <ListItemSecondaryAction>
                        <Button
                          variant={'contained'}
                          onClick={() => {
                            handleClickOpen(
                              prevChange,
                              change,
                              index,
                              'compare'
                            )
                          }}
                        >
                          <CompareArrowsIcon />
                          &nbsp;View Changes
                        </Button>
                        {/* <Button
                        variant={'contained'}
                        style={{ marginLeft: '5px' }}
                        onClick={() => {
                          handleClickOpen(doc, index, 'compare');
                        }}
                      >
                        Compare To Working Version
                      </Button>
                      <Button
                        variant={'contained'}
                        style={{ marginLeft: '5px' }}
                        onClick={() => {
                          handleClickOpen(doc, index, 'compare');
                        }}
                        color="primary"
                      >
                        <ExportIcon />
                        &nbsp;Export
                      </Button> */}
                      </ListItemSecondaryAction>
                    </ListItem>
                  )
                })
                .reverse()}
            </List>
          </div>
          <ChangesDialog
            prevChange={oldChange}
            currentChange={currentChange}
            index={index}
            open={open}
            onClose={handleClose}
            mode={dialogMode}
          />
        </Paper>
        {saveToNewVersionStatus === Status.loading && (
          <Loader open={saveToNewVersionStatus === Status.loading} />
        )}
      </div>
    )
  }

  return (
    <>
      <Loader open={true} />

      <div
        style={{
          width: '100%',
          height: '100%',
          padding: '1em',
        }}
      >
        <Skeleton
          style={{
            margin: 0,
          }}
          variant='rect'
          width={'100%'}
          height={'100%'}
        />
      </div>
    </>
  )
}

export default TemplateChanges
