export enum ProjectRoles {
    none = 'None',
    amsConsultant = 'AMS Consultant',
    clientABCApprover = 'Client ABC Approver',
    implementationConsultant = 'Implementation Consultant',
    leadConsultant = 'Lead Consultant',
    projectManager = 'Project Manager',
}
export enum Role {
    guest = 'Guest',
    administrator = 'Administrator',
}

