import { makeStyles, Theme } from '@material-ui/core/styles'
import React from 'react'
import InviteUserDialog from './InviteUserDialog'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0, 0, 0, 0)
  },
  EPIAppBarRoot: {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    padding: '0px !important',
    paddingRight: theme.spacing(1)
  },
  EPITabsRoot: {
    padding: '0px !important'

    /*         '& span.PrivateTabIndicator-root-75': {
                visibility: 'hidden !important',
            }, */
  },
  EPITab: {
    /* padding: '0px !important', */
  },

  EPITabBox: {
    padding: theme.spacing(0, 0, 0, 0)
  }
}))

// type TabPanelProps = {
//   children?: React.ReactNode;
//   index: any;
//   value: any;
// };
// const TabPanel: React.FC<TabPanelProps> = (props) => {
//   const { children, value, index, ...other } = props;
//   const classes = useStyles();

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//       style={{ padding: 0 }}
//     >
//       {value === index && <Box className={classes.EPITabBox}>{children}</Box>}
//     </div>
//   );
// };

// function a11yProps(index: any) {
//   return {
//     id: `simple-tab-${index}`,
//     'aria-controls': `simple-tabpanel-${index}`,
//   };
// }

const EditTabNavigation: React.FC<{
  handleClose: () => void
  updateRows: () => void
}> = ({ handleClose, updateRows }) => {
  const classes = useStyles()
  // const [value, setValue] = React.useState(0);

  // const handleChange = (
  //   event: React.ChangeEvent<Record<string, never>>,
  //   newValue: number
  // ) => {
  //   setValue(newValue);
  // };

  return (
    <div className={classes.root}>
      {/* <AppBar elevation={0} className={classes.EPIAppBarRoot} position="static">
        <Tabs
          indicatorColor="primary"
          className={classes.EPITabsRoot}
          value={value}
          onChange={handleChange}
          aria-label="edit user tab"
        >
          <Tab
            className={classes.EPITab}
            label="Invite to system"
            {...a11yProps(0)}
          />
          <Tab
            className={classes.EPITab}
            label="Assign to project"
            {...a11yProps(1)}
          />
        </Tabs>
      </AppBar>
      <Divider />
      <TabPanel value={value} index={0}> */}
      <InviteUserDialog handleClose={handleClose} />
      {/* </TabPanel> */}
      {/* <TabPanel value={value} index={1}>
                <EditUserProjects />
            </TabPanel> */}
      {/* <TabPanel value={value} index={1}>
        <InviteSystemUser handleClose={handleClose} updateRows={updateRows} />
      </TabPanel> */}
    </div>
  )
}

export default EditTabNavigation
