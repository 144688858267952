import { Skeleton } from '@material-ui/lab'
import React, { useContext } from 'react'
import { ChapterNavigation } from 'shared/components/DocumentEditor/ChapterNavigation/ChapterNavigation'
import { DocumentContext } from 'shared/components/DocumentEditor/DocumentEditor'
import { EditorContainer } from 'shared/components/DocumentEditor/EditorContainer/EditorContainer'
import { GlobalToolbar } from 'shared/components/DocumentEditor/GlobalToolbar/GlobalToolbar'
import Loader from 'shared/components/Feedback/Loader'

export const TemplateDocumentEditor: React.FC = () => {
  const context = useContext(DocumentContext)
  const { activeDocument } = context

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        width: '100%',
        height: '88vh',
        flexDirection: 'column',
        padding: '1em',
      }}
    >
      <div>
        <GlobalToolbar />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          width: '100%',
          height: '100%',
          marginTop: '1em',
        }}
      >
        {activeDocument.name === undefined && <Loader open={true} />}
        {activeDocument.name !== 'Wage Type Catalogue' && (
          <div
            style={{
              width: '15%',
              position: 'relative',
              overflowY: 'scroll',
              paddingBottom: '1em',
            }}
          >
            {activeDocument.name !== undefined ? (
              <ChapterNavigation />
            ) : (
              <div style={{ height: '100%' }}>
                <Skeleton
                  style={{
                    margin: 0,
                    position: 'absolute',
                    left: 0,
                  }}
                  variant='rect'
                  width={'100%'}
                  height={'100%'}
                />
              </div>
            )}
          </div>
        )}
        <div
          style={{
            position: 'relative',
            width:
              activeDocument.name !== 'Wage Type Catalogue' ? '85%' : '100%',
          }}
        >
          {activeDocument.name !== undefined ? (
            <EditorContainer />
          ) : (
            <div style={{ height: '100%' }}>
              <Skeleton
                style={{
                  margin: 0,
                  position: 'absolute',
                  left: 0,
                }}
                variant='rect'
                width={'100%'}
                height={'100%'}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
