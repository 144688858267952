import { ThemeProvider } from '@material-ui/core';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import './config';
import './index.css';
import store from './redux/store';
import { EPICENTREMuiTheme as theme } from './views/Styles/muiThemeStyle';

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </ThemeProvider>,

  document.getElementById('root')
);
