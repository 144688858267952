import { TableSortLabel } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import React, { ReactElement } from 'react'
import { ProjectRole, ProjectUserRole } from 'shared/types/project'
import ViewUserInfoDialog from './Dialogs/EditUserDialog/EditUserDialog'

export type UserRowData = {
  id?: string
  firstname: string
  surname: string
  email: string
  roles: ProjectRole[]
  /* role_name: string */
  views: string
}

export type UserRow = {
  id: string | undefined
  firstName: string | undefined
  lastName: string | undefined
  email: string | undefined
  roles: ReactElement<any, any> | null
  views: null
}
export type HeadCell = {
  disablePadding: boolean
  id: keyof UserRowData
  label: string
}

export type TableHeadEPIProps = {
  headCells: Array<HeadCell>
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof UserRowData
  ) => void
  order: Order
  orderBy: string
  rowCount: number
}

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    overflow: 'auto'
  },

  chips: {
    '& > *': {
      marginRight: `${theme.spacing(0.5)}px !important`
    }
  },

  buttonSpan: {
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(1)
    }
  },

  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
}))

function descendingComparator<T> (a: T, b: T, orderBy: keyof T) {
  if (Array.isArray(a[orderBy])) {
    // if (orderBy === 'roles') {
    //   let a1 = a[orderBy];
    //   let a2 = b[orderBy];
    // }
  }

  if (b[orderBy] < a[orderBy]) {
    return -1
  }

  if (b[orderBy] > a[orderBy]) {
    return 1
  }

  return 0
}

type Order = 'asc' | 'desc'

function getComparator<Key extends keyof any> (
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: string | any },
  b: { [key in Key]: string | any }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort<T> (array: T[], comparator: (a: T, b: T) => number | null) {
  if (comparator === null) return
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])

    if (order !== 0) return order

    return a[1] - b[1]
  })

  return stabilizedThis.map(el => el[0])
}

type CreateChipProp = {
  roles: Array<ProjectRole>
  outlined: boolean
}

const CreateChip: React.FC<CreateChipProp> = ({ roles, outlined }) => {
  return (
    <>
      {roles &&
        roles.length > 0 &&
        roles.map(r => (
          <span style={{ marginRight: `4px !important` }}>
            <Chip
              label={r.role_name}
              variant={r.role_name === 'None' ? 'default' : 'outlined'}
              color='primary'
              size='small'
            />
          </span>
        ))}
    </>
  )
}

// function createTableData(user: UserRowData) {
//   const result: UserRowData = {
//     id: user.id,
//     firstname: user.firstname,
//     surname: user.surname,
//     email: user.email,
//     roles: user.roles,
//     views: '',
//   };

//   return result;
// }

function mapProjectUserRoleToUserRowData (u: ProjectUserRole) {
  return {
    id: u.user.id,
    firstname: u.user.firstname,
    surname: u.user.surname,
    email: u.user.email,
    roles: u.role,
    views: ''
  } as UserRowData
}

const headCells: HeadCell[] = [
  { id: 'firstname', disablePadding: false, label: 'First name' },
  { id: 'surname', disablePadding: false, label: 'Last name' },
  { id: 'email', disablePadding: false, label: 'Email' },
  { id: 'roles', disablePadding: false, label: 'Roles' },
  { id: 'views', disablePadding: false, label: '' }
]
const TableHeadings: React.FC<TableHeadEPIProps> = (
  props: TableHeadEPIProps
) => {
  const { order, orderBy, onRequestSort } = props
  const createSortHandler = (property: keyof UserRowData) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property)
  }

  const classes = useStyles()

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => {
          return headCell.id !== 'views' ? (
            <TableCell
              key={headCell.id}
              align='left'
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                <Typography variant='body1' color='primary' component='h1'>
                  {headCell.label}
                </Typography>
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell
              key={headCell.id}
              align='right'
              padding={headCell.disablePadding ? 'none' : 'default'}
            >
              <Typography variant='body1' color='primary' component='h1'>
                {headCell.label}
              </Typography>
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

const TableContent: React.FC<{
  isOpen: boolean
  setOpen: (value: boolean) => void
  setUser: (value: UserRowData | undefined) => void
  order: Order
  orderBy: string
  user: UserRowData
  data: Array<UserRowData> | undefined
  page: number
  rowsPerPage: number
}> = ({
  data,
  page = 0,
  rowsPerPage = 0,
  setOpen = () => {
    return
  },
  setUser = () => {
    return
  },
  order,
  orderBy,
  user
}) => {
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage) // in case if last has not enough rows add extra rows
  const classes = useStyles()

  function getUserInfo (email: string) {
    let user1 = data.find(u => u.email === email && u)

    user1 !== null ? setUser(user1) : setUser(null)
  }

  return (
    <TableBody>
      {data &&
        data.length > 0 &&
        stableSort(data, getComparator(order, orderBy))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row: UserRowData, index) => (
            <TableRow key={row.id}>
              <TableCell component='th' scope='row' align='left'>
                {row.firstname}
              </TableCell>
              <TableCell component='th' scope='row'>
                {row.surname}
              </TableCell>
              <TableCell component='th' scope='row'>
                {row.email}
              </TableCell>
              <TableCell
                style={{ overflow: 'hidden' }}
                component='th'
                scope='row'
                sortDirection={false}
              >
                {CreateChip({ roles: row.roles, outlined: false })}
              </TableCell>
              {
                <TableCell component='th' scope='row' align='right'>
                  <span className={classes.buttonSpan}></span>
                  <Tooltip
                    title="view, edit user's roles on the project"
                    placement='right-end'
                  >
                    <Button
                      // style={{ color: "#004e44" }}
                      color='primary'
                      variant='outlined'
                      size='small'
                      onClick={() => {
                        getUserInfo(row.email)
                        setOpen(true)
                      }}
                    >
                      Edit Roles
                    </Button>
                  </Tooltip>
                </TableCell>
              }
            </TableRow>
          ))}
      {emptyRows > 0 && (
        <TableRow style={{ height: 53 * emptyRows }}>
          <TableCell colSpan={6} />
        </TableRow>
      )}
    </TableBody>
  )
}

const TableBodyEPI: React.FC<{
  data: Array<ProjectUserRole> | undefined
  page: number
  rowsPerPage: number
  updateRows: () => void
  setIsSuccess: (s: boolean) => void
}> = ({ data, page, rowsPerPage, updateRows, setIsSuccess }) => {
  const classes = useStyles()

  // const history = useHistory();
  // const projects = useSelector((state: RootState) => state.projects.projects);

  // const projectId = history.location.pathname.replace('/project/', '');

  // const project = useSelector((state: RootState) =>
  //   getProjectById(state, projectId)
  // );

  const [openViewDialog, setOpenViewDialog] = React.useState(false)
  const [user, setUser] = React.useState<UserRowData | null>(null)

  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof UserRowData>('firstname')

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof UserRowData
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  // React.useEffect(() => {
  //   //console.log("User changed")
  //   updateRows()
  // }, [user])

  // React.useEffect(() => {
  //   // console.log("Project change3")
  //   updateRows()
  // }, [project])

  const rows = data.map((dt, key) => {
    return mapProjectUserRoleToUserRowData(dt)
  })

  return (
    <>
      <Table aria-label='collapsible table' className={classes.table}>
        <TableHeadings
          headCells={headCells}
          onRequestSort={handleRequestSort}
          order={order}
          orderBy={orderBy}
          rowCount={data.length}
        />
        <TableContent
          data={rows}
          page={page}
          rowsPerPage={rowsPerPage}
          isOpen={openViewDialog}
          setOpen={setOpenViewDialog}
          setUser={setUser}
          order={order}
          orderBy={orderBy}
          user={user}
        />
      </Table>
      {user && (
        <ViewUserInfoDialog
          isOpen={openViewDialog}
          setOpen={setOpenViewDialog}
          userData={user}
          setUserData={setUser}
          updateRows={updateRows}
          // closeDialog={setClose}
          setIsSuccess={setIsSuccess}
        />
      )}
    </>
  )
}

export default TableBodyEPI
