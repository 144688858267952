import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import GlobalIcon from '@material-ui/icons/Language'
import { Skeleton } from '@material-ui/lab'
import * as lookup from 'country-code-lookup'
import React, { useContext, useEffect } from 'react'
import ReactCountryFlag from 'react-country-flag'
import { useHistory, useLocation } from 'react-router'
import { DocumentContext } from 'shared/components/DocumentEditor/DocumentEditor'
import { Country } from 'shared/types/country'
export const CountrySelector: React.FC = () => {
  function useQuery() {
    return new URLSearchParams(useLocation().search)
  }

  let query = useQuery()
  const section = query.get('section')

  const context = useContext(DocumentContext)
  const {
    activeCountries,
    project,
    activeDocument,
    setActiveCountryIndex,
    setActiveCountry,
    activeCountry,
    activeCountryIndex,
    activeDocumentChange,
  } = context
  const history = useHistory()

  const [tempActiveCountries, setTempActiveCountries] = React.useState<
    Country[]
  >(
    activeCountries
      ? activeCountries.filter((country) => country.active_status)
      : []
  )
  const [tempActiveCountryIndex, setTempActiveCountryIndex] =
    React.useState<number>(
      tempActiveCountries
        ? tempActiveCountries.findIndex(
            (country) => country.country_name === activeCountry.country_name
          ) !== -1
          ? tempActiveCountries.findIndex(
              (country) => country.country_name === activeCountry.country_name
            )
          : null
        : 0
    )

  const handleChange = (event: any) => {
    setActiveCountry(tempActiveCountries[event.target.value])
    let index =
      event.target.value !== null
        ? activeCountries.findIndex(
            (country) =>
              country.country_name ===
              tempActiveCountries[event.target.value].country_name
          )
        : null
    setTempActiveCountryIndex(event.target.value)
    setActiveCountryIndex(index)

    if (event.target.value !== null) {
      const countryName = tempActiveCountries[event.target.value].country_name

      if (history.location.pathname.includes('/projectEnvironment')) {
        if (history.location.pathname.includes('/document')) {
          history.push(
            `/projectEnvironment/project/${project.id}/document/${activeDocument.id}/country/${countryName}?section=${section}`
          )
        } else {
          history.push(
            `/projectEnvironment/project/${project.id}/country/${countryName}?section=${section}`
          )
        }
      } else {
        history.push(
          `/templateEnvironment/document/${activeDocument.id}/country/${countryName}?section=${section}`
        )
      }
    } else {
      if (history.location.pathname.includes('/projectEnvironment')) {
        history.push(
          `/projectEnvironment/project/${project.id}/document/${activeDocument.id}?section=${section}`
        )
      } else {
        history.push(
          `/templateEnvironment/document/${activeDocument.id}?section=${section}`
        )
      }
    }
  }

  useEffect(() => {
    if (activeCountries) {
      setTempActiveCountries(
        activeCountries.filter((country) => country.active_status)
      )
    }
  }, [activeCountry])

  useEffect(() => {
    if (activeCountries) {
      setTempActiveCountries(
        activeCountries.filter((country) => country.active_status)
      )

      if(activeCountry.country_name) {
        if (!activeCountry.active_status) {
          const firstActiveCountryIndex = activeCountries.findIndex((country) => country.active_status)
          let countryIndex = firstActiveCountryIndex === -1 ? 0 : firstActiveCountryIndex
          setActiveCountryIndex(countryIndex)

          const firstActiveCountry = activeCountries.find((country) => country.active_status)
          let country = firstActiveCountry === undefined ? project.countries[0] : firstActiveCountry
          setActiveCountry(country)
        }
      }
    }
  }, [activeCountry.active_status])

  useEffect(() => {
    if (activeCountries) {
      setTempActiveCountries(
        activeCountries.filter((country) => country.active_status)
      )
    }
  }, [activeCountries])

  useEffect(() => {
    setTempActiveCountryIndex(
      tempActiveCountries
        ? tempActiveCountries.findIndex(
            (country) => country.country_name === activeCountry.country_name
          ) !== -1
          ? tempActiveCountries.findIndex(
              (country) => country.country_name === activeCountry.country_name
            )
          : null
        : 0
    )
  }, [activeCountryIndex, tempActiveCountries])

  if (tempActiveCountries !== undefined && tempActiveCountries.length > 0) {
    return (
      <FormControl>
        <InputLabel shrink id='demo-simple-select-placeholder-label-label'>
          Country
        </InputLabel>
        <Select
          labelId='demo-simple-select-placeholder-label-label'
          id='demo-simple-select-placeholder-label'
          value={tempActiveCountryIndex}
          onChange={handleChange}
          displayEmpty
        >
          {history.location.pathname.includes('templateEnvironment') ===
            true && (
            <MenuItem key={'none'} value={null}>
              <GlobalIcon
                style={{
                  width: 16,
                  verticalAlign: 'middle',
                }}
              />
              &nbsp;Global
            </MenuItem>
          )}

          {tempActiveCountries.sort((countryA, countryB) =>
            (countryA.country_name < countryB.country_name ? -1 : 1)
          ).map((country: Country, index) => {
            const countryCode = lookup.byCountry(country.country_name).iso2
            let disabled = false

            if (
              history.location.pathname.includes('/projectEnvironment') &&
              history.location.pathname.includes('/document') &&
              activeDocumentChange &&
              activeDocumentChange.countries &&
              activeDocumentChange.countries.find(
                (changeCountry) =>
                  changeCountry.country_name === country.country_name
              ) === undefined
            ) {
              disabled = true
            }

            return (
              <MenuItem
                key={country.id}
                value={index}
                disabled={!country.active_status || disabled}
              >
                <ReactCountryFlag countryCode={countryCode} svg />
                &nbsp;
                {country.country_name}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    )
  }

  return (
    <div
      style={{
        width: '100%',
        padding: '1em',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Skeleton
        style={{
          margin: 0,
        }}
        variant='rect'
        width={200}
        height={50}
      />
    </div>
  )
}
