import React, { useContext } from 'react'
import { DocumentContext } from 'shared/components/DocumentEditor/DocumentEditor'
import DocumentSection from 'shared/components/DocumentEditor/EditorContainer/DocumentSections/DocumentSection/DocumentSection'
import { ChapterSection } from 'shared/types/document'

// interface ChapterNavigationType {
//   children: React.ReactNode
//   menuOpen: boolean
//   setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
//   tooltip: string
// }

export const DocumentSections: React.FC = () => {
  const context = useContext(DocumentContext)
  const { activeChapter, activeDocument, activeCountryIndex, activeCountry } = context

  if (activeChapter !== undefined) {
    return (
      <div
        style={{
          paddingLeft: '3em',
          paddingRight: '3em',
          paddingBottom: '3em',
        }}
      >
        {activeChapter.sections &&
          activeDocument.name !== 'Wage Type Catalogue' &&
          activeChapter.sections
            .filter((section) => {
              const isGlobal = section.countrySpecificContent.find(
                  (countryContent) => {
                    return countryContent.isChapterActiveInCountry === false
                  }
              ) === undefined
              const viewingGlobal = activeCountry.country_name === undefined

              if(!viewingGlobal && !isGlobal && section.isGlobal) {
                return false
              }

              return true
            })

            .filter((section) => {
              if (activeCountryIndex === null) {
                return section.isGlobal
              }

              return true
            })
            .map((chapterSection: ChapterSection) => {
              return <DocumentSection chapterSection={chapterSection} />
            })}
      </div>
    )
  }

  return null
}
