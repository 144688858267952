import { makeStyles, Theme } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import React from 'react';

type TablePaginationProps = {
  totalRows: number;
  page: number;
  setPage: (page: number) => void;
  rowsPerPage: number;
  setRowsPerPage: (page: number) => void;
  totalRowsPerPage: number;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    height: 'auto',
    justifyContent: 'center',
  },

  tablePg: {
    '&  p:last-of-type': {
      paddingRight: theme.spacing(0),
      paddingLeft: theme.spacing(2),
    },
  },
}));

const TablePaginationEPI: React.FC<TablePaginationProps> = ({
  totalRows,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  totalRowsPerPage,
}) => {
  const classes = useStyles();

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, totalRowsPerPage));
    setPage(0);
  };

  return (
    <div className={classes.root}>
      <TablePagination
        className={classes.tablePg}
        rowsPerPageOptions={[10]}
        component="div"
        count={totalRows}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default TablePaginationEPI;
