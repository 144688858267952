import { Chip, createStyles, makeStyles, Select } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import CountryRegionData from 'country-region-data';
import React, { ChangeEvent } from 'react';
import { Country } from 'shared/types/country';
import { Document } from 'shared/types/document';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      listStyle: 'none',
      '& > *': {
        margin: theme.spacing(0.5),
      },
      padding: theme.spacing(1, 1),
    },
    list: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      listStyle: 'none',
      '& > *': {
        margin: theme.spacing(0.5),
      },
      padding: theme.spacing(1, 1),
    },
    chip: {
      margin: theme.spacing(0.5),
      padding: theme.spacing(1, 2, 1, 2),
    },
  })
);

export type countryProp = {
  Document: Document | undefined;
  countries: Country[];
  setCountries: (countries: Country[]) => void;
};

const DocumentCountrySelector = ({
  Document,
  countries,
  setCountries,
}: countryProp) => {
  const classes = useStyles();
  // const [countries, setcountries] = React.useState<Country[]>(DocumentCountryList)
  const [lastSelected, setLastSelected] = React.useState<string>('');

  const addCountryToDocument = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    let countryNew = CountryRegionData.find(
      (c) => c.countryName === event.target.value
    );
    let newcountries = [...countries];
    newcountries.push({
      country_name: countryNew?.countryName ? countryNew.countryName : '',
      id: Date.now().toString(),
      active_status: true,
    });
    setCountries(newcountries);
    setLastSelected(event.target.value as string);
  };

  const handleDelete = (countryToDelete: string) => () => {
    let newCountries = countries.filter(
      (country) => country.country_name !== countryToDelete
    );
    setCountries(newCountries);
  };

  return (
    <div>
      <div className={classes.root}>
        <Select native value={lastSelected} onChange={addCountryToDocument}>
          <option value="" disabled>
            Select Country
          </option>
          {CountryRegionData.map((country) => (
            <option value={country.countryName}>{country.countryName}</option>
          ))}
        </Select>
      </div>
      <div className={classes.list}>
        {countries.map((country) => {
          return (
            <li key={country.country_name}>
              <Chip
                label={country.country_name}
                onDelete={handleDelete(country.country_name)}
              />
            </li>
          );
        })}
      </div>
    </div>
  );
};

export default DocumentCountrySelector;
