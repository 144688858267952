import {
  FormControl,
  makeStyles,
  MenuItem,
  Select,
  Theme,
} from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Toolbar from '@material-ui/core/Toolbar'
import logo from 'media/logo.svg'
import React, { useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import ProfileMenuItem from 'shared/components/ProfileMenuItem'
import styled from 'styled-components'
import { WarningDialog } from '../../../views/Pages/TemplateEnvironment/Home/SideNav/WarningDialog'
import {fetchDocumentsV2} from "../../../redux/thunks/documentsThunk";
import {useDispatch} from "react-redux";

const useStyles = makeStyles((theme: Theme) => ({
  select: {
    '&:before': {
      borderColor: 'white',
    },
    '&:after': {
      borderColor: 'white',
    },
    '&:not(.Mui-disabled):hover::before': {
      borderColor: 'white',
    },
  },
  icon: {
    fill: 'white',
  },
  root: {
    color: 'white',
  },
}))

export type dialogProps = {
  environment: string
  setEnvironment: (value: string) => void
  target: string
  setTarget: (value: string) => void
}

const MainNav: React.FC = () => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  // const userToken = useToken()
  const [backgroundColor, setBackgroundColor] = React.useState('white')
  const [color, setColor] = React.useState('111B43')
  const [role, setRole] = React.useState(localStorage.getItem('role_name'))
  const [isWarningDialogOpen, setIsWarningDialogOpen] = useState<boolean>(false)
  const [dropdown, setDropdown] = useState<boolean>(false)

  const [environment, setEnvironment] = React.useState(
    location.pathname.includes('templateEnvironment')
      ? 'templateEnvironment'
      : location.pathname.includes('templateEnvironment')
      ? 'projectEnvironment'
      : ''
  )
  const [target, setTarget] = React.useState(
    location.pathname.includes('templateEnvironment')
      ? 'projectEnvironment'
      : location.pathname.includes('templateEnvironment')
      ? 'templateEnvironment'
      : ''
  )

  const getBackgroundColor = () => {
    if (location.pathname.includes('projectEnvironment')) {
      return '#111B43'
    } else if (location.pathname.includes('templateEnvironment')) {
      return '#212121'
    }

    return 'white'
  }

  const getButtonColor = () => {
    if (
      location.pathname.includes('projectEnvironment') ||
      location.pathname.includes('templateEnvironment')
    ) {
      return 'white'
    }

    return '#111B43'
  }

  const handleLinkClick = (event) => {
    event.preventDefault()

    if (localStorage.getItem('ContentIsDirty')) {
      if (JSON.parse(localStorage.getItem('ContentIsDirty'))) {
        setEnvironment('/')
        setTarget('/')
        setIsWarningDialogOpen(true)
      } else {
        history.push('/')
      }
    } else {
      setEnvironment('/')
      history.push('/')
    }
  }

  React.useEffect(() => {
    setRole(localStorage.getItem('role_name'))
  }, [localStorage.getItem('role_name')])
  React.useEffect(() => {
    setRole(localStorage.getItem('role_name'))
    setBackgroundColor(getBackgroundColor())
    setColor(getButtonColor())
    setEnvironment(
      location.pathname.includes('templateEnvironment')
        ? 'templateEnvironment'
        : location.pathname.includes('projectEnvironment')
        ? 'projectEnvironment'
        : ''
    )
    setTarget(
      location.pathname.includes('templateEnvironment')
        ? 'projectEnvironment'
        : location.pathname.includes('projectEnvironment')
        ? 'templateEnvironment'
        : ''
    )
  }, [location.pathname])

  const HomeNavItems = () => {
    return (
      <>
        {!location.pathname.includes('projectEnvironment') &&
          !location.pathname.includes('templateEnvironment') && (
            <>
              {(sessionStorage.getItem('token') ||
                localStorage.getItem('token')) && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {role === 'Administrator' && (
                    <Link to='/templateEnvironment'>
                      <Button
                        onClick={() => setEnvironment('templateEnvironment')}
                        style={{
                          color,
                        }}
                      >
                        Template Environment
                      </Button>
                      <span style={{ paddingRight: '16px' }} />
                    </Link>
                  )}
                  <Link to='/projectEnvironment'>
                    <Button
                      onClick={() => setEnvironment('projectEnvironment')}
                      style={{
                        color,
                      }}
                    >
                      Project Environment
                    </Button>
                    <span style={{ paddingRight: '16px' }} />
                  </Link>

                  <ProfileMenuItem color={color} isMain={true} />
                </div>
              )}
            </>
          )}
      </>
    )
  }

  const SignIn = () => {
    return (
      <>
        {!sessionStorage.getItem('token') && !localStorage.getItem('token') && (
          <div>
            <Link to='/signin' style={{}}>
              <Button
                style={{
                  color,
                }}
              >
                Sign in
              </Button>
            </Link>
          </div>
        )}
      </>
    )
  }

  const EnvironmentNavItems = ({
    environment,
    setEnvironment,
    target,
    setTarget,
  }: dialogProps) => {
    const history = useHistory()

    React.useEffect(() => {
      if (
        location.pathname.includes('projectEnvironment') ||
        location.pathname.includes('templateEnvironment')
      ) {
        if (isWarningDialogOpen && environment !== '/') {
          setIsWarningDialogOpen(false)
        }

        if (
          !location.pathname.includes(environment) &&
          localStorage.getItem('ContentIsDirty')
        ) {
          if (JSON.parse(localStorage.getItem('ContentIsDirty')) && dropdown) {
            setTarget(environment)
            setIsWarningDialogOpen(true)
          } else if (dropdown) {
            console.log('WRONG PUSH', environment)
            history.push('/' + environment)
          }
        } else if (!location.pathname.includes(environment)) {
          history.push('/' + environment)
        }
      }
    }, [environment])

    const handleChange = (event) => {
      setEnvironment(event.target.value)

      if(event.target.value === 'templateEnvironment'){
        if(!localStorage.getItem('ContentIsDirty')) {
          const fetchDocumentsThunk = fetchDocumentsV2()
          dispatch(fetchDocumentsThunk)
        } else if(localStorage.getItem('ContentIsDirty') === 'false') {
          const fetchDocumentsThunk = fetchDocumentsV2()
          dispatch(fetchDocumentsThunk)
        }
      }
      setDropdown(true)
    }

    const classes = useStyles()

    return (
      <>
        {(location.pathname.includes('projectEnvironment') ||
          location.pathname.includes('templateEnvironment')) && (
          <>
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  width: 500,
                }}
              >
                <FormControl style={{ color: 'white' }}>
                  <Select
                    className={classes.select}
                    inputProps={{
                      classes: {
                        icon: classes.icon,
                        root: classes.root,
                      },
                    }}
                    style={{ color: 'white' }}
                    labelId='demo-simple-select-standard-label'
                    id='demo-simple-select-standard'
                    value={environment}
                    onChange={handleChange}
                    label='Environment'
                  >
                    <MenuItem value='projectEnvironment'>
                      Project Environment
                    </MenuItem>
                    <MenuItem
                      value='templateEnvironment'
                    >
                      Template Environment
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div>
                <ProfileMenuItem color={color} isMain={true} />
              </div>
            </div>
          </>
        )}
      </>
    )
  }

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <AppBar
        color='transparent'
        elevation={0}
        style={{
          width: '100%',
          padding: 0,
        }}
        position='static'
      >
        <Toolbar
          style={{
            backgroundColor,
            width: '100%',
            display: 'flex',
            padding: 0,
            paddingRight: 10,
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              marginRight: 5,
              backgroundColor: 'white',
              padding: 0,
              borderRadius: 5,
            }}
          >
            <Link to='/' onClick={handleLinkClick}>
              <Logo alt='' src={logo} />
            </Link>
          </div>
          <SignIn />
          <HomeNavItems />
          <EnvironmentNavItems
            environment={environment}
            setEnvironment={setEnvironment}
            target={target}
            setTarget={setTarget}
          />
          <WarningDialog
            isOpen={isWarningDialogOpen}
            setOpen={setIsWarningDialogOpen}
            dropDown={dropdown}
            setDropDown={setDropdown}
            pathValue={target}
            setEnvironment={setEnvironment}
          />
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default MainNav

const Logo = styled.img`
  width: 100px;
`
