import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { DocumentContext } from 'shared/components/DocumentEditor/DocumentEditor'
import {useDispatch} from "react-redux";
import {fetchProjectsByUserV2} from "../../../../../redux/thunks/projectsThunk";
import {fetchDocuments} from "../../../../../redux/thunks/documentsThunk";

export type dialogProps = {
  isOpen: boolean
  setOpen: (value: boolean) => void
}

export const WarningDialog = ({ isOpen, setOpen }: dialogProps) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const context = useContext(DocumentContext)
  const { project } = context

  const handleClose = () => {
    setOpen(false)
  }

  const handleCloseandNavAway = () => {
    setOpen(false)

    if (history.location.pathname.includes('/projectEnvironment')) {
      history.push(
        '/projectEnvironment/project/' + project.id + '?section=Home'
      )
      let fetchProjThunk = fetchProjectsByUserV2(
        sessionStorage.getItem('id')
      )
      localStorage.setItem('latestChanges', 'false')
      dispatch(fetchProjThunk)
      const fetchTemplateDocs = fetchDocuments()
      dispatch(fetchTemplateDocs)
    } else {
      history.push('/templateEnvironment/?section=Documents')
    }
  }

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>{'Unpublished Changes!'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to leave? You have unpublished changes.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          Cancel
        </Button>
        <Button onClick={handleCloseandNavAway} color='primary'>
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  )
}
