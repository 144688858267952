import { DialogContent, ListItemText, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { syncDocuments } from 'redux/thunks/projectsThunk';


const SyncNewTemplateDocsDialog: React.FC<{
  open: boolean;
  setOpen: (open: boolean) => void;
  projectId: string;
}> = ({ open, setOpen, projectId }) => {
  // const classes = useStyles();

  const dispatch = useDispatch()
  const handleClose = () => {
    setOpen(false);
  };

  const syncTemplates = () => {
    //Sync code
    const syncNewDocsThunk = syncDocuments(projectId)
    dispatch(syncNewDocsThunk)
    setOpen(false);
  };

  const syncNewTemplateDocs = useSelector(
    (state: RootState) => state.projects.syncNewTemplateDocs
  );

  React.useEffect(() => {
    //
  },[syncNewTemplateDocs])

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        // classes={{ paper: classes.dialogPadding }}
      >
        <DialogTitle id="form-dialog-title">
          Sync Template Documents
        </DialogTitle>
        <DialogContent>
          {syncNewTemplateDocs && syncNewTemplateDocs.length > 0 ? (
            syncNewTemplateDocs.map((newTemplateDoc) => {
              return (
                <ListItemText primary={newTemplateDoc.name} secondary={"Author: "+newTemplateDoc.author} />
              )
            })
              // {syncNewTemplateDocs.map((newTemplateDoc) => {
              //   return (
              //     <Typography>
              //   );
              // })}
            ) : (
          <div>
            <Typography>No new documents to sync</Typography>
          </div>)}
          </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={syncTemplates} color="primary" disabled={!(syncNewTemplateDocs && syncNewTemplateDocs.length > 0)}>
            Sync
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SyncNewTemplateDocsDialog;
