import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import * as EmailValidator from 'email-validator'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setInvitationStatus } from 'redux/slices/usersSlice'
import { RootState } from 'redux/store'
import { inviteUser } from 'redux/thunks/usersSliceThunk'
import { Status } from 'shared/types/status'
import { useStyles } from './StyleInviteUserDialog'
import Loader from 'shared/components/Feedback/Loader'

type inviteUserDialogProps = {
  isOpen: boolean
  setOpen: (value: boolean) => void
}

const InviteUserDialog = ({ isOpen, setOpen }: inviteUserDialogProps) => {
  const classes = useStyles()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [firstNameError, setFirstNameError] = useState(false)
  const [lastNameError, setLastNameError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  /* const [registerUser, setRegisterUser] = useState<InviteUser | null>(null)*/
  // const [isError, setIsError] = useState(false);
  // const [isSuccess, setIsSuccess] = useState(false);

  const dispatch = useDispatch()
  // const Message = useSelector(
  //   (state: RootState) => state.users.invitationMessage
  // );
  const theInvitationStatus = useSelector(
    (state: RootState) => state.users.invitationStatus
  )

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (theInvitationStatus === Status.success) {
      // setIsSuccess(true);
      // setIsError(false);
      setFirstName('')
      setLastName('')
      setEmail('')
    } else if (theInvitationStatus === Status.failed) {
      // setIsSuccess(false);
      // setIsError(true);
    }
  }, [theInvitationStatus])

  const sendDetailsToServer = () => {
    let valid = true

    let emailValidation = EmailValidator

    if (firstName === '') {
      setFirstNameError(true)
      valid = false

      return
    }
    setFirstNameError(false)
    valid = true

    if (lastName === '') {
      setLastNameError(true)
      valid = false

      return
    }
    setLastNameError(false)
    valid = true

    if (email === '' || !emailValidation.validate(email)) {
      setEmailError(true)
      valid = false

      return
    }
    setEmailError(false)
    valid = true

    if (valid) {
      const inviteThunk = inviteUser(email)
      dispatch(inviteThunk)
      dispatch({ type: setInvitationStatus.type, payload: Status.loading })

      valid = !valid

      handleClose()
    }
  }

  function handleKeyPress (e: React.KeyboardEvent<HTMLDivElement>) {
    if ((e.key as string) === 'Enter') {
      sendDetailsToServer()
    }
  }

  return (
    <div>
      <Dialog
        aria-labelledby='form-dialog-title'
        className={classes.gridContainer}
        open={isOpen}
      >
        <DialogTitle className={classes.dialogTitle} id='form-dialog-title'>
          Invite user
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText className={classes.dialogContentText}>
            After submission of the form, the invitation will be send to email
            you provided.
          </DialogContentText>
          <form
            className={classes.form}
            noValidate
            onSubmit={sendDetailsToServer}
          >
            <Grid spacing={1} container>
              <Grid sm={6} xs={12} item>
                <TextField
                  autoComplete='fname'
                  id='firstName'
                  label='First Name'
                  name='firstName'
                  variant='outlined'
                  autoFocus
                  fullWidth
                  required
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setFirstName(event.target.value)
                  }
                  onKeyPress={handleKeyPress}
                  error={firstNameError ? true : false}
                  helperText={
                    firstNameError ? 'Please provide invitee first name' : ''
                  }
                  value={firstName}
                />
              </Grid>
              <Grid sm={6} xs={12} item>
                <TextField
                  autoComplete='lname'
                  id='lastName'
                  label='Last Name'
                  name='lastName'
                  variant='outlined'
                  fullWidth
                  required
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setLastName(event.target.value)
                  }
                  onKeyPress={handleKeyPress}
                  error={lastNameError ? true : false}
                  helperText={
                    lastNameError ? 'Please provide invitee last name' : ''
                  }
                  value={lastName}
                />
              </Grid>
              <Grid xs={12} item>
                <TextField
                  autoComplete='email'
                  id='email'
                  label='Email Address'
                  name='email'
                  variant='outlined'
                  fullWidth
                  required
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setEmail(event.target.value)
                  }
                  error={emailError ? true : false}
                  onKeyPress={handleKeyPress}
                  helperText={
                    emailError ? 'Please provide correct email format' : ''
                  }
                  value={email}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button color='primary' onClick={handleClose}>
            Cancel
          </Button>
          <span className={classes.button} />
          <Button
            color='primary'
            variant='contained'
            type='submit'
            onClick={sendDetailsToServer}
          >
            Invite
          </Button>
        </DialogActions>
      </Dialog>
      <Loader open={theInvitationStatus === Status.loading} />
      {/* {isSuccess && (
        <EPISnackbar
          message={Message ? Message : 'Invitation was sent'}
          messageType={'success'}
          isOpen={isSuccess}
          setIsOpen={setIsSuccess}
        />
      )}
      {isError && (
        <EPISnackbar
          message={Message ? Message : 'Unknown error. Email was not sent'}
          messageType={'error'}
          isOpen={isError}
          setIsOpen={setIsError}
        />
      )} */}
    </div>
  )
}

export default InviteUserDialog
