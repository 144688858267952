import { Button, DialogActions, DialogContent } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useDispatch } from 'react-redux'
import { setDocumentChanges } from 'redux/slices/documents'
import ChangeComparison from 'views/Pages/ProjectEnvironment/Home/DocumentHome/Versions/ChangeComparison'
import ChangesList from 'views/Pages/ProjectEnvironment/Home/DocumentHome/Versions/ChangesList'

function ChangesDialog(props) {
  const { onClose, selectedValue, open, oldVersion, newVersion, versionIndex, mode, oldIndex, newIndex } = props

  const dispatch = useDispatch()

  const handleClose = () => {
    onClose(selectedValue)
    dispatch({ type: setDocumentChanges.type, payload: [] })
  }

  return (
    <Dialog
      maxWidth='xl'
      onClose={handleClose}
      aria-labelledby='simple-dialog-title'
      open={open}
    >
      <DialogTitle id='simple-dialog-title'>
        {/* <Button
          onClick={() => {
            setOldChange({} as DocumentChange);
            setCurrentChange({} as DocumentChange);
            setShowChanges(false);
          }}
        >
          <BackIcon />
        </Button> */}
        {`Version ${oldIndex} and Version ${newIndex} Change History`}
      </DialogTitle>
      <DialogContent
        style={{
          width: '75vw',
        }}
      >
        {mode === 'changes' ? (
          <ChangesList version={oldVersion} />
        ) : (
          <ChangeComparison
            index={versionIndex}
            oldChange={oldVersion.changes[oldVersion.changes.length - 1]}
            curChange={newVersion.changes[newVersion.changes.length - 1]}
            oldIndex={oldIndex}
            newIndex={newIndex}
          />
        )}
      </DialogContent>
      {mode === 'changes' ? (
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      ) : (
        <DialogActions>
          <Button onClick={handleClose}>Back</Button>
        </DialogActions>
      )}
    </Dialog>
  )
}
export default ChangesDialog
