import {
  Button,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Paper,
  Switch,
} from '@material-ui/core'
import SyncIcon from '@material-ui/icons/Refresh'
import SaveIcon from '@material-ui/icons/Save'
import { Skeleton } from '@material-ui/lab'
import * as lookup from 'country-code-lookup'
import React, { useEffect } from 'react'
import ReactCountryFlag from 'react-country-flag'
import { useDispatch, useSelector } from 'react-redux'
import { setUpdateProjectStatus } from 'redux/slices/projects'
import { RootState } from 'redux/store'
import {
  editProjectOnDB,
  fetchProjectsByUserV2,
} from 'redux/thunks/projectsThunk'
import { DocumentContext } from 'shared/components/DocumentEditor/DocumentEditor'
import { SnackType } from 'shared/components/Feedback/EPISnackBar'
import Loader from 'shared/components/Feedback/Loader'
import { Status } from 'shared/types/status'
import SyncCountriesDialog from 'views/Pages/ProjectEnvironment/Home/ProjectHome/ManageCountries/SyncCountriesDialog'

import {ChapterObject, Document, DocumentChange} from "../../../../../../shared/types/document";
import {Country} from "../../../../../../shared/types/country";
import * as reduxDocumentType from "../../../../../../shared/types/document";
import {findDFSBySharedIdSyncAll, reIndexChapters} from "../../../../../../shared/components/DocumentEditor/treeUtils";
import {Project} from "../../../../../../shared/types/project";
import { cloneDeep } from 'lodash'
import {fetchDocuments} from "../../../../../../redux/thunks/documentsThunk";
import IndexedDBFunctions from "../../../../../../IndexedDBFunctions";

const ManageCountries = () => {
  const context = React.useContext(DocumentContext)
  const {
    setActiveDocument,
    setActiveCountries,
    project,
    setProject,
    setDisplaySnack,
    setSnackMessage,
    setSnackSeverity,
    setDocumentChapters,
    activeDocumentChange,
    documents,
    setCopiedDocuments,
    setActiveCountriesOnProject,
    projectDocumentsLoaded,
    setProjectDocumentsLoaded,
  } = context
  const dispatch = useDispatch()

  const projectCountries = project && project.countries ? [...project.countries] : []

  const [addedCountries, setAddedCountries] = React.useState<Country[]>([])
  const [clickedButton, setClickedButton] = React.useState(false)
  const [openSyncCountryDialog, setOpenSyncCountryDialog] = React.useState(false)
  const [busy, setBusy] = React.useState<boolean>(false)
  const [isOnSave, setIsOnSave] = React.useState<boolean>(false)
  const [localTemplateDocuments, setLocalTemplateDocuments] = React.useState<Document[]>([])

  const templateDocuments = useSelector(
      (state: RootState) => state.documents.Documents
  )

  const templateDocsLoaded = useSelector(
    (state: RootState) => state.documents.getDocumentStatus
  )

  const updateProjectStatus = useSelector(
    (state: RootState) => state.projects.updateProjectStatus
  )

  React.useEffect(() => {
    if(project && project.countries.find((country) => country.active_status) !== undefined && addedCountries.length === 0) {
      setActiveCountriesOnProject(true)
    }
  })

  useEffect(() => {
    let localDocuments: Document[] = []

    if (templateDocuments
      && templateDocuments.length > 0
      && templateDocuments.find(doc => typeof doc === 'string') === undefined) {
      templateDocuments.map(document => {
        localDocuments.push(document)
      })
    }
    setLocalTemplateDocuments(JSON.parse(JSON.stringify(localDocuments)))

    console.log('IS ON SAVE', isOnSave)

    if(clickedButton === false && isOnSave) {
      saveChanges(templateDocuments)
    }
  }, [templateDocuments])

  const onSyncCountryButtonClicked = () => {
    setOpenSyncCountryDialog(true)
  }

  const handleActivate = (event: React.ChangeEvent<HTMLInputElement>) => {
    let countries: Country [] = addedCountries
    console.log(event.target.name)
    let projectTemp = project.countries.find(
      (country) => country.country_name === event.target.name
    )
    projectTemp.active_status = !projectTemp.active_status

    if (projectTemp.active_status) {
      if(countries.find(country => country.country_name === event.target.name) === undefined){
        countries.push(projectTemp)
      } else {
        const index = countries.map(e => e.country_name).indexOf(event.target.name);
        countries.splice(index, 1)
      }
    } else {
      const index = countries.map(e => e.country_name).indexOf(event.target.name);
      countries.splice(index, 1)
    }

    setAddedCountries(countries)
    console.log('SYNC ALL - Countries', addedCountries)

    setActiveCountries(JSON.parse(JSON.stringify(project.countries)))
    setProject(JSON.parse(JSON.stringify(project)))
  }

  const isTemplateDocsLoaded = () => {
    if(templateDocsLoaded === Status.loading) {
      return false
    } else if(templateDocsLoaded === Status.success) {
      return true
    } else if(templateDocsLoaded === Status.idle) {
      return true
    }
  }

  const isActiveInCountry = (activeCountry: Country, chapter:  reduxDocumentType.ChapterObject) => {
    const section = chapter.sections[0]
    const country = section.countrySpecificContent.find(content => content.country.country_name === activeCountry.country_name)

    if(country === undefined){
      return true
    } else if(country.isChapterActiveInCountry) {
      return true
    }

    return false
  }

  const syncStructure = (
      projectDocumentChapters: ChapterObject[],
      templateDocumentChapters: ChapterObject[],
      activeCountry: Country
  ) => {
    let newChapterStructure: ChapterObject[] = []
    let disjointedChapters: ChapterObject[] = []
    projectDocumentChapters.sort((a, b) => a.chapterOrder - b.chapterOrder)
    templateDocumentChapters.sort((a, b) => a.chapterOrder - b.chapterOrder)

    templateDocumentChapters.filter((chapter) => isActiveInCountry(activeCountry, chapter) ||
        projectDocumentChapters.find(projectChapter => projectChapter.sharedId === chapter.sharedId) !== undefined)
        .map((newChapter, index) => {
          let matchingOriginalChapter = projectDocumentChapters.find(
              originalChapter => originalChapter.sharedId === newChapter.sharedId
          )

          if (matchingOriginalChapter) {
            if (!matchingOriginalChapter.refId) {
              disjointedChapters.push(matchingOriginalChapter)
              newChapter.refId = newChapter.id
              newChapter.wasChanged = true
              newChapterStructure.push(newChapter)
            } else {
              let countrySpecificName: any = ''
              newChapter.sections.map(
                  (section: reduxDocumentType.ChapterSection) => {
                    if (
                        section.countrySpecificContent &&
                        section.countrySpecificContent.length > 0
                    ) {
                      let currentCountry: reduxDocumentType.CountrySpecificContent = section.countrySpecificContent.find(
                          countryContent =>
                              countryContent.country.country_name ===
                              activeCountry.country_name
                      )

                      if (currentCountry) {
                        countrySpecificName = currentCountry.chapterCountrySpecificName
                            ? currentCountry.chapterCountrySpecificName
                            : newChapter.name
                      }
                    }
                  }
              )

              let oldCountrySpecificName: any = ''
              matchingOriginalChapter.sections.map(
                  (section: reduxDocumentType.ChapterSection) => {
                    if (
                        section.countrySpecificContent &&
                        section.countrySpecificContent.length > 0
                    ) {
                      let currentCountry: reduxDocumentType.CountrySpecificContent = section.countrySpecificContent.find(
                          countryContent =>
                              countryContent.country.country_name ===
                              activeCountry.country_name
                      )

                      if (currentCountry) {
                        oldCountrySpecificName = currentCountry.chapterCountrySpecificName
                            ? currentCountry.chapterCountrySpecificName
                            : matchingOriginalChapter.name
                      }
                    }
                  }
              )

              if (oldCountrySpecificName !== countrySpecificName) {
                matchingOriginalChapter.sections.map(section => {
                  let currentCountry = section.countrySpecificContent.find(
                      countryContent =>
                          countryContent.country.country_name ===
                          activeCountry.country_name
                  )

                  if (currentCountry) {
                    currentCountry.chapterCountrySpecificName = countrySpecificName
                    newChapter.wasChanged = true
                  }
                })
              }

              if (matchingOriginalChapter.name !== newChapter.name) {
                matchingOriginalChapter.name = newChapter.name
                newChapter.wasChanged = true
              }

              if (matchingOriginalChapter.refId !== newChapter.id) {
                matchingOriginalChapter.refId = newChapter.id
              }

              if (
                  matchingOriginalChapter.chapterOrder !== newChapter.chapterOrder
              ) {
                matchingOriginalChapter.chapterOrder = newChapter.chapterOrder
              }

              if (
                  newChapter.subchapters.length > 0 ||
                  matchingOriginalChapter.subchapters.length > 0
              ) {
                matchingOriginalChapter.subchapters = syncStructure(
                    matchingOriginalChapter.subchapters,
                    newChapter.subchapters,
                    activeCountry
                )
              }

              newChapterStructure.push(matchingOriginalChapter)
            }
          } else {
            newChapter.refId = newChapter.id

            if (newChapter.subchapters.length > 0) {
              newChapter.subchapters = syncStructure([], newChapter.subchapters, activeCountry)
            }
            newChapter.wasChanged = true
            newChapterStructure.push(newChapter)
          }
        })

    projectDocumentChapters.map(projectChapter => {
      if (!projectChapter.refId) {
        newChapterStructure.push(projectChapter)
      }
    })

    disjointedChapters.map(chapter => {
      chapter.chapterOrder = newChapterStructure.length + 1
      newChapterStructure.push(chapter)
    })

    return newChapterStructure
  }

  const syncAllContent = (newChapterStructure: ChapterObject[], activeCountry: Country, templateDocumentChange: DocumentChange) => {
    let templateChange = cloneDeep(templateDocumentChange)
    findDFSBySharedIdSyncAll(
      newChapterStructure,
      templateChange,
      templateDocumentChange.countries,
      activeCountry
    )
  }

  function isCountryActiveInTemplate (activeDocument: Document, activeCountry: Country) {
    if (localTemplateDocuments && localTemplateDocuments.length > 0) {
      let tempDoc = localTemplateDocuments.find(
        tempDoc => tempDoc.id === activeDocument.refId
      )

      if(tempDoc) {
        let tempVersion = tempDoc.documentVersions[tempDoc.documentVersions.length - 1]
        let tempChange = tempVersion.changes[tempVersion.changes.length - 1]

        if (tempChange.countries.find(country => country.country_name === activeCountry.country_name) !== undefined) {
          return true
        }
      }
    }

    return false
  }

  const saveChanges = (latestTemplateDocuments: Document[]) => {
    try {
      console.log('localTemplateDocuments', latestTemplateDocuments)
      setProjectDocumentsLoaded(false)

      if (project.countries.find(country => country.active_status) !== undefined) {
        setActiveCountriesOnProject(true)
      } else {
        setActiveCountriesOnProject(false)
      }

      if (addedCountries.length > 0) {
        addedCountries.map(country => {
          project.documents.filter(doc => doc.name !== 'Wage Type Catalogue').map(projectDocument => {
            setActiveDocument(projectDocument)

            if (latestTemplateDocuments
              && latestTemplateDocuments.length > 0
              && isCountryActiveInTemplate(projectDocument, country)
            ) {
              console.log('SYNC ALL - Country', country.country_name + ' ' + projectDocument.name)
              let tempDoc = JSON.parse(JSON.stringify(latestTemplateDocuments.find(tempDoc => tempDoc.id === projectDocument.refId)))

              if (typeof tempDoc.documentVersions[0] !== 'string') {
                let tempDocVersion = tempDoc.documentVersions[tempDoc.documentVersions.length - 1]

                if (tempDocVersion !== undefined) {
                  let tempDocChange = tempDocVersion.changes[tempDocVersion.changes.length - 1]

                  let projectDocumentVersion = projectDocument.documentVersions[projectDocument.documentVersions.length - 1]
                  let projectDocumentChange = projectDocumentVersion.changes[projectDocumentVersion.changes.length - 1]

                  const newChapterStructure = syncStructure(projectDocumentChange.chapters, tempDocChange.chapters, country)

                  reIndexChapters(newChapterStructure, 0, '')
                  syncAllContent(newChapterStructure, country, tempDocChange)
                  setDocumentChapters(
                    JSON.parse(JSON.stringify(newChapterStructure))
                  )
                  let newReduxDocumentChange: DocumentChange = {
                    ...activeDocumentChange,
                    chapters: newChapterStructure,
                    countries: tempDocChange.countries,
                    version: projectDocumentVersion.latestVersion + 1,
                    lastUpdated: new Date().toString(),
                    id: null
                  }

                  let activeDocDocumentChanges: DocumentChange[] =
                    projectDocumentVersion.changes

                  projectDocumentVersion.lastUpdated = new Date().toString()

                  if (
                    activeDocDocumentChanges[
                    activeDocDocumentChanges.length - 1
                      ].id === null
                  ) {
                    activeDocDocumentChanges[
                    activeDocDocumentChanges.length - 1
                      ] = newReduxDocumentChange
                  } else {
                    activeDocDocumentChanges.push(newReduxDocumentChange)
                  }

                  let newDocument: Document = {
                    ...projectDocument,
                    documentVersions: projectDocument.documentVersions,
                    lastUpdated: new Date().toString()
                  }
                  let docs = documents
                  let newDocs: Document[] = documents
                  console.log(newDocs)

                  let index = docs.findIndex(d => d.id === projectDocument.id)

                  if (index !== -1) {
                    newDocs[index] = newDocument
                  }

                  setCopiedDocuments(JSON.parse(JSON.stringify(newDocs)))

                  let newReduxObj: Project = {
                    ...project,
                    documents: newDocs,
                    lastUpdated: new Date().toString()
                  }
                  Object.assign(project, newReduxObj)
                }
              }
            }
          })
        })
      }
      setProject(JSON.parse(JSON.stringify(project)))
      const editProjectThunk = editProjectOnDB(project)
      dispatch(editProjectThunk)
      //localStorage.removeItem('currentDocumentEdit')
      IndexedDBFunctions.deleteDocuments()
    } catch (e) {
      //
    }
  }

  const onSave = () => {
    setBusy(true)
    dispatch(fetchDocuments())
    setIsOnSave(true)
  }

  useEffect(() => {
    if (updateProjectStatus === Status.success) {
      setSnackMessage('Project countries updated successfully')
      setSnackSeverity(SnackType.SnackSuccess as string)
      setDisplaySnack(true)
      dispatch({ type: setUpdateProjectStatus.type, payload: Status.idle })
      let fetchProjThunk = fetchProjectsByUserV2(sessionStorage.getItem('id'))
      dispatch(fetchProjThunk)
    } else if (updateProjectStatus === Status.failed) {
      dispatch({ type: setUpdateProjectStatus.type, payload: Status.idle })
    }
  }, [updateProjectStatus])

  useEffect(() => {
    if(!projectDocumentsLoaded){
      setBusy(true)
    } else if(projectDocumentsLoaded){
      setBusy(false)
    } else {
      setBusy(true)
    }
  }, [projectDocumentsLoaded])

  if (project !== undefined) {
    return (
      <>
        {busy === true && <Loader open={true} />}
        <div style={{ padding: '1em' }}>
          <Paper
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '1em',
            }}
          >
            <h3>Manage Countries</h3>
            <div>
              <Button
                style={{
                  marginLeft: '0.25em',
                }}
                variant='contained'
                color='primary'
                onClick={onSyncCountryButtonClicked}
                disabled={busy || !isTemplateDocsLoaded()}
              >
                <SyncIcon />
                &nbsp;Sync Countries
              </Button>
              <Button
                style={{
                  marginLeft: '0.25em',
                }}
                variant='contained'
                color='primary'
                onClick={async ()=> await onSave()}
                disabled={busy || !isTemplateDocsLoaded()}
              >
                <SaveIcon />
                &nbsp;Save
              </Button>
            </div>
          </Paper>
          <br />
          <Paper
            style={{
              display: 'flex',
              padding: '1em',
            }}
          >
            <List
              dense
              style={{
                width: '100%',
              }}
            >
              {projectCountries.length > 0 && projectCountries.sort((countryA, countryB) =>
                (countryA.country_name < countryB.country_name ? -1 : 1)
              ).map((country) => {
                const countryCode = lookup.byCountry(country.country_name).iso2

                return (
                  <ListItem
                    style={{
                      padding: '1em',
                      borderBottom: '1px solid rgba(0,0,0,0.15)',
                    }}
                  >
                    <ListItemText
                      primary={
                        <>
                          <ReactCountryFlag countryCode={countryCode} svg />
                          &nbsp;{country.country_name}
                        </>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={country.active_status}
                          onChange={handleActivate}
                          name={country.country_name}
                          disabled={busy || !isTemplateDocsLoaded()}
                          color='primary'
                        />
                      }
                      label={country.active_status ? 'Active' : 'Inactive'}
                    />
                  </ListItem>
                )
              })}
            </List>
          </Paper>

          <SyncCountriesDialog
            open={openSyncCountryDialog}
            setOpen={setOpenSyncCountryDialog}
            projectId={project.id}
            clickedButton={clickedButton}
            setClickedButton={setClickedButton}
            busy={busy}
            setBusy={setBusy}
          />
        </div>
      </>
    )
  }

  return (
    <>
      <Loader open={true} />

      <div
        style={{
          width: '100%',
          height: '100%',
          padding: '1em',
        }}
      >
        <Skeleton
          style={{
            margin: 0,
          }}
          variant='rect'
          width={'100%'}
          height={'100%'}
        />
      </div>
    </>
  )
}

export default ManageCountries
