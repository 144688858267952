import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { useDispatch } from 'react-redux';
import { refreshToken } from 'redux/thunks/authorisationThunk';
import useToken from 'shared/auth/useToken';
import { ContentInfo } from 'shared/types/introduction';
import styled from 'styled-components';
import Banner from './Banner/Banner';
import {
  default as HomeCards,
  default as NavigationCards,
} from './Body/DisplayerCards';
import InformationField from './Body/InformationField';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // overflow: 'hidden',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },

  cardContainer: {
    height: '100%',
    marginBottom: '15vh',
    marginRight: '15vw',
    marginLeft: '15vw',
    justifyContent: 'space-around',
  },
  card: {
    display: 'flex',
  },
  title: {
    fontSize: 14,
  },
  cardDetails: {
    flex: 1,
  },
  explanationSection: {},
}));

const Displayer: React.FC = () => {
  const token = useToken().token;

  const catchyHeading: ContentInfo = {
    content: {
      title: 'Some inspirational words that explain the need of the app',
    },
  };

  const info: ContentInfo = {
    content: {
      title:
        'A bit longer explanation of the app. What it does and why it is valuable to use it.',
      body: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ultrices enim, nunc, volutpat adipiscing nam adipiscing egestas ultrices. In amet consequat fermentum duis. Ipsum vitae lectus nulla lorem pharetra. Leo justo urna magna non. Commodo ultrices ut elementum mus in enim, sed magna. Nunc leo fermentum venenatis libero quisque convallis ac metus. Auctor vitae id lacus sed cursus sagittis, consequat amet, enim.`,
    },
  };
  const classes = useStyles();

  const dispatch = useDispatch();
  
  React.useEffect(() => {

    if ( localStorage.getItem('token') !== null ) {

      const refreshThunk =  refreshToken( localStorage.getItem('token') );
      dispatch( refreshThunk );
    }
  }, []);

  return (
    <div className={classes.root}>
      {!token && (
        <>
          <Grid spacing={0} container>
            <Banner content={catchyHeading.content} />
          </Grid>

          <Grid className={classes.explanationSection} container>
            <InformationField {...info} />
          </Grid>
          <HomeContainer>
            <HomeCards token={undefined} />
          </HomeContainer>
        </>
      )}
      {token && (
        <>
          <Grid spacing={0} container>
            <Banner content={catchyHeading.content} />
          </Grid>
          <Grid className={classes.explanationSection} container>
            <InformationField {...info} />
          </Grid>
          <HomeContainer>
            <NavigationCards token={token} />
          </HomeContainer>
        </>
      )}
    </div>
  );
};

export default Displayer;

const HomeContainer = styled.div`
  /* padding: 1em 20em; */
`;
