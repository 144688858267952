import React from 'react';
import Table from 'views/Pages/ProjectEnvironment/Home/ProjectHome/Users/UserManagement/TableLayout';
import { useStyles } from 'views/Pages/ProjectEnvironment/Shared/styles';
import {DocumentContext} from "../../../../../../shared/components/DocumentEditor/DocumentEditor";

const ProjectEnvironment: React.FC = () => {
  const classes = useStyles();
  const context = React.useContext(DocumentContext)

  const {project, setActiveCountriesOnProject} = context

  React.useEffect(() => {
    if(project && project.countries.find((country) => country.active_status) !== undefined) {
      setActiveCountriesOnProject(true)
    }
  })

  return (
    <main
      style={{
        flexGrow: 1,
        padding: '1em',
      }}
    >
      <div className={classes.root}>
        <div className={classes.container}>
          <Table />
        </div>
      </div>
    </main>
  );
};

export default ProjectEnvironment;
