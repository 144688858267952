import {
  Chip,
  FormControl,
  IconButton,
  InputAdornment,
  makeStyles,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import { fetchProjectRoles } from 'redux/thunks/projectRolesThunk'
import { fetchUsers } from 'redux/thunks/usersSliceThunk'
import { Project, ProjectRole } from 'shared/types/project'
import { User } from 'shared/types/user'

const useStyles = makeStyles((theme: Theme) => ({
  formSpan: {
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(4),
    flex: '0 1 15px',
    [theme.breakpoints.down('sm')]: {
      flex: '1 1 25ch',
    },
  },
  searchField: {
    width: '45ch',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))

export type UserProjectsProp = {
  user: User | undefined
}

export type ProjectRow = {
  project: Project
  roles?: ProjectRole[]
}

type CreateChipProp = {
  message: string
  outlined: boolean
}

const CreateChip: React.FC<CreateChipProp> = ({ message, outlined }) => {
  return (
    <span style={{ marginRight: `4px !important`, paddingRight: `4px` }}>
      <Chip
        label={message}
        variant={outlined ? 'outlined' : 'outlined'}
        color='primary'
        size='small'
      />
    </span>
  )
}

const EditUserProjects = ({ user }: UserProjectsProp) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [search, setSearch] = React.useState('')
  const [finalRows, setFinalRows] = React.useState<ProjectRow[] | undefined>([])

  const projects = useSelector((state: RootState) => state.projects.allProjects)
  // const ArrayOfRoles: Array<ProjectRole> = useSelector(
  //   (state: RootState) => state.projectRoles.roles
  // );

  const createUserProject = (user: User, project: Project) => {
    if (project) {
      if (project.users) {
        const projectUsers = project.users?.filter(
          (u) => u.user.email === user.email
        )
        const checked = projectUsers.length > 0 ? true : false

        if (checked) {
          const projectRoles: ProjectRole[] = projectUsers[0].role
          const result: ProjectRow = {
            project,
            roles: projectRoles,
          }

          return result
        }

        const result: ProjectRow = {
          project,
          roles: [],
        }

        return result
      }

      const result: ProjectRow = {
        project,
        roles: [],
      }

      return result
    }

    const result: ProjectRow = {
      project,
      roles: [],
    }

    return result
  }

  const ProjectRows = projects
    .map((project) => {
      return createUserProject(user, project)
    })
    .filter((t): t is ProjectRow => !!t)
  const [rows, setRows] = React.useState<ProjectRow[]>(ProjectRows)
  // const [finalRows, setFinalRows] = React.useState<userRow[]>(rows)

  const searchTable = () => {
    if (search.length > 0) {
      if (rows && rows.length > 0) {
        const fiteredRows = rows.filter((row, key) => {
          if (
            row.project.name.toLowerCase().includes(search.toLowerCase()) ||
            row.project.author.toLowerCase().includes(search.toLowerCase())
          )
            return row
        })
        setFinalRows(fiteredRows)
      }
    } else {
      setFinalRows(rows)
    }
  }

  // const changeChecked = (id: string) => {
  //   const tempRows = [...rows];
  //   let rowIndex = tempRows.findIndex((row) => row.project.id === id);

  //   if (rowIndex > -1) {
  //     tempRows[rowIndex].checked = !tempRows[rowIndex].checked;
  //   }
  //   setRows(tempRows);
  // };

  // const changeRole = (value: string, id: string) => {
  //   const tempRows = [...rows];
  //   let rowIndex = tempRows.findIndex((row) => row.project.id === id);

  //   let finalRole =
  //     ArrayOfRoles && ArrayOfRoles.length > 0
  //       ? ArrayOfRoles.find((r) => r.role_name === value)
  //       : {
  //           role_name: 'None',
  //           description: '',
  //           id: '0',
  //         };
  //   console.log(finalRole);

  //   if (rowIndex > -1) {
  //     tempRows[rowIndex].role = finalRole;
  //   }
  //   setRows(tempRows);
  // };

  // const rows = users.map((user) => {

  // })

  React.useEffect(() => {
    const fetchUsersThunk = fetchUsers()
    dispatch(fetchUsersThunk)
    const rolesThunk = fetchProjectRoles()
    dispatch(rolesThunk)
  }, [])

  React.useEffect(() => {
    const temp = projects
      .map((project) => {
        return createUserProject(user, project)
      })
      .filter((t): t is ProjectRow => !!t)
    setRows(temp)
  }, [projects])

  React.useEffect(() => {
    setFinalRows(rows)
  }, [rows])
  // React.useEffect(() => {
  //     setRows(userRows)
  // }, [userRows])

  React.useEffect(() => {
    searchTable()
  }, [search])

  // let roles: string[] = [];

  /* for ( let value in ProjectRoles )
  {
    let role = ProjectRoles[ value as keyof typeof ProjectRoles ]
    roles.push( role.toString() )
  } */

  return (
    <div>
      <div>
        <span className={classes.formSpan}>
          <FormControl
            size='small'
            className={classes.searchField}
            variant='outlined'
          >
            <OutlinedInput
              id='outlined-adornment-weight'
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton edge='end'>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
              aria-describedby='outlined-weight-helper-text'
              inputProps={{
                'aria-label': 'weight',
              }}
              labelWidth={0}
              placeholder='Search...'
              value={search}
              onChange={(event) => setSearch(event.target.value as string)}
            />
          </FormControl>
        </span>
      </div>
      <div>
        <TableContainer>
          <Table aria-label='a dense table'>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Author</TableCell>
                <TableCell>Last Updated</TableCell>
                <TableCell>Roles</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {finalRows ? (
                finalRows
                  .sort((a, b) => (a.project.name < b.project.name ? -1 : 1))
                  .filter((project) =>
                    project.project.users.find((usr) => usr.user.id === user.id)
                  )
                  .map((row) => (
                    <TableRow key={row.project.id}>
                      <TableCell component='th' scope='row'>
                        {row.project.name}
                      </TableCell>
                      <TableCell component='th' scope='row'>
                        {row.project.author}
                      </TableCell>
                      <TableCell>{row.project.lastUpdated}</TableCell>
                      <TableCell>
                        {row.roles.map((role) => {
                          return CreateChip({
                            message: role.role_name,
                            outlined: true,
                          })
                        })}
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <div></div>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}

export default EditUserProjects
