import React from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { isExpired } from 'react-jwt'
import { useDispatch } from 'react-redux'
import { Redirect, Route, useHistory } from 'react-router-dom'
import { logout, refreshToken } from 'redux/thunks/authorisationThunk'

const RestrictedRoute: React.FC<{
  component: React.FC
  path: string
  exact: boolean
}> = (props) => {
  // const userToken = useToken()
  // const user = useSelector((state: RootState) => state.authorisation.user);
  const history = useHistory()
  const dispatch = useDispatch()
  const localStorageToken = localStorage.getItem('token')
  const sessionToken = sessionStorage.getItem('token')
  let token = null

  if (localStorageToken) {
    token = localStorageToken
  }

  if (sessionToken) {
    token = sessionToken
  }

  const handleOnIdle = (event) => {
    // console.log('user is idle', event)
    // console.log('last active', getLastActiveTime())
    dispatch(logout())
    sessionStorage.removeItem('token')
    localStorage.removeItem('token')
    sessionStorage.removeItem('role_name')
    localStorage.removeItem('role_name')
    localStorage.clear()
    sessionStorage.clear()
    token = null
    history.push('/')
  }

  const handleOnActive = (event) => {
    //
    getRemainingTime()
    getLastActiveTime()
  }

  const handleOnAction = (event) => {
    //
  }

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
    crossTab: {
      type: 'localStorage',
      channelName: 'idle-timer',
      fallbackInterval: 2000,
      responseTime: 100,
      removeTimeout: 1000 * 60,
      emitOnAllTabs: false,
    },
  })

  React.useEffect(() => {
    if ((token && isExpired(token)) || (localStorageToken && !sessionToken)) {
      dispatch(refreshToken(token))
    }
  }, [])

  return token ? (
    <Route path={props.path} exact={props.exact} component={props.component} />
  ) : (
    <Redirect to='/signin' />
  )
}
export default RestrictedRoute
