import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputAdornment, OutlinedInput, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import React, { useContext } from "react";
import { DocumentContext } from "shared/components/DocumentEditor/DocumentEditor";
import { Filter } from "shared/types/document";




export const AddNewFilterDialog: React.FC<{
    open: boolean
    setOpen: (open: boolean) => void
    setFilter: (filter: Filter) => void
    filters: Filter[]}>
= ({
    open,
    setOpen,
    setFilter,
    filters
}) => {

    const context = useContext(DocumentContext)

    const { extractedWorksheet } = context

    const finalRows = extractedWorksheet.columns.map((column) => {
        return {
            column,
            checked: false
        }
    })
    const [filterName, setFilterName] = React.useState<string>('')
    const [rows, setRows] = React.useState<any[]>(finalRows);
    const [search, setSearch] = React.useState('');

    const searchTable = () => {
        if (search.length > 0) {
            if (finalRows && finalRows.length > 0) {
                const fiteredRows = rows.filter((row, key) => {
                if (
                    row.column.title.toLowerCase().includes(search.toLowerCase())
                )
                    return row;
                });
                setRows(fiteredRows);
            }
        } else {
        setRows(finalRows);
        }
    };

    const isDuplicateName = (): boolean => {
        return filters.find(filter => filter.name === filterName) !== undefined;
    }

    const changeChecked = (index: number) => {
        const tempRows = [...rows];
        let rowIndex = tempRows.findIndex((row) => row.column.colIndex === index);

        if (rowIndex > -1) {
            tempRows[rowIndex].checked = !tempRows[rowIndex].checked;
        }
        setRows(tempRows);
    };

    React.useEffect(() => {
        //setFinalRows(rows);
    }, [rows]);
    // React.useEffect(() => {
    //     setRows(userRows)
    // }, [userRows])

    React.useEffect(() => {
        searchTable();
    }, [search]);

    return (
        <>
            <Dialog
                open={open}
                // onClose={handleClose}
                aria-labelledby='form-dialog-title'
                fullWidth
                maxWidth='xs'
            >
            <DialogTitle id='form-dialog-title'>Save new filter</DialogTitle>
            <DialogContent>
                {/* <DialogContentText>
                            To subscribe to this website, please enter your email address here. We will send updates
                            occasionally.
                        </DialogContentText> */}
                <TextField
                autoFocus
                id='name'
                label='Please enter a name for the filter'
                type='text'
                fullWidth
                error={filterName === null || filterName.match(/^ *$/) !== null || isDuplicateName()}
                onChange={(
                    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                    setFilterName(e.target.value as string)
                }}
                />
                <div
                    style={{
                        padding: '1em 1em',
                    }}
                >
                    <span>
                        <FormControl
                            size="small"
                            fullWidth
                            variant="outlined"
                        >
                            <OutlinedInput
                            id="outlined-adornment-weight"
                            endAdornment={
                                <InputAdornment position="end">
                                <IconButton edge="end">
                                    <SearchIcon />
                                </IconButton>
                                </InputAdornment>
                            }
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                                'aria-label': 'weight',
                            }}
                            labelWidth={0}
                            placeholder="Search..."
                            value={search}
                            onChange={(event) => setSearch(event.target.value as string)}
                            />
                        </FormControl>
                    </span>
                </div>

                <TableContainer
                    style={{
                        height: '40vh',
                        paddingTop: '1em'
                    }}
                >
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Column</TableCell>
                                <TableCell align="right">Add to Filter</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {rows ? (
                            rows.map((row) => (
                            <TableRow key={row.column.colIndex}>
                                <TableCell component="th" scope="row">
                                {row.column.title}
                                </TableCell>
                                <TableCell align="right">
                                <Checkbox
                                    checked={row.checked}
                                    onChange={() =>
                                        changeChecked(row.column.colIndex ? row.column.colIndex : 0)
                                    }
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                                </TableCell>
                            </TableRow>
                            ))
                        ) : (
                            <div></div>
                        )}
                        </TableBody>
                    </Table>
                    </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setOpen(false)
                }}
                color='primary'>
                    Cancel
                </Button>
                <Button
                  disabled={filterName === null || filterName.match(/^ *$/) !== null || isDuplicateName()}
                  onClick={() => {
                    //
                    let cols = rows.filter((row) => row.checked === true).map((row) => {
                        return row.column
                    })
                    setFilter({
                        name: filterName,
                        columns: cols
                    })
                    setOpen(false)
                }} color='primary'>
                    Save
                </Button>
            </DialogActions>
            </Dialog>
        </>
    )
}
