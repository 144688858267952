import { useIndexedDB } from 'react-indexed-db';
import * as reduxDocumentType from 'shared/types/document'

const IndexedDBFunctions = {
   getDocuments() {
     const {getAll} = useIndexedDB('Documents');

     getAll().then(indexedDocs => {
       return indexedDocs
     })
  },
  addDocuments(document: reduxDocumentType.Document[]) {
    const { add } = useIndexedDB('Documents');

    add({ document}).then(
      event => {
        console.log('IndexedDB Documents have been added');
      },
      error => {
        console.log(error);
      }
    );
  },
  deleteDocuments() {
    const { clear } = useIndexedDB('Documents');
    clear().then(event => {
      console.log('IndexedDB Deleted Documents');
    });
  }
}

export default IndexedDBFunctions


