import { Divider } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 a100%',
  },
  mainDiv: {
    minHeight: '86vh',
    display: 'flex',
  },
  paperContainer: {
    display: 'flex',
    padding: theme.spacing(9, 2, 9, 2),
    justifyContent: 'center',
  },
  paper: {
    dispaly: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: theme.spacing(3),
  },
  form: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    padding: theme.spacing(3, 0),
  },
  formFields: {
    paddingTop: theme.spacing(3),
  },
  submit: {},
  gridItemPaperContainer: {
    maxWidth: '55%',
  },
  checkbox: {
    paddingTop: theme.spacing(1),
    '& > *': {
      paddingLeft: '0 !important',
    },
  },
  formLink: {
    paddingTop: theme.spacing(1),
  },
  link: {
    textDecoration: 'none !important',
    color: theme.palette.text.primary,
  },
}));

const Disclaimer = () => {
  const classes = useStyles();

  return (
    <div className={classes.mainDiv}>
      <Grid
        spacing={3}
        container
        className={classes.gridContainer}
        justify="center"
        alignItems="center"
      >
        <Grid className={classes.gridItemPaperContainer} item xs={10}>
          <Paper className={classes.paperContainer}>
            <Container maxWidth="md" component="main">
              <CssBaseline />
              <div className={classes.paper}>
                <Typography
                  style={{ textAlign: 'left' }}
                  component="h1"
                  variant="h4"
                  color="textPrimary"
                >
                  Disclaimer
                  <br />
                  <br />
                </Typography>
                <Divider variant="middle" />
                <Typography
                  style={{ textAlign: 'left' }}
                  variant="body1"
                  color="textPrimary"
                >
                  <br />
                  <p>
                    The EPI-USE Group and its related entities, herein after
                    referred to as EPI-USE, assumes no responsibility for errors
                    or omissions in these materials. THESE MATERIALS ARE
                    PROVIDED “AS IS” WITHOUT WARRANTY OF ANY KIND, EITHER
                    EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO, THE
                    IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                    PARTICULAR PURPOSE, OR NON-INFRINGEMENT. EPI-USE further
                    does not warrant the accuracy or completeness of the
                    information, text, graphics, links or other items contained
                    within these materials. EPI-USE shall not be liable in any
                    manner or to any extent for any direct, indirect, special,
                    incidental or consequential damages, or losses or expenses,
                    including but not limited to loss of good will or business
                    profits, work stoppage, data loss, computer failure or
                    malfunction, or exemplary or punitive damages, arising out
                    of the use of the information included herewith. The
                    information on this server is subject to change without
                    notice and does not represent a commitment on the part of
                    EPI-USE in the future.
                  </p>
                </Typography>
              </div>
            </Container>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default Disclaimer;

// const LogoImg = styled.img`
//   display: block;
//   margin: 0 auto;
//   margin-bottom: 2em;
// `;
