import React, { useContext } from 'react';
import { DocumentContext } from '../../DocumentEditor';

export const SyncUnableDialog: React.FC = () => {
  const context = useContext(DocumentContext);
  const {
    activeCountry,
    activeDocument,
  } = context;

  return (
    <>
      <h3 style={{ flexGrow: 1, alignItems: 'center', display: 'flex' }}>
        Unable to Sync
      </h3>
      <div
        style={{
          display: 'flex',
          height: 'auto',
          flexDirection: 'row',
          justifyContent: 'center',
          flexGrow: 1,
        }}
      >
        A Sync can not be completed. The selected country ({activeCountry.country_name})
        is no longer active in the Template environment. Either re-enable the country in the
        Template Document ({activeDocument.name}) or Sync the countries on the Project to remove
        the country ({activeCountry.country_name}).
      </div>
    </>
  );
};
