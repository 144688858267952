import { Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import RefreshIcon from '@material-ui/icons/Refresh'
import React, { useState } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { fetchUsers } from 'redux/thunks/usersSliceThunk'
import TableSelectorGroup, {
  TableSelectorGroupProp
} from './TableSelectorGroup'
import InviteUserDialog from '../Dialogs/InviteUserDialog'
import {RootState} from "../../../../../../../../redux/store";
import {Status} from "../../../../../../../../shared/types/status";

const useStyles = makeStyles((theme: Theme) => ({
  titleDiv: {
    display: 'grid',
    gridTemplateColumns: '2fr  1fr',
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column'
    }
  },
  left: {
    gridRows: '2/3'
  },
  right: {
    gridRows: '2/4',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'flex-start'
    }
  },
  formSpan: {
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(4),
    flex: '0 1 15px',
    display: 'flex',
    justifyContent: 'flex-end',
    // alignContent: 'center',
    [theme.breakpoints.down('sm')]: {
      flex: '1 1 25ch'
    }
  },
  icon1: { marginRight: '8px !important' },
  button: {}
}))
const TableHeader: React.FC<TableSelectorGroupProp> = ({
  version,
  setVersion,
  role,
  setRole,
  search,
  setSearch
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()

  const userRemoved = useSelector(
    (state: RootState) => state.users.deleteUserStatus
  )

  React.useEffect(() => {
    if(userRemoved === Status.success) {
      const fetchUsersThunk = fetchUsers()
      dispatch(fetchUsersThunk)
    }
  },[userRemoved])

  return (
    <div>
      <div
        style={{
          flexGrow: 1
        }}
      >
        <Grid container spacing={3} direction='row' alignItems='center'>
          <Grid item xs>
            <Typography variant='h5' color='textPrimary' component='h1'>
              User Management
            </Typography>
            <Typography variant='body2' color='textSecondary' component='p'>
              Invite, edit or remove users of EPI-CENTRE
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TableSelectorGroup
              version={version}
              setVersion={setVersion}
              role={role}
              setRole={setRole}
              search={search}
              setSearch={setSearch}
            />
          </Grid>
          <Grid
            container
            item
            xs
            direction='row'
            justify='flex-end'
            alignItems='center'
          >
            <Button
              className={classes.button}
              style={{
                backgroundColor: '#2c8cd8',
                color: 'white'
              }}
              startIcon={<RefreshIcon className={classes.icon1} />}
              variant='contained'
              onClick={() => {
                const fetchUsersThunk = fetchUsers()
                dispatch(fetchUsersThunk)
              }}
            >
              Refresh
            </Button>
            <Button
              style={{ marginLeft: '0.25em' }}
              color='primary'
              startIcon={<PersonAddIcon />}
              variant='contained'
              onClick={() => setOpen(true)}
            >
              Invite
            </Button>
          </Grid>
        </Grid>
      </div>
      <InviteUserDialog isOpen={open} setOpen={setOpen} />
    </div>
  )
}

export default TableHeader
