import Avatar from '@material-ui/core/Avatar'
import Fade from '@material-ui/core/Fade'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
//import MenuItem from '@material-ui/core/MenuItem'
import MuiMenuItem from "@material-ui/core/MenuItem";
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import clsx from 'clsx'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { logout } from 'redux/thunks/authorisationThunk'
import { WarningDialog } from 'views/Pages/TemplateEnvironment/Home/SideNav/WarningDialog'
import {Card, CardHeader, Tooltip, withStyles} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  avatarSpan: {
    paddingLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    backgroundColor: '#ffffff',
    color: theme.palette.getContrastText('#ffffff'),
  },
  avatarInMain: {
    backgroundColor: '#37017e',
    color: theme.palette.getContrastText('#37017e'),
  },
  avatarName: {
    minWidth: '105px',
  },
  menuItem: {
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  menuItemIcon: {
    /* marginRight: `${theme.spacing(1)}px !important`, */
  },
  showMoreMenu: {
    justifyContent: 'flex-end',
    display: 'flex',
    paddingRight: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))

const MenuItem = withStyles({
  root: {
    justifyContent: 'center',
    color: 'red'
  }
})(MuiMenuItem);

const ProfileMenuItem: React.FC<{ isMain?: boolean; color?: string }> = ({
  isMain,
  color,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [isWarningDialogOpen, setIsWarningDialogOpen] = useState<boolean>(false)
  const [dropdown, setDropdown] = useState<boolean>(false)
  const [environment, setEnvironment] = React.useState('/signin')
  const openProfileMenu = Boolean(anchorEl)

  const classes = useStyles()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const logoutButtonClicked = () => {
    if (localStorage.getItem('ContentIsDirty')) {
      if (JSON.parse(localStorage.getItem('ContentIsDirty'))) {
        setIsWarningDialogOpen(true)
      } else {
        const logoutAction = logout()
        dispatch(logoutAction)
        history.push('/signin')
      }
    } else {
      const logoutAction = logout()
      dispatch(logoutAction)
      history.push('/signin')
    }
  }

  const name: string = localStorage.getItem('firstname') as string
  const surname: string = localStorage.getItem('surname') as string
  const role: string = localStorage.getItem('role_name') as string
  const email: string = localStorage.getItem('email') as string
  const subtitle = <>{role}<br/>{email}</>

  return (
    <>
      <div style={{ display: 'flex' }}>
        {!isMain && (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              className={classes.avatarName}
              variant='body2'
              color='initial'
              component='h1'
            >
              {name} {surname}, {role}
            </Typography>
          </span>
        )}

        <React.Fragment>
          <Tooltip title={email}>
            <IconButton
              aria-label='logout'
              className={classes.showMoreMenu}
              onClick={handleClick}
              disableRipple
              disableFocusRipple
            >
              <Avatar
                className={clsx(!isMain ? classes.avatar : classes.avatarInMain)}
                alt={`${name} ${surname}`}
                src=''
              >
                {name ? name.charAt(0) : ''}
              </Avatar>
            </IconButton>
          </Tooltip>
          <Menu
            id='fade-menu'
            anchorEl={anchorEl}
            keepMounted
            open={openProfileMenu}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <Card style={{borderRadius: '0', boxShadow: 'none'}}>
              <CardHeader
                avatar={
                  <Avatar
                    className={clsx(!isMain ? classes.avatar : classes.avatarInMain)}
                    alt={`${name} ${surname}`}
                    src=''
                  >
                    {name ? name.charAt(0) : ''}
                  </Avatar>
                }
                title={`${name} ${surname}`}
                subheader={subtitle}
              >
              </CardHeader>
            </Card>
            {/*<Divider />*/}
            {/*<MenuItem>*/}
            {/*  <HomeIcon style={{marginRight: '5px'}} fontSize='small' className={classes.menuItemIcon}/>*/}
            {/*  Home*/}
            {/*</MenuItem>*/}
            {/*<MenuItem>*/}
            {/*  <LibraryBooks style={{marginRight: '5px'}} fontSize='small' className={classes.menuItemIcon}/>*/}
            {/*  Template Environment*/}
            {/*</MenuItem>*/}
            {/*<MenuItem>*/}
            {/*  <LibraryBooks style={{marginRight: '5px'}} fontSize='small' className={classes.menuItemIcon}/>*/}
            {/*  Project Environment*/}
            {/*</MenuItem>*/}
            <MenuItem onClick={logoutButtonClicked} className={classes.menuItem} style={{textAlign: 'center'}}>
              <ExitToAppIcon style={{marginRight: '5px'}} fontSize='small' className={classes.menuItemIcon} />
              Logout
            </MenuItem>
          </Menu>
        </React.Fragment>
      </div>
      <WarningDialog
        isOpen={isWarningDialogOpen}
        setOpen={setIsWarningDialogOpen}
        dropDown={dropdown}
        setDropDown={setDropdown}
        pathValue={environment}
        setEnvironment={setEnvironment}
      />
    </>
  )
}

export default ProfileMenuItem
