import Paper from '@material-ui/core/Paper'
import { makeStyles, Theme } from '@material-ui/core/styles'
import TableContainer from '@material-ui/core/TableContainer'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import { fetchProjects } from 'redux/thunks/projectsThunk'
import { fetchUsers } from 'redux/thunks/usersSliceThunk'
import Loader from 'shared/components/Feedback/Loader'
import { Status } from 'shared/types/status'
import { User } from 'shared/types/user'
import TablePaginationEPI from 'views/Pages/TemplateEnvironment/Home/TemplateHome/TablePaginationEPI'
import EPIListView from './EPIListView'
import TableBodyEPI from './TableEPI'
import Head from './TableFilters/TableHeader'
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'grid',
    gridTemplateRows: 'auto auto auto auto',
    width: '100%',
    height: 'auto',
  },
  titleContainer: {
    width: '100%',
    /* overflow: 'auto', */
  },
  bodyContainer: {
    width: '100%',
    paddingTop: theme.spacing(3),
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '0px !important',
      height: '0px !important',
      padding: '0px !important',
      visibility: 'collapse',
    },
  },
  bodyContainerMobile: {
    width: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    /*         [theme.breakpoints.between('md', 'xl')]: {
            width: '0px !important',
            height: '0px !important',
            padding: '0px !important',
            visibility: 'collapse',
        },
        [theme.breakpoints.between('xs', 'md')]: {
            width: '100%',
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
            height: '100%',
            visibility: 'visible',
        }, */
  },
  footerContainer: {
    width: '100%',
    paddingTop: theme.spacing(3),
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '0px !important',
      height: '0px !important',
      padding: '0px !important',
      visibility: 'collapse',
    },
  },
  paper: {
    padding: theme.spacing(3),
    height: 'auto',
  },
  paperBody: {
    padding: theme.spacing(3),
    height: '100%',
  },
  tableContainer: {
    /* maxHeight: '350px', */
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      minWidth: 700,
      overflowX: 'auto',
    },
    [theme.breakpoints.up('xl')]: {
      maxHeight: 'auto',
    },
  },
  tableContent: {
    [theme.breakpoints.down('sm')]: {
      visibility: 'collapse',
    },
    [theme.breakpoints.up('md')]: {
      visibility: 'visible',
    },
  },
  listContent: {
    [theme.breakpoints.between('sm', 'xl')]: {
      visibility: 'collapse',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      visibility: 'visible',
    },
  },
}))

// const createUserData = (
//   id: string | undefined,
//   enabled: boolean,
//   role_id: Role_Id,
//   firstname: string,
//   surname: string,
//   email: string,
//   last_login: string,
//   projects?: Project[]
// ): User => {
//   return { firstname, surname, email, projects, role_id, enabled, last_login };
// };

type MobileViewProp = {
  data: Array<User>
}

const MobileView: React.FC<MobileViewProp> = ({ data }) => {
  const classes = useStyles()

  return (
    <div className={classes.bodyContainerMobile}>
      <Paper className={classes.paperBody}>
        <div className={classes.listContent}>
          <EPIListView data={data} />
        </div>
      </Paper>
    </div>
  )
}

type DesktopViewProp = {
  data: Array<User> | undefined
  page: number
  rowsPerPage: number
  setPage: (num: number) => void
  setRowsPerPage: (num: number) => void
  totalRowsPerPage: number
}

const DesktopView: React.FC<DesktopViewProp> = (props) => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.bodyContainer}>
        <Paper className={classes.paperBody}>
          <div className={classes.tableContent}>
            <TableContainer className={classes.tableContainer}>
              {
                <TableBodyEPI
                  data={props.data}
                  page={props.page}
                  rowsPerPage={props.rowsPerPage}
                />
              }
            </TableContainer>
          </div>
        </Paper>
      </div>
      <div className={classes.footerContainer}>
        <Paper className={classes.paper}>
          <TablePaginationEPI
            totalRows={props.data ? props.data.length : 0}
            page={props.page}
            setPage={props.setPage}
            rowsPerPage={props.rowsPerPage}
            setRowsPerPage={props.setRowsPerPage}
            totalRowsPerPage={props.totalRowsPerPage}
          />
        </Paper>
      </div>
    </>
  )
}

const Table: React.FC = () => {
  // const theme = useTheme();
  const matches = useMediaQuery('(min-width:960px)')
  const dispatch = useDispatch()
  const rows = useSelector((state: RootState) => state.users.users)
  const totalRowsPerPage = 10
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(totalRowsPerPage)
  const classes = useStyles()
  const [project, setProject] = React.useState('')
  const [role, setRole] = React.useState('AllRoles')
  const [search, setSearch] = React.useState('')
  const [rowsData, setRowsData] = React.useState<User[]>(rows)
  const [screenWidth, setScreenWidth] = React.useState<boolean>(matches)

  const fetchingUsersStatus = useSelector(
    (state: RootState) => state.users.getUserStatus
  )

  React.useEffect(() => {
    setScreenWidth(matches)
  }, [matches])

  function isInArray(arr: User[], user: User) {
    let isIn = false

    if (arr.length > 0) {
      arr.forEach((data) => {
        if (data.id === user.id) {
          isIn = true

          return
        }
      })
    }

    return isIn
  }

  const searchProject = () => {
    if (project !== '' && project !== 'AllProjects') {
      let res: User[] = []

      if (rows && rows.length > 0) {
        rows.map((row, i) => {
          if (row.projects && row.projects.length > 0) {
            row.projects.map((proj) => {
              proj.value === project && res.push(row)
            })
          }

          if (project === 'noproj' && row.projects?.length === 0) {
            res.push(row)
          }
        })
      }

      setRowsData(res)
      setPage(0)
    } else {
      //
      setRowsData(rows)
      setPage(0)
    }
  }

  const searchRoles = () => {
    if (role !== '' && role !== 'AllRoles') {
      let res: User[] = []

      if (rows && rows.length > 0) {
        rows.map((row, i) => {
          if (row.role_id) {
            row.role_id.role_name === role &&
              !isInArray(res, row) &&
              res.push(row)
          }
        })
      }
      setRowsData(res)
    } else {
      //
      setRowsData(rows)
    }
  }

  function isMatch(str1: string | undefined, str2: string) {
    if (str1 === undefined) str1 = ''
    str1 = str1.toLowerCase()
    str2 = str2.toLowerCase()

    if (str1.indexOf(str2) === -1) return false

    return true
  }

  const searchTable = () => {
    if (search.length > 0) {
      if (rows && rows.length > 0) {
        const fiteredRows = rows.filter((row, key) => {
          if (
            isMatch(row.firstname, search) ||
            isMatch(row.surname, search) ||
            isMatch(row.email, search) ||
            isMatch(row.role_id.role_name, search)
          )
            return row
        })

        setRowsData(fiteredRows)
      }
    } else {
      setRowsData(rows)
    }
  }

  React.useEffect(() => {

    if (rows.length === 0) {
      const fetchUsersThunk = fetchUsers()
      dispatch(fetchUsersThunk)
      const fetchProjectsThunk = fetchProjects()
      dispatch(fetchProjectsThunk)
    }
  }, [])

  React.useEffect(() => {
    setRowsData(rows)
  }, [rows])

  React.useEffect(() => {
    //
    searchRoles()
  }, [role])
  React.useEffect(() => {
    //
    searchProject()
  }, [project])
  React.useEffect(() => {
    //
    searchTable()
  }, [search])

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <Paper className={classes.paper}>
          <Head
            version={project}
            setVersion={setProject}
            role={role}
            setRole={setRole}
            search={search}
            setSearch={setSearch}
          />
        </Paper>
      </div>
      {screenWidth ? (
        <DesktopView
          data={rowsData}
          page={page}
          rowsPerPage={rowsPerPage}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          totalRowsPerPage={totalRowsPerPage}
        />
      ) : (
        <MobileView data={rowsData} />
      )}
      <Loader open={fetchingUsersStatus === Status.loading} />
    </div>
  )
}

export default Table
