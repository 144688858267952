import Chip from '@material-ui/core/Chip'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import VisibilityIcon from '@material-ui/icons/Visibility'
import React from 'react'
import { useSelector } from 'react-redux'
import { getUsers } from 'redux/slices/usersSlice'
import { Project } from 'shared/types/project'
import { User } from 'shared/types/user'
import ViewUserInfoDialog from './Dialogs/EditUserDialog/EditUserDialog'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      /* maxWidth: 360, */
      backgroundColor: theme.palette.background.paper,
      position: 'relative',
      overflow: 'auto',
      maxHeight: 350
    },
    listSection: {
      backgroundColor: 'inherit'
    },
    ul: {
      backgroundColor: 'inherit',
      padding: 0
    },
    subheaderStyle: {
      color: theme.palette.primary.main,
      fontSize: '1.2rem',
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      justifyContent: 'space-between'
    },
    nested: {
      paddingLeft: theme.spacing(4)
    },
    chips: {
      '& > *': {
        marginRight: `${theme.spacing(0.5)}px !important`
      }
    },
    spacer: {
      paddingRight: theme.spacing(8)
    }
  })
)

type CreateChipProp = {
  message: string
  outlined: boolean
}

const CreateChip: React.FC<CreateChipProp> = ({ message, outlined }) => {
  const classes = useStyles()

  return (
    <span className={classes.chips}>
      <Chip
        label={message}
        variant={outlined ? 'outlined' : 'default'}
        color='primary'
        size='small'
      />
    </span>
  )
}

type NestedlistProps = {
  projects: Project[] | undefined
}
const NestedList: React.FC<NestedlistProps> = ({ projects }) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  // const [openInnerList, setOpenInnerList] = React.useState(false);

  const handleClick = () => {
    setOpen(!open)
  }

  // const handleClickForInnerList = () => {
  //   setOpenInnerList(!openInnerList);
  // };

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemText primary='Projects:' />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout='auto' unmountOnExit>
        {projects && projects.length > 0 ? (
          projects.map((project, key) => (
            <>
              <ListItem>
                <ListItemText secondary={project.name} />
                {/*  {openInnerList ? <ExpandLess /> : <ExpandMore />} */}
              </ListItem>
              {/* <Collapse in={true} timeout="auto" unmountOnExit>
                                {project.roles &&
                                    project.roles.length > 0 &&
                                    project.roles.map((role, key) => (
                                        <List component="div" disablePadding>
                                            <ListItem button className={classes.nested}>
                                                {<CreateChip message={role.role_name} outlined={false} />}
                                            </ListItem>
                                        </List>
                                    ))}
                            </Collapse> */}
            </>
          ))
        ) : (
          <List component='div' disablePadding>
            <ListItem button className={classes.nested}>
              <CreateChip message='no projects' outlined={true} />
            </ListItem>
          </List>
        )}
      </Collapse>
    </>
  )
}

type EPIListProp = {
  data: User[]
}

const EPIListView: React.FC<EPIListProp> = ({ data }) => {
  const classes = useStyles()
  const users: User[] = useSelector(getUsers)
  const [openViewDialog, setOpenViewDialog] = React.useState(false)
  const [user, setUser] = React.useState<User | undefined>(undefined)

  function getUserInfo (email = '0') {
    let user = users.find(u => {
      return u.email === email && u
    })

    user !== undefined ? setUser(user) : setUser(undefined)

    return null
  }

  return (
    <>
      <List className={classes.root} subheader={<li />}>
        {data &&
          data.length > 0 &&
          data.map((d, index) => (
            <li key={`section-${index}`} className={classes.listSection}>
              <ul className={classes.ul}>
                <ListSubheader className={classes.subheaderStyle}>
                  {`${d.firstname} ${d.surname}`}
                  <span className={classes.spacer}></span>
                  <IconButton
                    edge='end'
                    size='small'
                    onClick={() => {
                      getUserInfo(d.email)
                      setOpenViewDialog(true)
                    }}
                  >
                    <VisibilityIcon fontSize='small' />
                  </IconButton>
                </ListSubheader>
                <ListItem key={`item-${index}-${d.email}`}>
                  <ListItemText primary='Email: ' secondary={`${d.email}`} />
                </ListItem>
                <NestedList projects={d.projects} />
              </ul>
            </li>
          ))}
      </List>
      {user && (
        <ViewUserInfoDialog
          isOpen={openViewDialog}
          setOpen={setOpenViewDialog}
          user={user}
        />
      )}
    </>
  )
}

export default EPIListView
