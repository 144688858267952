import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { cloneDeep } from 'lodash'
import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'
import { fetchProjectRoles } from 'redux/thunks/projectRolesThunk'
import { editProjectOnDB } from 'redux/thunks/projectsThunk'
import { DocumentContext } from 'shared/components/DocumentEditor/DocumentEditor'
import { Project, ProjectRole, ProjectUserRole } from 'shared/types/project'
import { User } from 'shared/types/user'
import EditUserAccessManagement, {
  CheckboxRoleCheck
} from './EditUserAccessManagement'

export type UserRowData = {
  id?: string
  firstname: string
  surname: string
  email: string
  roles: ProjectRole[]
  /* role_name: string */
  views: string
}

export type dialogProps = {
  isOpen: boolean
  setOpen: (value: boolean) => void
  user: UserRowData
  currentProject: Project
}

function mapUserRowDataToProjectUserRole (data: UserRowData, user: User) {
  return {
    user: { ...user },
    role: [...data.roles]
  } as ProjectUserRole
}

// function mapProjectUserRoleToUserRowData(
//   user: UserRowData,
//   roles: ProjectRole[]
// ) {
//   return {
//     id: user.id,
//     firstname: user.firstname,
//     surname: user.surname,
//     email: user.email,
//     roles,
//     views: '',
//   } as UserRowData;
// }

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {},
  dialogTitle: { padding: theme.spacing(5, 5, 0, 5) },
  dialogContent: {
    /* overflow: 'hidden', */
  },
  dialogContentText: { padding: theme.spacing(0, 5, 5, 2) },
  dialogActions: { padding: theme.spacing(5, 6, 5, 5) },
  button: {
    paddingLeft: '2rem'
  }
}))

const EditUserInfoDialog: React.FC<{
  isOpen: boolean
  setOpen: (value: boolean) => void
  userData: UserRowData
  setUserData: (userData: UserRowData) => void
  updateRows: () => void
  setIsSuccess: (s: boolean) => void
}> = ({ isOpen, setOpen, userData, setUserData, updateRows, setIsSuccess }) => {
  // const history = useHistory();
  const context = useContext(DocumentContext)
  const { project, setProject } = context

  // function getProjectId() {
  //   let p1 = history.location.pathname.replace('/project/', '');
  //   let p2 = p1.substring(0, p1.indexOf('/'));

  //   return p2;
  // }

  // const projectId = getProjectId();

  const currentProject = project

  const [firstName, setFirstName] = React.useState<string | undefined>(
    userData && userData.firstname ? userData.firstname : undefined
  )
  const [lastName, setLastName] = React.useState<string | undefined>(
    userData && userData.surname ? userData.surname : undefined
  )

  const user = project.users.find(userRole => userRole.user.id === userData.id)
    .user
  const [state, setState] = React.useState<CheckboxRoleCheck[]>([])

  React.useEffect(() => {
    // let fetchProjThunk = fetchProjectsByUser(sessionStorage.getItem('id'));
    // dispatch(fetchProjThunk);
    // const fetchUsersThunk = fetchUsers();
    // dispatch(fetchUsersThunk);
  }, [])

  // React.useEffect(() => {

  // }, [currentProject])

  React.useEffect(() => {
    if (isOpen) {
      setFirstName(
        userData && userData.firstname ? userData.firstname : undefined
      )
      setLastName(userData && userData.surname ? userData.surname : undefined)
    }
  }, [isOpen])

  React.useEffect(() => {
    // loop
  }, [userData])

  const handleCloseOnSave = () => {
    updateRows()
    setIsSuccess(true)
    //setState([]);
    setOpen(false)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const classes = useStyles()
  const dispatch = useDispatch()

  const onSaveButtonClicked = () => {
    let newProjectUserRole: ProjectUserRole = mapUserRowDataToProjectUserRole(
      userData,
      user
    )

    let currentProjUsers: ProjectUserRole[] =
      currentProject && currentProject.users
        ? cloneDeep(currentProject.users)
        : []

    if (currentProjUsers && currentProjUsers.length > 0) {
      let flagFound = false
      currentProjUsers.map(p => {
        if (p.user.id === newProjectUserRole.user.id) {
          flagFound = true
          p.role = newProjectUserRole.role
        }
      })

      if (!flagFound) {
        currentProjUsers.push(newProjectUserRole)
      }
    } else {
      currentProjUsers = []
      currentProjUsers.push(newProjectUserRole)
    }

    let newProject: Project = {
      ...currentProject,
      users: currentProjUsers
    }

    // dispatch( editProject( newProject ) )

    const editUserOnDBThunk = editProjectOnDB(newProject)
    dispatch(editUserOnDBThunk)
    //updateRows()
    setProject(newProject)

    updateRows()

    const rolesThunk = fetchProjectRoles()
    dispatch(rolesThunk)

    // dispatch(setUpdateProjectStatus(Status.idle))
    // dispatch(setUpdateProjectMessage(''))

    handleCloseOnSave()
  }

  return (
    <div>
      <Dialog
        aria-labelledby='form-dialog-title'
        className={classes.gridContainer}
        open={isOpen}
        // onClose={handleCloseOnSave}
      >
        <DialogTitle className={classes.dialogTitle} id='form-dialog-title'>
          {`Edit ${firstName} ${lastName} roles`}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <EditUserAccessManagement
            currentProjectUserData={userData}
            setCurrentProjectUserData={setUserData}
            state={state}
            setState={setState}
          />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button color='primary' onClick={handleClose}>
            Cancel
          </Button>
          <span className={classes.button} />
          <Button
            color='primary'
            variant='contained'
            onClick={onSaveButtonClicked}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default EditUserInfoDialog
