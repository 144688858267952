import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
} from '@material-ui/core'
import DocumentIcon from '@material-ui/icons/Assignment'
import ExitIcon from '@material-ui/icons/ExitToApp'
import VersionIcon from '@material-ui/icons/History'
import HomeIcon from '@material-ui/icons/Home'
import CountryIcon from '@material-ui/icons/Language'
import UsersIcon from '@material-ui/icons/PeopleAlt'
import GeneralIcon from '@material-ui/icons/Settings'
import SectionsIcon from '@material-ui/icons/ViewHeadline'
import { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import { getProjectUserRolesById } from 'redux/slices/projects'
import { RootState } from 'redux/store'
import {buildRoleStr, fetchProjectsByUserV2} from 'redux/thunks/projectsThunk'
import { DocumentContext } from 'shared/components/DocumentEditor/DocumentEditor'
import { WarningDialog } from 'views/Pages/ProjectEnvironment/Home/SideNav/WarningDialog'
import {fetchDocuments} from "../../../../../redux/thunks/documentsThunk";

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export const SideNav = () => {
  const history = useHistory()
  let query = useQuery()
  const section = query.get('section')
  const context = useContext(DocumentContext)
  const { project, activeDocument, activeCountry, projectDocumentsLoaded } = context

  function getProjectId() {
    let p1 = history.location.pathname.replace(
      '/projectEnvironment/project/',
      ''
    )
    let p2 = p1.substring(0, p1.indexOf('/'))

    if (p2 === '') {
      if (p1 === '/projectEnvironment' || p1 === '/projectEnvironment/') {
        return undefined
      }

      return p1
    }

    return p2
  }

  const dispatch = useDispatch()

  const userProjectRoles = useSelector((state: RootState) =>
    getProjectUserRolesById(state, getProjectId(), sessionStorage.getItem('id'))
  )

  // const fetchingProjectStatus = useSelector(
  //   (state: RootState) => state.projects.getProjectStatus
  // )

  const [showList, setShowList] = useState<string>(
    sessionStorage.getItem('project_roles') !== null &&
      (sessionStorage.getItem('project_roles').includes('Super User') ||
        sessionStorage.getItem('project_roles').includes('Project Manager'))
      ? 'listItemsForProject'
      : sessionStorage.getItem('project_roles') !== null &&
        sessionStorage.getItem('project_roles').includes('Lead Consultant')
      ? 'listItemsForProjectLeadConsultant'
      : 'listItemsForProjectOthers'
  )

  useEffect(() => {
    if (userProjectRoles) {
      let userProjectRoleThunk = buildRoleStr(userProjectRoles)
      dispatch(userProjectRoleThunk)
      setShowList(
        sessionStorage.getItem('project_roles') !== null &&
          (sessionStorage.getItem('project_roles').includes('Super User') ||
            sessionStorage.getItem('project_roles').includes('Project Manager'))
          ? 'listItemsForProject'
          : sessionStorage.getItem('project_roles') !== null &&
            sessionStorage.getItem('project_roles').includes('Lead Consultant')
          ? 'listItemsForProjectLeadConsultant'
          : 'listItemsForProjectOthers'
      )
    }
  }, [userProjectRoles])

  const [isWarningDialogOpen, setIsWarningDialogOpen] = useState<boolean>(false)

  const ProjectHomeLinks = () => {
    if (
      history.location.pathname.includes('/project/') &&
      !history.location.pathname.includes('/document/')
    ) {
      return (
        <>
          <ListItem
            button
            selected={section === 'Home'}
            onClick={() => {
              history.push('?section=Home')
            }}
            disabled={!projectDocumentsLoaded}
          >
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary='Project Home' />
          </ListItem>
          {(showList === 'listItemsForProject' ||
            showList === 'listItemsForProjectLeadConsultant') && (
            <ListItem
              button
              selected={section === 'Users'}
              onClick={() => {
                history.push('?section=Users')
              }}
              disabled={!projectDocumentsLoaded}
            >
              <ListItemIcon>
                <UsersIcon />
              </ListItemIcon>
              <ListItemText primary='Manage  Users' />
            </ListItem>
          )}
          {(showList === 'listItemsForProject' ||
            showList === 'listItemsForProjectLeadConsultant') && (
            <ListItem
              button
              selected={section === 'Countries'}
              onClick={() => {
                history.push('?section=Countries')
              }}
              disabled={!projectDocumentsLoaded}
            >
              <ListItemIcon>
                <CountryIcon />
              </ListItemIcon>
              <ListItemText primary='Manage Countries' />
            </ListItem>
          )}
          <ListItem
            button
            onClick={() => {
              history.push('/projectEnvironment')
            }}
            disabled={!projectDocumentsLoaded}
          >
            <ListItemIcon>
              <ExitIcon style={{ color: 'red' }} />
            </ListItemIcon>
            <ListItemText style={{ color: 'red' }} primary='Leave Project' />
          </ListItem>
        </>
      )
    }

    return null
  }

  const DocumentHomeLinks = () => {
    if (history.location.pathname.includes('/document/')) {
      return (
        <>
          <ListItem
            button
            selected={section === 'Content'}
            onClick={() => {
              history.push('?section=Content')
            }}
            disabled={!projectDocumentsLoaded}
          >
            <ListItemIcon>
              <DocumentIcon />
            </ListItemIcon>
            <ListItemText primary='Manage Content' />
          </ListItem>

          {activeDocument.name !== 'Wage Type Catalogue' &&
            showList === 'listItemsForProject' && (
              <>
                <ListItem
                  button
                  selected={section === 'Sections'}
                  onClick={() => {
                    history.push('?section=Sections')
                  }}
                  disabled={!projectDocumentsLoaded}
                >
                  <ListItemIcon>
                    <SectionsIcon />
                  </ListItemIcon>
                  <ListItemText primary='Manage Sections' />
                </ListItem>

                <ListItem
                  button
                  selected={section === 'General'}
                  onClick={() => {
                    history.push('?section=General')
                  }}
                  disabled={!projectDocumentsLoaded}
                >
                  <ListItemIcon>
                    <GeneralIcon />
                  </ListItemIcon>
                  <ListItemText primary='View Information' />
                </ListItem>
                <ListItem
                  button
                  selected={section === 'Versions'}
                  onClick={() => {
                    history.push('?section=Versions')
                  }}
                  disabled={!projectDocumentsLoaded}
                >
                  <ListItemIcon>
                    <VersionIcon />
                  </ListItemIcon>
                  <ListItemText primary='Manage Versions' />
                </ListItem>
              </>
            )}

          <ListItem
            button
            onClick={() => {
              if (localStorage.getItem('ContentIsDirty')) {
                if (JSON.parse(localStorage.getItem('ContentIsDirty'))) {
                  setIsWarningDialogOpen(true)
                } else {
                  if (activeCountry) {
                    history.push(
                      '/projectEnvironment/project/' +
                        project.id +
                        '/country/' +
                        activeCountry.country_name +
                        '?section=Home'
                    )
                  } else {
                    history.push(
                      '/projectEnvironment/project/' +
                        project.id +
                        '?section=Home'
                    )
                  }
                }
              } else {
                if (activeCountry) {
                  history.push(
                    '/projectEnvironment/project/' +
                      project.id +
                      '/country/' +
                      activeCountry.country_name +
                      '?section=Home'
                  )
                  let fetchProjThunk = fetchProjectsByUserV2(
                    sessionStorage.getItem('id')
                  )
                  localStorage.setItem('latestChanges', 'false')
                  dispatch(fetchProjThunk)
                  const fetchTemplateDocs = fetchDocuments()
                  dispatch(fetchTemplateDocs)
                } else {
                  history.push(
                    '/projectEnvironment/project/' +
                      project.id +
                      '?section=Home'
                  )
                  let fetchProjThunk = fetchProjectsByUserV2(
                    sessionStorage.getItem('id')
                  )
                  localStorage.setItem('latestChanges', 'false')
                  dispatch(fetchProjThunk)
                  const fetchTemplateDocs = fetchDocuments()
                  dispatch(fetchTemplateDocs)
                }
              }
            }}
            disabled={!projectDocumentsLoaded}
          >
            <ListItemIcon>
              <ExitIcon style={{ color: 'red' }} />
            </ListItemIcon>
            <ListItemText style={{ color: 'red' }} primary='Leave Document' />
          </ListItem>
        </>
      )
    }

    return null
  }

  return (
    <>
      <Paper
        style={{
          flex: 1,
          height: '100%',
          backgroundColor: 'white',
          width: '100%',
        }}
      >
        {project !== undefined && (
          <div
            style={{
              width: '100%',
              textAlign: 'center',
              zIndex: 100001,
              padding: '1em',
              paddingBottom: 0,
            }}
          >
            Active Project: <b>{project.name}</b>
          </div>
        )}
        <List component='nav' aria-label='main mailbox folders'>
          <DocumentHomeLinks />
          <ProjectHomeLinks />
        </List>
      </Paper>
      <WarningDialog
        isOpen={isWarningDialogOpen}
        setOpen={setIsWarningDialogOpen}
      />
    </>
  )
}
