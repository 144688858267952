import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import { setDocumentChanges, setDocumentVersions } from 'redux/slices/documents'
import {
  getProjectById,
  getProjectDocuments,
  getProjectUserRolesById,
} from 'redux/slices/projects'
import { RootState } from 'redux/store'
import { fetchDocuments } from 'redux/thunks/documentsThunk'
import {
  buildRoleStr,
  fetchDocumentOnProject,
  fetchProjectsByUserV2,
} from 'redux/thunks/projectsThunk'
import {
  DocumentContext,
  DocumentContextType,
} from 'shared/components/DocumentEditor/DocumentEditor'
import { DocumentToolbar } from 'shared/components/DocumentEditor/DocumentToolbar/DocumentToolbar'
import {
  findDFSBySharedId,
  findDFSByTreeIndexString,
  reIndexChapters,
} from 'shared/components/DocumentEditor/treeUtils'
import EPISnackBar, { SnackType } from 'shared/components/Feedback/EPISnackBar'
import Loader from 'shared/components/Feedback/Loader'
import { Country } from 'shared/types/country'
import * as reduxDocumentType from 'shared/types/document'
import { ChapterSection, Filter } from 'shared/types/document'
import { Project } from 'shared/types/project'
import { DocumentHomeContent } from 'views/Pages/ProjectEnvironment/Home/Content/DocumentHomeContent'
import { ProjectHomeContent } from 'views/Pages/ProjectEnvironment/Home/Content/ProjectHomeContent'
import { MyProjects } from 'views/Pages/ProjectEnvironment/Home/MyProjects/MyProjects'
import { SideNav } from 'views/Pages/ProjectEnvironment/Home/SideNav/SideNav'
import * as XLSX from 'xlsx'
import IndexedDBFunctions from "../../../../IndexedDBFunctions";
import {useIndexedDB} from "react-indexed-db";

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export const ProjectEnvironmentHome: React.FC<{
  children?: React.ReactNode
}> = ({ children }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  let query = useQuery()
  const section = query.get('section')
  console.log(section)

  function getProjectId() {
    let p1 = history.location.pathname.replace(
      '/projectEnvironment/project/',
      ''
    )
    let p2 = p1.substring(0, p1.indexOf('/'))

    if (p2 === '') {
      if (p1 === '/projectEnvironment' || p1 === '/projectEnvironment/') {
        return undefined
      }

      return p1
    }

    return p2
  }

  function getDocId() {
    let p1 = history.location.pathname.split('/document/')[1]

    if (p1 === undefined) {
      return null
    }

    let p2 = p1.split('/country/')[0]

    return p2
  }

  function getCountry() {
    let p1 = history.location.pathname.split('/country/')[1]
    console.log(p1)

    return p1 as undefined as string
  }

  const userProjectRoles = useSelector((state: RootState) =>
    getProjectUserRolesById(state, getProjectId(), sessionStorage.getItem('id'))
  )

  const [contentIsDirty, setContentIsDirty] = React.useState(false)
  const [sections, setSections] = React.useState<ChapterSection[]>([])
  const projectId: string = getProjectId()

  const project = useSelector((state: RootState) =>
    getProjectById(state, projectId)
  )

  const [copiedProject, setCopiedProject] = React.useState<Project>(project)

  //const [docs, setDocs] = React.useState<reduxDocumentType.Document[]>(project ? project.documents : []);
  const documents = useSelector((state: RootState) =>
    getProjectDocuments(state, projectId)
  )
  const templateDocuments = useSelector(
    (state: RootState) => state.documents.Documents
  )
  const [copiedDocuments, setCopiedDocuments] = React.useState<
    reduxDocumentType.Document[]
  >([] as reduxDocumentType.Document[])

  const [activeDocumentIndex, setActiveDocumentIndex] = React.useState(null)
  const [activeCountryIndex, setActiveCountryIndex] = React.useState(0)
  const [activeCountry, setActiveCountry] = React.useState<Country>(
    {} as Country
  )
  const [activeChapter, setActiveChapter] =
    React.useState<reduxDocumentType.ChapterObject>(
      {} as reduxDocumentType.ChapterObject
    )
  const [activeDocument, setActiveDocument] =
    React.useState<reduxDocumentType.Document>({} as reduxDocumentType.Document)
  const [numberOfEdits, setNumberOfEdits] =
    React.useState<number>(0)
  const [documentChapters, setDocumentChapters] = React.useState<
    Array<reduxDocumentType.ChapterObject>
  >([])
  const [activeDocumentVersions, setActiveDocumentVersions] = React.useState<
    Array<reduxDocumentType.DocumentVersion>
  >({} as Array<reduxDocumentType.DocumentVersion>)
  const [activeDocumentVersion, setActiveDocumentVersion] =
    React.useState<reduxDocumentType.DocumentVersion>(
      {} as reduxDocumentType.DocumentVersion
    )

  const [activeDocumentChange, setActiveDocumentChange] =
    React.useState<reduxDocumentType.DocumentChange>(
      {} as reduxDocumentType.DocumentChange
    )
  const [activeCountries, setActiveCountries] = React.useState<Array<Country>>()

  const [extractedWorksheet, setExtractedWorksheet] =
    React.useState<reduxDocumentType.WageCatalogueSheet>(null)
  const [uploadedFile, setUploadedFile] = React.useState<File>(null)
  const [wb, setWb] = React.useState<XLSX.WorkBook>(null)
  const [cachedDocuments, setCachedDocuments] = React.useState(null)

  const [selectedFilter, setSelectedFilter] = React.useState<Filter>({
    name: null,
    columns: null,
  })

  const [displaySnack, setDisplaySnack] = React.useState<boolean>(false)
  const [snackSeverity, setSnackSeverity] = React.useState<SnackType>(null)
  const [snackMessage, setSnackMessage] = React.useState<string>('')
  const [displayLoader, setDisplayLoader] = React.useState<boolean>(false)
  const [activeCountriesOnProject, setActiveCountriesOnProject] = React.useState<boolean>(false)
  const [projectDocumentsLoaded, setProjectDocumentsLoaded] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (templateDocuments.length === 0) {
      const getAllDocuments = async (): Promise<void> => {
        await dispatch(fetchDocuments())
      }
      getAllDocuments().then(() => {
        //
      })
    }

    if (project === undefined) {
      const getProjects = async (): Promise<void> => {
        await dispatch(fetchProjectsByUserV2(sessionStorage.getItem('id')))
      }
      getProjects().then(() => {
        //
      })
    }

    const {getAll} = useIndexedDB('Documents');

    getAll().then(docs => {
      if(docs[0]) {
        setCachedDocuments(docs[0].document);
      }
    });

    console.log('Waiting')

    // setActiveCountries(obj.countries)
    // setSections(obj.sections)
  }, [])

  React.useEffect(() => {
    if (
      project !== undefined &&
      project.documents.find((doc) => typeof doc !== 'string') === undefined
    ) {
      setProjectDocumentsLoaded(false)
      project.documents.map((doc) => {
        //
        console.log('THUNK TRIGGERED')
        const fetchDocumentThunk = fetchDocumentOnProject(
          doc as unknown as string,
          project.id
        )
        dispatch(fetchDocumentThunk)
      })
    }
  }, [project])

  React.useEffect(() => {
    if (userProjectRoles) {
      let userProjectRoleThunk = buildRoleStr(userProjectRoles)
      dispatch(userProjectRoleThunk)
    }
  }, [userProjectRoles])

  /**
   * Effect that changes the initial state of the system when documents are first fetched
   * Logic:
   * 1. Pull the ID of the document from the URL
   * 2. Use that to find the current active document (i.e. Business Blueprint)
   * 3. Find the latest version of the selected document
   * 4. Find the latest change on the selected document
   * 5. Find the country specific content for the currently selected country on the change
   *    5.1 change > chapters > sections > countrySpecificContent > clientContent | nonClientContent
   */
  React.useEffect(() => {
    if (documents !== undefined && documents.length > 0) {
      if(project.documents.find(document => typeof document === 'string')){
        console.log('Documents Loading busy')
      } else {
        setProjectDocumentsLoaded(true)
      }

      if (cachedDocuments !== null && cachedDocuments !== undefined && cachedDocuments.length !== 0) {
        setCopiedDocuments(cachedDocuments)
      } else {
        setCopiedDocuments(JSON.parse(JSON.stringify(documents)))
      }

      setCopiedProject(JSON.parse(JSON.stringify(project)))
    }
  }, [documents])

  function checkActiveDocumentCountry(
    latestChange: reduxDocumentType.DocumentChange
  ) {
    if (getCountry() !== undefined) {
      console.log('ACTIVECOUNTRY FOUND')
      let tempCountry = copiedProject.countries.find(
        (country) => country.country_name === getCountry()
      )
      let tempCountryIndex = copiedProject.countries.findIndex(
        (country) => country.country_name === getCountry()
      )
      setActiveCountry(
        tempCountry !== undefined ? tempCountry : copiedProject.countries[0]
      )
      setActiveCountryIndex(tempCountryIndex !== -1 ? tempCountryIndex : 0)
    } else {
      console.log('ACTIVECOUNTRY DEFAULT')

      if(!activeCountry.active_status) {
        const firstActiveCountry = copiedProject.countries.findIndex((country) => country.active_status)
        let countryIndex = firstActiveCountry === -1 ? 0 : firstActiveCountry
        setActiveCountry(copiedProject.countries[countryIndex])
        setActiveCountryIndex(countryIndex)
      }
    }
  }

  React.useEffect(() => {
    if (
      copiedProject !== undefined &&
      copiedProject.countries !== undefined &&
      copiedProject.countries.length
    ) {
      setActiveCountries(copiedProject.countries)
    }

    if (copiedDocuments !== undefined && copiedDocuments.length > 0) {
      const currentDocumentID: string = getDocId()

      copiedDocuments.forEach((doc) => {
        if (doc.documentVersions) {
          const docVersion =
            doc.documentVersions[doc.documentVersions.length - 1]
          const docChange = docVersion.changes[docVersion.changes.length - 1]
          reIndexChapters(docChange.chapters, 0, '')
        }
      })

      let activeDocument = copiedDocuments.find(
        (doc) => doc.id === currentDocumentID
      )

      if (activeDocument === undefined) {
        activeDocument = copiedDocuments[0]
      }

      if (activeDocument !== undefined && typeof activeDocument !== 'string') {
        const activeDocIndex = copiedDocuments.findIndex(
          (doc) => doc.id === currentDocumentID
        )
        const activeDocumentVersion =
          activeDocument.documentVersions[
            activeDocument.documentVersions.length - 1
          ]
        const latestChange =
          activeDocumentVersion.changes[
            activeDocumentVersion.changes.length - 1
          ]

        setActiveDocumentVersions(activeDocument.documentVersions)
        setActiveDocumentVersion(activeDocumentVersion)
        setActiveDocument(activeDocument)
        setDocumentChapters(latestChange?.chapters)

        if (localStorage.getItem('activeChapterIndexString') !== null) {
          let currentActiveChapterTreeIndex = localStorage.getItem('activeChapterIndexString')

          let findDFSResult = findDFSByTreeIndexString(
            latestChange.chapters,
            currentActiveChapterTreeIndex
          )

          if (findDFSResult === undefined) {
            findDFSResult = latestChange.chapters[0]
          }
          setActiveChapter(findDFSResult)
        }
        setActiveCountries(copiedProject.countries)

        checkActiveDocumentCountry(latestChange)

        setActiveDocumentChange(latestChange)
        setSections(latestChange?.sections)
        setActiveDocumentIndex(activeDocIndex)
      }
    }
  }, [copiedDocuments])

  React.useEffect(() => {
    if (copiedDocuments !== undefined && copiedDocuments.length > 0) {
      let activeDocument = copiedDocuments[activeDocumentIndex]
      console.log('activeDocument', activeDocument)

      if (activeDocument === undefined) {
        activeDocument = copiedDocuments[0]
      }

      if (activeDocument !== undefined) {
        const activeDocumentVersion =
          activeDocument.documentVersions[
            activeDocument.documentVersions.length - 1
          ]
        const latestChange =
          activeDocumentVersion.changes[
            activeDocumentVersion.changes.length - 1
          ]

        if (activeChapter !== undefined && activeChapter.sharedId !== null) {
          let currentActiveChapterSharedId = activeChapter.sharedId
          let findDFSResult = findDFSBySharedId(
            latestChange.chapters,
            currentActiveChapterSharedId
          )

          if (findDFSResult === undefined) {
            findDFSResult = latestChange.chapters[0]
          }
          setActiveChapter(findDFSResult)
        } else {
          setActiveChapter(latestChange.chapters[0])
        }

        const activeDocIndex = copiedDocuments.findIndex(
          (doc) => doc.id === activeDocument.id
        )

        setActiveDocumentVersions(activeDocument.documentVersions)
        setActiveDocumentVersion(activeDocumentVersion)
        setActiveDocument(activeDocument)
        setDocumentChapters(latestChange.chapters)
        setActiveCountries(copiedProject.countries)
        setActiveDocumentChange(latestChange)
        setActiveDocumentIndex(activeDocIndex)

        checkActiveDocumentCountry(latestChange)
      }
    }

    dispatch({ type: setDocumentVersions.type, payload: [] })
    dispatch({ type: setDocumentChanges.type, payload: [] })
  }, [activeDocumentIndex])

  React.useEffect(() => {
    if (copiedDocuments !== undefined && copiedDocuments.length > 0) {
      let activeDocument = copiedDocuments[activeDocumentIndex]
      console.log('activeDocument', activeDocument)

      if (activeDocument === undefined) {
        activeDocument = copiedDocuments[0]
      }

      if (activeDocument !== undefined) {
        const activeDocumentVersion =
          activeDocument.documentVersions[
            activeDocument.documentVersions.length - 1
          ]
        const latestChange =
          activeDocumentVersion.changes[
            activeDocumentVersion.changes.length - 1
          ]

        if (activeChapter !== undefined) {
          let currentActiveChapterTreeIndex = activeChapter.sharedId
          let findDFSResult = findDFSBySharedId(
            latestChange.chapters,
            currentActiveChapterTreeIndex
          )

          if (findDFSResult === undefined) {
            findDFSResult = latestChange.chapters[0]
          }
          setActiveChapter(findDFSResult)
        }

        const activeDocIndex = copiedDocuments.findIndex(
          (doc) => doc.id === activeDocument.id
        )

        setActiveDocumentVersions(activeDocument.documentVersions)
        setActiveDocumentVersion(activeDocumentVersion)
        setActiveDocument(activeDocument)
        setDocumentChapters(latestChange.chapters)
        setActiveCountries(copiedProject.countries)
        setActiveDocumentChange(latestChange)
        setActiveDocumentIndex(activeDocIndex)

        checkActiveDocumentCountry(latestChange)
      }
    }

    if (!history.location.pathname.includes('/document/')) {
      //localStorage.removeItem('currentDocumentEdit')
      IndexedDBFunctions.deleteDocuments()
      localStorage.removeItem('activeChapterIndex')
      localStorage.removeItem('ContentIsDirty')
      localStorage.removeItem('DocumentEdits')
    }
  }, [history.location.pathname])

  React.useEffect(() => {
    if (activeChapter.treeIndexString !== undefined) {
      localStorage.setItem(
        'activeChapterIndexString',
        activeChapter.treeIndexString
      )
    }
  }, [activeChapter])

  React.useEffect(() => {
    if (displayLoader) {
      setTimeout(() => {
        setDisplayLoader(false)
      }, 1000)
    }
  }, [displayLoader])

  React.useEffect(() => {
    console.log('countries changed')
  }, [])

  const contextPopulate: DocumentContextType = {
    mode: 'project',
    project: copiedProject,
    documents: copiedDocuments,
    activeCountryIndex,
    activeDocumentIndex,
    documentChapters,
    activeCountry,
    activeChapter,
    activeDocument,
    activeCountries,
    contentIsDirty,
    activeDocumentVersions,
    activeDocumentChange,
    activeDocumentVersion,
    sections,
    uploadedFile,
    activeCountriesOnProject,
    projectDocumentsLoaded,
    wb,
    extractedWorksheet,
    selectedFilter,
    displaySnack,
    snackSeverity,
    snackMessage,
    numberOfEdits,
    setSections,
    setActiveDocumentVersion,
    setActiveDocumentChange,
    setActiveDocumentVersions,
    setContentIsDirty,
    setActiveCountries,
    setActiveDocumentIndex,
    setActiveCountryIndex,
    setActiveDocument,
    setActiveChapter,
    setActiveCountry,
    setDocumentChapters,
    setUploadedFile,
    setWb,
    setExtractedWorksheet,
    setSelectedFilter,
    setCopiedDocuments,
    setProject: setCopiedProject,
    setDisplaySnack,
    setSnackSeverity,
    setSnackMessage,
    setDisplayLoader,
    setActiveCountriesOnProject,
    setProjectDocumentsLoaded,
    setNumberOfEdits,
  }

  return (
    <DocumentContext.Provider value={contextPopulate}>
      <div
        style={{
          display: 'flex',
          flex: 1,
          height: '100%',
          flexDirection: 'column',
        }}
      >
        <Loader open={displayLoader} />
        {getProjectId() !== undefined && (
          <div style={{ flex: 0.05, position: 'relative', zIndex: 1 }}>
            <DocumentToolbar />
          </div>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flex: 0.95,
            position: 'relative',
            zIndex: 0,
            maxHeight: '88vh',
          }}
        >
          {section !== undefined && section !== null && (
            <div
              style={{
                width: '15%',
              }}
            >
              <SideNav />{' '}
            </div>
          )}

          <div
            style={{
              width: '100%',
            }}
          >
            {section === undefined || (section === null && <MyProjects />)}
            <DocumentHomeContent />
            <ProjectHomeContent />
          </div>
        </div>
      </div>
      {displaySnack && (
        <EPISnackBar
          message={snackMessage}
          messageType={snackSeverity as string}
          isOpen={displaySnack}
          setIsOpen={setDisplaySnack}
        />
      )}
    </DocumentContext.Provider>
  )
}
