import axios from 'axios'
import { setRegistrationMessage, setRegistrationStatus } from 'redux/slices/registration'
import { Status } from 'shared/types/status'
import '../../config'
import config from '../../config'
import type { EPIDispatch, RootState } from '../store'

// interface RegisterUser {
//     firstName?: string
//     lastName?: string
//     email?: string
//     password?: string
// }

export function registerUser(email: string, password: string, surname: string, firstname: string) {
    return async function inviteUser(dispatch: EPIDispatch, getState: RootState) {
        axios
            .post(`${config.apiGateway.URL}/auth/register`, {
                password,
                surname,
                firstname,
                email,
            })
            .then(function (response) {
                const attributes = response.data.message as string
                dispatch({ type: setRegistrationStatus.type, payload: Status.success })
                dispatch({ type: setRegistrationMessage.type, payload: attributes })
            })
            .catch(function (error) {
                dispatch({ type: setRegistrationStatus.type, payload: Status.failed })

                if (error.response !== undefined) {
                    if (error.response.data !== undefined)
                        dispatch({ type: setRegistrationMessage.type, payload: error.response.data.message })
                } else dispatch({ type: setRegistrationMessage.type, payload: 'Error. Something went wrong' })
            })
    }
}
