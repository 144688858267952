import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import { Project } from 'shared/types/project';

export type formProp = {
  project: Project | undefined;
  projectName: string | undefined;
  setProjectName: (name: string) => void;
  author: string | undefined;
  setAuthor: (author: string) => void;
  projectNameError: boolean;
  setProjectNameError: (projectNameError: boolean) => void;
  authorError: boolean;
  setAuthorError: (authorError: boolean) => void;
  projectNameHelperText: string;
  authorHelperText: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: '100%',
    padding: theme.spacing(0, 0, 0, 0),
  },
  EPIGridSpacingLeft: {
    paddingLeft: '0px !important',
  },
  EPIGridSpacingLeftMobile: {
    paddingLeft: '0px !important',
  },
}));

const EditProjectDialogInfo = ({
  projectName,
  setProjectName,
  author,
  setAuthor,
  projectNameError,
  setProjectNameError,
  authorError,
  setAuthorError,
  projectNameHelperText,
  authorHelperText,
}: formProp) => {
  const classes = useStyles();

  return (
    <form className={classes.form} noValidate>
      <Grid spacing={3} container>
        <Grid className={classes.EPIGridSpacingLeft} sm={6} xs={12} item>
          <TextField
            autoComplete="projectName"
            id="projectName"
            label="Project Name"
            name="projectName"
            variant="outlined"
            fullWidth
            required
            value={projectName}
            error={projectNameError}
            helperText={projectNameHelperText}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setProjectName(event.target.value)
            }
          ></TextField>
        </Grid>
        <Grid className={classes.EPIGridSpacingLeft} sm={6} xs={12} item>
          <TextField
            autoComplete="author"
            id="author"
            label="Author"
            name="author"
            variant="outlined"
            value={author}
            fullWidth
            required
            error={authorError}
            helperText={authorHelperText}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setAuthor(event.target.value)
            }
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default EditProjectDialogInfo;
