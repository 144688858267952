import { Button, DialogActions, DialogContent } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import ChangeComparison from 'views/Pages/TemplateEnvironment/Home/DocumentHome/Versions/ChangeComparison';

function ChangesDialog(props) {
  const { onClose, selectedValue, open, prevChange, currentChange, index } =
    props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog
      maxWidth='xl'
      onClose={handleClose}
      aria-labelledby='simple-dialog-title'
      open={open}
    >
      <DialogTitle id='simple-dialog-title'>Change History</DialogTitle>
      <DialogContent
        style={{
          width: '75vw',
        }}
      >
        <ChangeComparison
          oldChange={prevChange}
          curChange={currentChange}
          index={index}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
export default ChangesDialog;
