import {
  Checkbox,
  FormControl,
  IconButton,
  InputAdornment,
  ListItemText,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import { fetchProjectRoles } from 'redux/thunks/projectRolesThunk'
import { fetchUsers } from 'redux/thunks/usersSliceThunk'
import { Project, ProjectRole } from 'shared/types/project'
import { User } from 'shared/types/user'

const useStyles = makeStyles((theme: Theme) => ({
  formSpan: {
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(4),
    flex: '0 1 15px',
    [theme.breakpoints.down('sm')]: {
      flex: '1 1 25ch',
    },
  },
  searchField: {
    width: '45ch',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))

export type projectUsersProp = {
  project: Project | undefined
  finalRows: userRow[] | undefined
  setFinalRows: (value: userRow[]) => void
}

export type userRow = {
  user: User
  checked: boolean
  roles?: string[]
}

const EditProjectUsers = ({
  project,
  finalRows,
  setFinalRows,
}: projectUsersProp) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [search, setSearch] = React.useState('')
  const users = useSelector((state: RootState) => state.users.users)
  const ArrayOfRoles: Array<ProjectRole> = useSelector(
    (state: RootState) => state.projectRoles.roles
  )

  const createProjectUser = (user: User, project: Project | undefined) => {
    if (project) {
      if (project.users) {
        const projectUsers = project.users?.filter(
          (u) => u.user.email === user.email
        )
        const checked = projectUsers.length > 0 ? true : false

        if (checked) {
          const projectRole: ProjectRole[] = projectUsers[0].role
          let roleNames: string[] = projectRole.map((role) => {
            return role.role_name
          })
          const result: userRow = {
            user,
            checked,
            roles: roleNames,
          }

          return result
        }

        const result: userRow = {
          user,
          checked,
          roles: [],
        }

        return result
      }

      const result: userRow = {
        user,
        checked: false,
        roles: [],
      }

      return result
    }

    const result: userRow = {
      user,
      checked: false,
      roles: [],
    }

    return result
  }

  const userRows = users
    .map((user) => {
      return createProjectUser(user, project)
    })
    .filter((t): t is userRow => !!t)
  const [rows, setRows] = React.useState<userRow[]>(userRows)
  // const [finalRows, setFinalRows] = React.useState<userRow[]>(rows)

  const searchTable = () => {
    if (search.length > 0) {
      if (rows && rows.length > 0) {
        const fiteredRows = rows.filter((row, key) => {
          if (
            row.user.firstname.toLowerCase().includes(search.toLowerCase()) ||
            row.user.surname.toLowerCase().includes(search.toLowerCase()) ||
            row.user.email.toLowerCase().includes(search.toLowerCase())
          )
            return row
        })
        setFinalRows(fiteredRows)
      }
    } else {
      setFinalRows(rows)
    }
  }

  const changeChecked = (id: string) => {
    const tempRows = [...rows]
    let rowIndex = tempRows.findIndex((row) => row.user.id === id)

    if (rowIndex > -1) {
      tempRows[rowIndex].checked = !tempRows[rowIndex].checked
    }
    setRows(tempRows)
  }

  const handleChange = (value: string[], id: string) => {
    const tempRows = [...rows]
    let rowIndex = tempRows.findIndex((row) => row.user.id === id)

    if (rowIndex > -1) {
      tempRows[rowIndex].roles = value
    }
    setRows(tempRows)
  }

  // const changeRole = (value: string, id: string) => {
  //   const tempRows = [...rows];
  //   let rowIndex = tempRows.findIndex((row) => row.user.id === id);
  //
  //   let finalRole =
  //     ArrayOfRoles && ArrayOfRoles.length > 0
  //       ? ArrayOfRoles.find((r) => r.role_name === value)
  //       : {
  //           role_name: 'None',
  //           description: '',
  //           id: '0',
  //         };
  //   // console.log(finalRole);
  //
  //   if (rowIndex > -1) {
  //     tempRows[rowIndex].role = finalRole;
  //   }
  //   setRows(tempRows);
  // };

  // const rows = users.map((user) => {

  // })

  React.useEffect(() => {
    const fetchUsersThunk = fetchUsers()
    dispatch(fetchUsersThunk)
    const rolesThunk = fetchProjectRoles()
    dispatch(rolesThunk)
  }, [])

  React.useEffect(() => {
    const temp = users
      .map((user) => {
        return createProjectUser(user, project)
      })
      .filter((t): t is userRow => !!t)
    setRows(temp)
  }, [users])

  React.useEffect(() => {
    setFinalRows(rows)
  }, [rows])
  // React.useEffect(() => {
  //     setRows(userRows)
  // }, [userRows])

  React.useEffect(() => {
    searchTable()
  }, [search])

  // let roles: string[] = [];

  /* for ( let value in ProjectRoles )
  {
    let role = ProjectRoles[ value as keyof typeof ProjectRoles ]
    roles.push( role.toString() )
  } */

  return (
    <div>
      <div>
        <span className={classes.formSpan}>
          <FormControl
            size='small'
            className={classes.searchField}
            variant='outlined'
          >
            <OutlinedInput
              id='outlined-adornment-weight'
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton edge='end'>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
              aria-describedby='outlined-weight-helper-text'
              inputProps={{
                'aria-label': 'weight',
              }}
              labelWidth={0}
              placeholder='Search...'
              value={search}
              onChange={(event) => setSearch(event.target.value as string)}
            />
          </FormControl>
        </span>
      </div>
      <div>
        <TableContainer>
          <Table size='small' aria-label='a dense table'>
            <TableHead>
              <TableRow>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Add to Project</TableCell>
                <TableCell>Roles</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {finalRows ? (
                finalRows
                  .sort((a, b) =>
                    a.user.firstname < b.user.firstname ? -1 : 1
                  )
                  .map((row) => (
                    <TableRow key={row.user.id}>
                      <TableCell component='th' scope='row'>
                        {row.user.firstname}
                      </TableCell>
                      <TableCell component='th' scope='row'>
                        {row.user.surname}
                      </TableCell>
                      <TableCell>{row.user.email}</TableCell>
                      <TableCell>
                        <Checkbox
                          checked={row.checked}
                          onChange={() =>
                            changeChecked(row.user.id ? row.user.id : '')
                          }
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      </TableCell>
                      <TableCell>
                        <Select
                          multiple
                          renderValue={(selected) =>
                            (selected as string[]).join(', ')
                          }
                          value={row.roles}
                          onChange={(event) => {
                            handleChange(
                              event.target.value as string[],
                              row.user.id ? row.user.id : ''
                            )
                          }}
                          inputProps={{
                            name: 'age',
                            id: 'age-native-simple',
                          }}
                        >
                          {ArrayOfRoles &&
                            ArrayOfRoles.length > 0 &&
                            ArrayOfRoles.map((role) => (
                              <MenuItem
                                key={role.role_name}
                                value={role.role_name}
                              >
                                <Checkbox
                                  checked={
                                    row.roles.find(
                                      (r) => r === role.role_name
                                    ) !== undefined
                                  }
                                />
                                <Tooltip
                                  title={role.description}
                                  placement='bottom-end'
                                >
                                  <ListItemText primary={role.role_name} />
                                </Tooltip>
                              </MenuItem>
                            ))}
                        </Select>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <div></div>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}

export default EditProjectUsers
