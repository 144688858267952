import React, { useContext } from 'react';
import { DocumentContext } from '../../DocumentEditor';
// import {useSelector} from "react-redux";
// import {RootState} from "redux/store";

export const SyncAllDialog: React.FC = () => {
  const context = useContext(DocumentContext);
  const {
    //documentChapters,
    activeDocument,
    //activeCountryIndex,
    //activeChapter
  } = context;

  // const templateDocuments = useSelector(
  //   (state: RootState) => state.documents.Documents
  // )
  // const templateDocument = templateDocuments.find(
  //   tempDoc => tempDoc.name === activeDocument.name
  // )
  // const templateDocumentVersion =
  //   templateDocument.documentVersions[
  //   templateDocument.documentVersions.length - 1
  //     ]
  // const templateDocumentChange =
  //   templateDocumentVersion.changes[templateDocumentVersion.changes.length - 1]
  return (
    <>
      <h3 style={{ flexGrow: 1, alignItems: 'center', display: 'flex' }}>
        Syncing: {activeDocument.name} - Structure and Content (Global and
        Country)
      </h3>
      <div
        style={{
          display: 'flex',
          height: 'auto',
          flexDirection: 'row',
          justifyContent: 'center',
          flexGrow: 1,
        }}
      >
        This will Sync the structure with the Template document and Sync the
        Global and Country content for all Chapters. Are you sure you want to
        continue with the Sync All of {activeDocument.name}?
      </div>
    </>
  );
};
