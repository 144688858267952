import { Button, ButtonBase, Paper, Typography } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import imageLogo from 'media/logo.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getProjects } from 'redux/slices/projects';
import { RootState } from 'redux/store';
import { fetchDocuments } from 'redux/thunks/documentsThunk';
import { fetchProjectsByUserV2 } from 'redux/thunks/projectsThunk';
import Loader from 'shared/components/Feedback/Loader';
import { Project } from 'shared/types/project';
import { Status } from 'shared/types/status';

export const MyProjects = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const fetchingProjectsStatus = useSelector(
    (state: RootState) => state.projects.getProjectStatus
  );
  const projects: Project[] = useSelector(getProjects);
  const openProjectEditor = (name: any, role_id: string) => {
    history.push(`/projectEnvironment/project/${role_id}?section=Home`);
  };

  const user =
    localStorage.getItem('firstname') + ' ' + localStorage.getItem('surname');

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <h2>Welcome to EPI-Centre, {user}!</h2>
      {fetchingProjectsStatus === Status.loading ? (
        <h3>Loading projects you're assigned to...</h3>
      ) : (
        <h3>Select a Project to begin</h3>
      )}

      <div
        style={{
          width: '50%',
          textAlign: 'center',
          padding: '1em',
          borderBottom: '1px solid rgba(0,0,0,0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <Button
            style={{
              backgroundColor: '#2c8cd8',
              color: 'white',
            }}
            variant='contained'
            startIcon={<RefreshIcon />}
            onClick={() => {
              let fetchProjThunk = fetchProjectsByUserV2(
                sessionStorage.getItem('id')
              );
              dispatch(fetchProjThunk);
              const fetchTemplateDocs = fetchDocuments();
              dispatch(fetchTemplateDocs);
            }}
          >
            Refresh
          </Button>
        </div>
      </div>
      <div
        style={{
          padding: '1em',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        {projects &&
          projects.length > 0 &&
          (fetchingProjectsStatus === Status.success ||
            fetchingProjectsStatus === Status.idle) &&
          projects.map((project) => (
            <div
              style={{
                padding: '1em',
              }}
            >
              <ButtonBase
                onClick={() => openProjectEditor(project.name, project.id)}
              >
                <Paper
                  key={project.id}
                  elevation={2}
                  style={{
                    padding: '1em',
                    height: 300,
                    width: 200,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div>
                    <div>
                      <img src={imageLogo} alt='' />
                    </div>
                    <Typography component='h1' variant='h6' color='primary'>
                      <div>Project:</div> {project.name}
                    </Typography>
                    {/* <Divider /> */}
                  </div>

                  {/* <Button
                    onClick={() => openProjectEditor(project.name, project.id)}
                    className={classes.button1}
                    color="primary"
                    variant="text"
                    fullWidth
                  >
                    Go to project
                  </Button> */}
                </Paper>
              </ButtonBase>
            </div>
          ))}
        {(!projects || projects.length === 0) && (
          <Typography component='h1' variant='h6' color='primary'>
            {fetchingProjectsStatus !== Status.loading && (
              <div>No projects assigned</div>
            )}
          </Typography>
        )}
      </div>
      <Loader open={fetchingProjectsStatus === Status.loading} />
    </div>
  );
};
