import { Paper } from '@material-ui/core';
import React from 'react';

const UneditableComponent: React.FC<{ content: string }> = ({ content }) => {

  const setHtml = () => {
    return {__html:content}
  }
  
  return (
    <Paper
      style={{
        width: '100%',
        height: '100%',
        padding: '24px',
      }}
      dangerouslySetInnerHTML={setHtml()}
      elevation={0}
    />
  );
};

export default UneditableComponent;
