import Paper from '@material-ui/core/Paper'
import { cloneDeep } from 'lodash'
import React from 'react'
import { ComparisonChapterNavigation } from 'shared/components/DocumentEditor/ChapterNavigation/ComparisonChapterNavigation'
import { reIndexChapters } from 'shared/components/DocumentEditor/treeUtils'
import { ChapterObject, DocumentChange } from 'shared/types/document'
import { ComparisonSections } from 'views/Pages/ProjectEnvironment/Home/DocumentHome/Versions/ComparisonSections'
export type ComparisonContextType = {
  prevChange: DocumentChange
  currentChange: DocumentChange
  prevChangeActiveChapter: ChapterObject
  currentChangeActiveChapter: ChapterObject
  viewToggle: boolean
  setPrevChange: React.Dispatch<React.SetStateAction<DocumentChange>>
  setCurrentChange: React.Dispatch<React.SetStateAction<DocumentChange>>
  setPrevChangeActiveChapter: React.Dispatch<
    React.SetStateAction<ChapterObject>
  >
  setCurrentChangeActiveChapter: React.Dispatch<
    React.SetStateAction<ChapterObject>
  >
  setViewToggle: React.Dispatch<React.SetStateAction<boolean>>
}

export const ComparisonContext = React.createContext<ComparisonContextType>(
  {} as ComparisonContextType
)

const getHR = () => (
  <div
    style={{
      width: '100%',
      height: '1px',
      border: 'none',
      opacity: 0.1,
      backgroundColor: '#001744',
    }}
  />
)

// const stringifyDoc = (doc: any) => {
//   return JSON.stringify(doc, null, 2);
// };

const ChangeComparison: React.FC<{
  oldChange: DocumentChange
  curChange: DocumentChange
  index?: number
  oldIndex?: number
  newIndex?: number
}> = ({ oldChange, curChange , oldIndex, newIndex}) => {
  const [prevChange, setPrevChange] = React.useState<DocumentChange>(
    cloneDeep(oldChange)
  )
  const [currentChange, setCurrentChange] = React.useState<DocumentChange>(
    cloneDeep(curChange)
  )
  prevChange.chapters = prevChange.chapters.sort(
    (a, b) => a.chapterOrder - b.chapterOrder
  )
  currentChange.chapters = currentChange.chapters.sort(
    (a, b) => a.chapterOrder - b.chapterOrder
  )
  reIndexChapters(currentChange.chapters, 0, '')
  reIndexChapters(prevChange.chapters, 0, '')
  const [prevChangeActiveChapter, setPrevChangeActiveChapter] =
    React.useState<ChapterObject>(prevChange ? prevChange.chapters[0] : null)
  const [currentChangeActiveChapter, setCurrentChangeActiveChapter] =
    React.useState<ChapterObject>(
      currentChange ? currentChange.chapters[0] : null
    )
  const [viewToggle, setViewToggle] = React.useState<boolean>(false)

  const contextPopulate = {
    prevChange,
    currentChange,
    prevChangeActiveChapter,
    currentChangeActiveChapter,
    viewToggle,
    setPrevChange,
    setCurrentChange,
    setPrevChangeActiveChapter,
    setCurrentChangeActiveChapter,
    setViewToggle,
  }

  return (
    <ComparisonContext.Provider value={contextPopulate}>
      <div
        style={{
          // padding: '1em',
          width: '100%',
          margin: '0 auto',
        }}
      >
        <div>
          <h3>Version Content Comparison</h3>
          <p>Old Version on the left and New Version on the right</p>
          {getHR()}
        </div>
        {getHR()}
        <br />
        <div style={{ padding: '1em' }}>
          <div
            style={{
              display: 'flex',
              backgroundColor: '#efefef',
              padding: '1em',
              justifyContent: 'space-between',
            }}
          >
            <div  style={{display: 'grid'}}>
              <p>
                <b>
                  Old
                </b>
              </p>
              <p>
                <b>
                  Version {oldIndex} <br />
                </b>
                {/* <div>
                  Author: <b>{whoPrev}</b>
                </div> */}
                <div>
                  Last Updated: <b>{oldChange.lastUpdated}</b>{' '}
                </div>
              </p>
            </div>
            <div
              style={{
                borderLeft: '1px solid #1e1e1e',
                minWidth: '1px',
                minHeight: '5px',
              }}
            ></div>
            <div style={{ display: 'grid', justifyContent: 'flex-end' }}>
              <p
                style={{
                  textAlign: 'right',
                }}>
                <b>
                  New
                </b>
              </p>
              <p
                style={{
                  textAlign: 'right',
                }}
              >
                <b style={{ textAlign: 'right', width: '100%' }}>
                  Version {newIndex} <br />
                </b>
                {/* <div>
                  Author: <b>{whoCur}</b>
                </div> */}
                <div>
                  Last Updated: <b>{currentChange.lastUpdated}</b>{' '}
                </div>
              </p>
            </div>
          </div>
        </div>
        <br />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <div style={{ width: '15%' }}>
            <ComparisonChapterNavigation
              documentChapters={prevChange.chapters}
              activeChapter={prevChangeActiveChapter}
              setActiveChapter={setPrevChangeActiveChapter}
              chapterMode={'previous'}
            />
          </div>
          <div style={{ width: '70%' }}>
            <Paper style={{ padding: '1em' }}>
              <ComparisonSections />
            </Paper>
          </div>
          <div style={{ width: '15%' }}>
            <ComparisonChapterNavigation
              documentChapters={currentChange.chapters}
              otherDocumentChapters={prevChange.chapters}
              activeChapter={currentChangeActiveChapter}
              setActiveChapter={setCurrentChangeActiveChapter}
              chapterMode={'current'}
            />
          </div>
        </div>
      </div>
    </ComparisonContext.Provider>
  )
}

export default ChangeComparison
