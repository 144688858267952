import { Button, FormControl, InputLabel, Select } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import UploadIcon from '@mui/icons-material/UploadFile'
import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'
import { editDocumentOnDB } from 'redux/thunks/documentsThunk'
import { editProjectOnDB } from 'redux/thunks/projectsThunk'
import { DocumentChange, Filter } from 'shared/types/document'
import { DocumentContext } from '../../DocumentEditor'
import { uploadWageTypeCatalogue } from '../WageTypeCatalogue/WageTypeUtils'
import { AddNewFilterDialog } from './Dialogs/AddNewFilterDialog'
import * as reduxDocumentType from "../../../../types/document";
import {Project} from "../../../../types/project";
import IndexedDBFunctions from "../../../../../IndexedDBFunctions";

export const WageTypeHeaders: React.FC<{setBusy: (busy: boolean) => void}> = ({setBusy}) => {
  const context = useContext(DocumentContext)
  const {
    activeDocumentChange,
    setActiveDocumentChange,
    setActiveDocument,
    selectedFilter,
    setSelectedFilter,
    setUploadedFile,
    setWb,
    setExtractedWorksheet,
    activeDocumentVersion,
    activeDocumentVersions,
    mode,
    project,
    activeDocument,
    documents,
    setNumberOfEdits
  } = context

  const dispatch = useDispatch()
  const [openAddNewFilterDialog, setOpenAddNewFilterDialog] =
    React.useState<boolean>()
  const [newFilter, setNewFilter] = React.useState<Filter>(null)
  const [filters, setFilters] = React.useState<Filter[]>(
    activeDocumentChange.wageDataFilters
  )
  const handleFilterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (event.target.value === 'No filter selected') {
      setSelectedFilter(null)
      // setTableColumns(columns)
    } else {
      let filter: Filter = activeDocumentChange.wageDataFilters.find(
        (c) => c.name === (event.target.value as string)
      )
      setSelectedFilter(filter)
      // setTableColumns(cloneDeep(filter.columns))
    }
  }

  React.useEffect(() => {
    if (newFilter !== null) {
      // let activeDocumentVersions = activeDocument.documentVersions;

      let newWageDataFilters: Filter[] = activeDocumentChange.wageDataFilters
        ? activeDocumentChange.wageDataFilters
        : []
      let newColumns = newFilter.columns.map((column) => {
        let col = { ...column, hidden: false }

        return col
      })
      let addedFilter: Filter = {
        name: newFilter.name,
        columns: newColumns,
      }
      console.log('NEWFILTER', addedFilter)

      newWageDataFilters.push(addedFilter)
      let newReduxObj: DocumentChange = {
        ...activeDocumentChange,
        wageDataFilters: newWageDataFilters,
        version: activeDocumentVersion.latestVersion + 1,
        id: null,
        changedBy: {
          firstname: sessionStorage.getItem('firstname'),
          id: sessionStorage.getItem('id'),
          surname: sessionStorage.getItem('surname'),
        },
      }

      // if (
      //   activeDocumentVersion.changes[activeDocumentVersion.changes.length - 1]
      //     .id === null
      // ) {
      //   activeDocumentVersion.changes[
      //     activeDocumentVersion.changes.length - 1
      //   ] = newReduxObj;
      // } else {
      //   activeDocumentVersion.changes.push(newReduxObj);
      // }

      // let newDocument: Document = {
      //   ...activeDocument,
      //   documentVersions: activeDocumentVersions,
      // };

      if (mode === 'project') {
        // let newDocs: Document[] = project.documents;
        // let index = project.documents.findIndex(
        //   (d) => d.id === activeDocument.id
        // );
        // newDocs[index] = newDocument;
        // let newReduxProject: Project = {
        //   ...project,
        //   documents: newDocs,
        //   lastUpdated: new Date().toString(),
        // };
        // console.log(newReduxProject);
        setBusy(true)
        setFilters(newWageDataFilters)
        let activeDocDocumentChanges = activeDocumentVersion.changes
        activeDocDocumentChanges.push(newReduxObj)
        let newDocument: reduxDocumentType.Document = {
          ...activeDocument,
          documentVersions: activeDocumentVersions,
          lastUpdated: new Date().toString(),
        }
        Object.assign(activeDocument, newDocument)
        setActiveDocument(newDocument)
        Object.assign(activeDocumentChange, newReduxObj)
        setActiveDocumentChange(activeDocumentChange)

        let newDocs: reduxDocumentType.Document[] = documents
        let index = documents.findIndex((d) => d.id === activeDocument.id)

        newDocs[index] = newDocument
        let newReduxProject: Project = {
          ...project,
          documents: newDocs,
          lastUpdated: new Date().toString(),
        }
        Object.assign(project, newReduxProject)

        const updateProjectThunk = editProjectOnDB(project)
        dispatch(updateProjectThunk)
        //localStorage.setItem('currentDocumentEdit', JSON.stringify(documents))

        IndexedDBFunctions.deleteDocuments()
        IndexedDBFunctions.addDocuments(documents)
        //This is just a work around as we encountered issues with Local Storage
        setNumberOfEdits(Math.random() * 1000)
      } else {
        // console.log('NEWDOC', newDocument);
        setFilters(newWageDataFilters)
        let activeDocDocumentChanges = activeDocumentVersion.changes
        activeDocDocumentChanges.push(newReduxObj)
        let newDocument: reduxDocumentType.Document = {
          ...activeDocument,
          documentVersions: activeDocumentVersions,
          lastUpdated: new Date().toString(),
        }
        Object.assign(activeDocument, newDocument)
        setActiveDocument(newDocument)
        Object.assign(activeDocumentChange, newReduxObj)
        setActiveDocumentChange(activeDocumentChange)
        const updateDocThunk = editDocumentOnDB(activeDocument)
        dispatch(updateDocThunk)
        //localStorage.setItem('currentDocumentEdit', JSON.stringify(documents))

        IndexedDBFunctions.deleteDocuments()
        IndexedDBFunctions.addDocuments(documents)
        //This is just a work around as we encountered issues with Local Storage
        setNumberOfEdits(Math.random() * 1000)
      }
    }
  }, [newFilter])

  React.useEffect(() => {
    //
  }, [filters])
  console.log('FILTERS', activeDocumentChange)

  return (
    <>
      <FormControl>
        <InputLabel>My Filters</InputLabel>
        <Select
          native
          value={
            selectedFilter && selectedFilter.name ? selectedFilter.name : null
          }
          onChange={handleFilterChange}
          inputProps={{
            name: 'age',
            id: 'age-native-simple',
          }}
        >
          <option value={null}>No filter selected</option>
          {filters &&
            filters.length > 0 &&
            filters.sort((filterA, filterB) =>
              (filterA.name < filterB.name ? -1 : 1)
            ).map((filter: Filter) => (
              <option value={filter.name}>{filter.name}</option>
            ))}
        </Select>
      </FormControl>

      <div
        style={{
          flexGrow: 1,
        }}
      />
      <div>
        <Button
          style={{
            marginLeft: '0.25em',
          }}
          variant='contained'
          color='primary'
          onClick={() => {
            setOpenAddNewFilterDialog(true)
          }}
        >
          <AddIcon></AddIcon>
          Add new filter
        </Button>
      </div>
      <div>
        <input
          accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
          id='contained-button-file'
          style={{
            display: 'none',
          }}
          type='file'
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            uploadWageTypeCatalogue(
              event,
              setUploadedFile,
              setWb,
              setExtractedWorksheet
            )
          }}
        />
        <label htmlFor='contained-button-file'>
          <Button
            style={{
              marginLeft: '0.25em',
            }}
            variant='contained'
            color='primary'
            component='span'
          >
            <UploadIcon></UploadIcon>
            Upload
          </Button>
        </label>
      </div>
      {openAddNewFilterDialog && (
        <AddNewFilterDialog
          open={openAddNewFilterDialog}
          setOpen={setOpenAddNewFilterDialog}
          setFilter={setNewFilter}
          filters={filters}
        />
      )}
    </>
  )
}
