import { useState } from 'react'

export default function useToken() {
    const getToken = () => {
        const tokenString = sessionStorage.getItem('token')

        if (tokenString) {
            // const userToken = JSON.parse(tokenString)
            return tokenString
        }

        const localToken = localStorage.getItem('token')

        if (localToken) {
            // const userToken = JSON.parse(localToken)

            return localToken
        }

        return null
    }

    const [token, setToken] = useState(getToken())

    const saveToken: (userToken: string) => void = function (userToken: any) {
        sessionStorage.setItem('token', userToken)
        localStorage.setItem('token', userToken)
        setToken(userToken.token)
    }

    return {
        setToken: saveToken,
        token,
    }
}
